import React from 'react'

const MobilePassVerification = (props) => {
    const { location } = props

    return (
        <div className='mobile-main'>
            <div className='mobile-loading' />
        </div>
    )
}

export default MobilePassVerification