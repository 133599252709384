import { ReloadOutlined } from '@ant-design/icons'
import { Button, DatePicker, Empty, Select, Space, Table, Tooltip } from 'antd'
import axios from 'axios'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { FaDirections } from 'react-icons/fa'
import { ImPushpin } from 'react-icons/im'
import { Map, MapMarker } from 'react-kakao-maps-sdk'
import { useDispatch, useSelector } from 'react-redux'
import { LoginAlert } from '..'
import { setPin } from '../../Redux/actions/userActions'
import { POST } from '../../utils/apis'
import { regions, regionsKr, regionsOnlyKr } from '../../utils/constants'
import { errorMessage, hideMiddleName, loginAlert, MyInfoAlert, stringLimiter, successMessage } from '../../utils/helpers'
import CoustomRangePicker from '../RangePicker/RengePicker'
import RegisterModal from '../TicketShareTabs/RegisterModal'
const { RangePicker } = DatePicker
const { Option } = Select
let origin = `https://securetech.vmvm.co.kr/`
if (process.env.NODE_ENV === 'development') {
    origin = `http://localhost:3000`
}
const GolfReservationHome = (props) => {
    const { user, language, history, lanKorCondition, selectedTags, ticketnameFilter, setclickedViewMore } = props
    const [dateRange1, setDateRange1] = useState([
        moment().add(-16, 'days'),
        moment()
    ])
    const pin = useSelector(state => state?.userReducer?.pin)
    const [dateRange, setDateRange] = useState({
        date1: moment().add(-16, 'days').format('YYYY-MM-DD'),
        date2: moment().format('YYYY-MM-DD')
    })
    // const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [newRegions, setNewRegions] = useState(regions[0])
    let [dataSource, setDataSource] = useState([])
    const [latitude, setLatitude] = useState(pin?.golfClub?.latitude)
    const [longitude, setLongitude] = useState(pin?.golfClub?.logitude)
    const [paymentLoading, setPaymentLoading] = useState(false)
    const [limit, setLimit] = useState(5)
    let dispatch = useDispatch()
    let geocoder = new kakao.maps.services.Geocoder()

    useEffect(() => {
        getGolfReservation()
    }, [dateRange, newRegions])

    const columns = [
        {
            title: language?.RegistrationDate,
            dataIndex: 'created',
            ellipsis: true,
            width: 110,
            render: (e) => <p style={{ width: 110 }}>{moment(e).format('YYYY-MM-DD')}</p>
        },
        {
            title: language?.Region,
            dataIndex: ['golfClub', 'region'],
            key: 'region',
            width: 180,
            ellipsis: true,
            render: (e) => <p style={{ width: 180 }}>{e ? lanKorCondition ? e :
                regionsKr[regionsOnlyKr?.indexOf(e)]?.TitleKr ?
                    regionsKr[regionsOnlyKr?.indexOf(e)]?.TitleKr :
                    regionsKr[regions?.indexOf(e)]?.TitleKr
                : '----'}</p>

        },
        {
            title: language?.GolfCourse,
            dataIndex: ['golfClub', 'clubName'],
            key: 'club',
            width: 180,
            ellipsis: true,
            render: (e) => <p style={{ width: 180 }}>{e ? e : '----'}</p>


        },
        {
            title: language?.ReservaDate,
            dataIndex: 'reservedDate',
            width: 110,
            render: (e) => <p style={{ width: 110 }}>{moment(e).add(-1, 'days').format('YYYY-MM-DD')}</p>
        },

        {
            title: '예약 일시',
            dataIndex: 'reservedTime',
            width: 110,
            render: (e) => <p style={{ width: 110 }}>{e}</p>
        },

        {
            title: language?.GreenFee,
            dataIndex: 'greenFee',
            width: 150,
            render: (e) => <p style={{ width: 150 }}>{e ? e + ' ' + language?.Won : '----'}</p>
        },
        {
            title: language?.TransferFee,
            dataIndex: 'transferFee',
            width: 150,
            render: (e) => <p style={{ width: 150 }}>{e ? e + ' ' + language?.Won : '----'}</p>

        },
        {
            title: language?.Registrant,
            // dataIndex: 'userId',
            width: 100,
            render: (e) => {
                return (
                    <p style={{ width: 100 }}>{e?.adminId ? 'admin' : hideMiddleName(e?.userId?.fullName)}</p>
                )
            }
        },
        {
            title: language?.Situation,
            dataIndex: 'reservationCompleted',
            width: 100,
            render: (e) => <p style={{ width: 100 }}> {e ? language?.completed : language?.waiting} </p>
        },
        {
            title: '',
            key: 'action',
            render: (e) => (

                <>
                    {
                        e?.pin?.includes(user?._id) ?
                            <>
                                <Space size="middle">
                                    {
                                        user?._id ?
                                            user?._id && user?.telNo && user?.bank && user?.bankAccountNumber ?
                                                <>
                                                    <Button type="primary" onClick={() => {
                                                        let id = e?._id
                                                        setPinGolfRes(id, false)
                                                        // dispatch(setPin({}))
                                                    }} shape="circle" icon={<ImPushpin />} />
                                                    <Button className='btn_inq' onClick={() => openChat(e)}>1:1 {language?.Inquiry} </Button>
                                                </>
                                                :
                                                <>
                                                    <Button type="primary" onClick={() => { MyInfoAlert(language) }} shape="circle" icon={<ImPushpin />} />
                                                    <Button className='btn_inq' onClick={() => { MyInfoAlert(language) }}>1:1 {language?.Inquiry} </Button>
                                                </>
                                            : <LoginAlert clas={'btn_inq'} buttonName={`1:1 ${language?.Inquiry}`} {...props} />
                                    }
                                </Space>
                            </> :
                            <Space size="middle">
                                {
                                    user?._id ?
                                        <>
                                            <Button onClick={() => {
                                                let id = e?._id
                                                setPinGolfRes(id, true)
                                                // dispatch(setPin({ _id: id }))
                                            }} shape="circle" icon={<ImPushpin />} />
                                            <Button className='btn_inq' onClick={() => openChat(e)}>1:1 {language?.Inquiry} </Button>
                                        </>
                                        // : <Button className='btn_inq' onClick={() => loginAlert(language)}>1:1 {language?.Inquiry} </Button>
                                        : <LoginAlert clas={'btn_inq'} buttonName={`1:1 ${language?.Inquiry}`} {...props} />

                                }
                            </Space>
                    }

                </>
            ),
        },
    ]

    const setPinGolfRes = (id, pinGolfRes) => {
        let obj = {
            userId: user?._id,
            _id: id,
            pinGolfRes: pinGolfRes,
        }
        axios.post(POST?.SET_PIN_GOLF_RES, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    // setDataSource(data?.data)
                    getGolfReservation()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    const paymentRequest = (id) => {
        setPaymentLoading(true)
        let obj = {
            buyerId: user?._id,
            golfReservationId: id
        }
        axios.post(POST?.CREATE_RESERVATION_REQUEST, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                setPaymentLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setPaymentLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    let selectePinData = dataSource && dataSource?.filter((v, i) => v?._id === pin?._id)

    let sortData = dataSource?.sort((a, b) => b?.pin?.includes(user?._id) - a?.pin?.includes(user?._id))

    const getGolfReservation = () => {
        setLoading(true)
        let obj = {
            region: newRegions,
            date1: dateRange?.date1,
            date2: dateRange?.date2,
            userId: user?._id,
        }

        axios.post(POST?.GET_ALL_GOLF_RESERVATION, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setDataSource(data?.data)
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    const handleChange = (value) => {
        setNewRegions(value)
    }

    const openMap = () => {
        window?.open(`https://map.kakao.com/link/roadview/${latitude},${longitude}`, '_blank')
    }

    const openChat = (e) => {
        // console.log('e', e?._id)
        // let receiverId = e?.userId?._id
        // let chatType = 'inquiryReservation'
        // e.chatType = chatType
        // history.push({
        //     pathname: '/chat',
        //     search: `?userId=${receiverId}&golfReservationId=${e?._id}&chatType=${chatType}`,
        //     state: e
        // })
        window.open('http://pf.kakao.com/_xdxebxjxj/chat', '_blank')
    }

    const setTopHeaderData = (record) => {
        let address = record?.golfClub?.clubAddress
        geocoder.addressSearch(address, (result, status) => {
            if (status === kakao.maps.services.Status.OK) {
                setLongitude(result[0]?.x)
                setLatitude(result[0]?.y)
            }
        })
        console.log('record', record)
        dispatch(setPin(record))
    }

    useEffect(() => {
        setDateRange({
            date1: dateRange1[0].format('YYYY-MM-DD'),
            date2: dateRange1[1].format('YYYY-MM-DD')
        })
    }, [dateRange1])

    return (
        <div className='ticket_share_panels'>
            <div className='container_tick_share'>
                {/* <Button onClick={() => dispatch(setPin({}))}>REMOVE DATA</Button> */}
                <div className='layout_tick_share'>
                    <div className='col_tick_share col_main_tick_share'>
                        <div className='left_panel'>
                            <h2 className='col_primary'>{language?.GolfReservation}</h2>
                            <p style={{ color: '#1890ff', margin: '7px 0px 0px 9px', textDecoration: 'underLine', cursor: 'pointer' }}
                                onClick={() => {
                                    setclickedViewMore(true);
                                    getGolfReservation()
                                }}> {language?.VeiwMore}</p>
                        </div>
                    </div>
                    <div className='col_tick_share col_complementary_tick_share' >
                        {
                            user?._id ?
                                <RegisterModal language={language} {...props} />
                                :
                                // <Button className='upload_sharing_btn' onClick={() => loginAlert(language)}>
                                //     {language?.RegisterGolfTicket}
                                // </Button>
                                <LoginAlert clas={'upload_sharing_btn'} buttonName={language?.RegisterGolfTicket} {...props} />

                        }
                    </div>
                </div>
            </div>

            <div className='row_golf'>
                <div className='column_golf ' >
                    <h5 className='left_golf_card'>{language?.ReservationPossible} ({dataSource?.length ? dataSource?.length : 0} {lanKorCondition ? 'Pieces' : '건'})</h5>
                    {
                        selectePinData?.length ?
                            <h5 className='' style={{ marginTop: 10 }}>
                                <div id="map" ></div>
                                <Map
                                    center={{ lat: latitude, lng: longitude }}
                                    style={{ width: "100%", height: "360px" }}
                                >
                                    <MapMarker position={{ lat: latitude, lng: longitude }}>
                                    </MapMarker>
                                </Map>
                            </h5> : <Empty />
                    }
                </div>
                <div className='column_golf '>
                    <div className='right_card_golf'>
                        <h5 style={{ textAlign: 'left' }}>{lanKorCondition ? 'address' : '주소'}</h5>
                        <span className='map_panel'>
                            <input style={{ width: '100%', border: 'none' }} value={selectePinData?.length ? selectePinData[0]?.golfClub?.clubAddress : language?.ReservationLocationDetails} disabled placeholder={language?.ReservationLocationDetails} />
                            <h4><Button loading={loading} type="text" onClick={openMap} icon={<FaDirections color='black' />} disabled={selectePinData.length ? false : true}></Button></h4>
                        </span>
                        {selectePinData?.length ?
                            selectePinData.map((v, i) => {
                                return (
                                    <>
                                        <div key={i} className='main_modal_con' style={{ padding: 10 }}>
                                            <div className='modal_first_panel purchaseDir'>
                                                <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                                    <h5>{language?.ReservedDate}</h5>
                                                </div>
                                                <div className='column-2 box'>
                                                    <p>{moment(v?.reservedDate?.date1).format('YYYY-MM-DD')}{'   '}{v?.reservedTime}</p>
                                                </div>
                                            </div>
                                            <div className='modal_first_panel purchaseDir'>
                                                <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                                    <h5>{language?.GolfClubName} </h5>
                                                </div>
                                                <div className='column-2 box'>
                                                    <p>{v?.golfClub?.clubName}</p>
                                                </div>
                                            </div>
                                            <div className='modal_first_panel purchaseDir'>
                                                <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                                    <h5>{language?.GreenFee}</h5>
                                                </div>
                                                <div className='column-2 box '>
                                                    <p>{v?.greenFee + ' ' + language?.Won}</p>

                                                </div>
                                            </div>
                                            <div className='modal_first_panel purchaseDir'>
                                                <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                                    <h5>{language?.TransferFee}</h5>
                                                </div>
                                                <div className='column-2 box'>
                                                    <p>{v?.transferFee + ' ' + language?.Won}</p>
                                                </div>
                                            </div>
                                            <div className='modal_first_panel purchaseDir'>
                                                <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                                    <h5>{language?.SellerName}</h5>
                                                </div>
                                                <div className='column-2 box'>
                                                    <p>{v?.adminId ? 'admin' : hideMiddleName(v?.userId?.fullName)}</p>
                                                </div>
                                            </div>
                                            <div className='modal_first_panel purchaseDir'>
                                                <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                                    <h5>{language?.Memo}</h5>
                                                </div>
                                                <div className='column-2 box widthh'>
                                                    <Tooltip placement='top' title={v?.memo}>
                                                        <p className="elipsis-mem0">{stringLimiter(v?.memo)}</p>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }) : <Empty />
                        }
                    </div>
                    <div className='btn_card'>
                        {user?._id && user?.telNo && user?.bank && user?.bankAccountNumber ?
                            <Button /* disabled={selectePinData[0]?.userId?._id ? false : true} */ className='btn_chat_golf' onClick={() => paymentRequest(selectePinData[0]?._id)}>
                                {lanKorCondition ? "Reserve this golf club ticket" : "골프예약 신청하기"}
                            </Button> :
                            <Button /* disabled={selectePinData[0]?.userId?._id ? false : true} */ className='btn_chat_golf' onClick={() => { MyInfoAlert(language) }}>
                                {lanKorCondition ? "Reserve this golf club ticket" : "골프예약 신청하기"}
                            </Button>}
                    </div>
                </div>
            </div>
            <div className='mid_panel_golf'>
                <div style={{ display: 'flex' }}>
                    <Select
                        defaultValue={regions[0]}
                        onChange={handleChange}
                        style={{
                            width: 'fit-content',
                            marginRight: 10
                        }}
                    >
                        {lanKorCondition ?
                            regions?.map((v, i) => (
                                <Option key={i} value={v}>{v}</Option>
                            )) :
                            regionsKr?.map((v, i) => (
                                <Option key={i} value={v?.TitleEn}>{v?.TitleKr}</Option>
                            ))
                        }
                    </Select>

                    <CoustomRangePicker format={'YYYY-MM-DD'} date={dateRange1} setDate={setDateRange1} />

                </div>
                <h3 style={{ color: '#2196f3' }}>
                    <Button loading={loading} type="text" onClick={getGolfReservation} icon={<ReloadOutlined />}>{language?.Refresh}</Button>
                </h3>
            </div>
            <div className='main_table_cont' style={{ marginTop: 20 }}>
                <Table
                    onRow={(record, rowIndex) => ({
                        onClick: () => {
                            setTopHeaderData(record)
                        },
                    })

                    }
                    rowClassName={(record, index) => {
                        return (record?._id != pin?._id ? 'table-row-light' : 'table-row-dark')
                    }}
                    className='table-striped-rows table_home'
                    columns={columns}
                    dataSource={sortData}
                    pagination={false}
                    bordered={false}
                    scroll={{ x: 800 }}
                    loading={loading}

                />
                <hr className='_hr' />
            </div>
        </div>
    )
}

export default GolfReservationHome