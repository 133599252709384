import { PlusOutlined } from '@ant-design/icons'
import InfoCircleFilled from '@ant-design/icons/InfoCircleFilled'
import { Button, Card, Input, Space, Tabs, Tag, Tooltip, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { RiSoundModuleFill } from 'react-icons/ri'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { allPaths } from '../../utils/constants'

import Header from '../Home/Header'

const { Title } = Typography;

const { Search } = Input;
const { TabPane } = Tabs;


const gridStyle = {
    width: '100%',
    textAlign: 'center',
};
const gridStyle1 = {
    width: '100%',
    textAlign: 'center',
    backgroundColor:'#F4F4F4'
};
const anotherStyle = {
    width: '100%',
    textAlign: 'center',
    innerHeight: '2px'
};


const FindID = (props) => {
    const { language, history } = props
    const [tags, setTags] = useState(['Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag',]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');
    const email = new URLSearchParams(location.search).get('email')

    const inputRef = useRef(null);
    const editInputRef = useRef(null);
    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);
    useEffect(() => {
        editInputRef.current?.focus();
    }, [inputValue]);

    const okFunc = () => {
        history.push(allPaths?.LOGIN)
    }

    return (
        <div className='home-main'>
            <Header {...props} />

            <div className='card-parent2'>
                <div className='card2'>
                    <Title level={3}>{language?.findId}</Title>
                    <br /><br /><hr style={{ border: '0.5px solid gray', backgroundColor: 'black' }} /><br />
                    <br /><br />

                    <div className='card_divs'>
                        <Card className='cards_width-find-id'>
                            <Card.Grid hoverable={false} style={gridStyle}>
                                <Title level={5}>{language?.yourIDisasfollow}</Title>
                            </Card.Grid>
                            <Card.Grid hoverable={false} style={gridStyle1}>
                                <Title level={5}>{email ? email : language?.ThereIsNoAccount}</Title>
                            </Card.Grid>
                            <br />
                            <div className='burttonDiv'>
                                <Button onClick={okFunc} className="buttonOK"/*  className='AuthButton'  */ type="primary" size='large'>
                                    {language?.OK}
                                </Button>
                            </div>
                            <br />
                        </Card>
                    </div>
                </div>
            </div >
        </div>
    )
}

export default FindID