import { PlusCircleFilled } from '@ant-design/icons'
import { Button, DatePicker, Divider, Form, Input, Modal, Select } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { POST } from '../../utils/apis'
// import SearchGolfClub from './SearchGolfClub'
import { regions } from '../../utils/constants'
import { errorMessage, successMessage } from '../../utils/helpers'

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
        md: {
            span: 6,
        }
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
        md: {
            span: 18,
        },
    },
}
const config = {
    rules: [
        {
            type: 'object',
            required: true,
            message: 'Please select date!',
        },
    ],
}

const RegisterForAuctionModal = (props) => {

    const { user, isModalOpen, setIsModalOpen, language ,lanKorCondition} = props

    const [memberFee, setMemberFee] = useState(null)
    const [nonMemberFee, setNonMemberFee] = useState(null)
    const [loading, setLoading] = useState(false)

    console.log('isModalOpen', isModalOpen)
    const handleCancel = () => {
        setIsModalOpen(false)
    }
    const onFinish = (fieldsValue) => {
        setLoading(true)
        console.log('fieldsValue', fieldsValue)


        const values = {
            ...fieldsValue,
            'expirationDate': fieldsValue['expirationDate']?.format('YYYY-MM-DD'),
            'usableDate': fieldsValue['expirationDate']?.format('YYYY-MM-DD'),
        }

        values.memberFee = (+memberFee)
        values.nonMemberFee = (+nonMemberFee)
        values.rightPrice = (+values.rightPrice)
        values.userId = user?._id
        values.ticketId = '633be09afd4a521218a6cff1'
        values.termsAndCondition = 'termsAndCondition'
        values.auctionType = 'typr'
        delete values.Fee
        console.log('values', values)

        axios.post(POST.CREATE_AUCTION, values)
            .then((res) => {
                const { data } = res
                console.log('data', data)
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    setIsModalOpen(false)
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage( lanKorCondition ? 'Oops Something Went Wrong!':'  문제가 발생했습니다!')
            })
    }
    const handleNumber = (e, type) => {
        const re = /^[0-9\b]+$/

        if (e.target.value === '' || re.test(e.target.value)) {
            if (type === 'member') {
                setMemberFee(e.target.value)
            } else {
                setNonMemberFee(e.target.value)
            }
        }
    }
    return (
        <>
            <Modal
                open={isModalOpen}
                // open={true}
                onCancel={handleCancel}
                className='paymentModal'
                footer={null}
            >
                <div className='modal_main_div' >
                    <center><h2>{language?.MoreInformationShare}</h2></center>
                    <h4 >{language?.ShareApplicationMembership}</h4>
                    <div className='disabled_text scroll_class'>
                        <table>
                            <tr>
                                <td>{language?.GolfClubName}</td>
                                <td>East Valley CC</td>
                            </tr>
                            <tr>
                                <td>{language?.Address}</td>
                                <td>123, Gohan-ri, Gohan-eup, Gohan-ri, Gohan-eup</td>
                            </tr>
                            <tr>
                                <td>{language?.Type}</td>
                                <td>Company</td>
                            </tr>
                        </table>
                    </div>
                    <br />

                    <Form requiredMark={false} name='time_related_controls'  {...formItemLayout} onFinish={onFinish}>


                        <Form.Item
                            name='Fee'
                            label={language?.Fee}
                            rules={[
                                {
                                    required: true,
                                    validator: async (_, names) => {
                                        if (!memberFee) {
                                            return Promise.reject(new Error(language?.PleaseEnterMemberFee))
                                        }
                                        if (!nonMemberFee) {
                                            return Promise.reject(new Error('Please Enter Non-Member Fee'))
                                        }
                                    }
                                }
                            ]}
                        >
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '47%', textAlign: 'center' }}>
                                    <label style={{ marginRight: '4%' }} htmlFor=''>{language?.Member}</label>
                                    <Input
                                        suffix={language?.KRW}
                                        style={{
                                            width: 'auto',
                                            // marginLeft: '4%',
                                        }}
                                        value={memberFee}
                                        onChange={(e) => handleNumber(e, 'member')}
                                    />
                                </div>

                                <div style={{ width: '50%', marginLeft: 'auto', textAlign: 'center' }}>
                                    <label htmlFor='' style={{ marginRight: '4%' }}>{language?.NonMember}</label>

                                    <Input
                                        suffix={language?.KRW}
                                        style={{
                                            width: 'auto',
                                            // marginLeft: '4%',

                                        }}
                                        value={nonMemberFee}
                                        onChange={(e) => handleNumber(e, 'nmember')}
                                    />
                                </div>
                            </div>
                        </Form.Item>

                        <Divider />

                        <Form.Item
                            name='rightPrice'
                            label={<h4><PlusCircleFilled className='plus_with_text' /> {language?.RightPrice}</h4>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Right Price!',
                                },
                            ]}
                        >
                            <Input
                                type='number'
                                suffix={language?.KRW}
                                min={0}
                                maxLength={7}
                            // value={couponsQuantity}
                            // onChange={(e) => setLotteriesQuantity(e?.target?.value)}
                            />
                        </Form.Item>

                        <Form.Item
                            name='usableDate'
                            label={<h4><PlusCircleFilled className='plus_with_text' /> {language?.UsableDate}</h4>}
                            {...config}
                        >
                            <DatePicker style={{ width: '100%' }} />
                        </Form.Item>

                        <Form.Item
                            name='expirationDate'
                            label={<h4><PlusCircleFilled className='plus_with_text' /> {language?.ExpirationDate}</h4>}
                            {...config}
                        >
                            <DatePicker style={{ width: '100%' }} />
                        </Form.Item>

                        <Form.Item
                            name='locationPreference'
                            label={<h4><PlusCircleFilled className='plus_with_text' /> {language?.LocationPreference}</h4>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select location!',
                                },
                            ]}
                        >
                            <Select mode='multiple' placeholder='select Location'>
                                {regions?.slice(1)?.map((v, i) => (<Option value={v}>{v}</Option>))}
                            </Select>
                        </Form.Item>

                        <Button loading={loading} className='req_exc_btn' type='primary' htmlType='submit'>{language?.Submit}</Button>
                        <Button style={{ width: '100%', marginTop: 10 }} onClick={handleCancel}>{language?.Cancel}</Button>
                    </Form>


                </div>
            </Modal>
        </>
    )
}

export default RegisterForAuctionModal