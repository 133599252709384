import { PlusCircleFilled } from '@ant-design/icons'
import { Button, Checkbox, Form, Input, Modal, Radio } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { POST } from '../../utils/apis'
import { errorMessage, MyInfoAlert, successMessage } from '../../utils/helpers'
import ChooseTicket from './ChooseTicket'
import englishLanguage from '../../utils/englishLanguage.json'
import { types, typesKr } from '../../utils/constants'
import moment from 'moment'

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 12,
        },
        lg: {
            span: 10,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 12,
        },
        lg: {
            span: 14,
        },
    },
}

const SellTheTicket = (props) => {
    const { user, language, lanKorCondition, fun } = props
    const [selectedTicket, setSelectedTicket] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [sellingPrice, setSellingPrice] = useState(null)

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const onFinish = (fieldsValue) => {
        setLoading(true)
        let obj = {
            userId: user?._id,
            ticketId: selectedTicket?._id,
            price: fieldsValue?.price,
            category: fieldsValue?.category === 'both' ? [englishLanguage[0]?.NormalSell, englishLanguage[0].GroupBuySell] : fieldsValue?.category === 'normal' ? [englishLanguage[0]?.NormalSell] : [englishLanguage[0]?.GroupBuySell],
            auctionType: 'auctionSale',
            termsAndCondition: 'termsAndCondition',
            advanceFee: Number(fieldsValue?.advanceFee)
        }

        axios.post(POST.CREATE_AUCTION_SALE, obj)
            .then((res) => {
                const { data } = res
                console.log('data', data)
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    fun()
                    setIsModalOpen(false)
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })

    }

    console.log('selectedTicket?.golfClub',selectedTicket)
    return (
        <>
            {user?._id && user?.telNo && user?.bank && user?.bankAccountNumber ?

                <Button className='upload_sharing_btn' onClick={showModal}>
                    {language?.RegisterAuctionPost}
                </Button> :
                <Button className='upload_sharing_btn' onClick={() => { MyInfoAlert(language) }}>
                    {language?.RegisterAuctionPost}
                </Button>}
            <center>
                <Modal
                    open={isModalOpen}
                    // open={true}
                    onCancel={handleCancel}
                    className='responsiveModal'
                    footer={null}
                    style={{ top: '10px', }}
                >
                    <div
                        className='scroll_class'
                        style={{
                            height: 'auto',
                            width: '100%',
                            maxHeight: '85vh',
                            overflowY: 'scroll',
                            marginTop: '12px'
                        }}>
                        <div className='modal_main_div' >
                            <center>
                                {/* <h2>{language?.SellTheTicket}</h2> */}
                                <center><h2>{language?.RegisterAuctionPost}</h2></center>
                                <p style={{ color: '#1890ff' }}> {language?.EnterMembershipInformation}</p>
                            </center>

                            <Form requiredMark={false} name='time_related_controls'  {...formItemLayout} onFinish={onFinish}>

                                <Form.Item
                                    name='gender'
                                    label={lanKorCondition ? 'Membership' : "회원권"}
                                    rules={[
                                        {
                                            validator: async (names) => {

                                                if (!selectedTicket?.golfClub?.clubName) {
                                                    console.log(selectedTicket == {})
                                                    return Promise.reject(new Error(language?.ChooseYourTicket))
                                                }
                                            }
                                        }
                                    ]}

                                >

                                    <ChooseTicket setSelectedTicket={setSelectedTicket}  {...props} />
                                </Form.Item>


                                <div className='disabled_text scroll_class'>
                                    <table>

                                        <tr>
                                            <td>{language?.GolfClubName}</td>
                                            <td>{selectedTicket && selectedTicket?.golfClub?.clubName || '-----------------'}</td>
                                        </tr>
                                        <br />
                                        <tr>
                                            <td>{lanKorCondition ? 'Address' : '주소'}</td>
                                            <td>{selectedTicket && selectedTicket?.golfClub?.clubAddress || '-----------------'}</td>
                                        </tr>
                                        <br />

                                        <tr>
                                            <td>{language?.Type}</td>
                                            <td>{selectedTicket?.membershipType ? lanKorCondition ? selectedTicket?.membershipType : typesKr[types?.indexOf(selectedTicket?.membershipType)]?.typesKr : '-----------------'}</td>
                                        </tr>
                                        <br />

                                        <tr>
                                            <td>{language?.JoiningFee} </td>
                                            <td>{selectedTicket && selectedTicket?.joiningFee || '-----------------'}</td>
                                        </tr>
                                        <br />

                                        <tr>
                                            <td>{language?.JoiningDate}</td>
                                            <td>{selectedTicket?.joiningDate?.date1 && moment(selectedTicket?.joiningDate?.date1).format('YYYY-MM-DD') + "-"+moment(selectedTicket?.joiningDate?.date2).format('YYYY-MM-DD') || '-----------------'}</td>
                                        </tr>
                                        <br />

                                        <tr>
                                            <td>{language?.SpecialGift}</td>
                                            {selectedTicket?._id ?
                                                // 주중  2회  50 만원  | 주말  1회  80만원
                                                // Weekday   2times 50만원  | weekend c 80만원
                                                lanKorCondition ?
                                                    <td>Weekday {selectedTicket && selectedTicket?.specialGiftWeekDay} times  {selectedTicket && selectedTicket?.specialGiftWeekDayKRW} milion won | weekend {selectedTicket && selectedTicket?.specialGiftWeekEnd} times  {selectedTicket && selectedTicket?.specialGiftWeekEndKRW} million won</td> :
                                                    <td>주중 {selectedTicket && selectedTicket?.specialGiftWeekDay} 회    {selectedTicket && selectedTicket?.specialGiftWeekDayKRW} 만원  | 주말  {selectedTicket && selectedTicket?.specialGiftWeekEnd}  회 {selectedTicket && selectedTicket?.specialGiftWeekEndKRW} 만원</td>
                                                :
                                                <td>----------------</td>}                                    </tr>
                                    </table>
                                </div>


                                <br />

                                {/* <Form.Item
                                    name='category'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input select category!',
                                        }
                                    ]}
                                    initialValue={'both'}

                                >
                                    <Radio.Group buttonStyle='solid'>
                                        <Radio.Button value={'both'} style={{ marginRight: 10, borderRadius: 8 }}>{lanKorCondition ? 'Both' : '일반 판매와 공동구매로 판매'}</Radio.Button>
                                        <Radio.Button value={'normal'} style={{ marginRight: 10, borderRadius: 8 }}>{language?.NormalSell}</Radio.Button>
                                        <Radio.Button value={'group'} style={{ marginRight: 10, borderRadius: 8 }}>{lanKorCondition ? 'GroupBuySell' : '공동구매 판매'}</Radio.Button>
                                    </Radio.Group>
                                </Form.Item> */}

                                <Form.Item
                                    name='price'
                                    label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' />{language?.WantedSellingTicket}</h4>}
                                    rules={[
                                        {
                                            required: true,
                                            message: language?.PleaseInputSellingPrice,
                                        },
                                    ]}
                                >
                                    <div>
                                        <Input
                                            suffix={language?.KRW}
                                            // placeholder='Joining Fee'
                                            maxLength={10}
                                            value={sellingPrice}
                                            onChange={(e) => setSellingPrice(e.target.value?.replace(/[^0-9]/g, ''))}
                                        />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    name='advanceFee'
                                    label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' /> {language?.advanceFee}</h4>}
                                    rules={[
                                        {
                                            required: true,
                                            message: language?.advanceFeeAmountHere,
                                        },
                                    ]}
                                >
                                    <div>
                                        <Input
                                            min={0}
                                            maxLength={7}
                                            suffix={language?.KRW}
                                            placeholder={language?.advanceFeeAmountHere}
                                        />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    name='TermAndConditions'
                                    label={language?.TermCondition}
                                    valuePropName='checked'
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject(new Error(language?.Shouldacceptagreement)),
                                        }
                                    ]}
                                >
                                    <Checkbox />
                                </Form.Item>
                                <div className='agreement scroll_class' style={{ marginBottom: 20 }}>
                                    <p>{language?.TAC1}</p>
                                    <p>{language?.TAC2}</p>
                                    <p>{language?.TAC3}</p>
                                    <p>{language?.TAC4}</p>
                                    <p>{language?.TAC5}</p>
                                    <p>{language?.TAC6}</p>
                                    <p>{language?.TAC7}</p>
                                </div>
                                <Button
                                    loading={loading}
                                    className='req_exc_btn'
                                    type='primary'
                                    htmlType='submit'>{language?.RequestSell}</Button>

                            </Form>
                        </div>
                    </div>
                </Modal>
            </center>
        </>
    )
}
export default SellTheTicket