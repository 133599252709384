import { Link } from 'react-router-dom'
import LOGO from '../../assets/logoVM.png'
import RemoveBGMenu from '../../assets/RemoveBGMenu.png'
import { Button, Dropdown, Space } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { loginUser, removeUser } from '../../Redux/actions/authActions'
import { isUserLoginApi, PUBLIC_PATHS } from '../../utils/helpers'
import { Select } from 'antd'
import { setLanguage } from '../../Redux/actions/generalActions'
import { Radio } from 'antd'
import { setPriceFilter } from '../../Redux/actions/ticketPriceActions'
import { setSellType } from '../../Redux/actions/sellTypeAction'
import { setRouteBreadCrumbs, clearRouteBreadCrumbs } from '../../Redux/actions/routeActions'
import { allPaths } from '../../utils/constants'
import { GrMenu } from 'react-icons/gr'
import { FcList } from 'react-icons/fc'
import { MenuOutlined, MoreOutlined } from '@ant-design/icons'
const { Option } = Select;
const Header = (props) => {


    const { history, language, userSelectedLanguage, goto } = props
    let currentPath = location?.pathname
    let dispatch = useDispatch()
    const user = useSelector(state => state?.authReducer?.user)

    let loc = window?.location?.pathname != '/'
    let indLoc = window?.location?.pathname != '/individual-signup'
    let compLoc = window?.location?.pathname != '/corporate-signup'

    if (!user && !PUBLIC_PATHS) {
        window.location.reload()
        return (
            <>
                <Redirect to={'/login'} />
            </>
        )
    }

    const logOut = async () => {
        history.push('/login')

        await isUserLoginApi(user?._id, false)
        dispatch(removeUser())
        dispatch(clearRouteBreadCrumbs())

        dispatch(setPriceFilter({
            start: 0,
            end: 0
        }))
        dispatch(setSellType({
            transactionType: ''
        }))
    }
    const myPage = () => {
        history.push('/my-page')
        // history.push({
        //     pathname: allPaths?.MYPAGE,
        //     search: `?membershipTransaction`
        // })
    }
    const home = () => {
        history.push('/')
    }

    const handleChange = (value) => {
        dispatch(setLanguage(value))
    }


    const items = [
        {
            key: '1',
            label: (
                <a
                    onClick={() => {
                        !user?._id ?
                            history.push('/login')
                            : currentPath === '/' ? myPage() : home()
                    }}
                >
                    {!user?._id ? '로그인/회원가입' : currentPath === '/' ? language?.MyPage : 'Home'}
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a
                    onClick={() => { logOut() }}
                >
                    {!user?._id ? '' : language?.Logout}
                </a>
            ),
        },
    ];

    return (
        <>
            <div className='nav_bar' >
                <div className='container_nav'>
                    <div className='layout_nav'>
                        <div className='col_nav col_main_nav'>
                            <img onClick={home} src={LOGO} className='logo' />
                            <h4 onClick={home} style={{ color: 'white', marginBottom: 0, cursor: 'pointer' }}>{language?.VALUEMARKET}</h4>
                        </div>

                        <div className='col_nav col_complementary_nav'>
                            <div className=''>
                                {
                                    !user ? (
                                        <>
                                            <div className='bigscreenHEade'>
                                                <Space>
                                                    <Button style={{ marginRight: 10 }} onClick={() => history.push('/login')} ghost>로그인 / 회원가입</Button>
                                                </Space>
                                            </div>
                                            <div className='smallscreenHEade'>
                                                <Space className='menuSpace' style={{ justifyContent: 'flex-end' }}>
                                                    <Dropdown key='more-m' menu={{ items }} placement='bottomRight'>
                                                        <Button type='text' icon={<img src={RemoveBGMenu} style={{ height: 30, width: 50 }} />} />
                                                    </Dropdown>
                                                </Space>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className='bigscreenHEade'>
                                                <Space>
                                                    {currentPath === '/' ?
                                                        <Button style={{ marginRight: '10px' }} onClick={myPage} ghost>{language?.MyPage}</Button>
                                                        :
                                                        <Button style={{ marginRight: '10px' }} onClick={home} ghost>{'HOME'}</Button>
                                                    }
                                                    <Button style={{ marginRight: '10px' }} onClick={logOut} ghost>{language?.Logout}</Button>
                                                </Space>


                                            </div>
                                            <div className='smallscreenHEade'>
                                                <Space className='menuSpace' style={{ justifyContent: 'flex-end' }}>
                                                    <Dropdown key='more' menu={{ items }} placement='bottomRight'>
                                                        <Button type='text' icon={<img src={RemoveBGMenu} style={{ height: 30, width: 50 }} />} />
                                                    </Dropdown>
                                                </Space>
                                            </div>
                                        </>
                                    )
                                }
                            </div>


                            {process.env.NODE_ENV === 'development' ?
                                <Space>
                                    <Select
                                        className='languageSelector'
                                        defaultValue={userSelectedLanguage == 'korean' ? 'korean' : 'english'}
                                        style={{
                                            width: 100, marginRight: 10
                                        }}
                                        onChange={handleChange}
                                    >
                                        <Option value='english'>{language?.English}</Option>
                                        <Option value='korean'>{language?.Korean}</Option>
                                    </Select>
                                </Space>
                                : null}

                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Header