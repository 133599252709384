import { Divider, Modal, Typography } from 'antd';
import axios from 'axios';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { POST } from '../../utils/apis';
import { types, typesKr } from '../../utils/constants';
const { Text } = Typography;

const HoldingMembershipModal = (props) => {

    const { setshowEditHolding, showEditHolding, user, editData, language, lanKorCondition } = props
    const [ticketInfo, setTicketInfo] = useState([])

    const handleCancel = () => {
        setshowEditHolding(false)
    }

    const getSingleTicket = () => {
        let obj = {
            _id: editData,
            userId: user?._id
        }
        axios.post(`${POST.SINGLE_TICKET_INFO}`, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setTicketInfo(data?.data)
                }
                else {
                    console.log(data?.message)
                }
            })
            .catch((e) => {
                console.log('e', e)
            })
    }
    useEffect(() => {
        getSingleTicket()
    }, [editData])

    useEffect(() => {

    }, [ticketInfo?._id])

    return (
        <>
            <Modal
                open={showEditHolding}
                // open={true}
                onCancel={handleCancel}
                className='paymentModal'
                footer={null}
                style={{ top: '10px', }}
            >
                <div
                    className='scroll_class'
                    style={{
                        height: 'auto',
                        width: '100%',
                        maxHeight: '85vh',
                        overflowY: 'scroll',
                        marginTop: '12px'
                    }}>
                    <div className='modal_main_div'>
                        <center><h2>{language?.HoldingMembership}</h2></center>
                        <h2>{language?.Info}</h2>

                        <div className='static_text'>
                            <table>
                                <tr>
                                    <td style={{ minWidth: 100, maxWidth: 'auto' }}><Text>{language?.GolfClubName}</Text></td>
                                    <td className='values_style'><Text>{ticketInfo?.golfClub?.clubName}</Text></td>
                                </tr>
                                <tr>
                                    <td style={{ minWidth: 100, maxWidth: 'auto' }}><Text>{language?.Type}</Text></td>
                                    {/* <td className='values_style'><Text>{ticketInfo?.membershipType}</Text></td> */}
                                    <td className='values_style'><Text>{lanKorCondition ? ticketInfo?.membershipType : typesKr[types?.indexOf(ticketInfo?.membershipType)]?.typesKr}</Text></td>
                                </tr>
                                <tr>
                                    <td style={{ minWidth: 100, maxWidth: 'auto' }}><Text>{language?.JoiningFee}</Text></td>
                                    <td className='values_style'><Text>{ticketInfo?.joiningFee}</Text></td>
                                </tr>
                                <tr>
                                    <td style={{ minWidth: 100, maxWidth: 'auto' }}><Text>{language?.JoiningDate}</Text></td>
                                    <td className='values_style'><Text>{`${moment(ticketInfo?.joiningDate?.date1).format('YYYY-MM-DD')} ~ ${moment(ticketInfo?.joiningDate?.date2).format('YYYY-MM-DD')}`}</Text></td>
                                </tr>
                                <tr>
                                    <td style={{ minWidth: 100, maxWidth: 'auto' }}><Text>{language?.SpecialGift}</Text></td>
                                    <td className='values_style'><Text>{ticketInfo?.specialGiftWeekDay} {language?.timesaweek} | {ticketInfo?.specialGiftWeekDayKRW} {language?.millionwon} | {ticketInfo?.specialGiftWeekEnd} {language?.Weekend} | {ticketInfo?.specialGiftWeekEndKRW} {language?.millionwon}</Text></td>
                                </tr>
                                <tr>
                                    <td style={{ minWidth: 100, maxWidth: 'auto' }}><Text>{language?.Fee}</Text></td>
                                    <td className='values_style'><Text>{language?.Member} | {ticketInfo?.memberFee} {language?.millionwon} {language?.NonMember} | {ticketInfo?.NONmemberFee
                                    } {language?.millionwon}</Text></td>
                                </tr>
                            </table>
                        </div>

                        <Divider style={{ backgroundColor: '#bbb', marginBottom: 20 }} />

                        <h2>{language?.GolfCourseInformation}</h2>

                        <div className='static_text'>
                            <table>
                                <tr>
                                    <td style={{}}><Text>{language?.address}</Text></td>
                                    <td className='values_style'><Text>{ticketInfo?.golfClub?.clubAddress}</Text></td>
                                </tr>
                                <tr>
                                    <td style={{}}><Text>{language?.OpeningDate}</Text></td>
                                    <td className='values_style'><Text>{moment(ticketInfo?.golfClub?.created).format('YYYY-MM-DD')}</Text></td>
                                </tr>
                                <tr>
                                    <td style={{}}><Text>{language?.GreenFee}</Text></td>
                                    <td className='values_style'><Text>{ticketInfo?.greenFeeWeekDay} {language?.Weekday} | {ticketInfo?.greenFeeWeekEnd} {language?.Weekend}</Text></td>
                                </tr>
                                <tr>
                                    <td style={{}}><Text>{language?.CaddyFee}</Text></td>
                                    <td className='values_style'><Text>{ticketInfo?.caddyFee}</Text></td>
                                </tr>
                                <tr>
                                    <td style={{}}><Text>{language?.PhoneNumber}</Text></td>
                                    <td className='values_style'><Text>{ticketInfo?.phoneNumber}</Text></td>
                                </tr>
                                <tr>
                                    <td style={{}}><Text>{language?.Homepage}</Text></td>
                                    <td className='values_style'><Text>{ticketInfo?.homePage}</Text></td>
                                </tr>
                            </table>
                        </div>

                        <Divider style={{ backgroundColor: '#bbb', marginBottom: 20 }} />
                        <h2>{language?.AdditionalBenefits}</h2>

                        <div className='static_text'>

                            <table>
                                <tr>
                                    {ticketInfo?.additionalBenefits?.map((v, i) => {
                                        return (
                                            <div className='static_text'>
                                                <table>
                                                    <tr>
                                                        <td><Text>{language?.BenefitName}</Text></td>
                                                        <td className='values_style'><Text>{v?.benefitName}</Text></td>
                                                    </tr>
                                                    <tr>
                                                        <td><Text>{language?.BenefitContents}</Text></td>
                                                        <td className='values_style'><Text>{v?.benefitContent}</Text></td>
                                                    </tr>
                                                </table>
                                            </div>

                                        )
                                    })}

                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal>

        </>
    )
}

export default HoldingMembershipModal