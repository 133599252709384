import React, { useState } from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Checkbox, DatePicker, Form, Input, Radio } from 'antd'
import axios from 'axios'
import { AUTH } from '../../utils/apis'
import { successMessage, errorMessage } from '../../utils/helpers'
import Header from '../Home/Header'
import { allPaths } from '../../utils/constants'
import moment from 'moment'

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
        md: {
            span: 7,
        },
        lg: {
            span: 7,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 19,
        },
        md: {
            span: 18,
        },
        lg: {
            span: 18,
        },
    },
}
const config = {
    rules: [
        {
            type: 'object',
            required: true,
            message: 'Please select date!',
        }
    ]
}
const adminPath = 'http://admin.securetech.vmvm.co.kr/membership'

const IndividualSignupAdmin = (props) => {
    const { history, language, lanKorCondition } = props
    const [phoneValue, setPhoneValue] = useState('')
    const [dateValue, setdateValue] = useState(null)
    const [tempDate, setTempDate] = useState(null)
    const [email, setEmail] = useState('')
    const [verificationCode, setVerificationCode] = useState('')
    const [nameValue, setname] = useState('')
    const [password, setPassword] = useState('')
    const [gender, setGender] = useState('others')
    const [countdown, setcountdown] = useState(0)
    const [showTimer, setshowTimer] = useState(false)
    const [fieldsDisable, setfieldsDisable] = useState(true)
    const [verifyDisable, setverifyDisable] = useState(false)
    const [confirmDisable, setConfirmDisable] = useState(false)
    const [loading, setLoading] = useState(false)
    const [verifyLoading, setVerifyLoading] = useState(false)
    const [confirmLoading, setconfirmLoading] = useState(false)
    // console.log("dateValue tempDate", dateValue, tempDate)
    const onFinish = (value) => {

        let obj = {
            email: value?.email,
            userType: 'individual',
            gender: value?.gender,
            fullName: value?.fullName,
            telNo: value?.telNo,
            DOB: moment(dateValue).format('YYYY-MM-DD'),
            password: value?.password,
        }
        console.log('value', obj)
        // return true
        if (!obj?.fullName || !obj?.email || !obj?.userType || !obj?.gender || !obj?.telNo || !obj?.password || !obj?.DOB) {
            return errorMessage('PLEASE PROVIDE ALL VALUES')
        }
        setLoading(true)
        axios.post(AUTH?.INDVIDUAL_SIGNUP, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    window.location.href = adminPath
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                else {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)

                }
                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageKr)
            })
    }

    const changeDate = (date, dateString) => {
        setTempDate(date, dateString)
        setdateValue(dateString)
    }

    const startTimer = () => {
        setshowTimer(true)
        var timeleft = 60
        var downloadTimer1 = setInterval(function () {
            timeleft--
            setcountdown(timeleft)
            if (timeleft <= 0) {
                clearInterval(downloadTimer1)
                setshowTimer(false)
                setverifyDisable(false)
            }
        }, 1000)
    }

    const sendEmailVerification = () => {
        setshowTimer(true)
        // setverifyDisable(true)
        setVerifyLoading(true)
        let obj = {
            email: email,
            userType: 'individual'
        }

        axios.post(AUTH?.SEND_EMAIL_VERIFICATION_CODE, obj)
            .then((res) => {
                const { data } = res
                setVerifyLoading(false)
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    setverifyDisable(true)
                    startTimer()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                    setverifyDisable(false)
                }
            })
            .catch((e) => {
                console.log('e ', e)
                setverifyDisable(false)
                setVerifyLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageKr)
            })
    }

    const confirmEmailVerification = () => {
        setconfirmLoading(true)
        let obj = {
            email: email,
            userType: 'individual',
            verificationCode: verificationCode
        }

        axios.post(AUTH.VERIFY_EMAIL, obj)
            .then((res) => {
                const { data } = res
                setVerifyLoading(false)
                setconfirmLoading(false)
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    setfieldsDisable(false)
                    setverifyDisable(true)
                    setConfirmDisable(true)
                    setshowTimer(false)
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                    // setverifyDisable(false)
                    setConfirmDisable(false)
                }
            })
            .catch((e) => {
                console.log('e ', e)
                setconfirmLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageKr)
            })
    }
    const handlePhoneInput = (e) => {
        let value = e?.target?.value?.replace(/[^0-9-]/g, '')
        let event = e?.nativeEvent?.data
        if (event && ((value?.length === 3 || value?.length === 8) || (phoneValue?.length === 3 || phoneValue?.length === 8))) {
            if (phoneValue?.length === 3 || phoneValue?.length === 9) {
                value = `${phoneValue}-${event}`
            }
            else {
                value = `${value}-`
            }
        }
        setPhoneValue(value)
    }
    return (
        <div className='home-main'>
            <Header {...props} />
            <div className='card-parent2'>
                <div className='card2'>
                    <p className='heading greenColor'>{language?.Signup}</p>
                    <br />
                    <br />
                    {/* <div className='disabled_text scroll_class line-height'>
                        <table>
                            <tr>
                                <td>{lanKorCondition ? 'Mobile Phone Number' : "휴대전화번호" + ":"}</td>
                                <td>{userPhone ? userPhone : '-----'}</td>
                            </tr>
                            <tr>
                                <td>{lanKorCondition ? 'Date of birth' : '생년월일'}:</td>
                                <td>{userBirthday ? moment(userBirthday).format('YYYY-MM-DD') : '-----'}</td>
                            </tr>
                            <tr>
                                <td>{lanKorCondition ? 'Name' : '성명'}:</td>
                                <td>{userName ? userName : '-----'}</td>
                            </tr>
                            {/* <tr>
                                <td>Gender:</td>
                                <td>Gender</td>
                            </tr> 
                        </table>
                    </div> */}
                    <br />
                    <br />
                    <Form
                        {...formItemLayout}
                        name='form'
                        onFinish={onFinish}
                    // requiredMark={true}
                    >

                        <Form.Item
                            name='email'
                            label={`ID` + language?.Email}
                            rules={[
                                {
                                    required: true,
                                    message: lanKorCondition ? 'Please enter your email address' : '이메일 주소를 입력해주세요'
                                }
                            ]}
                        >
                            <div className='Corporate_Signup'>
                                <Input
                                    size='large'
                                    value={email}
                                    disabled={!fieldsDisable}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className='Signup_input'
                                    placeholder={'example@name.com'}
                                />
                                <Button
                                    className='input_field_buttons'
                                    style={verifyDisable ? { opacity: 0.3 } : null}
                                    disabled={verifyDisable}
                                    loading={verifyLoading}
                                    size='large'
                                    onClick={sendEmailVerification}
                                >{language?.Validate}</Button>
                            </div>
                        </Form.Item>

                        <Form.Item
                            name='certificateNumber'
                            label={language?.EmailVerificationNumber}
                            rules={[
                                {
                                    required: true,
                                    message: lanKorCondition ? 'Please enter the verification code' : '인증번호를 입력해주세요'

                                }
                            ]}
                        >
                            <div className='Corporate_Signup'>
                                <Input
                                    size='large'
                                    disabled={!fieldsDisable}
                                    maxLength={6}
                                    // type={Number}
                                    className='Signup_input'
                                    value={verificationCode}
                                    onChange={(e) => setVerificationCode(e.target.value)}
                                    placeholder={'1234567'}
                                />
                                <Button
                                    className='input_field_buttons'
                                    size='large'
                                    disabled={confirmDisable}
                                    style={confirmDisable ? { opacity: 0.3 } : null}
                                    loading={confirmLoading}
                                    onClick={confirmEmailVerification}
                                >{language?.Check}</Button>
                            </div>
                        </Form.Item>
                        {showTimer ? <p className='special_text1'><InfoCircleOutlined /> {language?.TimerCond} {countdown} {language?.seconds}</p> : null}
                        <Form.Item
                            name='fullName'
                            label={language?.Name}
                            rules={[
                                {
                                    required: true,
                                    message: language?.Inputyourname

                                }
                            ]}
                        >
                            <div className='Corporate_Signup'>
                                <Input
                                    className='Signup_input'
                                    autoFocus
                                    disabled={fieldsDisable}
                                    required
                                    value={nameValue}
                                    onChange={(e) => setname(e.target.value)}
                                    placeholder={language?.Name}
                                />
                            </div>
                        </Form.Item>
                        <Form.Item
                            name='telNo'
                            label={language?.MobilePhoneNumber}
                            rules={[
                                {
                                    required: true,
                                    message: language?.PleaseEnterYourMobilePhoneNumber

                                }
                            ]}
                        >
                            <div className='Corporate_Signup'>
                                <Input
                                    className='Signup_input'
                                    autoFocus
                                    required
                                    disabled={fieldsDisable}
                                    maxLength={13}
                                    value={phoneValue}
                                    onChange={handlePhoneInput}
                                    placeholder='e.g: 123-4567-8910'
                                />
                            </div>
                        </Form.Item>

                        <Form.Item
                            name='DOB'
                            label={language?.DOB}
                        >
                            <div className='Corporate_Signup'>
                                <DatePicker placeholder={language?.ChooseYourDateBirth} disabled={fieldsDisable} value={tempDate} onChange={changeDate} />
                            </div>
                        </Form.Item>
                        <Form.Item
                            name='gender'
                            label={language?.Gender}
                            rules={[
                                {
                                    required: true,
                                    message: language?.Inputyour + '  ' + language?.Gender

                                }
                            ]}
                            initialValue={gender}
                        >
                            <div className='Corporate_Signup'>
                                <Radio.Group value={gender} disabled={fieldsDisable} onChange={(e) => setGender(e.target.value)}>
                                    <Radio value={'male'}>{language?.Male}</Radio>
                                    <Radio value={'female'}>{language?.Female}</Radio>
                                    {/* <Radio value={'others'}>{language?.Other}</Radio> */}
                                </Radio.Group>
                            </div>
                        </Form.Item>


                        <Form.Item
                            name='password'
                            label={language?.Password}
                            rules={[
                                {
                                    required: true,
                                    message: language?.PleaseEnterAPassword

                                },
                                {
                                    min: 8
                                }
                            ]}
                        >
                            <Input.Password
                                disabled={fieldsDisable}
                                autocomplete='new-password'
                                size='large'
                                visibilityToggle={false}
                                className='Signup_input'
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder={language?.PleaseEnterAPassword}
                            />
                        </Form.Item>
                        <Form.Item
                            label={language?.ConfirmPassword}
                            name='confirm'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: language?.PleaseReEnterYourPassword

                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve()
                                        }

                                        return Promise.reject(language?.passCond)
                                    },
                                })
                            ]}
                        >
                            <Input.Password
                                disabled={fieldsDisable}
                                size='large'
                                visibilityToggle={false}
                                placeholder={language?.PleaseReEnterYourPassword}
                                className='Signup_input'
                            />
                        </Form.Item>

                        <Form.Item
                            name='termsConditions1'
                            label={language?.TermCondition}
                            valuePropName='checked'
                            hasFeedback
                            rules={[
                                {
                                    validator: (_, value) =>
                                        value ? Promise.resolve() : Promise.reject(new Error(language?.ShouldAccept)),
                                }
                            ]}
                        >
                            <Checkbox />
                        </Form.Item>

                        <div className='agreement scroll_class'>
                            <p>{language?.TAC1}</p>
                            <p>{language?.TAC2}</p>
                            <p>{language?.TAC3}</p>
                            <p>{language?.TAC4}</p>
                            <p>{language?.TAC5}</p>
                            <p>{language?.TAC6}</p>
                            <p>{language?.TAC7}</p>
                        </div>
                        <br />
                        <br />
                        {/* <Form.Item> */}
                        <Button
                            loading={loading}
                            size='large'
                            className='responsible_buttons'
                            htmlType='submit'
                        >
                            {language?.Signup}
                        </Button>
                        {/* </Form.Item> */}

                    </Form>
                </div>
            </div >
        </div >
    )
}

export default IndividualSignupAdmin