import { Button, Checkbox, DatePicker, Form, Image, Input, Modal, Select } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { HiCheckCircle } from 'react-icons/hi'
import { ADMIN, POST } from '../../utils/apis'
import { errorMessage, MyInfoAlert, successMessage } from '../../utils/helpers'
import SearchGolfClub from './SearchGolfClub'
import ChooseTicket from './ChooseTicket'
import CoustomRangePicker from '../RangePicker/RengePicker'
import moment from 'moment'
import { useEffect } from 'react'
import { allPaths, regions, regionsKr } from '../../utils/constants'
import CoustomDatePicker from '../DatePicker/DatePicker'
import { useHistory } from 'react-router-dom'

const { RangePicker } = DatePicker


const { TextArea } = Input
const { Option } = Select


const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
        md: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
        md: {
            span: 16,
        },
    },
}


const RegisterModal = (props) => {

    const { user, language, lanKorCondition } = props
    const nums = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
    const mins = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60']
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [am, setAm] = useState('am')
    const [hr, setHr] = useState('1')
    const [min, setMin] = useState('1')
    const [totalTime, setTotalTime] = useState(hr + ':' + min + ' ' + am)
    const [isModalOpen2, setIsModalOpen2] = useState(false)
    const [isModalOpen3, setIsModalOpen3] = useState(false)
    const [showGolfClub, setShowGolfClub] = useState(false)
    const [golfClubData, setGolfClubData] = useState(null)
    const [greenFee, setGreenFee] = useState(null)
    const [transferFee, setTransferFee] = useState('0')
    const [address, setAddress] = useState(null)
    const [loading, setLoading] = useState(false)
    const [golfClubName, setGolfClubName] = useState(null)
    const [golfClubRequest, setGolfClubRequest] = useState(false)

    const history = useHistory()

    const [dateRange, setDateRange] = useState({
        date1: moment().add(-16, 'days'),
        date2: moment()
    })
    const config = {
        rules: [
            {
                type: 'object',
                required: true,
                message: language?.Pleaseselectdate,
            },
        ],
    }
    const rangeConfig = {
        rules: [
            {
                type: 'array',
                required: true,
                message: language?.Pleaseselectdate,
            },
        ],
    }
    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }
    const showModal2 = () => {
        setIsModalOpen2(true)
        setIsModalOpen(false)

    }

    const handleCancel2 = () => {
        setIsModalOpen2(false)
    }
    const showModal3 = () => {
        setIsModalOpen3(true)
        setIsModalOpen2(false)
    }
    const handleOk3 = () => {
        setIsModalOpen3(false)
    }
    const handleCancel3 = () => {
        setIsModalOpen3(false)
    }
    let getClubName = () => {
        axios.get(ADMIN.GOLF_CLUB)
            .then((res) => {

                const { data } = res
                if (data.success) {
                    setGolfClubName(data?.data)
                }
                else {
                }
            })
            .catch((e) => {
                console.log('err', e)
            })
    }

    useEffect(() => {
        getClubName()
    }, [])
    const onFinish = (fieldsValue) => {
        setLoading(true)
        // Should format date value before submit.
        // const rangeValue = fieldsValue['reservedDate']
        if (!golfClubRequest && !golfClubData?._id) {
            return errorMessage('please select golf club')
        }
        if (golfClubRequest && (golfClubName.find((v) => v?.clubName.trim().toLowerCase() === fieldsValue?.golfClubRequest))?._id) {
            return errorMessage(lanKorCondition ? 'This golf club exists, select the golf club' : '해당 골프장은 이미 등록되어있습니다. 골프장을 선택해주세요.')
        }
        const values = {
            ...fieldsValue
        }

        values.userId = user?._id
        values.address = address
        values.greenFee = +values.greenFee
        values.reservedTime = totalTime
        values.transferFee = transferFee
        !golfClubRequest && (values.region = golfClubData?.region || null)
        !golfClubRequest && (values.golfClub = golfClubData?._id || null)
        golfClubRequest && (values.golfClubRequest = fieldsValue?.golfClubRequest || null)
        // values.ticketId = selectedTicket?._id
        console.log('values', values)
        axios.post(POST?.GOLF_RESERVATION_REGISTRATION, values)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    setIsModalOpen(false)
                    setGolfClubData(null)
                    setGreenFee(null)
                    setTransferFee(null)
                    form.resetFields()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    const setExpiryDate = (e) => {
        let date = moment(e?._d).add(1, 'days').format('YYYY-MM-DD')
        form.setFieldValue('reservedDate', moment(date, 'YYYY-MM-DD'))
    }

    useEffect(() => {
        form?.setFieldValue('reservedDate', dateRange)
    }, [dateRange])

    return (
        <>
            {user?._id && user?.telNo && user?.bank && user?.bankAccountNumber ?
                <Button className='upload_sharing_btn' onClick={showModal}>
                    {language?.RegisterGolfTicket}
                </Button> :
                <Button className='upload_sharing_btn' onClick={() => { MyInfoAlert(language) }}>
                    {language?.RegisterGolfTicket}
                </Button>}
            <Modal
                open={isModalOpen}
                onCancel={handleCancel}
                className='responsiveModal'
                footer={null}
                style={{ top: '10px', }}
            >
                <div
                    className='scroll_class'
                    style={{
                        height: 'auto',
                        width: '100%',
                        maxHeight: '85vh',
                        overflowY: 'scroll',
                        marginTop: '12px'
                    }}>
                    <div className='modal_main_div'>
                        <center><h2>{language?.GolfReservationRegistration}</h2></center>

                        {!golfClubRequest &&
                            <div className='row_fields' style={{ marginBottom: 10, alignItems: 'baseline' }} >
                                <p style={{ marginLeft: 0 }}>{language?.GolfClubName}</p>
                                <Button
                                    className='input_field_buttons2'
                                    size='middle'
                                    onClick={() => setShowGolfClub(true)}
                                >{language?.Search}</Button>
                            </div>}
                        <SearchGolfClub language={language} setAddress={setAddress} golfClubData={golfClubData} setGolfClubData={setGolfClubData} showGolfClub={showGolfClub} setShowGolfClub={setShowGolfClub} />
                        {golfClubData?._id && !golfClubRequest ?
                            <div className='row_fields1' style={{ maxWidth: 300, marginBottom: 20 }}>
                                <Image width={100} height={100} src={golfClubData?.imgUrl} />
                                <div style={{ marginTop: -10 }}>
                                    <h2>{golfClubData?.clubName}</h2>
                                    {/* <p>{golfClubData?.region}</p> */}
                                    <p>{lanKorCondition ? golfClubData?.region : regionsKr[regions?.indexOf(golfClubData?.region)]?.TitleKr}</p>

                                    <Button onClick={() => { setGolfClubData(null); setAddress(null) }}>{lanKorCondition ? 'Delete' : '삭제'}</Button>
                                </div>
                            </div> : null}

                        <Form name='time_related_controls' form={form} requiredMark={false} {...formItemLayout} onFinish={onFinish}>

                            {!golfClubRequest ?
                                <Form.Item
                                    name='address'
                                    label={language?.golfclubAddress}
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'Please input Address!',
                                    //     },
                                    // ]}
                                    initialValue={address}
                                >
                                    <div className='row_fields1'>

                                        <Input
                                            placeholder={language?.golfclubAddress}
                                            type='text'
                                            style={{ width: '100%' }}
                                            value={address}
                                            disabled
                                        // onChange={(e) => setAddress(e?.target?.value)}
                                        />
                                    </div>
                                </Form.Item> :
                                <Form.Item
                                    name='golfClubRequest'
                                    label={'Club name'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'please enter club name!',
                                        },
                                    ]}
                                >
                                    <div className='row_fields1'>

                                        <Input
                                            placeholder={'Club name'}
                                            type='text'
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </Form.Item>}
                            <br />
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                {!golfClubRequest ?
                                    <Button onClick={() => setGolfClubRequest(true)} type='primary' style={{ backgroundColor: '#727171', border: 'none', color: 'white' }}>골프장 추가 요청
                                    </Button>
                                    :
                                    <Button onClick={() => setGolfClubRequest(false)} type='primary' style={{ backgroundColor: '#727171', border: 'none', color: 'white' }}>골프장 검색
                                    </Button>
                                }
                            </div>
                            <br />
                            <Form.Item name='reservedDate' label={language?.ReservedDate}{...config}>
                                <CoustomDatePicker placeholder={language?.Selectdate} setDate={setExpiryDate} />
                            </Form.Item>

                            {/* <Form.Item initialValue={dateRange} name='reservedDate' label={language?.ReservedDate} {...rangeConfig}>
                            <CoustomRangePicker format={'YYYY-MM-DD'} date={dateRange} setDate={setDateRange} />
                        </Form.Item> */}

                            <Form.Item
                                name='reservedTime'
                                label={language?.ReservationTime}
                                initialValue={totalTime}
                                rules={[
                                    {
                                        required: true,
                                        // message: 'Please input your username!',
                                    },
                                ]}
                            >
                                <div className='row_fields1'>
                                    <Select
                                        // defaultValue='A.M'
                                        className='special_width'
                                        value={am}
                                        onChange={(value) => { setAm(value); setTotalTime(hr + ':' + min + ' ' + value) }}
                                    >
                                        <Option value='am'> {lanKorCondition ? 'AM' : '오전'}</Option>
                                        <Option value='pm'>{lanKorCondition ? 'PM' : '오후'}</Option>
                                    </Select>
                                    <Select
                                        style={{
                                            width: '35%'
                                        }}
                                        value={hr}
                                        suffixIcon={lanKorCondition ? 'Hrs' : '시'}
                                        onChange={(value) => { setHr(value); setTotalTime(value + ':' + min + ' ' + am) }}
                                    >
                                        {nums?.map((v, i) => (<Option key={i} value={v}>{v}</Option>))}
                                    </Select>
                                    <p></p>
                                    <Select
                                        style={{
                                            width: '35%'
                                        }}
                                        value={min}
                                        suffixIcon={lanKorCondition ? 'Min' : '분'}
                                        onChange={(value) => { setMin(value); setTotalTime(hr + ':' + value + ' ' + am) }}
                                    >
                                        {mins?.map((v, i) => (<Option key={i} value={v}>{v}</Option>))}
                                    </Select>
                                </div>
                            </Form.Item>

                            <Form.Item
                                name='greenFee'
                                label={language?.GreenFee}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.Pleaseinputgreenfee,
                                    },
                                ]}
                            >
                                <div>
                                    <Input
                                        suffix={language?.KRW}
                                        min={0}
                                        maxLength={10}
                                        value={greenFee}
                                        onChange={(e) => setGreenFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item
                                name='transferFee'
                                label={language?.transferFee}
                                rules={[
                                    {
                                        // required: true,
                                        // message: language?.PleaseinputTransferFee,
                                        validator: async () => {
                                            if (!transferFee) {
                                                return Promise.reject(new Error(language?.PleaseinputTransferFee))
                                            }
                                        }
                                    },
                                ]}
                            >
                                <div className='row_fields1'>
                                    <Input
                                        suffix={language?.KRW}
                                        // min={0}
                                        maxLength={10}
                                        value={transferFee}
                                        onChange={(e) => setTransferFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                    />
                                    <Button onClick={() => setTransferFee('0')} style={{ width: 150, marginLeft: 10, backgroundColor: '#f4f4f4' }}>{lanKorCondition ? 'No Transfer Fee' : '양도비 없음'}</Button>
                                </div>
                            </Form.Item>

                            <Form.Item
                                name='memo'
                                label={language?.Memo}
                            >
                                <div className='row_fields1'>
                                    <TextArea maxLength={200} rows={4} />
                                </div>
                            </Form.Item>

                            <Form.Item
                                name='TermAndConditions'
                                label={language?.TermCondition}
                                valuePropName='checked'
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        validator: (_, value) =>
                                            value ? Promise.resolve() : Promise.reject(new Error(language?.Shouldacceptagreement)),
                                    }
                                ]}
                            >
                                <Checkbox />
                            </Form.Item>

                            <div className='agreement scroll_class' style={{ marginBottom: 20 }}>
                                {/* <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eum porro assumenda, in dicta ab fuga, vero quasi nostrum molestiae, corporis perferendis similique quis deleniti ullam. Recusandae sequi quam vitae cumque! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae, nihil cupiditate rerum mollitia, nemo vitae temporibus amet quos esse architecto quod repellat laboriosam explicabo similique, alias beatae odio possimus? Accusamus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque blanditiis eaque saepe odio voluptas obcaecati at voluptatum, aliquid omnis laborum libero numquam architecto sit! Architecto quas ratione alias debitis deserunt!</p> */}
                                <p>{language?.TAC1}</p>
                                <p>{language?.TAC2}</p>
                                <p>{language?.TAC3}</p>
                                <p>{language?.TAC4}</p>
                                <p>{language?.TAC5}</p>
                                <p>{language?.TAC6}</p>
                                <p>{language?.TAC7}</p>
                            </div>


                            <Button loading={loading} className='req_exc_btn' type='primary' htmlType='submit' /* type='primary' */ /* onClick={handleCancel} */>{language?.Register}</Button>
                        </Form>


                    </div>
                </div>
            </Modal>
            <Modal
                open={isModalOpen2}
                onCancel={handleCancel2}
                footer={[<Button className='req_exc_btn' type='primary' onClick={showModal3}>{language?.BuyTicket}</Button>]}
                width={800}
            >
                <h2 style={{ textAlign: 'center' }}>{language?.Purchase}</h2>
                <p style={{ textAlign: 'left' }}>
                    {language?.MembershipToTrade}
                </p>
                <div className='main_modal_con'>
                    <div className='modal_first_panel'>
                        <div className='column-1 box'>
                            <h4>{language?.GolfClubName}</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>East Valley CC</p>
                        </div>
                    </div>
                    <div className='modal_first_panel'>
                        <div className='column-1 box'>
                            <h4>{language?.Type}</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>Company</p>
                        </div>
                    </div>
                    <div className='modal_first_panel'>
                        <div className='column-1 box'>
                            <h4>{language?.MemberFee}/{language?.NonMemberFee}</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>Member Fee 5 million won/ Non-Member Fee 30 millio won</p>
                        </div>
                    </div>
                    <div className='modal_first_panel'>
                        <div className='column-1 box'>
                            <h4>{language?.RightPrice}</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>80 million won</p>
                        </div>
                    </div>
                    <div className='modal_first_panel'>
                        <div className='column-1 box'>
                            <h4>{language?.ReservedDate}</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>2022.4.22 | 13:30</p>
                        </div>
                    </div>
                    <div className='modal_first_panel'>
                        <div className='column-1 box'>
                            <h4>{language?.ExpirationDate}</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>2022.4.22</p>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                open={isModalOpen3}
                onCancel={handleCancel3}
                footer={[<div className='message_modal'><Button
                    className='message_modal_btn'
                    type='primary'
                    onClick={() => {
                        handleOk3(); history.push({
                            pathname: allPaths?.MYPAGE,
                            search: `?membershipTransaction`
                        })
                    }}>{lanKorCondition ? 'Go To My Page' : language?.GoTicketSalesRight}</Button>
                    <Button className='message_modal_btn marginLeft' type='primary' onClick={handleOk3}>{lanKorCondition ? 'Chat Bot' : language?.TalkToUser}</Button></div>]}
            // width={800}
            >
                <h2 style={{ textAlign: 'center' }}>{language?.Completion}</h2>
                <div style={{ padding: '20px' }}>
                    <center><HiCheckCircle className='tick_icon' /></center>
                    <h4 style={{ textAlign: 'center' }}>
                        {language?.ExchangeRentRequest}
                    </h4>
                    <h4 style={{ textAlign: 'center' }}>
                        {language?.PleaseWaitFor}
                    </h4>
                </div>
            </Modal>
        </>
    )
}

export default RegisterModal