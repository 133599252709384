import { Divider, Modal, Typography } from 'antd';
import axios from 'axios';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { POST } from '../../utils/apis';
import { types, typesKr, typesOnlyKr } from '../../utils/constants';
const { Text } = Typography;




const TicketSalesInfo = (props) => {

    const { setshowEditHolding, showEditHolding, user, editData, language, allData, lanKorCondition } = props
    const [ticketInfo, setTicketInfo] = useState([])
    const handleCancel = () => {
        setshowEditHolding(false)
    }

    const getSingleTicket = () => {
        let obj = {
            _id: editData,
            userId: user?._id
        }
        axios.post(`${POST.SINGLE_TICKET_INFO}`, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setTicketInfo(data?.data)
                }
                else {
                    console.log(data?.message)
                }
            })
            .catch((e) => {
                console.log('e', e)
            })
    }
    useEffect(() => {
        getSingleTicket()
    }, [editData])

    useEffect(() => {

    }, [ticketInfo?._id])

    // console.log('allData', allData)

    return (
        <>
            <Modal
                open={showEditHolding}
                // open={true}
                onCancel={handleCancel}
                className='paymentModal'
                footer={null}
                style={{ top: '10px', }}
            >
                <div
                    className='scroll_class'
                    style={{
                        height: 'auto',
                        width: '100%',
                        maxHeight: '85vh',
                        overflowY: 'scroll',
                        marginTop: '12px'
                    }}>
                    <div className='modal_main_div'>
                        <center><h2>{language?.SeeMoreInformation}</h2></center>

                        <div className='paddongThirty' style={{}}>
                            <h2>{language?.Info}</h2>
                            <div className='static_text'>
                                <table>
                                    <tr>
                                        <td style={{ minWidth: 100, maxWidth: 'auto', lineHeight: '20px' }}><Text>{language?.GolfClubName}</Text></td>
                                        <td className='values_style'><Text>{allData && allData?.ticketId?.golfClub?.clubName}</Text></td>
                                    </tr>
                                    <tr>
                                        <td style={{ minWidth: 100, maxWidth: 'auto', lineHeight: '20px' }}><Text>{language?.Type}</Text></td>
                                        <td className='values_style'>
                                            {/* <Text>{allData && allData?.ticketId?.membershipType}</Text> */}
                                            <Text>{lanKorCondition ? (allData?.ticketId?.membershipType) :

                                                typesKr[typesOnlyKr?.indexOf(allData?.ticketId?.membershipType)]?.typesKr ?
                                                    typesKr[typesOnlyKr?.indexOf(allData?.ticketId?.membershipType)]?.typesKr :
                                                    typesKr[types?.indexOf(allData?.ticketId?.membershipType)]?.typesKr
                                            }</Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ minWidth: 100, maxWidth: 'auto', lineHeight: '20px' }}><Text>{language?.JoiningFee}</Text></td>
                                        <td className='values_style'><Text>{allData && allData?.ticketId?.joiningFee + ' ' + language?.KRW}</Text></td>
                                    </tr>
                                    <tr>
                                        <td style={{ minWidth: 100, maxWidth: 'auto', lineHeight: '20px' }}><Text>{language?.JoiningDate}</Text></td>
                                        <td className='values_style'><Text>{`${moment(allData && allData?.ticketId?.joiningDate?.date1).format('YYYY-MM-DD')} ~ ${moment(allData && allData?.ticketId?.joiningDate?.date2).format('YYYY-MM-DD')}`}</Text></td>
                                    </tr>
                                    <tr>
                                        <td style={{ minWidth: 100, maxWidth: 'auto', lineHeight: '20px' }}><Text>{language?.SpecialGift}</Text></td>
                                        <td className='values_style'>
                                            <Text>
                                                {lanKorCondition ? 'Weekday' : '주중'} {allData && allData?.ticketId?.specialGiftWeekDay} {lanKorCondition ? 'time' : '회'} | {allData && allData?.ticketId?.specialGiftWeekDayKRW} {language?.millionwon}
                                            </Text>
                                            <br />
                                            <Text>
                                                {lanKorCondition ? 'Weekend' : '주말'}{allData && allData?.ticketId?.specialGiftWeekEnd}  {lanKorCondition ? 'time' : '회'}| {allData && allData?.ticketId?.specialGiftWeekEndKRW} {language?.millionwon}
                                            </Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ minWidth: 100, maxWidth: 'auto', lineHeight: '20px' }}><Text>{language?.usageFee}</Text></td>
                                        <td className='values_style'><Text>{language?.Member} | {allData?.ticketId?.NONmemberFee} {language?.millionwon} {language?.NonMember} | {allData?.ticketId?.memberFee} {language?.millionwon}</Text></td>
                                    </tr>
                                </table>
                            </div>

                            <Divider style={{ backgroundColor: '#bbb', marginBottom: 20 }} />

                            <h2>{language?.GolfCourse + language?.Info}</h2>

                            <div className='static_text'>
                                <table>
                                    <tr>
                                        <td style={{}}><Text>{language?.address}</Text></td>
                                        <td className='values_style'><Text>{allData && allData?.ticketId?.golfClub?.clubAddress}</Text></td>
                                    </tr>
                                    <tr>
                                        <td style={{}}><Text>{language?.OpeningDate}</Text></td>
                                        <td className='values_style'><Text>{moment(allData && allData?.ticketId?.golfClub?.created).format('YYYY-MM-DD')}</Text></td>
                                    </tr>
                                    <tr>
                                        <td style={{}}><Text>{language?.GreenFee}</Text></td>
                                        <td className='values_style'><Text>{allData && allData?.ticketId?.greenFeeWeekDay} {language?.Weekday} | {allData && allData?.ticketId?.greenFeeWeekEnd} {language?.Weekend}</Text></td>
                                    </tr>
                                    <tr>
                                        <td style={{}}><Text>{language?.CaddyFee}</Text></td>
                                        <td className='values_style'><Text>{allData && allData?.ticketId?.caddyFee + ' ' + language?.KRW}</Text></td>
                                    </tr>
                                    <tr>
                                        <td style={{}}><Text>{language?.PhoneNumber}</Text></td>
                                        <td className='values_style'><Text>{allData && allData?.ticketId?.phoneNumber}</Text></td>
                                    </tr>
                                    <tr>
                                        <td style={{}}><Text>{language?.CartFee}</Text></td>
                                        <td className='values_style'><Text>{allData && allData?.ticketId?.cartFee}</Text></td>
                                    </tr>
                                    <tr>
                                        {/* <td style={{}}><Text>a</Text></td> */}
                                        <td style={{}}><Text>{language?.HomePage}</Text></td>
                                        <td className='values_style'><Text>{allData && allData?.ticketId?.homepage}</Text></td>
                                    </tr>
                                </table>
                            </div>

                            <Divider style={{ backgroundColor: '#bbb', marginBottom: 20 }} />
                            <h2>{language?.AdditionalBenefits}</h2>

                            <div className='static_text'>

                                <table>
                                    <tr>
                                        {allData && allData?.ticketId?.additionalBenefits?.map((v, i) => {
                                            return (
                                                <div className='static_text'>
                                                    <table>
                                                        <tr>
                                                            <td><Text>{language?.BenefitName}</Text></td>
                                                            <td className='values_style'><Text>{v?.benefitName}</Text></td>
                                                        </tr>
                                                        <tr>
                                                            <td><Text>{language?.BenefitContents}</Text></td>
                                                            <td className='values_style'><Text>{v?.benefitContent}</Text></td>
                                                        </tr>
                                                    </table>
                                                </div>

                                            )
                                        })}

                                    </tr>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>

            </Modal>

        </>
    )
}

export default TicketSalesInfo