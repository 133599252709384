import React, { useState } from 'react';
import { Button, Modal, Select, Input, DatePicker } from 'antd';
import { HiPlusCircle, HiCheckCircle } from "react-icons/hi";
import { RiCheckboxBlankFill } from "react-icons/ri";
import { CheckCircleOutlined } from '@ant-design/icons'
import { MyInfoAlert } from '../../utils/helpers';

const { TextArea } = Input;
const ChatAdmin = (props) => {
    const { language, lanKorCondition, user, purchaseData } = props
    let userId = user?._id
    const options = [
        {
            eng: '-From low to high (based on current price)',
            kr: ' 저가순 '
        },
        {
            eng: '-From high to low (based on current price)',
            kr: '고가순'
        },
        {
            eng: 'Latest (based on current price)',
            kr: '최신순'
        },
        {
            eng: 'Order of number of Transactions',
            kr: '거래건이 많은 순'
        }]
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalComp, setIsModalComp] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const showModalComp = () => {
        setIsModalComp(true);
        setIsModalOpen(false);
    };

    const handleOkComp = () => {
        setIsModalComp(false);
    };

    const handleCancelComp = () => {
        setIsModalComp(false);
    };
    const onChange = (value) => {
        console.log(`selected ${value}`);
    };

    const onSearch = (value) => {
        console.log('search:', value);
    };
    const onChangeDate = (date, dateString) => {
        console.log(date, dateString);
    };
    return (
        <>
            {/* {user?._id && user?.telNo && user?.bank && user?.bankAccountNumber ?
                <Button style={purchaseData?.ticketId?.userId === userId ? { opacity: 0.3 } : null} disabled={purchaseData?.ticketId?.userId === userId} className='btn_chat_Admin' onClick={()=> window.open('http://pf.kakao.com/_xdxebxjxj/chat', '_blank')}>
                    {language?.ChatWithAdmin}
                </Button> :
                <Button style={purchaseData?.ticketId?.userId === userId ? { opacity: 0.3 } : null} disabled={purchaseData?.ticketId?.userId === userId} className='btn_chat_Admin' onClick={() => { MyInfoAlert(language) }}>
                    {language?.ChatWithAdmin}
                </Button>} */}
            {user?._id && user?.telNo && user?.bank && user?.bankAccountNumber ?
                <Button className={lanKorCondition ? 'btn_chat_Admin' : 'btn_chat_Admin_kr'} style={purchaseData?.ticketId?.userId === user?._id ? { opacity: 0.3 } : null} disabled={purchaseData?.ticketId?.userId == user?._id} onClick={() => {
                    // history?.push(allPaths?.CHAT)
                    window.open('http://pf.kakao.com/_xdxebxjxj/chat', '_blank')
                }}>
                    {language?.ChatWithAdmin}
                </Button> :
                <Button className={lanKorCondition ? 'btn_chat_Admin' : 'btn_chat_Admin_kr'} style={purchaseData?.ticketId?.userId === user?._id ? { opacity: 0.3 } : null} disabled={purchaseData?.ticketId?.userId == user?._id} onClick={() => { MyInfoAlert(language) }}>
                    {language?.ChatWithAdmin}
                </Button>}
            {/* <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[<Button className='req_exc_btn' type="primary" onClick={showModalComp}>Request Exchange</Button>]}
                width={800}
            >
                <div >
                    <div>
                        <h1 style={{ textAlign: 'center' }}>{language?.LetExchange}</h1>
                        <h3 style={{ textAlign: 'center', color: '#2196f3' }}>{language?.PlzFillBlack}</h3>
                    </div>
                    <div className='main_modal_con'>
                        <div className='modal_first_panel'>
                            <div className="column-1 box">
                                <h4>{language?.GolfClubName}</h4>
                            </div>
                            <div className="column-2 box">
                                <p>East Valley CC</p>
                            </div>
                        </div>
                        <div className='modal_first_panel'>
                            <div className="column-1 box">
                                <h4>{language?.Type}</h4>
                            </div>
                            <div className="column-2 box">
                                <p>Company</p>
                            </div>
                        </div>
                        <div className='modal_first_panel'>
                            <div className="column-1 box">
                                <h4>{language?.MemberFee}/{language?.NonMemberFee}</h4>
                            </div>
                            <div className="column-2 box">
                                <p>Member Fee 5 million won/ Non-Member Fee 30 millio won</p>
                            </div>
                        </div>
                        <div className='modal_first_panel'>
                            <div className="column-1 box">
                                <h4>{language?.RightPrice}</h4>
                            </div>
                            <div className="column-2 box">
                                <p>80 million won</p>
                            </div>
                        </div>
                        <div className='modal_first_panel'>
                            <div className="column-1 box">
                                <h4>{language?.ReservedDate}</h4>
                            </div>
                            <div className="column-2 box">
                                <p>2022.4.22 | 13:30</p>
                            </div>
                        </div>
                        <div className='modal_first_panel'>
                            <div className="column-1 box">
                                <h4>{language?.ExpirationDate}</h4>
                            </div>
                            <div className="column-2 box">
                                <p>2022.4.22</p>
                            </div>
                        </div>
                    </div>
                    <div className='second_panel'>
                        <div className='modal_second_panel'>
                            <div className="column-1 box">
                                <h4 className='font_modal'>{language?.Membership}</h4>
                            </div>
                            <div className="column-2 box">
                                <Select
                                    className='select_drp_down'
                                    showSearch
                                    placeholder={language?.ChooseYourTicket}
                                    optionFilterProp="children"
                                    onChange={onChange}
                                    onSearch={onSearch}
                                    filterOption={(input, option) => option.children.toLowerCase()?.includes(input?.toLowerCase())}
                                >
                                    {
                                        options?.map((v, i) => {
                                            return <Option value={v?.eng}>{lanKorCondition ? v?.eng : v?.kr}</Option>
                                        })

                                    }
                                </Select>
                            </div>
                        </div>
                        <div className='modal_second_panel'>
                            <div className="column-1 box">
                                <h4 className='font_modal'><HiPlusCircle className='plus_icon' />{language?.RightPrice}</h4>
                            </div>
                            <div className="column-2 box">
                                <Input style={{ width: 400 }} suffix="RMB" />
                            </div>
                        </div>
                        <div className='modal_second_panel'>
                            <div className="column-1 box">
                                <h4 className='font_modal'><HiPlusCircle className='plus_icon' />{language?.UsableDate}</h4>
                            </div>
                            <div className="column-2 box">
                                <DatePicker style={{ width: 400, textAlign: 'center' }} onChange={onChangeDate} />
                            </div>
                        </div>
                        <div className='modal_second_panel'>
                            <div className="column-1 box">
                                <h4 className='font_modal'><HiPlusCircle className='plus_icon' />{language?.ExpirationDate}</h4>
                            </div>
                            <div className="column-2 box">
                                <DatePicker style={{ width: 400 }} onChange={onChangeDate} />
                            </div>
                        </div>
                        <div className='modal_second_panel'>
                            <div className="column-1 box">
                                <h4 className='font_modal'><HiPlusCircle className='plus_icon' />{language?.LocationPreference}</h4>
                            </div>
                            <div className="column-2 box">

                            </div>
                        </div>
                        <div className="items">
                            <div className="item"><Button type="primary">{language?.SeoulGyeonggi} {language?.North}</Button></div>
                            <div className="item"><Button type="primary">{language?.SeoulGyeonggi} {language?.South}</Button></div>
                            <div className="item"><Button type="primary">{language?.SeoulGyeonggi} {language?.West}</Button></div>
                            <div className="item"><Button type="primary">{language?.SeoulGyeonggi} {language?.East}</Button></div>
                            <div className="item"><Button type="primary">{language?.Chungbuk}</Button></div>
                        </div>
                        <div>
                            <h4 className='font_modal'>{language?.TermCondition}<RiCheckboxBlankFill className='' style={{ verticalAlign: 'middle' }} /></h4>
                            <TextArea className='text_area' rows={4} />
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                open={isModalComp}
                onOk={handleOkComp}
                onCancel={handleCancelComp}
                footer={null}
            >
                <h2 style={{ textAlign: 'center' }}>Completion</h2>
                <center><HiCheckCircle className='tick_icon' /></center>
                <h4 style={{ textAlign: 'center' }}>
                    Exchange/ rent request delivered to user now.
                </h4>
                <h4 style={{ textAlign: 'center' }}>
                    Please wait for the response
                </h4>
            </Modal> */}
        </>
    );
};

export default ChatAdmin;