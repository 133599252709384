import { Card } from 'antd';
import axios from 'axios';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { HiPlusCircle } from 'react-icons/hi';
import { LoginAlert } from '..';
import noData from '../../assets/noData.gif';
import { GET } from '../../utils/apis';
import { types, typesKr, typesOnlyKr } from '../../utils/constants';
import TicketSalesInfo from '../MyPageTabs/TicketSalesInfo';
import ExchangeModal from './ExchangeModal';
import RentModal from './RentModal';

const TicketShareTab = (props) => {
    const { user, language, ticketnameFilter, ticketPrice, selectedTags, selectedClubs, selectedMemberships, lanKorCondition } = props
    const [ticketData, setTicketData] = useState([])
    const [purchaseData, setPurchaseData] = useState([])
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [showEditHolding, setshowEditHolding] = useState(false)
    const [ticketId, setTicketId] = useState(false)
    const [loading, setLoading] = useState(false)
    const [filteredData, setfilteredData] = useState([])
    const [allData, setAllData] = useState({})
    const [editData, setEditData] = useState(null)
    const getTickets = () => {
        setLoading(true)
        axios.get(`${GET.TICKET_SHARE_RECOMMENDATION}?id=${user?._id}&limit=${0}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setTicketData(data?.data || [])
                }
                else {
                    console.log(data?.message)
                }
                setLoading(false)
            }).catch((err) => {
                setLoading(false)
            })
    }

    const checkFilterData = (v) => {
        if (ticketnameFilter && selectedTags?.length) {
            return (v?.ticketId?.membershipCardName?.toLowerCase()?.includes(ticketnameFilter.trim().toLowerCase())) && (_.difference(selectedTags, v?.ticketId?.locationPreference).length !== selectedTags?.length)
        }
        else if (ticketnameFilter && !selectedTags?.length) {
            return v?.ticketId?.membershipCardName?.toLowerCase()?.includes(ticketnameFilter.trim().toLowerCase())
        }
        else if (!ticketnameFilter && selectedTags?.length) {
            return _.difference(selectedTags, v?.ticketId?.locationPreference).length !== selectedTags?.length
        }
        else {
            return v
        }
    }
    const checkClubsData = (v) => {
        if (selectedClubs?.length) {
            return selectedClubs.includes(v?.ticketId?.golfClub?.clubName)
        }
        else {
            return v
        }
    }
    const checkMembershipType = (v) => {
        if (selectedMemberships?.length) {
            return selectedMemberships.includes(v?.ticketId?.membershipType)
        }
        else {
            return v
        }
    }
    const checkTicketPrice = (v) => {
        if (ticketPrice?.start || ticketPrice?.end) {
            return (ticketPrice?.start < v?.ticketId?.rightPrice && v?.ticketId?.rightPrice < ticketPrice?.end)
        }
        else {
            return v
        }
    }
    useEffect(() => {
        setfilteredData(ticketData.filter(checkFilterData).filter(checkClubsData).filter(checkMembershipType).filter(checkTicketPrice))
    }, [ticketData, ticketnameFilter, selectedTags, selectedClubs, selectedMemberships])
    useEffect(() => {
        getTickets()
    }, [])
    const handleCancel = () => {
        setIsModalOpen2(false);
    }

    console.log('filteredData',filteredData)
    return (
        <div className='ticket_share_panels'>
            <h2 className='head_ticket_share' >{language?.TicketShareRecommendations} - {user?.fullName} {language?.ThisisForYou}</h2>
            {/* <TicketInformation language={language} ticketId={ticketId} setshowEditHolding={setshowEditHolding} {...props} showEditHolding={showEditHolding} /> */}
            {/* <TicketInformationNew language={language} ticketId={ticketId} setshowEditHolding={setshowEditHolding} {...props} showEditHolding={showEditHolding} /> */}

            {showEditHolding ?
                <TicketSalesInfo allData={allData} editData={editData} showEditHolding={showEditHolding} setshowEditHolding={setshowEditHolding} {...props} />
                : null}

            <div className='row_new_cont'>

                {!loading ?
                    filteredData?.length ?
                        filteredData && filteredData?.map((v, i) => {
                            return (
                                <div key={i} className='column_new_cont'>
                                    <span onClick={() => {
                                        setshowEditHolding(true)
                                        setTicketId(v)
                                        setAllData(v)
                                        setEditData(v?._id)
                                    }}>
                                        <div className='card_panel'>
                                            <h5>{language?.GolfClub}</h5>
                                            <h5 className='not_bold'>{v && v?.ticketId?.golfClub?.clubName}</h5>
                                        </div>
                                        <div className='card_panel'>
                                            <h5>{language?.Type}</h5>
                                            <h5 className='not_bold'>
                                                {/* {v && v?.ticketId?.membershipType} */}
                                                {lanKorCondition ? v?.ticketId?.membershipType :
                                                    typesKr[typesOnlyKr?.indexOf(v?.ticketId?.membershipType)]?.typesKr ?
                                                        typesKr[typesOnlyKr?.indexOf(v?.ticketId?.membershipType)]?.typesKr :
                                                        typesKr[types?.indexOf(v?.ticketId?.membershipType)]?.typesKr
                                                }
                                            </h5>
                                        </div>
                                        <div className='card_panel'>
                                            <h5>{language?.MemberFee}</h5>
                                            <h5 className='not_bold'>{v && v?.ticketId?.memberFee}{' '}{language?.Won}</h5>
                                        </div>
                                        <div className='card_panel'>
                                            <h5>{language?.NonMemberFee}</h5>
                                            <h5 className='not_bold'>{v && v?.ticketId?.NONmemberFee}{' '}{language?.Won}</h5>
                                        </div>
                                        <div className='card_panel'>
                                            <h5><HiPlusCircle className='plus_icon' />{language?.InstantPrice}</h5>
                                            <h5 className='not_bold'>{v && v?.rightPrice}{' '}{language?.Won}</h5>
                                        </div>
                                        <div className='card_panel '>
                                            <h5><HiPlusCircle className='plus_icon' />{language?.ReservationDate}</h5>
                                            <h5 className='not_bold'>{moment(v && v?.ticketId?.joiningDate?.date1).format('YYYY-MM-DD')}</h5>

                                        </div>
                                        <div className='card_panel last_des'>
                                            <h5><HiPlusCircle className='plus_icon' />{language?.ExpirationDate}</h5>
                                            <h5 className='not_bold'>{moment(v && v?.ticketId?.joiningDate?.date2).format('YYYY-MM-DD')}</h5>
                                        </div>
                                    </span>
                                    <div className='btn_card'>
                                        {user?._id ? <ExchangeModal shareTicket={v} language={language} {...props} ticketId={v?._id} /> :
                                            // <Button className='btn_exchange1' onClick={() => {
                                            //     loginAlert(language)
                                            // }}>
                                            //     {language?.Exchange}
                                            // </Button>
                                            <LoginAlert clas={'btn_exchange'} buttonName={language?.Exchange} {...props} />

                                        }
                                        {user?._id ? <RentModal language={language} {...props} purchaseData={v} /> :
                                            // <Button className='btn_exchange1' onClick={() => {
                                            //     loginAlert(language)
                                            // }}>
                                            //     {language?.Rent}
                                            // </Button>
                                            <LoginAlert clas={'btn_exchange1'} buttonName={language?.Rent} {...props} />

                                        }
                                    </div>
                                </div>
                            )
                        }) :

                        <div className='NodataDiv'>
                            <img src={noData} className='NodataImg' />
                            <h3>{language?.TicketsNotFound}</h3>
                        </div> :
                    <div className='cards-lodaing-div'>
                        <Card className='column_new_cont' loading={true} />
                        <Card className='column_new_cont' loading={true} />
                        <Card className='column_new_cont' loading={true} />
                        <Card className='column_new_cont' loading={true} />
                    </div>
                }
            </div>
        </div>
    )
}

export default TicketShareTab