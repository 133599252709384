import { Button, Card } from 'antd'
import axios from 'axios'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { HiPlusCircle } from 'react-icons/hi'
import { LoginAlert } from '..'
import loadingImg from '../../assets/loading.gif'
import noData from '../../assets/noData.gif'
import { GET } from '../../utils/apis'
import { types, typesKr, typesOnlyKr } from '../../utils/constants'
import { loginAlert, MyInfoAlert } from '../../utils/helpers'
import TicketSalesInfo from '../MyPageTabs/TicketSalesInfo'
import ChatAdmin from './ChatAdmin'
import ExchangeModal from './ExchangeModal'
import GroupBuyInfo from './GroupBuyInfo'
import GroupBuyParticipate from './GroupBuyParticipate'
import RentModal from './RentModal'
import RequestBuyModal from './RequestBuy'
import TicketShareInfoModal from './TicketShareInfo'

const TodayHotTab = (props) => {
    const { user, ticketPrice, language, ticketnameFilter, lanKorCondition, selectedTags, selectedClubs, selectedMemberships, setclickedViewMore } = props
    const [ticketShare, setTicketShare] = useState([])
    const [ticketSell, setTicketSell] = useState([])
    const [ticketGroupBuy, setTicketGroupBuy] = useState([])
    const [ticketShareLength, setTicketShareLength] = useState(0)
    const [ticketSellLength, setTicketSellLength] = useState(0)
    const [ticketGroupBuyLength, setTicketGroupBuyLength] = useState(0)
    const [loading, setLoading] = useState(false)
    const [ticketShareLoading, setTicketShareLoading] = useState(false)
    const [ticketSellLoading, setTicketSellLoading] = useState(false)
    const [ticketGroupBuyLoading, setTicketGroupBuyLoading] = useState(false)
    const [showMoreData, setShowMoreData] = useState([])
    const [showMoreTitle, setShowMoreTitle] = useState('')
    const [showMoreLoading, setShowMoreLoading] = useState(false)
    const [showEditHolding, setshowEditHolding] = useState(false)
    const [showTicketShareInfo, setshowTicketShareInfo] = useState(false)
    const [editData, setEditData] = useState(null)
    const [allData, setAllData] = useState({})
    const [open, setOpen] = useState(false)
    const [groupBuyInfoData, setGroupBuyInfoData] = useState({})
    const [editModal, setEditModal] = useState(false)
    const [filteredShareData, setfilteredShareData] = useState([])
    const [filteredSellData, setfilteredSellData] = useState([])
    const [filteredGroupBuyData, setfilteredGroupBuyData] = useState([])

    const showGroupInfo = (v) => {
        setGroupBuyInfoData(v)
        setOpen(true)
    }

    const CancelGroupInfo = () => {
        setOpen(false)
    }
    const getTodayHotTicketShare = () => {
        setTicketShareLoading(true)
        axios.get(`${GET.GET_TODAY_HOT_TICKET_SHARE}/?id=${user?._id}&limit=${3}`)
            .then((res) => {
                setTicketShareLength(res?.data?.totalCount)
                const { data } = res
                if (data.success) {
                    setTicketShare(data?.data || [])
                    setTicketShareLength(data?.totalCount)
                }
                else {
                    console.log(data?.message)
                }
                setLoading(false)
                setTicketShareLoading(false)
            }).catch((err) => {
                console.log(err, 'err')
                setLoading(false)
                setTicketShareLoading(false)
            })
    }
    const getTodayHotTicketSale = () => {
        setTicketSellLoading(true)

        axios.get(`${GET.GET_TODAY_HOT_TICKET_SALE}/?id=${user?._id}&limit=${3}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setTicketSell(data?.data || [])
                    setTicketSellLength(data?.totalCount)
                }
                else {
                    console.log(data?.message)
                }
                setTicketSellLoading(false)
                setLoading(false)
            }).catch((err) => {
                console.log(err, 'err')
                setTicketSellLoading(false)
                setLoading(false)
            })
    }
    const getTodayHotTicketGroupBuy = () => {
        setTicketGroupBuyLoading(true)
        axios.get(`${GET.GET_TODAY_HOT_TICKET_GROUP_BUY}/?id=${user?._id}&limit=${3}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setTicketGroupBuy(data?.data || [])
                    setTicketGroupBuyLength(data?.totalCount)
                }
                else {
                    console.log(data?.message)
                }
                setTicketGroupBuyLoading(false)
                setLoading(false)
            }).catch((err) => {
                setTicketGroupBuyLoading(false)
                console.log(err, 'err')
                setLoading(false)
            })
    }
    const showMoreTicket = (Api) => {
        setShowMoreLoading(true)
        axios.get(`${Api}/?id=${user?._id}&limit=${0}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setShowMoreData(data?.data || [])
                }
                else {
                    console.log(data?.message)
                }
                setShowMoreLoading(false)
            }).catch((err) => {
                console.log(err, 'err')
                setShowMoreLoading(false)
            })
    }

    useEffect(() => {
        getTodayHotTicketSale()
        getTodayHotTicketShare()
        getTodayHotTicketGroupBuy()
        setLoading(true)
    }, [])

    const checkFilterShareData = (v) => {
        if (ticketnameFilter && selectedTags?.length) {
            return (v?.ticketId?.membershipCardName?.toLowerCase()?.includes(ticketnameFilter?.trim().toLowerCase())) && (_.difference(selectedTags, v?.ticketId?.locationPreference).length !== selectedTags?.length)
        }
        else if (ticketnameFilter && !selectedTags?.length) {
            return v?.ticketId?.membershipCardName?.toLowerCase()?.includes(ticketnameFilter.trim().toLowerCase())
        }
        else if (!ticketnameFilter && selectedTags?.length) {
            return _.difference(selectedTags, v?.ticketId?.locationPreference).length !== selectedTags?.length
        }
        else {
            return v
        }
    }
    const checkFilterSellData = (v) => {
        if (ticketnameFilter && selectedTags?.length) {
            return (v?.ticketId?.membershipCardName?.toLowerCase()?.includes(ticketnameFilter.trim().toLowerCase())) && (_.difference(selectedTags, v?.ticketId?.locationPreference).length !== selectedTags?.length)
        }
        else if (ticketnameFilter && !selectedTags?.length) {
            return v?.ticketId?.membershipCardName?.toLowerCase()?.includes(ticketnameFilter.trim().toLowerCase())
        }
        else if (!ticketnameFilter && selectedTags?.length) {
            return _.difference(selectedTags, v?.ticketId?.locationPreference).length !== selectedTags?.length
        }
        else {
            return v
        }
    }
    const checkFilterGroupBuyData = (v) => {
        if (ticketnameFilter && selectedTags?.length) {
            return (v?.ticketId?.membershipCardName?.toLowerCase()?.includes(ticketnameFilter.trim().toLowerCase())) && (_.difference(selectedTags, v?.ticketId?.locationPreference).length !== selectedTags?.length)
        }
        else if (ticketnameFilter && !selectedTags?.length) {
            return v?.ticketId?.membershipCardName?.toLowerCase()?.includes(ticketnameFilter.trim().toLowerCase())
        }
        else if (!ticketnameFilter && selectedTags?.length) {
            return _.difference(selectedTags, v?.ticketId?.locationPreference).length !== selectedTags?.length
        }
        else {
            return v
        }
    }

    const checkClubsData = (v) => {
        if (selectedClubs?.length) {
            return selectedClubs.includes(v?.ticketId?.golfClub?.clubName)
        }
        else {
            return v
        }
    }
    const checkMembershipType = (v) => {
        if (selectedMemberships?.length) {
            return selectedMemberships.includes(v?.ticketId?.membershipType)
        }
        else {
            return v
        }
    }
    const checkTicketPrice = (v) => {
        if (ticketPrice?.start && ticketPrice?.end) {
            return (ticketPrice?.start < v?.rightPrice && v?.rightPrice < ticketPrice?.end)
        }
        else {
            return v
        }
    }


    useEffect(() => {
        setfilteredShareData(ticketShare.filter(checkFilterShareData).filter(checkClubsData).filter(checkMembershipType).filter(checkTicketPrice))
        setfilteredSellData(ticketSell.filter(checkFilterSellData).filter(checkClubsData).filter(checkMembershipType).filter(checkTicketPrice))
        setfilteredGroupBuyData(ticketGroupBuy.filter(checkFilterGroupBuyData).filter(checkClubsData).filter(checkMembershipType).filter(checkTicketPrice))
    }, [ticketShare, ticketSell, ticketGroupBuy, selectedClubs, ticketnameFilter, selectedTags, selectedMemberships])

    return (
        <div className='ticket_share_panels'>
            <h2 className='head_ticket_share' >{language?.TodayHot}</h2>
            <GroupBuyInfo language={language} CancelGroupInfo={CancelGroupInfo} GroupBuyInfoData={groupBuyInfoData} open={open} {...props} />
            <TicketSalesInfo language={language} allData={allData} editData={editData} editModal={editModal} showEditHolding={showEditHolding} setshowEditHolding={setshowEditHolding} {...props} />
            <TicketSalesInfo language={language} allData={allData} editData={editData} editModal={editModal} showEditHolding={showTicketShareInfo} setshowEditHolding={setshowTicketShareInfo} {...props} />
            {/* <TicketShareInfoModal lanKorCondition={lanKorCondition} language={language} All_Data={allData} showTicketShare={showTicketShareInfo} setshowTicketShare={setshowTicketShareInfo} {...props} /> */}

            {!showMoreTitle ?
                !loading ?
                    // <div >
                    <div className='pricing'>
                        <div className='pricing-plan'>
                            {/* <div > */}
                            <div className='top_head_card'>
                                <h4>
                                    {language?.TicketSharing}
                                </h4>
                                {/* {ticketShareLength > 3 ? */}
                                <>
                                    <p style={{
                                        cursor: 'pointer',
                                        color: '#2196f3', textDecoration: 'underline'
                                    }} onClick={() => {
                                        setclickedViewMore(true)
                                        setShowMoreTitle(lanKorCondition ? 'Ticket Sharing' : '회원권 공유')
                                        showMoreTicket(GET?.GET_TODAY_HOT_TICKET_SHARE)
                                    }}>
                                        {language?.VeiwMore}
                                    </p>
                                </>
                                {/* : null} */}
                            </div>
                            <div className='todayhotCardsDiv scroll_class_Today_Hot'>
                                {!ticketShareLoading ?
                                    filteredShareData?.length ?
                                        filteredShareData?.map((val, ind) => {
                                            return (
                                                <div className='height Today_hot_column_new_cont ' >
                                                    <span onClick={() => {
                                                        setshowTicketShareInfo(true)
                                                        setAllData(val)
                                                    }}>
                                                        <div className='card_panel'>
                                                            <h5>{language?.GolfClub}</h5>
                                                            <h5 className='not_bold'>{val && val?.ticketId?.golfClub?.clubName || '----'}</h5>
                                                        </div>
                                                        <div className='card_panel'>
                                                            <h5>{language?.Type}</h5>
                                                            <h5 className='not_bold'>{val?.ticketId?.membershipType ? lanKorCondition ?
                                                                val?.ticketId?.membershipType :
                                                                typesKr[typesOnlyKr?.indexOf(val?.ticketId?.membershipType)]?.typesKr ?
                                                                    typesKr[typesOnlyKr?.indexOf(val?.ticketId?.membershipType)]?.typesKr :
                                                                    typesKr[types?.indexOf(val?.ticketId?.membershipType)]?.typesKr
                                                                : '----'}</h5>
                                                        </div>
                                                        <div className='card_panel'>
                                                            <h5>{language?.MemberFee}</h5>
                                                            <h5 className='not_bold'>{val && val?.ticketId?.memberFee || '----'}{language?.Won}</h5>
                                                        </div>
                                                        <div className='card_panel'>
                                                            <h5>{language?.NonMemberFee}</h5>
                                                            <h5 className='not_bold'>{val && val?.ticketId?.NONmemberFee || '----'} {language?.Won}</h5>
                                                        </div>
                                                        <div className='card_panel'>
                                                            <h5><HiPlusCircle className='plus_icon' />{language?.rightprice}</h5>
                                                            <h5 className='not_bold'>{val && val?.rightPrice || '----'} {language?.Won}</h5>
                                                        </div>
                                                        <div className='card_panel '>
                                                            <h5><HiPlusCircle className='plus_icon' />{language?.ReservationDate}</h5>
                                                            <h5 className='not_bold'>{moment(val && val?.created).format('YYYY-MM-DD') || '----'}</h5>
                                                        </div>
                                                        <div className='card_panel last_des'>
                                                            <h5><HiPlusCircle className='plus_icon' />{language?.ExpirationDate}</h5>
                                                            <h5 className='not_bold'>{moment(val && val?.ticketId?.expirationDate).format('YYYY-MM-DD') || '----'}</h5>
                                                        </div>
                                                    </span>
                                                    <br className='brbr' />
                                                    <br className='brbr' />
                                                    <div className='btn_card'>
                                                        {user?._id ?
                                                            <ExchangeModal language={language} ticketId={val?.ticketId?._id} shareTicket={val} {...props} /> :
                                                            <Button className={'btn_exchange'} onClick={() => { MyInfoAlert(language) }} >{language?.Exchange}</Button>

                                                        }
                                                        {user?._id ?
                                                            <RentModal  {...props} purchaseData={val} language={language} /> :
                                                            <LoginAlert clas={'btn_exchange'} buttonName={language?.Rent} {...props} />

                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }) :
                                        <div className='NodataDiv'>
                                            <img src={noData} className='NodataImg' />
                                            <h3>{language?.ThereNoTicket}</h3>
                                        </div> :
                                    <div className='NodataDiv'>
                                        <Card className='Today_hot_column_new_cont' loading={true} />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='pricing-plan'>
                            {/* <div > */}
                            <div className='top_head_card'>
                                <h4>
                                    {language?.GroupBuy}
                                </h4>
                                {/* {ticketGroupBuyLength > 3 ? */}
                                <>
                                    <p style={{
                                        cursor: 'pointer',
                                        color: '#2196f3', textDecoration: 'underline'
                                    }} onClick={() => {
                                        setclickedViewMore(true)
                                        setShowMoreTitle('Group buy')
                                        showMoreTicket(GET.GET_TODAY_HOT_TICKET_GROUP_BUY)
                                    }}>
                                        {language?.VeiwMore}
                                    </p>
                                </>
                                {/* : null} */}
                            </div>
                            <div className='todayhotCardsDiv scroll_class_Today_Hot'>
                                {!ticketGroupBuyLoading ?
                                    filteredGroupBuyData?.length ?
                                        filteredGroupBuyData?.map((v, ind) => {
                                            // console.log(val, 'val')
                                            return (
                                                <div className='  height Today_hot_column_new_cont' >
                                                    <span onClick={() => showGroupInfo(v)} >
                                                        <div className='card_panel'>
                                                            <h5>{language?.GolfClub}</h5>
                                                            <h5 className='not_bold'>{v?.ticketId?.golfClub?.clubName}</h5>
                                                        </div>
                                                        <div className='card_panel'>
                                                            <h5>{language?.Type}</h5>
                                                            <h5 className='not_bold'>{v?.ticketId?.membershipType ? lanKorCondition ? v?.ticketId?.membershipType :
                                                                typesKr[typesOnlyKr?.indexOf(v?.ticketId?.membershipType)]?.typesKr ?
                                                                    typesKr[typesOnlyKr?.indexOf(v?.ticketId?.membershipType)]?.typesKr :
                                                                    typesKr[types?.indexOf(v?.ticketId?.membershipType)]?.typesKr
                                                                : '----'}</h5>

                                                        </div>
                                                        <div className='card_panel'>
                                                            <h5>{language?.JoiningFee}</h5>
                                                            <h5 className='not_bold'>{v?.ticketId?.joiningFee}{language?.Won}</h5>
                                                        </div>
                                                        <div className='card_panel'>
                                                            <h5>{language?.Timeperiod}</h5>
                                                            <h5 className='not_bold'>{`${moment(v && v?.ticketId?.joiningDate?.date1).format('YYYY-MM-DD')} ~ ${moment(v && v?.ticketId?.joiningDate?.date2).format('YYYY-MM-DD')}`}</h5>
                                                        </div>
                                                        <div className='card_panel last_des'>
                                                            <h5><HiPlusCircle className='plus_icon' />{language?.TotalSeatsAvailable}</h5>
                                                            <h5 className='not_bold'>{v?.ticketId?.totalSeats - v?.ticketId?.usedSeats?.length} {language?.Account}</h5>
                                                        </div>
                                                        <div className='card_panel '>
                                                            <h5><HiPlusCircle className='plus_icon' />{language?.SeatPrice}</h5>
                                                            <h5 className='not_bold'>{v?.ticketId?.totalSeatsPrice / v?.ticketId?.totalSeats} {language?.Won}</h5>
                                                        </div>
                                                        <div className='card_panel last_des'>
                                                            <h5><HiPlusCircle className='plus_icon' />{language?.SpecialGiftTIKSALE}</h5>
                                                        </div>
                                                        <div className='card_panel last_des'>
                                                            {lanKorCondition ?
                                                                <h5 className='not_bold'> Weekday {v && v?.ticketId?.specialGiftWeekDay}  회/  {v && v?.ticketId?.specialGiftWeekDayKRW} KRW</h5>
                                                                :
                                                                <h5 className='not_bold'>주중{v && v?.ticketId?.specialGiftWeekDay}회/ {v && v?.ticketId?.specialGiftWeekDayKRW}만원</h5>
                                                            }
                                                        </div>
                                                        <div className='card_panel last_des' style={{ textAlign: 'left' }}>
                                                            {lanKorCondition ?
                                                                <h5 className='not_bold'> Weekend {v && v?.ticketId?.specialGiftWeekEnd}  회/{v && v?.ticketId?.specialGiftWeekEndKRW}KRW</h5>
                                                                :
                                                                <h5 className='not_bold'> 주말  {v && v?.ticketId?.specialGiftWeekEnd}회/{v && v?.ticketId?.specialGiftWeekEndKRW}만원</h5>
                                                            }
                                                        </div>
                                                    </span>

                                                    <div className='btn_card'>
                                                        {user?._id ?
                                                            <GroupBuyParticipate language={language} participateData={v} {...props} /> :
                                                            <LoginAlert clas={'btn_participate'} buttonName={language?.Participate} {...props} />

                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className='NodataDiv'>
                                            <img src={noData} className='NodataImg' />
                                            <h3>{language?.ThereNoTicket}</h3>
                                        </div> :
                                    <div className='NodataDiv'>
                                        <Card className='Today_hot_column_new_cont' loading={true} />
                                    </div>}
                            </div >
                        </div>
                        <div className='pricing-plan'>
                            {/* <div > */}
                            <div className='top_head_card'>
                                <h4>
                                    {language?.TicketSale}
                                </h4>

                                {/* {ticketSellLength > 3 ? */}
                                <>
                                    <p style={{
                                        cursor: 'pointer',
                                        color: '#2196f3', textDecoration: 'underline'
                                    }} onClick={() => {
                                        setclickedViewMore(true)
                                        setShowMoreTitle(lanKorCondition ? 'Ticket Sale' : '회원권 매매')
                                        showMoreTicket(GET?.GET_TODAY_HOT_TICKET_SALE)
                                    }}>
                                        {language?.VeiwMore}
                                    </p>
                                </>
                                {/* : null} */}
                            </div>
                            <div className='todayhotCardsDiv scroll_class_Today_Hot'>
                                {!ticketSellLoading ?
                                    filteredSellData?.length ?
                                        filteredSellData?.map((v, ind) => {

                                            return (
                                                <div className="  height Today_hot_column_new_cont" >
                                                    <span onClick={() => {
                                                        setshowEditHolding(true)
                                                        // setEditData(v?._id)
                                                        setAllData(v)
                                                    }}>
                                                        <div className='card_panel'>
                                                            <h5>{language?.GolfClub}</h5>
                                                            <h5 className='not_bold'>{v && v?.ticketId?.golfClub?.clubName}</h5>
                                                        </div>
                                                        <div className='card_panel'>
                                                            <h5>{language?.Type}</h5>
                                                            <h5 className='not_bold'>{v?.ticketId?.membershipType ? lanKorCondition ? v?.ticketId?.membershipType :
                                                                typesKr[typesOnlyKr?.indexOf(v?.ticketId?.membershipType)]?.typesKr ?
                                                                    typesKr[typesOnlyKr?.indexOf(v?.ticketId?.membershipType)]?.typesKr :
                                                                    typesKr[types?.indexOf(v?.ticketId?.membershipType)]?.typesKr
                                                                : '----'}</h5>
                                                        </div>
                                                        <div className='card_panel'>
                                                            <h5>{language?.JoiningFee}</h5>
                                                            <h5 className='not_bold'>{v && v?.ticketId?.joiningFee}{language?.Won}</h5>
                                                        </div>
                                                        <div className='card_panel'>
                                                            <h5>{language?.Timeperiod}</h5>
                                                            <h5 className='not_bold'>{`${moment(v && v?.ticketId?.joiningDate?.date1).format('YYYY-MM-DD')} ~ ${moment(v && v?.ticketId?.joiningDate?.date2).format('YYYY-MM-DD')}`} </h5>
                                                        </div>
                                                        <div className='card_panel last_des'>
                                                            <h5><HiPlusCircle className='plus_icon' />{language?.SpecialGift}</h5>
                                                        </div>
                                                        <div className='card_panel last_des'>
                                                            {lanKorCondition ?
                                                                <h5 className='not_bold'> Weekday {v && v?.ticketId?.specialGiftWeekDay}  회/  {v && v?.ticketId?.specialGiftWeekDayKRW} KRW</h5>
                                                                :
                                                                <h5 className='not_bold'>주중{v && v?.ticketId?.specialGiftWeekDay}회/ {v && v?.ticketId?.specialGiftWeekDayKRW}만원</h5>
                                                            }
                                                        </div>
                                                        <div className='card_panel last_des' style={{ textAlign: 'left' }}>
                                                            {lanKorCondition ?
                                                                <h5 className='not_bold'> Weekend {v && v?.ticketId?.specialGiftWeekEnd}  회/{v && v?.ticketId?.specialGiftWeekEndKRW}KRW</h5>
                                                                :
                                                                <h5 className='not_bold'> 주말  {v && v?.ticketId?.specialGiftWeekEnd}회/{v && v?.ticketId?.specialGiftWeekEndKRW}만원</h5>
                                                            }
                                                        </div>
                                                    </span>
                                                    <br className='brbr' />
                                                    <br className='brbr' />
                                                    <div className='btn_card'>
                                                        {user?._id ?
                                                            <ChatAdmin {...props} language={language} /> :
                                                            // <Button className='btn_chat_Admin' onClick={() => {
                                                            //     loginAlert(language)
                                                            // }}>
                                                            //     {language?.ChatWithAdmin}

                                                            // </Button>
                                                            <LoginAlert clas={'btn_chat_Admin'} buttonName={language?.ChatWithAdmin} {...props} />
                                                        }
                                                        {user?._id ?
                                                            <RequestBuyModal language={language} purchaseData={v} {...props} /> :
                                                            // <Button className='btn_exchange' onClick={() => {
                                                            //     loginAlert(language)
                                                            // }}>
                                                            //     {language?.RequestBuyGo}
                                                            // </Button>
                                                            <LoginAlert clas={'btn_exchange'} buttonName={language?.RequestBuyGo} {...props} />

                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }) :
                                        <div className='NodataDiv'>
                                            <img src={noData} className='NodataImg' />
                                            <h3>{language?.ThereNoTicket}</h3>
                                        </div> :
                                    <div className='NodataDiv'>
                                        <Card className='Today_hot_column_new_cont' loading={true} />
                                    </div>
                                }
                            </div >

                        </div>
                    </div> :
                    <div className='NodataDiv'>
                        <img src={loadingImg} className='NodataImg' />
                        <h3>Loading...</h3>
                    </div> : null}

            {showMoreTitle ?
                <>
                    <div className='top_head_card'>
                        <h4>
                            {showMoreTitle}
                        </h4>
                        <p style={{
                            cursor: 'pointer',
                            color: '#2196f3', textDecoration: 'underline'
                        }}
                            onClick={() => {
                                setclickedViewMore(false);
                                setShowMoreData([]);
                                setShowMoreTitle('')
                            }}
                        >
                            {language?.Showless}
                        </p>
                    </div>
                    {showMoreTitle === 'Group buy' ?
                        <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                            {!showMoreLoading ?
                                showMoreData && showMoreData.map((v, ind) => {
                                    return (
                                        <div className='column_new_cont height' >
                                            <span onClick={() => showGroupInfo(v)} >
                                                <div className='card_panel'>
                                                    <h5>{language?.GolfClub}</h5>
                                                    <h5 className='not_bold'>{v?.ticketId?.golfClub?.clubName}</h5>
                                                </div>
                                                <div className='card_panel'>
                                                    <h5>{language?.Type}</h5>
                                                    <h5 className='not_bold'>{v?.ticketId?.membershipType ? lanKorCondition ? v?.ticketId?.membershipType :
                                                        typesKr[typesOnlyKr?.indexOf(v?.ticketId?.membershipType)]?.typesKr ?
                                                            typesKr[typesOnlyKr?.indexOf(v?.ticketId?.membershipType)]?.typesKr :
                                                            typesKr[types?.indexOf(v?.ticketId?.membershipType)]?.typesKr
                                                        : '----'}</h5>
                                                </div>
                                                <div className='card_panel'>
                                                    <h5>{language?.JoiningFee}</h5>
                                                    <h5 className='not_bold'>{v?.ticketId?.joiningFee}{language?.Won}</h5>
                                                </div>
                                                <div className='card_panel'>
                                                    <h5>{language?.Timeperiod}</h5>
                                                    <h5 className='not_bold'>{`${moment(v && v?.ticketId?.joiningDate?.date1).format('YYYY-MM-DD')} ~ ${moment(v && v?.ticketId?.joiningDate?.date2).format('YYYY-MM-DD')}`}</h5>
                                                </div>
                                                <div className='card_panel last_des'>
                                                    <h5><HiPlusCircle className='plus_icon' />{language?.TotalSeatsAvailable}</h5>
                                                    <h5 className='not_bold'>{v?.ticketId?.totalSeats - v?.ticketId?.usedSeats?.length} {language?.Account}</h5>
                                                </div>
                                                <div className='card_panel '>
                                                    <h5><HiPlusCircle className='plus_icon' />{language?.SeatPrice}</h5>
                                                    <h5 className='not_bold'>{v?.ticketId?.totalSeatsPrice / v?.ticketId?.totalSeats} won</h5>
                                                </div>
                                                <div className='card_panel last_des'>
                                                    <h5><HiPlusCircle className='plus_icon' />{language?.SpecialGiftTIKSALE}</h5>
                                                </div>
                                                <div className='card_panel last_des'>
                                                    {lanKorCondition ?
                                                        <h5 className='not_bold'> Weekday {v && v?.ticketId?.specialGiftWeekDay}  회/  {v && v?.ticketId?.specialGiftWeekDayKRW} KRW</h5>
                                                        :
                                                        <h5 className='not_bold'>주중{v && v?.ticketId?.specialGiftWeekDay}회/ {v && v?.ticketId?.specialGiftWeekDayKRW}만원</h5>
                                                    }
                                                </div>
                                                <div className='card_panel last_des' style={{ textAlign: 'left' }}>
                                                    {lanKorCondition ?
                                                        <h5 className='not_bold'> Weekend {v && v?.ticketId?.specialGiftWeekEnd}  회/{v && v?.ticketId?.specialGiftWeekEndKRW}KRW</h5>
                                                        :
                                                        <h5 className='not_bold'> 주말  {v && v?.ticketId?.specialGiftWeekEnd}회/{v && v?.ticketId?.specialGiftWeekEndKRW}만원</h5>
                                                    }
                                                </div>
                                            </span>
                                            <div className='btn_card'>
                                                {user?._id ?
                                                    <GroupBuyParticipate language={language} participateData={v} {...props} /> :
                                                    // <Button className='btn_participate' style={{ width: '100%' }} onClick={() => loginAlert(language)}>
                                                    //     {language?.Participate}
                                                    // </Button>
                                                    <LoginAlert clas={'btn_participate'} buttonName={language?.Participate} {...props} />

                                                }
                                            </div>
                                        </div>
                                    )
                                }) :
                                <div className='NodataDiv'>
                                    <Card className='Today_hot_column_new_cont' loading={true} />
                                </div>}
                        </div>
                        : showMoreTitle === 'Ticket Sharing' ?
                            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                                {!showMoreLoading ?
                                    showMoreData && showMoreData.map((val, ind) => {
                                        return (
                                            <div className='column_new_cont height' >
                                                <span onClick={() => {
                                                    setshowTicketShareInfo(true)
                                                    setAllData(val)
                                                }}>
                                                    <div className='card_panel'>
                                                        <h5>{language?.GolfClub}</h5>
                                                        <h5 className='not_bold'>{val && val?.ticketId?.golfClub?.clubName || '----'}</h5>
                                                    </div>
                                                    <div className='card_panel'>
                                                        <h5>{language?.Type}</h5>
                                                        <h5 className='not_bold'>{val?.ticketId?.membershipType ? lanKorCondition ? val?.ticketId?.membershipType :
                                                            typesKr[typesOnlyKr?.indexOf(val?.ticketId?.membershipType)]?.typesKr ?
                                                                typesKr[typesOnlyKr?.indexOf(val?.ticketId?.membershipType)]?.typesKr :
                                                                typesKr[types?.indexOf(val?.ticketId?.membershipType)]?.typesKr
                                                            : '----'}</h5>
                                                    </div>
                                                    <div className='card_panel'>
                                                        <h5>{language?.MemberFee}</h5>
                                                        <h5 className='not_bold'>{val && val?.ticketId?.memberFee || '----'}{language?.Won}</h5>
                                                    </div>
                                                    <div className='card_panel'>
                                                        <h5>{language?.NonMemberFee}</h5>
                                                        <h5 className='not_bold'>{val && val?.ticketId?.NONmemberFee || '----'} {language?.Won}</h5>
                                                    </div>
                                                    <div className='card_panel'>
                                                        <h5><HiPlusCircle className='plus_icon' />{language?.rightprice}</h5>
                                                        <h5 className='not_bold'>{val && val?.ticketId?.rightPrice || '----'} {language?.Won}</h5>
                                                    </div>
                                                    <div className='card_panel '>
                                                        <h5><HiPlusCircle className='plus_icon' />{language?.ReservationDate}</h5>
                                                        <h5 className='not_bold'>{moment(val && val?.created).format('YYYY-MM-DD') || '----'}</h5>
                                                    </div>
                                                    <div className='card_panel last_des'>
                                                        <h5><HiPlusCircle className='plus_icon' />{language?.ExpirationDate}</h5>
                                                        <h5 className='not_bold'>{moment(val && val?.ticketId?.expirationDate).format('YYYY-MM-DD') || '----'}</h5>
                                                    </div>
                                                </span>
                                                <br className='brbr' />
                                                <br className='brbr' />
                                                <br className='brbr' />
                                                <div className='btn_card'>
                                                    {user?._id ?
                                                        <ExchangeModal language={language} ticketId={val?.ticketId?._id} shareTicket={val} {...props} /> : <Button className='btn_exchange' onClick={() => loginAlert(language)}>
                                                            {language?.Exchange}
                                                        </Button>}
                                                    {user?._id ? <RentModal  {...props} purchaseData={val} language={language} /> :
                                                        // <Button className='btn_exchange' onClick={() => loginAlert(language)}>
                                                        //     {language?.Rent}
                                                        // </Button>
                                                        <LoginAlert clas={'btn_exchange'} buttonName={language?.Rent} {...props} />
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }) :
                                    <div className='NodataDiv'>
                                        <Card className='Today_hot_column_new_cont' loading={true} />
                                    </div>}
                            </div>
                            :
                            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                                {!showMoreLoading ?
                                    showMoreData && showMoreData.map((v, ind) => {
                                        return (
                                            <div className="column_new_cont" >
                                                <span onClick={() => {
                                                    setshowEditHolding(true)
                                                    setEditData(v?._id)
                                                    setAllData(v)
                                                }}>
                                                    <div className='card_panel'>
                                                        <h5>{language?.GolfClub}</h5>
                                                        <h5 className='not_bold'>{v && v?.ticketId?.golfClub?.clubName}</h5>
                                                    </div>
                                                    <div className='card_panel'>
                                                        <h5>{language?.Type}</h5>
                                                        <h5 className='not_bold'>{v?.ticketId?.membershipType ? lanKorCondition ? v?.ticketId?.membershipType :
                                                            typesKr[typesOnlyKr?.indexOf(v?.ticketId?.membershipType)]?.typesKr ?
                                                                typesKr[typesOnlyKr?.indexOf(v?.ticketId?.membershipType)]?.typesKr :
                                                                typesKr[types?.indexOf(v?.ticketId?.membershipType)]?.typesKr
                                                            : '----'}</h5>
                                                    </div>
                                                    <div className='card_panel'>
                                                        <h5>{language?.JoiningFee}</h5>
                                                        <h5 className='not_bold'>{v && v?.ticketId?.joiningFee}{language?.Won}</h5>
                                                    </div>
                                                    <div className='card_panel'>
                                                        <h5>{language?.Timeperiod}</h5>
                                                        <h5 className='not_bold'>{`${moment(v && v?.ticketId?.joiningDate?.date1).format('YYYY-MM-DD')} ~ ${moment(v && v?.ticketId?.joiningDate?.date2).format('YYYY-MM-DD')}`} </h5>
                                                    </div>
                                                    <div className='card_panel last_des'>
                                                        <h5><HiPlusCircle className='plus_icon' />{language?.rightprice}</h5>
                                                    </div>
                                                    <div className='card_panel last_des'>
                                                        {lanKorCondition ?
                                                            <h5 className='not_bold'> Weekday {v && v?.ticketId?.specialGiftWeekDay}  회/  {v && v?.ticketId?.specialGiftWeekDayKRW} KRW</h5>
                                                            :
                                                            <h5 className='not_bold'>주중{v && v?.ticketId?.specialGiftWeekDay}회/ {v && v?.ticketId?.specialGiftWeekDayKRW}만원</h5>
                                                        }
                                                    </div>
                                                    <div className='card_panel last_des' style={{ textAlign: 'left' }}>
                                                        {lanKorCondition ?
                                                            <h5 className='not_bold'> Weekend {v && v?.ticketId?.specialGiftWeekEnd}  회/{v && v?.ticketId?.specialGiftWeekEndKRW}KRW</h5>
                                                            :
                                                            <h5 className='not_bold'> 주말  {v && v?.ticketId?.specialGiftWeekEnd}회/{v && v?.ticketId?.specialGiftWeekEndKRW}만원</h5>
                                                        }
                                                    </div>
                                                </span>
                                                <div className='btn_card'>
                                                    {user?._id ?
                                                        <ChatAdmin user={user} {...props} language={language} purchaseData={v} /> : <Button className='btn_chat_Admin' onClick={() => loginAlert(language)}>
                                                            {language?.ChatWithAdmin}
                                                        </Button>}
                                                    {user?._id ?
                                                        <RequestBuyModal language={language} purchaseData={v} {...props} /> :
                                                        // <Button className='btn_exchange' onClick={() => loginAlert(language)}>
                                                        //     {language?.RequestBuyGo}
                                                        // </Button>
                                                        <LoginAlert clas={'btn_exchange'} buttonName={language?.RequestBuyGo} {...props} />

                                                    }
                                                </div>
                                            </div>
                                        )
                                    }) :
                                    <div className='NodataDiv'>
                                        <Card className='Today_hot_column_new_cont' loading={true} />
                                    </div>}
                            </div>}
                </> : null}
        </div >
    )
}

export default TodayHotTab