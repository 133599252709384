import { SELL_TYPE } from '../types'

const setSellType = (sellType) => {
    console.log('sellType', sellType)
    return {
        type: SELL_TYPE,
        sellType
    }
}

export {
    setSellType
}
