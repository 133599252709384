import { PRICE_FILTER } from '../types'

const setPriceFilter = (ticketPrice) => {
    return {
        type: PRICE_FILTER,
        ticketPrice
    }
}

export {
    setPriceFilter
}
