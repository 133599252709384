import { Modal } from 'antd';
import moment from 'moment-timezone';
import React from 'react';
import { types, typesKr, typesOnlyKr } from '../../utils/constants';
const GroupBuyInfo = (props) => {
    const { GroupBuyInfoData, CancelGroupInfo, open, language, lanKorCondition } = props

    // console.log('GroupBuyInfoData', GroupBuyInfoData)


    return (
        <>
            <Modal
                open={open}
                onCancel={CancelGroupInfo}
                footer={null}
                style={{ top: '10px', }}
            >
                <div
                    className='scroll_class'
                    style={{
                        height: 'auto',
                        width: '100%',
                        maxHeight: '85vh',
                        overflowY: 'scroll',
                        marginTop: '12px'
                    }}>
                    <h2 style={{ textAlign: 'center' }}>{lanKorCondition ? 'GroupBuyInformation' : '공동 구매 티켓 정보'}</h2>
                    <p style={{ paddingLeft: 14 }}>
                        {language?.MembershipToTrade}
                    </p>
                    <div className='main_modal_con' style={{ padding: 10 }}>
                        <div className='modal_first_panel purchaseDir'>
                            <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                <h4>{language?.GolfClubName}</h4>
                            </div>
                            <div className='column-2 box'>
                                <p>{GroupBuyInfoData?.golfClub?.clubName}</p>
                            </div>
                        </div>
                        <div className='modal_first_panel purchaseDir'>
                            <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                <h4>{language?.Type}</h4>
                            </div>
                            <div className='column-2 box'>
                                <p>{GroupBuyInfoData?.membershipType ? lanKorCondition ? GroupBuyInfoData?.membershipType :
                                    typesKr[typesOnlyKr?.indexOf(GroupBuyInfoData?.membershipType)]?.typesKr ?
                                        typesKr[typesOnlyKr?.indexOf(GroupBuyInfoData?.membershipType)]?.typesKr :
                                        typesKr[types?.indexOf(GroupBuyInfoData?.membershipType)]?.typesKr
                                    : '----'}</p>
                            </div>
                        </div>
                        <div className='modal_first_panel purchaseDir'>
                            <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                <h4>{language?.JoiningFee}</h4>
                            </div>
                            <div className='column-2 box'>
                                <p>{GroupBuyInfoData?.joiningFee}{' ' + language?.Won}</p>
                            </div>
                        </div>
                        <div className='modal_first_panel purchaseDir'>
                            <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                <h4>{language?.JoiningDate}</h4>
                            </div>
                            <div className='column-2 box'>
                                <p>{`${moment(GroupBuyInfoData && GroupBuyInfoData?.joiningDate?.date1).format('YYYY-MM-DD')} ~ ${moment(GroupBuyInfoData && GroupBuyInfoData?.joiningDate?.date2).format('YYYY-MM-DD')}`}</p>
                            </div>
                        </div>
                        <div className='modal_first_panel purchaseDir'>
                            <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                <h4>{language?.TotalSeatsAvailable}</h4>
                            </div>
                            <div className='column-2 box'>
                                <p>{GroupBuyInfoData?.totalSeats - GroupBuyInfoData?.usedSeats?.length}{language?.Account}</p>
                            </div>
                        </div>
                        <div className='modal_first_panel purchaseDir'>
                            <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                <h4>{language?.SeatPrice}</h4>
                            </div>
                            <div className='column-2 box'>
                                <p>{GroupBuyInfoData?.totalSeatsPrice / GroupBuyInfoData?.totalSeats}{' ' + language?.Won}</p>
                            </div>
                        </div>

                        <div className='modal_first_panel purchaseDir'>
                            <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                <h4>{language?.SpecialGift}</h4>
                            </div>
                            <div className='column-2 box'>

                                {lanKorCondition ?
                                    <h5 className='not_bold'>{GroupBuyInfoData?.specialGiftWeekDay} times a week (2 times)/ KRW {GroupBuyInfoData?.specialGiftWeekDayKRW}</h5>
                                    :
                                    <h5 className='not_bold'>주중{GroupBuyInfoData?.specialGiftWeekDay}회(2회)/ {GroupBuyInfoData?.specialGiftWeekDayKRW}만원</h5>
                                }
                                {lanKorCondition ?
                                    <h5 className='not_bold'> Weekend {GroupBuyInfoData?.specialGiftWeekEnd} times (2 times)/{GroupBuyInfoData?.specialGiftWeekEndKRW}</h5>
                                    :
                                    <h5 className='not_bold'> 주말  {GroupBuyInfoData?.specialGiftWeekEnd} 회 (2회) /{GroupBuyInfoData?.specialGiftWeekEndKRW}만원</h5>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </Modal>
        </>
    );
};

export default GroupBuyInfo;