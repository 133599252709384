import React, { useState } from 'react'
import { Button, Input, Table } from 'antd'
import { HiPlusCircle } from "react-icons/hi"
import moment from 'moment'
import axios from 'axios'
import { POST } from '../../utils/apis'
import { errorMessage, successMessage } from '../../utils/helpers'
import { FaStarOfLife } from "react-icons/fa"

const GolfReservationAuctionMyPage = (props) => {
    let { user, auction, language, lanKorCondition, getAuction } = props
    let userId = user?._id
    const [amount, setAmount] = useState(0)
    const [kEy, setKEy] = useState(null)


    const updateAuctionAmount = (id) => {
        let obj = {
            amount, _id: id
        }

        if (amount == 0 || amount < 0) {
            return errorMessage(lanKorCondition ? 'Please Enter a bid price!' : '입찰가를 입력하세요!')
        }
        axios.post(POST.UPDATE_GOLF_RES_AUCTION_BID_PRICE, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    getAuction()
                    setKEy(null)
                    setAmount(0)

                }
                else {
                    console.log('runing')
                    setKEy(null)
                    setAmount(0)

                }
            })
            .catch((e) => {
                console.log('err', e)
                setKEy(null)
                setAmount(0)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })

    }

    const cancelBid = (id) => {
        let obj = {
            bidId: id, userId: user?._id
        }
        if (!id) {
            return true
        }
        axios.post(POST.DELETE_AUCTION_RESERVATION_GOLF_BID, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    getAuction()
                }
                else {
                    console.log('runing')
                }
            })
            .catch((e) => {
                console.log('err', e)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })

    }

    const columns = [
        {
            title: language?.RegistrationDate,
            dataIndex: 'createdAt',
            width: 180,
            render: (e) => <p style={{ marginBottom: 0 }}>{moment(e).format('YYYY-MM-DD')}</p>
        },
        {
            title: language?.Region,
            dataIndex: ['auctionId', 'golfClub', 'region'],
            width: 180,
        },
        {
            title: language?.GolfCourse,
            dataIndex: ['auctionId', 'golfClub', 'clubName'],
            key: 'golfClubName',
            width: 180,

        },
        {
            title: language?.ReservationDate,
            dataIndex: ['auctionId', 'reservedDate', 'date1'],
            width: 180,
            render: (e) => <p style={{ marginBottom: 0 }}>{moment(e).format('YYYY-MM-DD')}</p>
        },
        {
            title: language?.GreenFee,
            dataIndex: ['auctionId', 'greenFee'],
            width: 180,
            render: (e) => <p style={{ marginBottom: 0 }}>{e}만원</p>

        },
        {
            title: language?.TransferFee,
            dataIndex: ['auctionId', 'transferFee'],
            width: 180,

        },
        {
            title: language?.Registrant,
            dataIndex: ['auctionId', 'userId', 'fullName'],
            width: 180,

        },
        {
            title: language?.Action,
            key: 'action',
            render: (v, record) => (<>
                {
                    v?.isParticipated && !v?.isParticipationAccepted ?
                        <>
                            <div className='btn_card'>
                                <Button
                                    disabled
                                    className='partcipation-auction-btn-golf'
                                    style={{ fontSize: 12, width: '390px' }}
                                >
                                    {lanKorCondition ? ' Applied (Waiting for partcipation)' : '경매신청완료'}
                                </Button>
                            </div>
                        </>
                        :
                        <>
                            <span style={{ display: 'flex' }}>
                                <Input disabled={v?.isAccepted || v?.isCanceled} onClick={() => { setKEy(record?._id) }} style={{ width: 120 }} placeholder={v?.price} onChange={(e) => setAmount(e?.target?.value)} type='number' suffix={language?.KRW} />
                                {!v?.isAccepted && !v?.isCanceled ? amount > 0 && kEy === record?._id && v?.price > 0 ?
                                    <Button disabled={v?.isAccepted || v?.isCanceled} onClick={() => updateAuctionAmount(v?._id)} className={v?.isCanceled && !v?.isAccepted ? 'btn_failed_bid' : 'btn_successfull_bid '} style={{ fontSize: 11, marginLeft: '10px' }}>
                                        {language?.changebidamount}
                                    </Button>
                                    : <Button disabled={v?.isAccepted || v?.isCanceled} onClick={() => updateAuctionAmount(v?._id)} className={v?.isCanceled && !v?.isAccepted ? 'btn_failed_bid' : 'btn_successfull_bid '} style={{ fontSize: 11, marginLeft: '10px' }}>
                                        {language?.BidThisAmount}
                                    </Button> : !v?.isAccepted && v?.isCanceled ?
                                    <Button disabled={v?.isAccepted || v?.isCanceled} onClick={() => updateAuctionAmount(v?._id)} className={v?.isCanceled && !v?.isAccepted ? 'btn_failed_bid' : 'btn_successfull_bid '} style={{ fontSize: 11, marginLeft: '10px' }}>
                                        {language?.Failed}
                                    </Button> :
                                    <Button disabled={true} className='start-auction' style={{ fontSize: 12, marginLeft: '10px' }}>
                                        {language?.SuccessfulBid}
                                    </Button>
                                }
                                {!v?.isAccepted && !v?.isCanceled ? '' : !v?.isAccepted && v?.isCanceled ? '' :
                                    <Button disabled={v?.isAccepted || v?.isCanceled} onClick={() => updateAuctionAmount(v?._id)} className={v?.isCanceled && !v?.isAccepted ? 'btn_failed_bid' : 'btn_successfull_bid '} style={{ fontSize: 11, marginLeft: '10px' }}>
                                        {language?.DealMade}
                                    </Button>
                                }
                                {!v?.isAccepted && !v?.isCanceled ?
                                    !v?.isAccepted && !v?.isCanceled && v?.price > 0 ?
                                        <Button onClick={() => cancelBid(v?._id)} className='start-auction-btn-res' style={{ fontSize: 12, marginLeft: '10px' }}>
                                            {lanKorCondition ? 'Cancel this amount' : '입찰취소'}
                                        </Button> :
                                        <Button disabled={true} className='start-auction-btn-res' style={{ fontSize: 12, marginLeft: '10px' }}>
                                            {language?.Nowstartauction}
                                        </Button> :
                                    !v?.isAccepted && v?.isCanceled ?
                                        <Button disabled={true} className='start-auction-next-tim' style={{ fontSize: 12, marginLeft: '10px' }}>
                                            {language?.Trynexttime}
                                        </Button> :
                                        ''}

                            </span>
                        </>}
            </>
            ),
        },

    ]

    return (
        <div className='auction_golf_res_table'>

            <div className='main_table_cont' style={{ marginTop: 20 }}>
                <Table
                    className="table-striped-rows table_home"
                    columns={columns}
                    dataSource={auction}
                    pagination={false}
                    bordered={false}
                    scroll={{
                        x: 1300,
                    }}
                />
                <hr className='_hr' />
            </div>
        </div>
    )
}

export default GolfReservationAuctionMyPage