import React, { useEffect, useState } from "react"
import { RiChat3Line } from 'react-icons/ri'
import Draggable from "react-draggable"
import { Popover, Badge } from 'antd'
import allPaths from '../../Config/paths'
import { Redirect, useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import axios from "axios"
import { GET } from "../../utils/apis"
import io from 'socket.io-client'

let origin = `https://securetech.vmvm.co.kr`
if (process.env.NODE_ENV === 'development') {
    origin = `http://localhost:4000`
}

const socket = io(origin, { reconnect: true });

socket.on('connect', () => {
    console.log('connected')
})

const ChatMenu = (props) => {
    const { language } = props
    const history = useHistory()
    const windowWidth = window.innerWidth
    const user = useSelector(state => state?.authReducer?.user)
    const [count, setCount] = useState(0)
    const [socketData, setSocketData] = useState({})

    useEffect(() => {
        axios.get(`${GET?.GET_CHAT_MENU_COUNT}/${user?._id}`)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setCount(data?.data)
                }
            })
    }, [])

    const getSocketInfo = () => {
        socket.on('notification-added', (d) => {
            console.log('d', d)
            const { documentKey, operationType, updateDescription } = d
            if (documentKey) {
                if (operationType === 'insert') {
                    setSocketData(d)
                }
            }
        })
    }

    useEffect(() => {
        getSocketInfo()
    }, [])
    
    const updateState = () => {
        if (user?._id) {
            const { fullDocument, operationType } = socketData
            if (fullDocument?._id) {
                if (operationType === 'insert') {
                    if (fullDocument?.reciverId == user?._id) {
                        setCount((prev) => Number(prev) + 1)
                    }
                }
            }
        }
    }
    useEffect(() => {
        updateState()
    }, [socketData?.fullDocument?._id])

    const content = (
        <div>
            <div className='drop_down_chat' onClick={() => { history?.push(allPaths?.NOTIFICATION) }}>
                <h5 >
                    {language?.Notification}
                </h5>
                <h5>
                    ({count})
                </h5>
            </div>
            <div className='drop_down_chat' onClick={() => { history?.push(allPaths?.EVENTS) }}>
                <h5>
                    {language?.Event}
                </h5>
                <h5>

                </h5>
            </div>
            <div className='drop_down_chat' onClick={() => { history?.push(allPaths?.NOTICE) }}>
                <h5>
                    {language?.Notice}
                </h5>
                <h5>

                </h5>
            </div>
            <div className='drop_down_chat drop_down_chat4'
                onClick={
                    () => {
                        window.open('http://pf.kakao.com/_xdxebxjxj/chat', '_blank')
                    }
                }
            >
                <h5>
                    {language?.ServiceCenter}
                </h5>
            </div>
            <h5>

            </h5>

        </div>
    );
    return (
        <div className='chat_menu'>
            <div className='demo'>
                <Draggable cancel='.drag-click'>

                    <div placement='bottom' content={content} visible={true} >
                        {user?._id ?
                            <Badge /* color='#2db7f5' count={} */ offset={[-10, 10]}>
                                <div
                                    className='circle bg_white_transparent'><RiChat3Line style={{ display: 'none' }} /></div>
                            </Badge> :
                            <Badge color='#2db7f5' offset={[-10, 10]}>
                                <div className='circle bg_white_transparent'><RiChat3Line style={{ display: 'none' }} /></div>
                            </Badge>
                        }

                        <div className='customerCSS1'>
                            <div>
                                <div className='drop_down_chat1 drag-click' onClick={() => { history?.push(allPaths?.NOTIFICATION) }}>
                                    <h5 className="not_text drag-click">
                                        {language?.Notification}
                                    </h5>
                                    <h5>
                                        {count > 0 ? (count) : null}
                                    </h5>
                                </div>
                                <div className='drop_down_chat2 drag-click' onClick={() => { history?.push(allPaths?.EVENTS) }}>
                                    <h5 className="not_text drag-click">
                                        {language?.Event}
                                    </h5>
                                    <h5>

                                    </h5>
                                </div>
                                <div className='drop_down_chat3 drag-click' onClick={() => { history?.push(allPaths?.NOTICE) }}>
                                    <h5 className="not_text drag-click">
                                        {language?.Notice}
                                    </h5>
                                    <h5>

                                    </h5>
                                </div>
                                <div className='drop_down_chat drop_down_chat4 drag-click' onClick={
                                    () => {
                                        window.open('http://pf.kakao.com/_xdxebxjxj/chat', '_blank')
                                    }
                                }>
                                    <h5 className="not_text drag-click">
                                        {language?.ServiceCenter}
                                    </h5>
                                    <h5>

                                    </h5>
                                </div>

                            </div>
                        </div>
                    </div>
                </Draggable>

            </div >
        </div >
    )
}

export default ChatMenu