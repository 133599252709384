import { PlusCircleFilled } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Divider, Form, Input, Modal, Select } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { LoginAlert } from '..';
import { POST } from '../../utils/apis';
import { regions, regionsKr, typesOnlyKr } from '../../utils/constants';
import { errorMessage, loginAlert, MyInfoAlert, successMessage } from '../../utils/helpers';
import CoustomDatePicker from '../DatePicker/DatePicker';
import ChooseTicket from './ChooseTicket';
import { types, typesKr } from '../../utils/constants'

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 10,
        },
        md: {
            span: 12,
        },
        lg: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 14,
        },
        md: {
            span: 14,
        },
        lg: {
            span: 16,
        },
    },
}

const UploadTicketShareModal = (props) => {
    const { user, fun, language, lanKorCondition } = props
    const userID = user?._id
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [memberFee, setMemberFee] = useState(null)
    const [nonMemberFee, setNonMemberFee] = useState(null)
    const [rightPrice, setRightPrice] = useState(null)
    const [selectedTicket, setSelectedTicket] = useState({})
    // const [expiry, setExpiry] = useState(moment().format('YYYY-MM-DD'))
    const [expiry, setExpiry] = useState(null)

    console.log('memberFee', memberFee, typeof memberFee)
    console.log('nonMemberFee', nonMemberFee, typeof nonMemberFee)

    const config = {
        rules: [
            {
                type: 'object',
                required: true,
                message: language?.Pleaseselectdate,
            }
        ]
    }
    const showModal = () => {
        setIsModalOpen(true);
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const onFinish = (fieldsValue) => {

        if (!nonMemberFee) {
            return errorMessage(language?.PleaseEnterNon_MemberFee)
        }
        if (!memberFee) {
            return errorMessage(language?.PleaseEnterMemberFee)
        }

        setLoading(true)
        let joinDate = {
            date1: fieldsValue['usableDate']?.format('YYYY-MM-DD'),
            date2: fieldsValue['expirationDate']?.format('YYYY-MM-DD')
        }
        fieldsValue._id = userID
        fieldsValue.ticketId = selectedTicket?._id
        fieldsValue.rightPrice = (+fieldsValue.rightPrice)
        fieldsValue.termsAndCondition = fieldsValue.termsConditions1
        fieldsValue.usableDate = joinDate.date1
        fieldsValue.expirationDate = expiry
        fieldsValue.locationPreference = fieldsValue.locationPreference
        fieldsValue.memberFee = Number(memberFee)
        fieldsValue.NONmemberFee = Number(nonMemberFee)
        console.log('fieldsValue', fieldsValue)

        delete fieldsValue.Fee

        axios.post(POST.SHARE_TICKET, fieldsValue)
            .then((res) => {
                const { data } = res
                console.log('data', data)
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    form.resetFields()
                    setIsModalOpen(false)
                    setRightPrice('')
                    setSelectedTicket('')
                    fun()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    const handleNumber = (e, type) => {
        const re = /^[0-9\b]+$/

        if (e.target.value === '' || re.test(e.target.value)) {
            if (type === 'member') {
                setMemberFee(e.target.value)
            } else {
                setNonMemberFee(e.target.value)
            }
        }
    }

    const setExpiryDate = (e) => {
        // console.log(e)
        let date = moment(e?._d).add(-1, 'days').format('YYYY-MM-DD HH:MM')
        // console.log('date', date)
        form.setFieldValue('usableDate', moment(date, 'YYYY-MM-DD HH:MM'))
        const weekday = moment(date).format('dddd')
        const isWeekend = weekday === 'Sunday' || weekday === 'Saturday' || weekday === '토' || weekday === '일' ? true : false
        // console.log('*****', isWeekend, weekday)
        let setExpDate
        if (!isWeekend) {
            setExpDate = moment(date).subtract(3, 'days').format('YYYY-MM-DD HH:MM')
            form.setFieldValue('expirationDate', moment(setExpDate, 'YYYY-MM-DD HH:MM'))
            setExpiry(setExpDate)
        } else {
            console.log('setExpDate', setExpDate)
            setExpDate = moment(date).subtract(7, 'days').format('YYYY-MM-DD HH:MM')
            form.setFieldValue('expirationDate', moment(setExpDate, 'YYYY-MM-DD HH:MM'))
            setExpiry(setExpDate)
        }
    }


    useEffect(() => {
        setMemberFee(selectedTicket?.memberFee)
        setNonMemberFee(selectedTicket?.NONmemberFee)
    }, [selectedTicket])

    // console.log('selectedTicket', memberFee + ' --', nonMemberFee)
    return (
        <>
            {user?._id ?
                user?._id && user?.telNo && user?.bank && user?.bankAccountNumber ?
                    <Button className='upload_sharing_btn' onClick={showModal}>
                        {language?.UploadTicketSharing}
                    </Button> :
                    <Button className='upload_sharing_btn' onClick={() => { MyInfoAlert(language) }}>
                        {language?.UploadTicketSharing}
                    </Button> :
                <LoginAlert clas={'upload_sharing_btn'} buttonName={language?.UploadTicketSharing} {...props} />
            }
            <center>
                <Modal
                    open={isModalOpen}
                    // open={true}
                    onCancel={handleCancel}
                    // className='paymentModal'
                    className='responsiveModal'
                    // bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 20px)' }}
                    footer={null}
                    style={{ maxWidth: 800, top: '10px', }}
                >
                    <div
                        className='scroll_class'
                        style={{
                            height: 'auto',
                            width: '100%',
                            maxHeight: '85vh',
                            overflowY: 'scroll',
                            marginTop: '12px'
                        }}>
                        <div className='modal_main_div' >
                            <center><h2>{lanKorCondition ? 'Upload Ticket Sharing....' : '공유를 원하는 회원권 정보를 입력해 주세요'}</h2></center>
                            <br />

                            <div className='paddongThirty'>
                                <Form requiredMark={false} form={form} name='time_related_controls'
                                    {...formItemLayout}
                                    onFinish={onFinish}>

                                    <Form.Item
                                        name='membership'
                                        label={language?.MembershipTicketsharing}
                                        rules={[
                                            {
                                                validator: async (names) => {

                                                    if (!selectedTicket?.golfClub?.clubName) {
                                                        console.log(selectedTicket == {})
                                                        return Promise.reject(new Error(language?.ChooseYourticket))
                                                    }
                                                }
                                            }
                                        ]}

                                    >
                                        <ChooseTicket setSelectedTicket={setSelectedTicket}  {...props} />
                                    </Form.Item>

                                    {/* <Form.Item
                                    name='Fee'
                                    label={language?.Fee}
                                >
                                    <div style={{ display: 'inline-block', width: 'calc(50% - 1px)' }}>
                                        <Input
                                            addonBefore={language?.Member}
                                            addonAfter={language?.KRW}
                                            disabled={true}
                                            style={{
                                                width: '100%',
                                            }}
                                            value={memberFee}
                                            onChange={(e) => setMemberFee(e?.target?.value)}
                                        />
                                    </div>

                                    <div style={{ display: 'inline-block', width: 'calc(50% - 1px)' }}>
                                        <Input
                                            addonBefore={language?.NonMember}
                                            addonAfter={language?.KRW}
                                            disabled={true} 
                                            style={{
                                                width: '100%',

                                            }}
                                            value={nonMemberFee}
                                            onChange={(e) => setNonMemberFee(e?.target?.value)}
                                        />
                                    </div>
                                </Form.Item> */}

                                    <div className='disabled_text scroll_class'>
                                        <table>
                                            <tr>
                                                <td>{language?.GolfClubName}</td>
                                                <td>{selectedTicket?.golfClub?.clubName || '-----------------'}</td>
                                            </tr>
                                            <br />
                                            <tr>
                                                <td>{language?.golfclubAddress}</td>
                                                <td>{selectedTicket?.golfClub?.clubAddress || '-----------------'}</td>
                                            </tr>
                                            <br />
                                            <tr>
                                                <td>{language?.Type}</td>
                                                {selectedTicket?.membershipType ?
                                                    lanKorCondition ? selectedTicket?.membershipType :
                                                        typesKr[typesOnlyKr?.indexOf(selectedTicket?.membershipType)]?.typesKr ?
                                                            typesKr[typesOnlyKr?.indexOf(selectedTicket?.membershipType)]?.typesKr :
                                                            typesKr[types?.indexOf(selectedTicket?.membershipType)]?.typesKr :
                                                    '-----------------'
                                                }
                                                {/* <td>{selectedTicket?.membershipType || '-----------------'}</td> */}
                                            </tr>
                                            <br />
                                            {/* <tr>
                                            <td>{language?.JoiningFee}</td>
                                            <td>{selectedTicket?.joiningFee ? selectedTicket?.joiningFee + ' ' + language?.Won : '-----------------'}</td>
                                        </tr> */}
                                            <br />
                                            {/* <tr>
                                        <td>{language?.JoiningDate}</td>
                                        <td>{selectedTicket?.JoiningFee ? (moment(selectedTicket?.joiningDate?.date1).format('YYYY-MM-DD') / moment(selectedTicket?.joiningDate?.date2).format('YYYY-MM-DD')) : '-----------------'}</td>
                                    </tr>
                                    <br /> */}
                                            {/* <tr>
                                                <td>{language?.MemberFeeORNonFee}</td>
                                                <td>{memberFee ? memberFee + ' ' + language?.Won : '-------'}  / {nonMemberFee ? nonMemberFee + ' ' + language?.Won : '------'}</td>
                                            </tr> */}
                                            <br />

                                        </table>
                                    </div>

                                    <Divider style={{ backgroundColor: '#bbb', marginBottom: 20 }} />
                                    <Form.Item
                                        initialValue={memberFee}
                                        label={<h4 style={{ marginBottom: 0 }}>
                                            <PlusCircleFilled className='plus_with_text' /> {language?.MemberFee}</h4>}
                                        rules={[
                                            {
                                                required: true,
                                                message: language?.MemberFee,
                                            },
                                        ]}
                                    >
                                        <div>
                                            <Input
                                                suffix={language?.KRW}
                                                maxLength={7}
                                                value={memberFee}
                                                onChange={(e) => setMemberFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                            />
                                        </div>
                                    </Form.Item>

                                    <Form.Item
                                        initialValue={nonMemberFee}
                                        label={<h4 style={{ marginBottom: 0 }}>
                                            <PlusCircleFilled className='plus_with_text' /> {language?.NonMemberFee}</h4>}
                                        rules={[
                                            {
                                                required: true,
                                                message: language?.NonMemberFee,
                                            },
                                        ]}
                                    >
                                        <div>
                                            <Input
                                                suffix={language?.KRW}
                                                maxLength={7}
                                                value={nonMemberFee}
                                                onChange={(e) => setNonMemberFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                            />
                                        </div>
                                    </Form.Item>

                                    <Form.Item
                                        name='rightPrice'
                                        label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' /> {language?.RightPrice}</h4>}
                                        rules={[
                                            {
                                                required: true,
                                                message: language?.PleaseInputRightPrice,
                                            },
                                        ]}
                                    >
                                        <div>
                                            <Input
                                                suffix={language?.KRW}
                                                maxLength={7}
                                                value={rightPrice}
                                                onChange={(e) => setRightPrice(e.target.value?.replace(/[^0-9]/g, ''))}
                                            />
                                        </div>
                                    </Form.Item>

                                    <Form.Item
                                        name='usableDate'
                                        label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' /> {language?.UsableDate}</h4>}
                                        {...config}
                                    >
                                        <CoustomDatePicker placeholder={language?.Usabledate} setDate={setExpiryDate} showTime={true} format={' YYYY-MM-DD h:mm a'} />
                                    </Form.Item>

                                    <Form.Item
                                        name='expirationDate'
                                        label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' /> {language?.ExpirationDate}</h4>}
                                        {...config}
                                        initialValue={expiry}
                                    >
                                        <div>
                                            <Input value={expiry} disabled placeholder={language?.Selectdate} style={{ width: '100%' }} />
                                        </div>
                                    </Form.Item>

                                    <Form.Item
                                        name='locationPreference'
                                        label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' />
                                            {
                                                lanKorCondition ? 'Location Preference' : '선호지역'
                                            }
                                        </h4>}
                                        rules={[
                                            {
                                                required: true,
                                                message: language?.PleaseselectLocation,
                                            },
                                        ]}
                                    >
                                        <Select mode='multiple' placeholder={lanKorCondition ? 'Preferred Location' : '위치 설정'}>
                                            {lanKorCondition ?
                                                regions?.slice(1)?.map((v, i) => (<Option value={v}>{v}</Option>))
                                                : regionsKr?.slice(1)?.map((v, i) => (<Option value={v?.TitleEn}>{v?.TitleKr}</Option>))
                                            }

                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name='termsConditions1'
                                        label={language?.TermCondition}
                                        valuePropName='checked'
                                        hasFeedback
                                        rules={[
                                            {
                                                validator: (_, value) =>
                                                    value ? Promise.resolve() : Promise.reject(new Error(language?.Shouldacceptagreement)),
                                            }
                                        ]}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                    <div className='agreement scroll_class' style={{ width: '100%' }}>
                                        <p>{language?.TAC1}</p>
                                        <p>{language?.TAC2}</p>
                                        <p>{language?.TAC3}</p>
                                        <p>{language?.TAC4}</p>
                                        <p>{language?.TAC5}</p>
                                        <p>{language?.TAC6}</p>
                                        <p>{language?.TAC7}</p>
                                    </div>
                                    <br />
                                    <Button loading={loading} className='req_exc_btn' type='primary' htmlType='submit'>{lanKorCondition ? 'Upload Sharing' : '공유 신청하기'}</Button>
                                </Form>
                            </div>



                        </div>
                    </div>
                </Modal>
            </center>

        </>
    );
};

export default UploadTicketShareModal;