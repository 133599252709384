import { PlusOutlined } from '@ant-design/icons'
import { Button, Descriptions, Input, Space, Tabs, Tag, Tooltip, Table, Typography, Popover, Badge } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { RiSoundModuleFill, RiChat3Line } from 'react-icons/ri'
import { GET, ADMIN } from '../../utils/apis'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import Header from '../Home/Header'
import moment from 'moment'
import { CloseOutlined } from '@ant-design/icons'
import { stringLimiter } from '../../utils/helpers'

const { Search } = Input
const { TabPane } = Tabs
const { Title } = Typography
const { Column, ColumnGroup } = Table


const Event = (props) => {
    const { lanKorCondition, language } = props
    const [tags, setTags] = useState(['Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag',])
    const [inputVisible, setInputVisible] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [searchText, setSearchText] = useState('')
    const [editInputIndex, setEditInputIndex] = useState(-1)
    const [editInputValue, setEditInputValue] = useState('')
    const [loading, setLoading] = useState(false)
    const [event, setEvents] = useState([])
    const [view, setView] = useState(false)
    const [viewData, setViewData] = useState({})

    const inputRef = useRef(null)
    const onSearch = (e) => {
        setSearchText(e.target.value.trim())
    }

    const editInputRef = useRef(null)
    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus()
        }
    }, [inputVisible])
    useEffect(() => {
        editInputRef.current?.focus()
    }, [inputValue])

    useEffect(() => {
        getEventsAndNotices()
    }, [])

    const getEventsAndNotices = () => {
        setLoading(true)
        axios.get(`${ADMIN.GET_NOTICES_AND_EVENTS}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    setEvents(data?.getEvents)
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log('err', e)
            })
    }

    const columns = [
        {
            title: 'status',
            dataIndex: 'isCompleted',
            align: 'center',
            render: (e) => (
                <Tag style={{ borderRadius: '20px' }} color={e ? '#2db7f5' : 'grey'}>{e ? language?.CompletedEvnt : language?.proceIng}</Tag>
            )
        },
        {
            title: 'title',
            dataIndex: 'title',
            align: 'center',
        },
        {
            title: 'content',
            dataIndex: 'content',
            align: 'center',
            render: (e) => <p>{stringLimiter(e)}</p>
        },
        {
            title: 'date',
            dataIndex: 'created',
            align: 'center',
            render: (e) => <p>{moment(e).utc().format('YYYY-MM-DD')}</p>
        },
    ]
    let filterData = event.filter((val, i) => {
        if (searchText.trim() === "") {
            return val
        } else if (val.title.toLowerCase().includes(searchText.toLowerCase().trim()) || val.content.toLowerCase().includes(searchText.toLowerCase().trim())) {
            return val
        }
    })
    return (
        <div className='home-main'>
            <Header {...props} />

            <center>
                <div className='div_text'>
                    <Title level={4}>{language?.Event}</Title>
                </div>
            </center>
            {
                view && viewData?._id ?
                    <div className='card-parent2 flex-new'>
                        <div className='card3' style={{ marginTop: 0 }}>
                            <p style={{ textAlign: 'right' }}>
                                <Button style={{ border: 'none' }} onClick={() => {
                                    setView(false)
                                    setViewData({})
                                }} icon={<CloseOutlined />}></Button>
                            </p>
                            <h2 style={{ textAlign: 'start' }}>  {language?.Title}</h2>
                            <br />
                            {viewData?.title}
                            <br />
                            <br />
                            <h2 style={{ textAlign: 'start' }}>
                                {language?.Content}</h2>
                            <br />
                            {viewData?.content}
                        </div>
                    </div>
                    :
                    <div className='card-parent2'>
                        <div className='card3' style={{ marginTop: 0 }}>
                            <Search
                                placeholder={language?.inputSearchText}
                                onChange={onSearch}
                                className='noti_search'
                            />
                            <br />
                            <br />
                            <Table
                                className='table-striped-rows table_home scroll_class'
                                onRow={(record, rowIndex) => ({
                                    onClick: () => {
                                        setView(true)
                                        setViewData(record)
                                    },
                                })
                                }
                                dataSource={filterData}
                                showHeader={false}
                                scroll={{ x: 800 }}
                                pagination={false}
                                rowClassName={'tableTextColor'}
                                columns={columns}
                            />

                        </div>
                    </div >
            }
        </div >
    )
}

export default Event