import { Button, Checkbox, DatePicker, Form, Image, Input, Modal, Select } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { HiCheckCircle } from 'react-icons/hi'
import { POST } from '../../utils/apis'
import { errorMessage, successMessage } from '../../utils/helpers'
import SearchGolfClub from './SearchGolfClub'
import moment from 'moment'
import CoustomDatePicker from '../DatePicker/DatePicker'
import { useHistory } from 'react-router-dom'
import { allPaths } from '../../utils/constants'

const { RangePicker } = DatePicker

const { TextArea } = Input
const { Option } = Select
const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
        md: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
        md: {
            span: 16,
        },
    },
}
const config = {
    rules: [
        {
            type: 'object',
            required: true,
            message: 'Please select time!',
        },
    ],
}
const rangeConfig = {
    rules: [
        {
            type: 'array',
            required: true,
            message: 'Please select time!',
        },
    ],
}

const EditGolfReservation = (props) => {

    const { user, language, setCorrection, correction, golfData, fun, setGolf,lanKorCondition } = props
    const nums = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
    const mins = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60']
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [am, setAm] = useState(golfData?.reservedTime?.slice(-2) || 'am')
    const [hr, setHr] = useState(golfData?.reservedTime?.slice(0, -3).split(':')[0] || '1')
    const [min, setMin] = useState(golfData?.reservedTime?.slice(0, -3).split(':')[1] || '1')
    const [totalTime, setTotalTime] = useState(hr + ':' + min + ' ' + am)
    const [isModalOpen2, setIsModalOpen2] = useState(false)
    const [isModalOpen3, setIsModalOpen3] = useState(false)
    const [showGolfClub, setShowGolfClub] = useState(false)
    const [golfClubData, setGolfClubData] = useState(null)
    const [golfDatas, setGolfData] = useState(golfData)

    console.log('golfData', language,lanKorCondition)

    const [greenFee, setGreenFee] = useState(golfDatas?.greenFee)
    const [transferFee, setTransferFee] = useState(golfDatas?.transferFee)
    const [address, setAddress] = useState(null)
    const [loading, setLoading] = useState(false)
    const [memo, setMemo] = useState(golfData?.memo)
    const history = useHistory()


    const config = {
        rules: [
            {
                type: 'object',
                required: true,
                message: language?.Pleaseselectdate,
            },
        ],
    }

    const handleCancel = () => {
        setIsModalOpen(false)
        setCorrection(false)
        setGolf({})
    }

    const handleCancel2 = () => {
        setIsModalOpen2(false)
    }
    const showModal3 = () => {
        setIsModalOpen3(true)
        setIsModalOpen2(false)

    }

    const handleOk3 = () => {
        setIsModalOpen3(false)
    }

    const handleCancel3 = () => {
        setIsModalOpen3(false)
    }

    const onFinish = (fieldsValue) => {
        setLoading(true)
        // Should format date value before submit.
        const values = {
            ...fieldsValue
        }
        values.userId = user?._id
        values.address = address
        values.region = golfClubData?.region
        values.golfClub = golfClubData?._id
        values.greenFee = +greenFee
        values.memo = fieldsValue?.memo
        values._id = golfData?._id
        values.reservedTime = totalTime
        values.transferFee = transferFee

        console.log('values', values)
        // return true
        axios.post(POST?.EDIT_GOLF_RESERVATION, values)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(data?.message)
                    setIsModalOpen(false)
                    setGolfClubData(null)
                    setGreenFee(null)
                    setTransferFee(null)
                    setMin('')
                    setHr('')
                    setAm('')
                    setMemo('')
                    handleCancel()
                    form.resetFields()
                    fun()
                }
                else {
                    errorMessage(data?.message)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    const setExpiryDate = (e) => {
        let date = moment(e?._d).add(1, 'days').format('YYYY-MM-DD')
        form.setFieldValue('reservedDate', moment(date, 'YYYY-MM-DD'))
    }

    useEffect(() => {
        let date = moment(golfData?.reservedDate).format('YYYY-MM-DD')
        form.setFieldValue('reservedDate', moment(date, 'YYYY-MM-DD'))

        setGolfData(golfData)
        setGolfClubData(golfData?.golfClub)
        setAddress(golfData?.golfClub?.clubAddress)
        setGreenFee(golfData?.greenFee)
        setTransferFee(golfData?.transferFee)
        setAm(golfData?.reservedTime?.split(" ")[1])
        setMemo(golfData?.memo)
        setHr(golfData?.reservedTime?.split(":")[0])
        setMin(golfData?.reservedTime?.split(":")[1]?.split(' ')[0])
    }, [golfData?._id])

    return (
        <>
            <Modal
                open={isModalOpen || correction}
                onCancel={handleCancel}
                className='responsiveModal'
                footer={null}
            >
                <div className='modal_main_div'>
                    <center><h2>{language?.GolfReservationCorrection}</h2></center>

                    <div className='row_fields' style={{ marginBottom: 10, alignItems: 'baseline' }} >
                        <p style={{ marginLeft: 10 }}>{language?.GolfClubName}</p>
                        <Button
                            className='input_field_buttons2'
                            size='middle'
                            onClick={() => setShowGolfClub(true)}
                        >{language?.Search}</Button>
                    </div>

                    <SearchGolfClub setAddress={setAddress} golfClubData={golfClubData} d={golfData?.golfClub} setGolfClubData={setGolfClubData} showGolfClub={showGolfClub} setShowGolfClub={setShowGolfClub} />

                    {golfClubData?._id ?
                        <div className='row_fields1' style={{ maxWidth: 300, marginBottom: 20 }}>
                            <Image width={100} height={100} src={golfClubData?.imgUrl} />
                            <div style={{ marginTop: -10 }}>
                                <h2>{golfClubData?.clubName}</h2>
                                <p>{golfClubData?.region}</p>
                                <Button onClick={() => { setGolfClubData(null); setAddress(null) }}>{language?.Delete}</Button>
                            </div>
                        </div> : null}

                    <Form name='time_related_controls' form={form} requiredMark={false} {...formItemLayout} onFinish={onFinish}>
                        {/* <Form.Item name='reservedDate' label={language?.ReservedDate} {...rangeConfig}>
                            <RangePicker defaultValue={[moment(golfData?.reservedDate?.date1), moment(golfData?.reservedDate?.dat)]} style={{ width: '100%' }} />
                        </Form.Item> */}
                        <Form.Item name='reservedDate' label={language?.ReservedDate}{...config}>
                            <CoustomDatePicker defaultValue={moment(golfData?.reservedDate, 'YYYY-MM-DD')} placeholder={language?.Selectdate} setDate={setExpiryDate} />
                        </Form.Item>

                        <Form.Item
                            name='reservedTime'
                            label={language?.ReservedTime}
                            initialValue={totalTime}
                            rules={[
                                {
                                    required: true,
                                    // message: 'Please input your username!',
                                },
                            ]}
                        >
                            <div className='row_fields1'>
                                <Select
                                    // defaultValue='A.M'
                                    className='special_width'
                                    value={am}
                                    onChange={(value) => { setAm(value); setTotalTime(hr + ':' + min + ' ' + value) }}
                                >
                                    <Option value='am'>A.M.</Option>
                                    <Option value='pm'>P.M.</Option>
                                </Select>
                                <Select
                                    style={{
                                        width: '35%'
                                    }}
                                    value={hr}
                                    onChange={(value) => { setHr(value); setTotalTime(value + ':' + min + ' ' + am) }}
                                >
                                    {nums?.map((v, i) => (<Option key={i} value={v}>{v}</Option>))}
                                </Select>
                                <Select
                                    style={{
                                        width: '35%'
                                    }}
                                    value={min}
                                    onChange={(value) => { setMin(value); setTotalTime(hr + ':' + value + ' ' + am) }}
                                >
                                    {mins?.map((v, i) => (<Option key={i} value={v}>{v}</Option>))}
                                </Select>
                            </div>
                        </Form.Item>

                        <Form.Item
                            name='greenFee'
                            label={language?.GreenFee}
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please input Green Fee!',
                        //     },
                        // ]}
                        >
                            <div>
                                <Input
                                    suffix={language?.KRW}
                                    min={0}
                                    maxLength={10}
                                    value={greenFee}
                                    onChange={(e) => setGreenFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                />
                            </div>
                        </Form.Item>

                        <Form.Item
                            name='transferFee'
                            label={language?.TransferFee}
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please input Transfer Fee!',
                        //     },
                        // ]}
                        >
                            <div className='row_fields1'>

                                <Input
                                    suffix={language?.KRW}
                                    min={0}
                                    maxLength={7}
                                    value={transferFee}
                                    onChange={(e) => setTransferFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                />
                                <button onClick={() => setTransferFee('0')} style={{ width: 150, marginLeft: 10, border: 'none', cursor: 'pointer' }}>{language?.NoTransferFee}</button>

                            </div>
                        </Form.Item>

                        <Form.Item
                            name='address'
                            label={language?.Address}
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please input Address!',
                            //     },
                            // ]}
                            initialValue={address}
                        >
                            <div className='row_fields1'>

                                <Input
                                    type='text'
                                    style={{ width: '100%' }}
                                    value={address}
                                    disabled
                                // onChange={(e) => setAddress(e?.target?.value)}
                                />

                            </div>
                        </Form.Item>

                        <Form.Item
                            initialValue={golfData?.memo}
                            name='memo'
                            label={language?.Memo}
                        >
                            <TextArea defaultValue={golfData?.memo} rows={4} />
                        </Form.Item>

                        <Form.Item
                            name='TermAndConditions'
                            label={language?.TermCondition}
                            valuePropName='checked'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    validator: (_, value) =>
                                        value ? Promise.resolve() : Promise.reject(new Error(language?.Shouldacceptagreement)),
                                }
                            ]}
                        >
                            <Checkbox />
                        </Form.Item>

                        <div className='agreement scroll_class' style={{ marginBottom: 20 }}>
                            {/* <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eum porro assumenda, in dicta ab fuga, vero quasi nostrum molestiae, corporis perferendis similique quis deleniti ullam. Recusandae sequi quam vitae cumque! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae, nihil cupiditate rerum mollitia, nemo vitae temporibus amet quos esse architecto quod repellat laboriosam explicabo similique, alias beatae odio possimus? Accusamus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque blanditiis eaque saepe odio voluptas obcaecati at voluptatum, aliquid omnis laborum libero numquam architecto sit! Architecto quas ratione alias debitis deserunt!</p> */}
                            <p>{language?.TAC1}</p>
                            <p>{language?.TAC2}</p>
                            <p>{language?.TAC3}</p>
                            <p>{language?.TAC4}</p>
                            <p>{language?.TAC5}</p>
                            <p>{language?.TAC6}</p>
                            <p>{language?.TAC7}</p>
                        </div>


                        <Button loading={loading} className='req_exc_btn' type='primary' htmlType='submit' /* type='primary' */ /* onClick={handleCancel} */>{language?.Register}</Button>
                    </Form>


                </div>
            </Modal>
            <Modal
                open={isModalOpen2}
                onCancel={handleCancel2}
                footer={[<Button className='req_exc_btn' type='primary' onClick={showModal3}>{language?.BuyThisTicket}</Button>]}
                width={800}
            >
                <h2 style={{ textAlign: 'center' }}>{language?.Purchase}</h2>
                <p style={{ textAlign: 'left' }}>
                    {language?.MembershipToTrade}
                </p>
                <div className='main_modal_con'>
                    <div className='modal_first_panel'>
                        <div className='column-1 box'>
                            <h4>{language?.GolfClubName}</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>East Valley CC</p>
                        </div>
                    </div>
                    <div className='modal_first_panel'>
                        <div className='column-1 box'>
                            <h4>{language?.Type}</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>Company</p>
                        </div>
                    </div>
                    <div className='modal_first_panel'>
                        <div className='column-1 box'>
                            <h4>{language?.MemberFee}/{language?.NonMemberFee}</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>Member Fee 5 million won/ Non-Member Fee 30 millio won</p>
                        </div>
                    </div>
                    <div className='modal_first_panel'>
                        <div className='column-1 box'>
                            <h4>{language?.RightPrice}</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>80 million won</p>
                        </div>
                    </div>
                    <div className='modal_first_panel'>
                        <div className='column-1 box'>
                            <h4>{language?.ReservedDate}</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>2022.4.22 | 13:30</p>
                        </div>
                    </div>
                    <div className='modal_first_panel'>
                        <div className='column-1 box'>
                            <h4>{language?.ExpirationDate}</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>2022.4.22</p>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                open={isModalOpen3}
                onCancel={handleCancel3}
                footer={[<div className='message_modal'><Button className='message_modal_btn' type='primary' onClick={() => {
                    handleOk3();
                    history.push({
                        pathname: allPaths?.MYPAGE,
                        search: `?membershipTransaction`
                    })
                }}>{lanKorCondition ? 'Go To My Page' : language?.GoTicketSalesRight}</Button>
                    <Button className='message_modal_btn marginLeft' type='primary' onClick={handleOk3}>{lanKorCondition ? 'Chat Bot' : language?.TalkUser}</Button></div>]}
            // width={800}
            >
                <h2 style={{ textAlign: 'center' }}>{language?.Completion}</h2>
                <div style={{ padding: '20px' }}>

                    <center><HiCheckCircle className='tick_icon' /></center>
                    <h4 style={{ textAlign: 'center' }}>
                        {language?.ExchangeRentRequest}
                    </h4>
                    <h4 style={{ textAlign: 'center' }}>
                        {language?.PleaseWaitFor}
                    </h4>
                </div>
            </Modal>
        </>
    )
}

export default EditGolfReservation