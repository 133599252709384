import { useHistory } from 'react-router-dom'
import LOGOTRANS from '../../assets/logoVMTrans.png'
import TermsAConditionsFile from '../../assets/Terms&Conditions.docx'
import { ChatMenu, CompanyIntro } from '../../Components'
import { allPaths } from '../../utils/constants'
import { useState } from 'react'

const Footer = (props) => {
    const history = useHistory()
    const { language, user } = props
    const [modalOpen, setModalOpen] = useState(false)

    return (
        <>
            <ChatMenu {...props} />
            <div className='footer'>
                <div className="pagewrap">
                    <div className='test'>
                        <div className='content_DicsDiv'>
                            <div className="content">
                                <img src={LOGOTRANS} className='logo_' />
                                <h2 className='heading_2'>{language?.CompanyNumber === 'Company Number' ? 'ValueMarket' : '가치가게'}
                                </h2>
                            </div>
                            <div className='DicsDiv' >
                                <div className='DicsDivLine'>
                                    <div>
                                        <h3 className='colorWhite'>{language?.CEOINFO}</h3>
                                    </div>
                                </div>
                                <div className='DicsDivLine'>
                                    <div>
                                        <h3 className='colorWhite'>{language?.CompanyNumber}: 676-88-02405 </h3>
                                    </div>
                                </div>
                                <div className='DicsDivLine'>
                                    <div>
                                        <h3 className='colorWhite'>{language?.mailOrder}: {language?.noCompany} </h3>
                                    </div>
                                </div>
                                <div className='DicsDivLine'>
                                    <div>
                                        <h3 className='colorWhite'>{language?.email} : sns@vmvm.kr </h3>
                                    </div>
                                </div>
                                <div className='DicsDivLine'>
                                    <div>
                                        <h3 className='colorWhite'> {language?.address} : {language?.addres}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='FaqDiv'>
                            <h3 className='colorWhite underline cursor-pointer' onClick={() => { setModalOpen(true) }}>{language?.Aboutus} </h3>
                            <h3 className='colorWhite underline cursor-pointer' onClick={() => { history.push(allPaths?.FAQ) }}>FAQ</h3>
                            <h3 className='colorWhite underline cursor-pointer'><a className='colorWhite' href={TermsAConditionsFile}>{language?.TermCond}</a> </h3>
                            <h3 className='colorWhite underline cursor-pointer'><a className='colorWhite' href='http://pf.kakao.com/_xdxebxjxj/chat' >{language?.PartnershipInquiry}</a></h3>
                        </div>
                    </div>
                </div>
            </div>
            <CompanyIntro setModalOpen={setModalOpen} modalOpen={modalOpen} />
        </>

    )
}

export default Footer