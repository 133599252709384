import React, { useEffect, useState } from 'react';
import { Button, Modal, Select, Input, DatePicker, Form, Checkbox } from 'antd';
import { HiCheckCircle } from "react-icons/hi";
import { PlusCircleFilled } from '@ant-design/icons'
import { POST } from '../../utils/apis';
import axios from 'axios';
import moment from 'moment-timezone'
import { errorMessage, successMessage } from '../../utils/helpers';
import ChooseTicket from '../TicketShareTabs/ChooseTicket';
import { regions } from '../../utils/constants';


const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
        md: {
            span: 6,
        }
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
        md: {
            span: 18,
        },
    },
}


const config = {
    rules: [
        {
            type: 'object',
            required: true,
            message: 'Please select date!',
        },
    ],
}

const TicketInformation = (props) => {
    const { ticketId, user, setshowEditHolding, showEditHolding, language } = props
    const [ticketInfo, setTicketInfo] = useState([])

    const handleCancel = () => {
        setshowEditHolding(false);
    };


    const onFinish = () => {
        console.log('run')
    }



    return (
        <>
            <Modal
                open={showEditHolding}
                onCancel={handleCancel}
                footer={null}
                width={800}
            >
                <div >
                    <div>
                        <h1 style={{ textAlign: 'center' }}>{language?.TicketInformation}</h1>
                    </div>

                    <div className='main_modal_con' >
                        <div className='modal_first_panel purchaseDir'>
                            <div className="column-1 box" style={{ paddingLeft: 15 }}>
                                <h4>{language?.GolfCourseName}</h4>
                            </div>
                            <div className="column-2 box">
                                <p>{ticketId?.ticketId?.golfClub?.clubName}</p>
                            </div>
                        </div>
                        <div className='modal_first_panel purchaseDir'>
                            <div className="column-1 box" style={{ paddingLeft: 15 }}>
                                <h4>{language?.Address}</h4>
                            </div>
                            <div className="column-2 box">
                                <p>{ticketId && ticketId?.ticketId?.golfClub?.clubAddress}</p>
                            </div>
                        </div>
                        <div className='modal_first_panel purchaseDir'>
                            <div className="column-1 box" style={{ paddingLeft: 15 }}>
                                <h4>{language?.Type}</h4>
                            </div>
                            <div className="column-2 box">
                                <p>{ticketId && ticketId?.ticketId?.membershipType}</p>
                            </div>
                        </div>
                    </div>

                    <Form requiredMark={false} name='time_related_controls'  {...formItemLayout} onFinish={onFinish}>

                        <div className='second_panel'>
                            <Form.Item
                                name='rightPrice'
                                label={<h4><PlusCircleFilled className='plus_with_text' /> {language?.RightPrice}</h4>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input Right Price!',
                                    },
                                ]}
                            >
                                <Input
                                    type='number'
                                    suffix={language?.KRW}
                                    min={0}
                                    maxLength={7}
                                />
                            </Form.Item>

                            <Form.Item
                                name='usableDate'
                                label={<h4><PlusCircleFilled className='plus_with_text' /> {language?.UsableDate}</h4>}
                                {...config}
                            >
                                <DatePicker defaultValue={moment(ticketId?.joiningDate?.date1)} style={{ width: '100%' }} />
                            </Form.Item>

                            <Form.Item
                                name='expirationDate'
                                label={<h4><PlusCircleFilled className='plus_with_text' /> {language?.ExpirationDate}</h4>}
                                {...config}
                            >
                                <DatePicker defaultValue={moment(ticketId?.joiningDate?.date2)} style={{ width: '100%' }} />
                            </Form.Item>

                            <Form.Item
                                name='locationPreference'
                                label={<h4><PlusCircleFilled className='plus_with_text' /> {language?.LocationPreference}</h4>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select location!',
                                    },
                                ]}
                            >
                                <Select mode='multiple' placeholder='select Location'>
                                    {regions?.slice(1)?.map((v, i) => (<Option value={v}>{v}</Option>))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name='termsConditions1'
                                label={language?.TermCondition}
                                valuePropName='checked'
                                hasFeedback
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            value ? Promise.resolve() : Promise.reject(new Error(language?.Shouldacceptagreement)),
                                    }
                                ]}
                            >
                                <Checkbox />
                            </Form.Item>
                            <div className='agreement scroll_class' style={{ width: '100%' }}>
                                {/* <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eum porro assumenda, in dicta ab fuga, vero quasi nostrum molestiae, corporis perferendis similique quis deleniti ullam. Recusandae sequi quam vitae cumque! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae, nihil cupiditate rerum mollitia, nemo vitae temporibus amet quos esse architecto quod repellat laboriosam explicabo similique, alias beatae odio possimus? Accusamus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque blanditiis eaque saepe odio voluptas obcaecati at voluptatum, aliquid omnis laborum libero numquam architecto sit! Architecto quas ratione alias debitis deserunt!</p> */}
                                <p>{language?.TAC1}</p>
                                <p>{language?.TAC2}</p>
                                <p>{language?.TAC3}</p>
                                <p>{language?.TAC4}</p>
                                <p>{language?.TAC5}</p>
                                <p>{language?.TAC6}</p>
                                <p>{language?.TAC7}</p>
                            </div>
                        </div>
                        <br />
                        <Button className='req_exc_btn' type="primary">{language?.UploadTicket}</Button>
                    </Form>

                </div>
            </Modal >

        </>
    );
};

export default TicketInformation;