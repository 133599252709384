import React, { useState } from 'react'
import { Button, Input, Table, Modal, Form } from 'antd'
import { HiPlusCircle } from 'react-icons/hi'
import AuctionPostModal from './AuctionPostModal'
import { errorMessage, loginAlert, MyInfoAlert, successMessage } from '../../utils/helpers'
import axios from 'axios'
import { POST } from '../../utils/apis'
import SharedRentalAuctionModal from '../MyPageTabs/SharedRentalAuctionModal'
import ReservationModal from './ReservationModal'
import moment from 'moment-timezone'
import { IoIosAlert } from 'react-icons/io'
import { regions, regionsKr, regionsOnlyKr } from '../../utils/constants'
import { LoginAlert } from '..'
import AuctionModal from './AuctionModal'

const GolfReservationAuction = (props) => {
    let { user, auction, ticket, fun, language, lanKorCondition, adminBankDetail } = props
    let userId = user?._id
    const [price, setPrice] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [showEditHolding, setshowEditHolding] = useState(false)
    const [showHoldingModal, setShowHoldingModal] = useState(false)
    const [bankDetail, setBankDetail] = useState({})
    const [bankDetailLoading, setBankDetailLoading] = useState(false)
    const [isModalOpen2, setIsModalOpen2] = useState(false)
    const [loading, setLoading] = useState(false)
    const [index, setIndex] = useState(null)
    const [auctionData, setAuctionData] = useState({})

    const handleCancel2 = () => {
        setIsModalOpen2(false)
    }

    const children = []

    const applyForAuction = (vs) => {
        // let obj = {
        //     price,
        //     buyerId: userId,
        //     auctionId: vs?._id
        // }
        let obj = {
            price: 0,
            buyerId: userId,
            auctionId: vs?._id,
            bankDetail: bankDetail,
            isParticipated: true
        }

        if (!bankDetail?.name || !bankDetail?.bankName || !bankDetail?.bankAccountNo) {
            return errorMessage(lanKorCondition ? 'Please provide All fields!' : '모든 항목을 작성해주세요!')
        }
        setLoading(true)
        axios.post(POST.CREATE_AUCTION_GOLF_BID, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                setIsModalOpen2(false)
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    fun()
                }
                else {
                    console.log('runing')
                }
            })
            .catch((e) => {
                console.log('err', e)
                setLoading(false)
                setIsModalOpen2(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })

    }
    const onFinish = (values) => {
        setBankDetailLoading(true)
        let obj = {
            name: values?.name,
            bankName: values?.bankName,
            bankAccountNo: values?.bankAccNo,
        }
        if (obj?.name && obj?.bankName && obj?.bankAccountNo) {
            setBankDetail(obj)
            setTimeout(() => {
                setBankDetailLoading(false)
                successMessage('Saved')
            }, 1000)
        }
    }

    const columns = [
        {
            title: language?.RegistrationDate,
            dataIndex: 'createdAt',
            width: 150,
            render: (e) => <p style={{ marginBottom: 0 }}> {moment(e).format('YYYY-MM-DD')}  </p>
        },
        {
            title: language?.Region,
            dataIndex: ['golfClub', 'region'],
            key: 'region',
            width: 150,
            render: (e) => <p>{e ? lanKorCondition ? e :
                regionsKr[regionsOnlyKr?.indexOf(e)]?.TitleKr ?
                    regionsKr[regionsOnlyKr?.indexOf(e)]?.TitleKr :
                    regionsKr[regions?.indexOf(e)]?.TitleKr
                : '----'}</p>
        },
        {
            title: language?.GolfCourse,
            dataIndex: ['golfClub', 'clubName'],
            key: 'clubname',
            width: 150,

        },
        {
            title: language?.ReservaDate,
            dataIndex: 'reservedDate',
            width: 150,
            render: (e) => {
                const date1 = new Date(e?.date1);
                const date2 = new Date(e?.date2);

                const diffInMilliseconds = Math.abs(date2 - date1); // Difference in milliseconds

                const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
                return <p style={{ marginBottom: 0 }}> {diffInDays} </p>
            }
            // render: (e) => <p style={{ marginBottom: 0 }}> {moment.duration(e?.date2?.format('YYYY-MM-DD').diff(e?.date1)).days()} </p>

        },
        {
            title: language?.GreenFee,
            dataIndex: 'greenFee',
            width: 150,
            render: (e) => <p style={{ marginBottom: 0 }}>
                {e ? e + ' ' + language?.Won : '---'}
            </p>
        },
        {
            title: language?.TransferFee,
            dataIndex: 'transferFee',
            width: 150,
            render: (e) => <p style={{ marginBottom: 0 }}>
                {e ? e + ' ' + language?.Won : '---'}
            </p>

        },
        {
            title: language?.Registrant,
            // dataIndex: ['userId', 'fullName'],
            width: 150,
            render: (e) => <p style={{ marginBottom: 0 }}>{e?.adminId ? 'admin' : e?.userId?.fullName}</p>

        },
        {
            title: language?.ActionNew,
            key: 'action',
            render: (record, index) => (<>

                {/* <span style={{ display: 'flex' }}>
                <Input onChange={(e) => setPrice(e.target.value)} style={{ width: 150 }} suffix={language?.KRW} />
                    {user?._id ? <Button disabled={!price}
                        onClick={() => applyForAuction(_, record)}
                        className='btn_apply-new'>{language?.Apply}</Button> : <Button
                            onClick={() => loginAlert(language)}
                            className='btn_apply-new'>{language?.Apply}</Button>}
                            
                </span> */}
                <div className='btn_card'>
                    {user?._id ?
                        user?._id && user?.telNo && user?.bank && user?.bankAccountNumber ?
                            <Button key={record?._id}
                                // loading={indexNum == i ? loading : false}
                                onClick={() => { setIsModalOpen2(true); setIndex(index); setAuctionData(record) }}
                                className='partcipation-auction-btn'
                                style={user?._id === record?.userId?._id ? { opacity: 0.5 } : null}
                                disabled={user?._id === record?.userId?._id}
                            >
                                {language?.Apply}
                            </Button> :
                            <Button onClick={() => MyInfoAlert(language)} className='partcipation-auction-btn' >
                                {language?.Apply}
                            </Button> :
                        <LoginAlert clas={'partcipation-auction-btn'} buttonName={language?.Apply} {...props} />

                    }
                </div>
            </>
            ),
        },

    ]

    return (
        <div className='ticket_share_panels'>
            <div className='container_tick_share'>
                <div className='layout_tick_share'>
                    <div className='col_tick_share col_main_tick_share'>
                        <div className='left_panel'>
                            <h2 className='col_primary'>{language?.GolfReservationAuction}</h2>
                        </div>
                    </div>
                    <div className='col_tick_share col_complementary_tick_share' >
                        {
                            user?._id ?
                                <ReservationModal language={language} ticket={ticket}  {...props} />
                                :
                                // <Button className='upload_sharing_btn' onClick={() => loginAlert(language)}>
                                //     {language?.RegisterAuctionPost}
                                // </Button>
                                <LoginAlert clas={'upload_sharing_btn'} buttonName={language?.RegisterAuctionPost} {...props} />

                        }
                    </div>
                </div>
            </div>
            <SharedRentalAuctionModal language={language} ticket={ticket} showHoldingModal={showHoldingModal} tittle={language?.GolfReservationAuction} showEditHolding={showEditHolding} setshowEditHolding={setshowEditHolding} {...props} />

            <div className='main_table_cont' style={{ marginTop: 20 }}>
                <Table
                    scroll={{ x: 1200 }}
                    className='table-striped-rows table_home'
                    columns={columns}
                    dataSource={auction}
                    pagination={false}
                    bordered={false}
                />
                <hr className='_hr' />
                <AuctionModal
                    apiRoute={POST.CREATE_AUCTION_GOLF_BID}
                    setLoading={setLoading}
                    loading={loading}
                    v={auctionData}
                    i={index}
                    isModalOpen2={isModalOpen2}
                    setIsModalOpen2={setIsModalOpen2}
                    {...props} />

            </div>
        </div>
    )
}

export default GolfReservationAuction