import { Select } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { GET } from '../../utils/apis'
import { ticketTranslate } from '../../utils/helpers'


const ChooseTicket = (props) => {
    const { user, setSelectedTicket, language, lanKorCondition } = props
    const [ticketData, setTicketData] = useState([])

    const onChange = (value) => {
        setSelectedTicket(_.find(ticketData, { _id: value }))
    }
    const onSearch = (value) => {
        console.log('search:', value)
    }

    useEffect(() => {
        getTickets()
    }, [])

    const getTickets = () => {
        axios.get(`${GET?.GET_TICKTS}/${user?._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setTicketData(data?.data)
                    // setLoading(false)
                    // setTicketData(data?.data || [])
                    console.log('data', data)
                }
                else {
                    console.log(data?.message)
                }
            }).catch((err) => {
                console.log('err', err)
            })
    }


    return (
        <Select
            showSearch
            placeholder={lanKorCondition ? 'Select Membership' : '회원권 선택'}
            optionFilterProp='children'
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) => option.children.toLowerCase()?.includes(input?.toLowerCase())}
        >
            {/* {ticketData?.map((v) => (<Option value={v?._id}>{v?.membershipCardName}</Option>))} */}
            {ticketData?.map((v) => (<Option value={v?._id}>{ticketTranslate(v?.membershipCardName,lanKorCondition)}</Option>))}
        </Select>
    )
}
export default ChooseTicket