import { PlusCircleFilled } from '@ant-design/icons'
import { Button, Checkbox, Divider, Form, Image, Input, InputNumber, Modal, Select } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ADMIN, POST } from '../../utils/apis'
import { regions, regionsKr, types, typesKr } from '../../utils/constants'
import { errorMessage, MyInfoAlert, successMessage } from '../../utils/helpers'
import ChooseGroupBuyTickets from './ChooseGroupBuyTickets'

const { Option } = Select

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 10,
        },
        md: {
            span: 10,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 14,
        },
        md: {
            span: 14,
        },
    },
}

const UploadGroupBuyModal = (props) => {
    const { user, language, lanKorCondition } = props
    const [form] = Form.useForm()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedTicket, setSelectedTicket] = useState({})
    const [golfClubData, setGolfClubData] = useState(null)
    const [fee, setFee] = useState(null)
    const [seats, setSeats] = useState(null)
    const [seatPrice, setSeatPrice] = useState(null)
    const [loading, setLoading] = useState(false)
    const [golfClub, setGolfClubName] = useState(null)
    const [golfClubRequest, setGolfClubRequest] = useState(false)

    const showModal = () => {
        setIsModalOpen(true);
    }

    const handleCancel = () => {
        setIsModalOpen(false)
        setGolfClubData(null)
    }
    // const onChange1 = (value) => {
    //     let new_value = golfClub?.find((v) => v?._id === value)
    //     setGolfClubData(new_value)
    // }

    const onChanges = (value) => {

        let new_value = golfClub?.find((v) => v?._id === value)
        setGolfClubData(new_value)
        // setAddress(new_value?.clubAddress)

    }

    const setStatesEmpty = () => {
        setSelectedTicket({})
        setFee(null)
        setSeats(null)
        setSeatPrice(null)
    }

    const onFinish = (fieldsValue) => {
        setLoading(true)

        if (golfClubRequest && (golfClub.find((v) => v?.clubName.trim().toLowerCase() === fieldsValue?.golfClubRequest))?._id) {
            return errorMessage(lanKorCondition ? 'This golf club exists, select the golf club' : '해당 골프장은 이미 등록되어있습니다. 골프장을 선택해주세요.')
        }

        if (!golfClubRequest && !golfClubData?._id) {
            return errorMessage(lanKorCondition ? 'please select golf course' : '골프장을 선택해주세요')
        }

        fieldsValue.golfClubRequest = fieldsValue?.golfClubRequest
        fieldsValue.golfClub = golfClubData?._id
        fieldsValue.seatPrice = seatPrice
        fieldsValue.totalSeats = Number(fieldsValue.totalSeats)
        fieldsValue.totalSeatsPrice = Number(fieldsValue.totalSeatsPrice)
        fieldsValue._id = user?._id

        console.log('fieldsValue', fieldsValue)

        axios.post(POST.UPLOAD_GROUP_BUY, fieldsValue)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    setIsModalOpen(false)
                    setGolfClubData(null)

                    form.resetFields()
                    setStatesEmpty()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }
    let golfClubName = () => {
        axios.get(ADMIN.GOLF_CLUB)
            .then((res) => {

                const { data } = res
                if (data.success) {
                    setGolfClubName(data?.data)
                }
                else {
                }
            })
            .catch((e) => {
                console.log('err', e)
            })
    }
    useEffect(() => {
        golfClubName()
    }, [])

    const handleInputChange = (value) => {

        if (value > 9) {
            errorMessage('글자 수 초과')
        } else {
            setSeats(value)
            setSeatPrice(fee / value)
        }
    };
    const spaceValidator = (rule, value, callback) => {
        if (!value) {
            callback('Please Input Golf Club Name');
        }else if (value && value?.trim() === '') {
            callback('Input cannot be empty spaces');
        } else {
            callback();
        }
    }
    return (
        <>
            {user?._id && user?.telNo && user?.bank && user?.bankAccountNumber ?
                <Button className='upload_sharing_btn' onClick={showModal}>
                    {language?.UploadGroupBuy}
                </Button> :
                <Button className='upload_sharing_btn' onClick={() => { MyInfoAlert(language) }}>
                    {language?.UploadGroupBuy}
                </Button>}
            <Modal
                open={isModalOpen}
                // open={true}
                onCancel={handleCancel}
                className='responsiveModal'
                footer={null}
                style={{ top: '10px', }}
            >
                <div
                    className='scroll_class'
                    style={{
                        height: 'auto',
                        width: '100%',
                        maxHeight: '85vh',
                        overflowY: 'scroll',
                        marginTop: '12px'
                    }}>
                    <div className='modal_main_div' >
                        <center>
                            <h2>{language?.RequestGroupB}</h2>
                            <p style={{ color: '#1890ff', marginBottom: 0 }}>{language?.PleaseFillinthisformofwantedgroupbuy}</p>
                            <p style={{ color: '#1890ff', marginBottom: 0 }}>{language?.Wewillnotifyyouwhenhasbeenprocessed}</p>
                        </center>
                        <br />

                        <Form requiredMark={false} form={form} name='time_related_controls'  {...formItemLayout} onFinish={onFinish}>
                            {!golfClubRequest ?
                                <>
                                    <Form.Item
                                        name='golfClub'
                                        label={language?.GolfCourseName}
                                    >
                                        <Select
                                            style={{ width: '100%' }}
                                            showSearch
                                            placeholder={language?.SearchGolfCourseName}
                                            optionFilterProp='children'
                                            // onChange={onChange1}
                                            onChange={onChanges}
                                            // onSearch={onSearch}
                                            filterOption={(input, option) => option.children.toLowerCase()?.includes(input?.toLowerCase())}
                                        >
                                            {golfClub?.map((v, i) => (<Option key={i} value={v?._id}>{v?.clubName}</Option>))}
                                        </Select>
                                    </Form.Item>
                                    {golfClubData?._id ?
                                        <div className='row_fields1' style={{ maxWidth: 300, marginBottom: 20 }}>
                                            <Image width={100} height={100} src={golfClubData?.imgUrl} />
                                            <div style={{ marginTop: -10 }}>
                                                <h2>{golfClubData?.clubName}</h2>
                                                <p>
                                                    {lanKorCondition ? golfClubData?.region : regionsKr[regions?.indexOf(golfClubData?.region)]?.TitleKr}
                                                </p>
                                                {/* <p>{golfClubData?.region}</p> */}

                                                <Button onClick={() => { setGolfClubData(null); setAddress(null), form.setFieldsValue({ golfClub: null }) }}>{language?.Delete}</Button>
                                            </div>
                                        </div> : null}
                                    <br />
                                </>
                                :
                                <>
                                    <Form.Item
                                        name='golfClubRequest'
                                        label={language?.GolfClubRequest}
                                        rules={[
                                            {
                                                required: true,
                                                // message: 'Please Input Golf Club Name',
                                                validator: spaceValidator
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder={language?.GolfClubName}
                                        />
                                    </Form.Item>
                                    <br /></>}

                            {!golfClubRequest ?
                                <Button onClick={() => setGolfClubRequest(true)} type='primary' style={{ float: 'right', backgroundColor: '#727171', border: 'none', color: 'white' }}>골프장 추가 요청
                                </Button>
                                :
                                <Button onClick={() => setGolfClubRequest(false)} type='primary' style={{ float: 'right', backgroundColor: '#727171', border: 'none', color: 'white' }}>골프장 검색
                                </Button>
                            }
                            <br />
                            <br />

                            <Form.Item
                                name='totalSeatsPrice'
                                label={language?.membershipAmount}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.PleaseinputFee,
                                    },
                                ]}
                            >
                                <div>
                                    <Input
                                        suffix={language?.KRW}
                                        placeholder={language?.membershipAmount}
                                        maxLength={10}
                                        value={fee}
                                        onChange={(e) => { setFee(e.target.value?.replace(/[^0-9]/g, '')), setSeatPrice(e.target.value / seats) }}
                                    />
                                </div>
                            </Form.Item>

                            <Divider style={{ backgroundColor: '#bbb', marginBottom: 20 }} />

                            <Form.Item
                                name='totalSeats'
                                label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' />{language?.TotalDesiredAccount}</h4>}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.PleaseinputSeats,
                                    },
                                ]}
                            >
                                <div style={{ width: '100%' }}>
                                    <InputNumber
                                        style={{ width: '100%' }}
                                        type='number'
                                        // suffix={language?.KRW}
                                        placeholder={language?.TotalDesiredAccount}
                                        // max={9}
                                        min={1}
                                        precision={0}
                                        value={seats}
                                        onChange={handleInputChange}
                                    // onChange={(e) => { setSeats(e), setSeatPrice(fee / e) }}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item
                                name='seatPrice'
                                label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' />{lanKorCondition ? 'Seat Price' : '구좌당 금액'}</h4>}
                            >
                                <div>
                                    <Input
                                        suffix={language?.KRW}
                                        placeholder={language?.AmoutperAccount}
                                        maxLength={7}
                                        disabled
                                        value={seatPrice}
                                    />
                                </div>
                            </Form.Item>

                            <br />

                            <Form.Item
                                name='TermAndConditions'
                                label={lanKorCondition ? 'Terms & Conditions (required)' : '이용약관(필수)'}
                                valuePropName='checked'
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        validator: (_, value) =>
                                            value ? Promise.resolve() : Promise.reject(new Error(language?.ShouldAcceptAgreement)),
                                    }
                                ]}
                            >
                                <Checkbox />
                            </Form.Item>

                            <div className='agreement scroll_class' style={{ marginBottom: 20 }}>
                                <p>{language?.TAC1}</p>
                                <p>{language?.TAC2}</p>
                                <p>{language?.TAC3}</p>
                                <p>{language?.TAC4}</p>
                                <p>{language?.TAC5}</p>
                                <p>{language?.TAC6}</p>
                                <p>{language?.TAC7}</p>
                            </div>

                            <Button loading={loading} className='req_exc_btn' type='primary' htmlType='submit'>{language?.joinGroupBuy}</Button>
                        </Form>


                    </div>
                </div>
            </Modal>
            {/* <Modal
                open={isModalOpen2}
                onCancel={handleCancel2}
                footer={[<Button className='req_exc_btn' type='primary' onClick={showModal3}>Buy this Ticket</Button>]}
                width={800}
            >
                <h2 style={{ textAlign: 'center' }}>Purchase</h2>
                <p style={{ textAlign: 'left' }}>
                    Membership wish to trade
                </p>
                <div className='main_modal_con'>
                    <div className='modal_first_panel'>
                        <div className='column-1 box'>
                            <h4>Golf Club Name</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>East Valley CC</p>
                        </div>
                    </div>
                    <div className='modal_first_panel'>
                        <div className='column-1 box'>
                            <h4>Type</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>Company</p>
                        </div>
                    </div>
                    <div className='modal_first_panel'>
                        <div className='column-1 box'>
                            <h4>Member Fee/Non-Member Fee</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>Member Fee 5 million won/ Non-Member Fee 30 millio won</p>
                        </div>
                    </div>
                    <div className='modal_first_panel'>
                        <div className='column-1 box'>
                            <h4>Right Price</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>80 million won</p>
                        </div>
                    </div>
                    <div className='modal_first_panel'>
                        <div className='column-1 box'>
                            <h4>Reserved Date</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>2022.4.22 | 13:30</p>
                        </div>
                    </div>
                    <div className='modal_first_panel'>
                        <div className='column-1 box'>
                            <h4>Expiration Date</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>2022.4.22</p>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                open={isModalOpen3}
                onCancel={handleCancel3}
                footer={[<div className='message_modal'><Button className='message_modal_btn' type='primary' onClick={handleOk3}>Go to Ticket Sales right away</Button>
                    <Button className='message_modal_btn marginLeft' type='primary' onClick={handleOk3}>Talk to User</Button></div>]}
                width={800}
            >
                <h2 style={{ textAlign: 'center' }}>Completion</h2>
                <center><HiCheckCircle className='tick_icon' /></center>
                <h4 style={{ textAlign: 'center' }}>
                    Exchange/ rent request delivered to user now.
                </h4>
                <h4 style={{ textAlign: 'center' }}>
                    Please wait for the response
                </h4>
            </Modal> */}
        </>
    );
};

export default UploadGroupBuyModal;