import { Button, Input, Table } from 'antd';
import axios from 'axios';
import moment, { lang } from 'moment';
import React, { useState } from 'react';
import { POST } from '../../utils/apis';
import { errorMessage, successMessage } from '../../utils/helpers';

const ManageRental = (props) => {
    let { user, auction, loading, getManageAuction, language, lanKorCondition } = props
    let userId = user?._id
    const [price, setPrice] = useState(0)
    const [loading1, setLoading1] = useState(false)

    const bidsData = auction?.map((v, i) =>

        v?.bids?.map((x, i) => {
            return {
                ...v,
                bids: x
            }
        })
    )

    // const bidsData = auction?.map((v, i) =>
    //     v?.bids?.filter((x) => {

    //             return {
    //                 ...v,
    //                 bids: x
    //             }
    //     })
    // )
    const applyForAuction = (vs) => {
        setLoading1(true)
        let obj = {
            bidId: vs?.bids?._id,
            userId: user?._id,
        }

        axios.post(POST.ACCEPT_MANAGE_AUCTION_RENTAL_GOLF_BID, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    getManageAuction()
                }
                else {
                    console.log('runing')
                }
                setLoading1(false)
            })
            .catch((e) => {
                console.log('err', e)
                errorMessage(e)
                setLoading1()
            })

    }
    console.log('isCanceled', auction)
    const cancelAuction = (v) => {

        const obj = {
            userId: user?._id,
            bidId: v?.bids?._id
        }

        console.log('obj cancel', obj)
        axios.post(POST.CANCEL_MANAGE_AUCTION_RENTAL_GOLF_BID, obj)
            .then((res) => {
                const { data } = res
                console.log('data', data)

                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    getManageAuction()
                }
                else {
                    console.log('runing')
                }
            })
            .catch((e) => {
                console.log('err', e)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })


    }

    const columns = [
        {
            title: language?.RegistrationDate,
            dataIndex: 'createdAt',
            width: 150,
            render: (e) => <p style={{ marginBottom: 0 }}>{moment(e).format('YYYY-MM-DD')}</p>
        },
        {
            title: language?.GolfCourse,
            dataIndex: ['ticketId', 'golfClub', 'clubName'],
            key: 'golfClubName',
            width: 150,

        },
        {
            title: language?.Type,
            dataIndex: ['ticketId', 'membershipType'],
            width: 150,
        },
        {
            title: language?.MemberFee,
            dataIndex: ['ticketId', 'memberFee'],
            width: 150
        },
        {
            title: language?.NonMemberFee,
            dataIndex: ['ticketId', 'NONmemberFee'],
            width: 150,

        },
        {
            title: language?.UsableDate,
            dataIndex: 'usableDate',
            width: 150,
            render: (e) => <p style={{ marginBottom: 0 }}>{moment(e).format('YYYY-MM-DD')}</p>
        },
        {
            title: language?.ExpirationDate,
            dataIndex: 'expirationDate',
            width: 150,
            render: (e) => <p style={{ marginBottom: 0 }}>{moment(e).format('YYYY-MM-DD')}</p>
        },
        {
            title: language?.Registrant,
            dataIndex: ['bids', 'buyerId', 'fullName'],
            key: 'fullName',
            width: 150,
            // render: (e)=>console.log('e',e)

        },
        {
            // title: 'Action',
            key: 'action',
            render: (v) => (<>
                <span style={{ display: 'flex' }}>
                    <Input disabled value={v?.bids?.price} style={{ width: 150 }} suffix={language?.KRW} />
                    <Button loading={loading1} onClick={() => applyForAuction(v)} className='btn_apply'>{language?.Accept}</Button>
                    <Button onClick={() => cancelAuction(v)} className='btn_apply'>{language?.Cancel}</Button>
                </span>
            </>
            ),
        },

    ];

    return (

        <div className='auction_golf_res_table'>
            <div className='tab_con_auction_child_main'>
                <h2>{language?.ManageMyAuctionPosts}</h2>
            </div>
            <div className='main_table_cont' style={{ marginTop: 20 }}>
                <Table
                    className="table-striped-rows table_home"
                    columns={columns}
                    loading={loading}
                    dataSource={bidsData?.flat()}
                    pagination={false}
                    bordered={false}
                    scroll={{
                        x: 1300,
                    }}
                />
                <hr className='_hr' />
            </div>
        </div>
    )
}

export default ManageRental