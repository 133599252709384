// import { loginUser, removeUser } from '../../Redux/actions/authActions'
import { loginUser, removeUser } from '../Redux/actions/authActions'
import { message, notification } from 'antd'
import allPaths from '../Config/paths'
import axios from 'axios'
import swal from 'sweetalert'
import { useSelector } from 'react-redux'
import { AUTH } from '../utils/apis'
import { LoginAlert } from '../Components'
import { breadCrumbsKr, typesKr, types } from './constants'

const requiredMessage = (value) => value

const inputPlace = (value) => `Input your ${value} Here...!`

const setActiveMenu = (path) => path === allPaths.HOME ? 0 : 1

const successMessage = (desc = 'Successfully Complete!') => {
    return message.success(desc)
}

const infoMessage = (desc = 'Successfully Complete!') => {
    return message.info(desc)
}

const errorMessage = (desc = 'Oops Something Went Wrong!') => {
    return message.error(desc)
}

const warningMessage = (desc = 'Warning!') => {
    return message.warning(desc)
}

const successNotification = (message = 'Successfully Complete!') => {
    return notification.success({ message })
}

const errorNotification = (message = 'Oops Something Went Wrong!') => {
    return notification.error({ message })
}

const convertTitle = (val) => val.charAt(0).toUpperCase() + val.slice(1)

const hideMiddleName = (val, limit = 2) => val?.length > limit ? `${val.slice(0, limit)}*${val.slice(3)}` : val
const stringLimiter = (val, limit = 50) => val?.length > limit ? `${val.slice(0, limit)}...` : val

const userObject = (result) => {
    const { profileObj } = result
    return {
        email: profileObj?.email,
        authType: 'social',
        fullName: `${profileObj?.givenName} ${profileObj?.familyName}`,
        uid: profileObj?.googleId
    }
}

const userObject2 = (result) => {
    return {
        email: result?.email,
        authType: 'social',
        fullName: result?.name,
        uid: result?.userID
    }
}
const isUserLoginApi = (id, isLogin) => {
    // IS_USER_LOGIN
    let obj = { _id: id, isLogin: isLogin }
    axios.post(AUTH?.IS_USER_LOGIN, obj)
        .then((res) => {
            const { data } = res
            if (data?.success) {
                console.log('ONLINE...!')
            } else {
                console.log('OFFINE...!')
            }
        })
}

const googleLogin = (result, history, loginUser, dispatch, lanKorCondition) => {
    const obj = userObject(result)
    axios.post(AUTH?.SOCIAL_LOGIN, obj)
        .then(async (res) => {
            const { data } = res
            if (data.success) {
                dispatch(loginUser(data?.user))
                successNotification(lanKorCondition ? 'Successfully Logged In!' : '로그인 성공')
                await isUserLoginApi(data?.user?._id, true)
                return setTimeout(() => {
                    history?.replace(allPaths?.HOME)
                }, 300)
            } else {
                errorMessage(data.message)
                console.log('e mess', data?.message)
            }
        })
        .catch((e) => {
            console.log('e', e)
            errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
        })
}

const facebookLogin = (result, history, loginUser, dispatch, lanKorCondition) => {
    const obj = userObject2(result)
    axios.post(AUTH?.SOCIAL_LOGIN, obj)
        .then(async (res) => {
            const { data } = res
            console.log('facebook login data==>', data)
            if (data?.success) {
                dispatch(loginUser(data?.user))
                // successMessage(data.message)
                successNotification(lanKorCondition ? 'Successfully Logged In!' : '로그인 성공')
                await isUserLoginApi(data?.user?._id, true)
                return setTimeout(() => {
                    history?.replace(allPaths?.HOME)
                }, 300)
            } else {
                console.log('e mess', data?.message)
                errorMessage(lanKorCondition ? data.message : data.messageKr)
            }
        })
        .catch((e) => {
            console.log('e', e)
            errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
        })
}

const kakaoLogin = (result, history, loginUser, dispatch, lanKorCondition) => {
    let { response, profile } = result

    let obj = {
        email: profile?.email,
        authType: 'social',
        fullName: profile?.kakao_account?.profile?.nickname,
        uid: profile?.id
    }
    // return true
    axios.post(AUTH?.SOCIAL_LOGIN, obj)
        .then(async (res) => {
            const { data } = res
            if (data?.success) {
                dispatch(loginUser(data?.user))
                // successMessage(data.message)
                successNotification(lanKorCondition ? 'Successfully Logged In!' : '로그인 성공')
                await isUserLoginApi(data?.user?._id, true)
                return setTimeout(() => {
                    history?.replace(allPaths?.HOME)
                }, 300)
            } else {
                console.log('e mess', data?.message)
                errorMessage(lanKorCondition ? data.message : data.messageKr)
            }
        })
        .catch((e) => {
            console.log('e', e)
            errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
        })

}
const naverLogin = (result, history, loginUser, dispatch, lanKorCondition) => {
    // let { response, profile } = result
    let obj = {
        email: result?.email,
        authType: 'social',
        fullName: result?.nickname,
        uid: result?.id,
        gender: result?.gender == "F" ? 'female' : 'male'
    }

    axios.post(AUTH?.SOCIAL_LOGIN, obj)
        .then(async (res) => {
            const { data } = res
            if (data?.success) {
                dispatch(loginUser(data?.user))
                successNotification(lanKorCondition ? 'Successfully Logged In!' : '로그인 성공')
                await isUserLoginApi(data?.user?._id, true)
                return setTimeout(() => {
                    history?.replace(allPaths?.HOME)
                }, 300)
            } else {
                console.log('e mess', data?.message)
                errorMessage(lanKorCondition ? data.message : data.messageKr)
            }
        })
        .catch((e) => {
            console.log('e', e)
            errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
        })

}
let location = window?.location?.pathname
let { FIND, FINDIID, FINDCID, FINDIP, FINDCP, INDIVIDUAL_SIGNUP, ADMIN_INDIVIDUAL_SIGNUP, CORPORATE_SIGNUP, LOGIN, HOME, FIND_ID_PASS_VERIFICATION, FIND_ID_GUIDE, FINDPASSWORD, FIND_PW_GUIDE, FAQ, ADMIN_SIGNUP } = allPaths

let PUBLIC_PATHS = location === FIND || location === FINDIID
    || location === FINDCID || location === FINDIP || location === FINDCP
    || location === INDIVIDUAL_SIGNUP || location === CORPORATE_SIGNUP || location === LOGIN || location === HOME
    || location === FIND_ID_PASS_VERIFICATION || location === FIND_ID_GUIDE
    || location === FINDPASSWORD
    || location === FIND_PW_GUIDE
    || location === FAQ
    || location === ADMIN_INDIVIDUAL_SIGNUP

const loginAlert = (language) => {
    swal({
        title: language?.login,
        text: language?.LoginAlertText,
        type: 'error',
        button: language?.login,
        dangerMode: true,
    }).then(login => {
        if (login) {
            window.location.href = allPaths?.LOGIN
        }
    })
}





const MyInfoAlert = (language) => {
    swal({
        title: language?.IncompleteInformation,
        text: language?.Pleasecompleteinputuserinfo,
        type: 'info',
        button: language?.GoToMyPage,
        dangerMode: true,
    }).then(login => {
        if (login) {
            window.location.href = `${allPaths?.MYPAGE}?edit=true`
        }
    })
}

const translateBreadcrumb = (bread, lanKorCondition) => {
    const result = breadCrumbsKr.find(({ TitleEn }) => TitleEn === bread)
    return lanKorCondition ? result?.TitleEn : result?.TitleKr
}

const typeTranslate = (membershipType, lanKorCondition) => {
    return lanKorCondition ? membershipType : typesKr[types?.indexOf(membershipType)]?.typesKr
}

const ticketTranslate = (ticketName, lanKorCondition) => {
    let tempArr = ticketName?.split(',')
    tempArr[1] = typeTranslate(tempArr[1], lanKorCondition)
    return tempArr?.join()
}

const formatTelNo = (val) => {
    if (val?.length) {
        val = val.replace(/[^0-9-]/g, '')
        val = val.replace(/[-]/g, '')
        return `${val?.slice(0, 3)}-${val?.slice(3, 7)}-${val?.slice(7)}`
    }
    return val
}

export {
    requiredMessage,
    inputPlace,
    setActiveMenu,
    successMessage,
    infoMessage,
    errorMessage,
    warningMessage,
    successNotification,
    errorNotification,
    convertTitle,
    stringLimiter,
    googleLogin,
    userObject,
    facebookLogin,
    PUBLIC_PATHS,
    hideMiddleName,
    kakaoLogin,
    loginAlert,
    isUserLoginApi,
    naverLogin,
    translateBreadcrumb,
    typeTranslate,
    ticketTranslate,
    MyInfoAlert,
    formatTelNo
}