import { Button, Card } from 'antd';
import axios from 'axios';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { HiPlusCircle } from 'react-icons/hi';
import { LoginAlert } from '..';
import noData from '../../assets/noData.gif';
import { GET } from '../../utils/apis';
import { allPaths, types, typesKr, typesOnlyKr } from '../../utils/constants';
import { MyInfoAlert } from '../../utils/helpers';
import TicketSalesInfo from '../MyPageTabs/TicketSalesInfo';
import MembershipTicketModal from './MembershipTicketModal';
import RequestBuyModal from './RequestBuy';

const TicketSale = (props) => {

    const { ticketPrice, user, language, selectedTags, lanKorCondition, selectedClubs, ticketnameFilter, selectedMemberships, sellType, history ,setclickedViewMore} = props
    const [shareTicket, setShareTicket] = useState([])
    const [loading, setLoading] = useState(false)
    const [showEditHolding, setshowEditHolding] = useState(false)
    const [editData, setEditData] = useState(null)
    const [editModal, setEditModal] = useState(false)
    const [allData, setAllData] = useState({})
    const [filteredData, setfilteredData] = useState([])
    const [text, setText] = useState(language?.VeiwMore)

    const seeAll = async () => {
        setclickedViewMore(true)
        setLoading(true)
        axios.get(`${GET.GET_ALL_SALE_TICKET}/?id=${user?._id}&limit=${0}`)
            .then((res) => {

                const { data } = res
                if (data.success) {
                    setShareTicket(data?.data)
                }
                else {
                    console.log('runing')
                }
                setLoading(false)
            })
            .catch((e) => {
                setLoading(true)
                console.log('err', e)
            })
    }

    const getAllTicketSale = () => {
        setLoading(true)
        axios.get(`${GET.GET_ALL_SALE_TICKET}/?id=${user?._id}&limit=${6}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setShareTicket(data?.data)
                }
                else {
                    console.log('runing')
                }
                setLoading(false)
            })
            .catch((e) => {
                setLoading(true)
                console.log('err', e)
            })
    }

    useEffect(() => {
        getAllTicketSale()
    }, [])

    const checkFilterData = (v) => {
        if (ticketnameFilter && selectedTags?.length) {
            return (v?.ticketId?.membershipCardName?.toLowerCase()?.includes(ticketnameFilter.trim().toLowerCase())) && (_.difference(selectedTags, v?.ticketId?.locationPreference).length !== selectedTags?.length)
        }
        else if (ticketnameFilter && !selectedTags?.length) {
            return v?.ticketId?.membershipCardName?.toLowerCase()?.includes(ticketnameFilter.trim().toLowerCase())
        }
        else if (!ticketnameFilter && selectedTags?.length) {
            return _.difference(selectedTags, v?.ticketId?.locationPreference).length !== selectedTags?.length
        }
        else {
            return v
        }
    }
    const checkClubsData = (v) => {
        if (selectedClubs?.length) {
            return selectedClubs.includes(v?.ticketId?.golfClub?.clubName)
        }
        else {
            return v
        }
    }
    const checkMembershipType = (v) => {
        if (selectedMemberships?.length) {
            return selectedMemberships.includes(v?.ticketId?.membershipType)
        }
        else {
            return v
        }
    }
    const checkTicketPrice = (v) => {
        if (ticketPrice?.start && ticketPrice?.end) {
            return (ticketPrice?.start < v?.ticketId?.rightPrice && v?.ticketId?.rightPrice < ticketPrice?.end)
        }
        else {
            return v
        }
    }
    const checkSellType = (v) => {
        if (sellType?.length == 1) {
            return v?.sellingType?.includes(sellType[0])
        }
        else {
            return v
        }
    }

    useEffect(() => {
        setfilteredData(shareTicket.filter(checkFilterData).filter(checkClubsData).filter(checkMembershipType).filter(checkTicketPrice).filter(checkSellType))
    }, [shareTicket, ticketnameFilter, selectedTags, selectedClubs, selectedMemberships])

    return (
        <div className='ticket_share_panels'>
            <div className='container_tick_share'>
                <div className='layout_tick_share'>
                    <div className='col_tick_share col_main_tick_share'>
                        <div className='left_panel'>
                            <h2 className='col_primary'>{lanKorCondition ? 'Ticket Sales' : '회원권 매매'}</h2>
                            <p onClick={seeAll} style={{ cursor: 'pointer', color: '#1890ff', margin: '7px 0px 0px 9px', textDecoration: 'underLine' }}>{text}</p>
                        </div>
                    </div>
                    <div className='col_tick_share col_complementary_tick_share' >
                        <MembershipTicketModal  {...props} fun={getAllTicketSale} />
                    </div>
                </div>
            </div>
            <div className='row_new_cont'>
                <TicketSalesInfo allData={allData} editData={editData} editModal={editModal} showEditHolding={showEditHolding} setshowEditHolding={setshowEditHolding} {...props} />

                {!loading ?
                    filteredData?.length ?
                        filteredData && filteredData?.map((v, i) => {
                            return (

                                <div className='column_new_cont' >
                                    <span onClick={() => {
                                        setshowEditHolding(true)
                                        setEditData(v?._id)
                                        setAllData(v)
                                    }}>
                                        <div className='card_panel'>
                                            <h5>{language?.GolfClub}</h5>
                                            <h5 className='not_bold'>{v && v?.ticketId?.golfClub?.clubName}</h5>
                                        </div>
                                        <div className='card_panel'>
                                            <h5>{language?.Type}</h5>
                                            <h5 className='not_bold'>{v?.ticketId?.membershipType ? lanKorCondition ? v?.ticketId?.membershipType :
                                                typesKr[typesOnlyKr?.indexOf(v?.ticketId?.membershipType)]?.typesKr ?
                                                    typesKr[typesOnlyKr?.indexOf(v?.ticketId?.membershipType)]?.typesKr :
                                                    typesKr[types?.indexOf(v?.ticketId?.membershipType)]?.typesKr
                                                : '----'}</h5>
                                        </div>
                                        <div className='card_panel'>
                                            <h5>{language?.JoiningFee}</h5>
                                            <h5 className='not_bold'>{v && v?.ticketId?.joiningFee}{language?.Won}</h5>
                                        </div>
                                        <div className='card_panel'>
                                            <h5>{language?.Timeperiod}</h5>
                                            <h5 className='not_bold'>{`${moment(v && v?.ticketId?.joiningDate?.date1).format('YYYY-MM-DD')} ~ ${moment(v && v?.ticketId?.joiningDate?.date2).format('YYYY-MM-DD')}`} </h5>
                                        </div>
                                        <div className='card_panel last_des'>
                                            <h5><HiPlusCircle className='plus_icon' />{language?.SpecialGift}</h5>
                                        </div>

                                        <div className='card_panel last_des'>
                                            {lanKorCondition ?
                                                <h5 className='not_bold'> Weekday {v && v?.ticketId?.specialGiftWeekDay}  회/  {v && v?.ticketId?.specialGiftWeekDayKRW} KRW</h5>
                                                :
                                                <h5 className='not_bold'>주중{v && v?.ticketId?.specialGiftWeekDay}회/ {v && v?.ticketId?.specialGiftWeekDayKRW}만원</h5>
                                            }
                                        </div>
                                        <div className='card_panel last_des' style={{ textAlign: 'left' }}>
                                            {lanKorCondition ?
                                                <h5 className='not_bold'> Weekend {v && v?.ticketId?.specialGiftWeekEnd}  회/{v && v?.ticketId?.specialGiftWeekEndKRW}KRW</h5>
                                                :
                                                <h5 className='not_bold'> 주말  {v && v?.ticketId?.specialGiftWeekEnd}회/{v && v?.ticketId?.specialGiftWeekEndKRW}만원</h5>
                                            }
                                        </div>
                                    </span>
                                    <div className='btn_card'>
                                        {user?._id ?
                                            user?._id && user?.telNo && user?.bank && user?.bankAccountNumber ?
                                                <Button className={lanKorCondition ? 'btn_chat_Admin' : 'btn_chat_Admin_kr'} style={v?.ticketId?.userId === user?._id ? { opacity: 0.3 } : null} disabled={v?.ticketId?.userId === user?._id} onClick={() => {
                                                    // history?.push(allPaths?.CHAT)
                                                    window.open('http://pf.kakao.com/_xdxebxjxj/chat', '_blank')
                                                }}>
                                                    {language?.ChatWithAdmin}
                                                </Button> :
                                                <Button className={lanKorCondition ? 'btn_chat_Admin' : 'btn_chat_Admin_kr'} style={v?.ticketId?.userId === user?._id ? { opacity: 0.3 } : null} disabled={v?.ticketId?.userId === user?._id} onClick={() => { MyInfoAlert(language) }}>
                                                    {language?.ChatWithAdmin}
                                                </Button>
                                            :
                                            <LoginAlert clas={lanKorCondition ? 'btn_chat_Admin' : 'btn_chat_Admin_kr'} buttonName={language?.ChatWithAdmin} {...props} />

                                        }
                                        {user?._id ?
                                            <RequestBuyModal language={language} purchaseData={v} {...props} /> :
                                            <LoginAlert clas={'btn_exchange1'} buttonName={language?.RequestBuyGo} {...props} />

                                        }
                                    </div>
                                </div>
                            )
                        }) :

                        <div className='NodataDiv'>
                            <img src={noData} className='NodataImg' />
                            <h3>{language?.ThereNoTicket}</h3>
                        </div> :
                    <div className='cards-lodaing-div'>
                        <Card className='column_new_cont' loading={true} />
                        <Card className='column_new_cont' loading={true} />
                        <Card className='column_new_cont' loading={true} />
                        <Card className='column_new_cont' loading={true} />
                    </div>}

            </div>
        </div>
    )
}

export default TicketSale