import { PlusOutlined } from '@ant-design/icons'
import { Button, Descriptions, Input, Space, Tabs, Tag, Tooltip, Typography } from 'antd'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { RiSoundModuleFill } from 'react-icons/ri'
import { useDispatch } from 'react-redux'
import { AUTH, POST } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import { errorMessage } from '../../utils/helpers'
import Header from '../Home/Header'

const { Title } = Typography;

const { Search } = Input;
const { TabPane } = Tabs;

const FindCorporateIDMember = (props) => {
    let { history, lanKorCondition, language } = props

    const [tags, setTags] = useState(['Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag',]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');
    const [name, setName] = useState('');
    const [telNum, setTelNum] = useState(null);
    const [loading, setLoading] = useState(false)

    const inputRef = useRef(null);
    const editInputRef = useRef(null);
    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);
    useEffect(() => {
        editInputRef.current?.focus();
    }, [inputValue]);

    const handleClose = (removedTag) => {
        const newTags = tags.filter((tag) => tag !== removedTag);
        console.log(newTags);
        setTags(newTags);
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        if (inputValue && tags?.indexOf(inputValue) === -1) {
            setTags([...tags, inputValue]);
        }

        setInputVisible(false);
        setInputValue('');
    };

    const handleEditInputChange = (e) => {
        setEditInputValue(e.target.value);
    };

    const handleEditInputConfirm = () => {
        const newTags = [...tags];
        newTags[editInputIndex] = editInputValue;
        setTags(newTags);
        setEditInputIndex(-1);
        setInputValue('');
    };

    const onChange = (value) => {
        console.log(value);
    };

    const onSearch = (value) => console.log(value);
    const onChangeTab = (key) => {
        console.log(key);
    };
    const dispatch = useDispatch()

    const onFinish = (value) => {

    }
    const registrationToNext = (value) => {
        setLoading(true)
        let obj = {
            corporateName: name,
            corporateRegistrationNumber: telNum
        }

        axios.post(AUTH.FIND_CORPORATE_MEMBER_ID, obj)
            .then((res) => {
                const { data } = res
                console.log('data', data)
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    history.push(allPaths.FINDPASSWORD, { data: data?.data })
                    setLoading(false)
                }

                else {
                    setLoading(false)
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                    // history.push(allPaths.FINDPASSWORD,{data:'data'})

                }
            })
            .catch((e) => {
                setLoading(false)
                console.log('e ', e)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })

    }


    return (
        <div className='home-main'>
            <Header {...props} />

            <div className='card-parent2'>
                <div className='card2'>
                    <Title level={3}>{language?.FindyourCorporateMemberID}</Title>
                    <br /><br /><hr style={{ border: '0.5px solid gray', backgroundColor: 'black' }} /><br />
                    <Title level={5}>{language?.SearchByBussenissNum}</Title>
                    <br />
                    <p className='special_text'>{language?.ContactCoustomer}</p>
                    <Descriptions bordered>
                        <Descriptions.Item label={language?.NameContactperson} span={3}>
                            <Input onChange={(e) => setName(e.target.value)} size="large" className='Signup_input1' />
                        </Descriptions.Item>


                        <Descriptions.Item label={language?.BusinessRegistrationNumber} span={3}>
                            <Input onChange={(e) => setTelNum(e.target.value)} size="large" className='Signup_input1' placeholder={language?.enterWithoutSong}/>
                        </Descriptions.Item>
                    </Descriptions>
                    <br /><br />
                    <center>
                        <Button loading={loading} onClick={registrationToNext} className='AuthButton' type="primary" size='large'>
                            {language?.Next}
                        </Button>
                    </center>

                </div>
            </div >
        </div >
    )
}

export default FindCorporateIDMember