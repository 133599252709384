import { BREAD_CRUMBS, CLEAR_BREAD_CRUMBS } from '../types'

const reducer = (state = { breadCrumbs: [] }, action) => {
    switch (action.type) {
        case BREAD_CRUMBS: {
            return { breadCrumbs: [...state.breadCrumbs, action.breadCrumbs] };
            // return { ...state, /* breadCrumbs: action.breadCrumbs */ breadCrumbs: [...state, action.breadCrumbs] }
        }
        case CLEAR_BREAD_CRUMBS:
            return {
                ...state, breadCrumbs: [],
            }
        default: {
            return state
        }
    }
}

export default reducer