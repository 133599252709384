import { Button, Form, Input, Modal } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { IoIosAlert } from 'react-icons/io'
import { POST } from '../../utils/apis'
import { errorMessage, successMessage } from '../../utils/helpers'

const AuctionModal = (props) => {
    let { user, loading, apiRoute, setLoading, fun, language, lanKorCondition, adminBankDetail, isModalOpen2, setIsModalOpen2, i, v } = props
    let userId = user?._id
    const [form] = Form.useForm()
    const [keyIndex, setKeyIndex] = useState(null)
    const [indexNum, setIndexNum] = useState(null)
    const [bankDetail, setBankDetail] = useState({})
    const [bankDetailLoading, setBankDetailLoading] = useState(false)

    const handleCancel2 = () => {
        setIsModalOpen2(false)
    }

    const applyForAuction = (vs, i) => {
        const name = form.getFieldValue('name');
        const bankName = form.getFieldValue('bankName');
        const bankAccNo = form.getFieldValue('bankAccNo');
        let bankDetail = {
            name: name,
            bankName: bankName,
            bankAccountNo: bankAccNo,
        }
        let obj = {
            price: 0,
            buyerId: userId,
            auctionId: vs?._id,
            bankDetail: bankDetail,
            isParticipated: true
        }

        console.log(bankDetail, "==bank")
        // return true
        if (!bankDetail?.name || !bankDetail?.bankName || !bankDetail?.bankAccountNo) {
            return errorMessage(lanKorCondition ? 'Please provide All fields!' : '모든 항목을 작성해주세요!')
        }
        setIndexNum(i)
        setLoading(true)
        // console.log('obj',obj)
        // return true
        axios.post(apiRoute, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setLoading(false)
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    fun()
                    setIsModalOpen2(false)
                }
                else {
                    setLoading(false)
                    console.log('runing')
                    setIsModalOpen2(false)
                }
            })
            .catch((e) => {
                console.log('err', e)
                setIsModalOpen2(false)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })

    }

    const onFinish = (values) => {
        setBankDetailLoading(true)
        let obj = {
            name: values?.name,
            bankName: values?.bankName,
            bankAccountNo: values?.bankAccNo,
        }

        if (obj?.name && obj?.bankName && obj?.bankAccountNo) {
            setBankDetail(obj)
            setTimeout(() => {
                setBankDetailLoading(false)
            }, 1000)
        }
    }

    return (
        <Modal
            open={isModalOpen2}
            onCancel={handleCancel2}
            footer={[
                <Button
                    key={i} loading={indexNum == i ? loading : false}
                    // disabled={!bankDetail?.name || !bankDetail?.bankName || !bankDetail?.bankAccountNo}
                    className='gray_buttons req_exc_btn' type='primary'
                    onClick={() => { applyForAuction(v, i), setKeyIndex(i) }}
                >{language?.Apply}</Button>
            ]}
            width={600}
            style={{ top: '10px', }}
        >
            <div
                className='scroll_class'
                style={{
                    height: 'auto',
                    width: '100%',
                    maxHeight: '80vh',
                    overflowY: 'scroll',
                    marginTop: '12px'
                }}>
                <div>
                    <h2 style={{ textAlign: 'center' }}>{language?.applyToAuction}</h2>
                    <h4 style={{ paddingLeft: '4%' }} >
                        {language?.advanceFeeAmount} <span style={{ color: 'blue' }}>{v?.advanceFee} {language?.KRW}</span>
                    </h4>
                    <h4 style={{ display: 'flex', flexDirection: 'row', paddingLeft: '4%' }}>
                        <IoIosAlert size={20} color='grey' />
                        <p style={{ paddingLeft: '5px' }}>
                            {language?.pleasePayAdvance}
                        </p>
                    </h4>
                    <div className='main_modal_con'>
                        <div className='modal_first_panel' style={{ paddin: '10px' }}>
                            <div className='column-1 box'>
                                <h4>{language?.BankName}:</h4>
                            </div>
                            <div className='column-2 box'>
                                <p>{adminBankDetail && adminBankDetail?.bankName}</p>
                            </div>
                        </div>
                        <div className='modal_first_panel' style={{ paddin: '10px' }}>
                            <div className='column-1 box'>
                                <h4>{language?.bankAccount}:</h4>
                            </div>
                            <div className='column-2 box'>
                                <p>{adminBankDetail && adminBankDetail?.bankAccount}</p>
                            </div>
                        </div>
                        <div className='modal_first_panel' style={{ paddin: '10px' }}>
                            <div className='column-1 box'>
                                <h4>{language?.BankAccountNumber}:</h4>
                            </div>
                            <div className='column-2 box'>
                                <p>{adminBankDetail && adminBankDetail?.bankAccountNumber}</p>
                            </div>
                        </div>

                        <div style={{ backgroundColor: '#f4f4f4', marginTop: '20px', padding: '10px', height: 'auto' }}>
                            <Form
                                name='form'
                                form={form}
                                labelCol={{
                                    span: 8,
                                }}
                                wrapperCol={{
                                    span: 16,
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                            >
                                <div>
                                    <h4>
                                        {language?.Inputyourbankaccount}
                                    </h4>
                                </div>

                                <Form.Item
                                    label={language?.Name}
                                    name='name'
                                    rules={[
                                        {
                                            required: true,
                                            message: language?.PleaseinputyourName,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label={language?.BankName}
                                    name='bankName'
                                    rules={[
                                        {
                                            required: true,
                                            message: language?.PleaseinputBank,
                                        }
                                    ]}
                                >
                                    <Input type='text' />
                                </Form.Item>
                                <Form.Item
                                    label={language?.BankAccountNumber}
                                    name='bankAccNo'
                                    rules={[
                                        {
                                            required: true,
                                            message: language?.PleaseinputBankacc,
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                {/* <Form.Item >
                                    <div style={{ textAlign: 'end' }}>
                                        <Button loading={bankDetailLoading} className='gray_buttons' type='primary' htmlType='submit'>
                                            {language?.Save}
                                        </Button>
                                    </div>
                                </Form.Item> */}

                            </Form>
                        </div>

                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AuctionModal