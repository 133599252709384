import { } from '@ant-design/icons'
import { Button, Select, Table } from 'antd'
import axios from 'axios'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { AiOutlineShareAlt } from 'react-icons/ai'
import { BiShoppingBag } from 'react-icons/bi'
import { FaGreaterThan } from 'react-icons/fa'
import { HiOutlineUserGroup } from 'react-icons/hi'
import { VscQuestion } from 'react-icons/vsc'
import { GET, MEMBER_TRANSACTION } from '../../utils/apis'
import TicketsInfo from './MemberTransactionInfo'
import GroupImg from '../../assets/groupBuy.png'
import SellImg from '../../assets/sell.png'
import { types, typesKr } from '../../utils/constants'

const MembershipTransactinTab = (props) => {
    const { user, language, lanKorCondition } = props
    const userId = user?._id
    const [data, setData] = useState({})
    const [filter, setFlter] = useState('3-months')
    const [loading, setLoading] = useState(false)

    const [exchangeBuy, setExchangeBuy] = useState([])
    const [exchangeSell, setExchangeSell] = useState([])
    const [rentBuy, setRentBuy] = useState([])
    const [rentSell, setRentSell] = useState([])
    const [ticketBuy, setTicketBuy] = useState([])
    const [ticketSell, setTicketSell] = useState([])
    const [groupbuyBuy, setGroupbuyBuy] = useState([])
    const [groupbuySell, setGroupbuySell] = useState([])
    // COUNT
    const [exchangeBuyCount, setExchangeBuyCount] = useState(0)
    const [exchangeSellCount, setExchangeSellCount] = useState(0)
    const [rentBuyCount, setRentBuyCount] = useState(0)
    const [rentSellCount, setRentSellCount] = useState(0)
    const [ticketBuyCount, setTicketBuyCount] = useState(0)
    const [ticketSellCount, setTicketSellCount] = useState(0)
    const [groupbuyBuyCount, setGroupbuyBuyCount] = useState(0)
    const [groupbuySellCount, setGroupbuySellCount] = useState(0)
    console.log('groupbuyBuy', groupbuyBuy)
    useEffect(() => {
        getAllRequestData()
    }, [filter])

    const getAllRequestData = () => {
        setLoading(true)
        let todayDate = moment().format('YYYY-MM-DD')
        let splitDate = filter.split('-')
        let oldDate = moment().subtract(splitDate[0], splitDate[1]).format('YYYY-MM-DD')
        let date1 = oldDate
        let date2 = todayDate

        let obj = {
            userId, date1, date2
        }

        axios.post(MEMBER_TRANSACTION?.REQ_EXCHANGE_BUY, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setExchangeBuy(data?.data)
                    setExchangeBuyCount(data?.data?.length)
                }
                setLoading(false)
            }).catch((err) => {
                setLoading(false)
            })

        axios.post(MEMBER_TRANSACTION?.REQ_EXCHANGE_SELL, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setExchangeSell(data?.data)
                    setExchangeSellCount(data?.data?.length)
                }
                setLoading(false)
            }).catch((err) => {
                setLoading(false)
            })

        axios.post(MEMBER_TRANSACTION?.REQ_RENT_BUY, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    // console.log('REQ_RENT_BUY', data?.data)
                    setRentBuy(data?.data)
                    setRentBuyCount(data?.data?.length)
                }
                setLoading(false)
            }).catch((err) => {
                console.log('REQ_RENT_BUY err', err)
                setLoading(false)
            })
        axios.post(MEMBER_TRANSACTION?.REQ_RENT_SELL, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    // console.log('REQ_RENT_SELL', data?.data)
                    setRentSell(data?.data)
                    setRentSellCount(data?.data?.length)
                }
                setLoading(false)
            }).catch((err) => {
                // console.log('REQ_RENT_SELL err', err)
                setLoading(false)
            })
        axios.post(MEMBER_TRANSACTION?.REQ_TICKET_BUY, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    // console.log('REQ_TICKET_BUY', data?.data)
                    setTicketBuy(data?.data)
                    setTicketBuyCount(data?.data?.length)
                }
                setLoading(false)
            }).catch((err) => {
                console.log('REQ_RENT_SELL err', err)
                setLoading(false)
            })
        axios.post(MEMBER_TRANSACTION?.REQ_TICKET_SELL, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    // console.log('REQ_TICKET_SELL', data?.data)
                    setTicketSell(data?.data)
                    setTicketSellCount(data?.data?.length)
                }
                setLoading(false)
            }).catch((err) => {
                console.log('REQ_RENT_SELL err', err)
                setLoading(false)
            })
        axios.post(MEMBER_TRANSACTION?.REQ_GROUPBUY_BUY, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setGroupbuyBuy(data?.data)
                    setGroupbuyBuyCount(data?.data?.length)
                }
                setLoading(false)
            }).catch((err) => {
                console.log('REQ_GROUPBUY_BUY err', err)
                setLoading(false)
            })
        axios.post(MEMBER_TRANSACTION?.REQ_GROUPBUY_SELL, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setGroupbuySell(data?.data)
                    setGroupbuySellCount(data?.data?.length)
                }
                setLoading(false)
            }).catch((err) => {
                console.log('REQ_GROUPBUY_SELL err', err)
                setLoading(false)
            })
    }
    let arr = [
        { val: '5-years', title: lanKorCondition ? 'Latest 5 year' : '최근 5년' },
        { val: '1-years', title: lanKorCondition ? 'Latest 1 year' : '최근 1년' },
        { val: '9-months', title: lanKorCondition ? 'Latest 9 month' : '최근 9개월' },
        { val: '6-months', title: lanKorCondition ? 'Latest 6 month' : '최근 6개월' },
        { val: '3-months', title: lanKorCondition ? 'Latest 3 month' : '최근 3개월' },
    ]

    const onChange = (value) => {
        setFlter(value)
        getAllRequestData()
    }

    const onSearch = (value) => {
    }

    const ticketShareOne = [
        {
            title: lanKorCondition ? 'Golfclub name' : '골프장명',
            // dataIndex: ['ticketId' || 'buyerTicketId', 'golfClub', 'clubName'],
            width: 150,
            render: (e) => {
                let ID = e?.ticketId ? e?.ticketId : e?.buyerTicketId
                return ID?.golfClub?.clubName
            }

        },
        {
            title: lanKorCondition ? 'Type' : '회원권종류',
            // dataIndex: ['ticketId' || 'buyerTicketId', 'membershipType'],
            width: 120,
            render: (e) => {
                let ID = e?.ticketId ? e?.ticketId : e?.buyerTicketId
                return <p>{lanKorCondition ? ID?.membershipType : typesKr[types?.indexOf(ID?.membershipType)]?.typesKr}</p>
            }
        },
        {
            title: lanKorCondition ? 'Right price' : '즉시공유/구매가격',
            // dataIndex: ['ticketId' || 'buyerTicketId', 'rightPrice'],
            // dataIndex: ,
            width: 120,
            render: (e) => {
                let ID = e?.ticketId ? e?.ticketId : e?.buyerTicketId
                return <p>{e?.rightPrice ? e?.rightPrice + ' ' + language?.KRW : '---'}</p>
            }

        },
        {
            title: lanKorCondition ? 'Member Fee' : '회원가',
            // dataIndex: ['ticketId' || 'buyerTicketId', 'memberFee'],
            width: 120,
            render: (e) => {
                let ID = e?.ticketId ? e?.ticketId : e?.buyerTicketId
                return <p>{ID?.memberFee ? ID?.memberFee + ' ' + language?.KRW : '---'}</p>
            }
        },
        {
            title: lanKorCondition ? 'Non Member Fee' : '비회원 이용가',
            // dataIndex: ['ticketId' || 'buyerTicketId', 'NONmemberFee'],
            width: 120,
            render: (e) => {
                let ID = e?.ticketId ? e?.ticketId : e?.buyerTicketId
                return <p>{ID?.NONmemberFee ? ID?.NONmemberFee + ' ' + language?.KRW : '---'}</p>
            }

        },
        {
            title: lanKorCondition ? 'Expiration Date' : '마감일',
            // dataIndex: ['ticketId' || 'buyerTicketId', 'expirationDate'],
            // dataIndex: ,
            width: 120,
            render: (e) => {
                let ID = e?.ticketId ? e?.ticketId : e?.buyerTicketId
                return <p>{moment(ID?.expirationDate).format('YYYY-MM-DD')}</p>
            }
        },
        {
            title: lanKorCondition ? 'Status' : '진행상황',
            // dataIndex: ,
            width: 120,
            render: (e) =>
                <div style={{ width: '200px' }}>
                    <span onClick={() => { setData(e) }} className='des_card'>
                        <a style={{ width: '160px' }}>
                            {/* memberTransData?.isAdminApproved ? lanKorCondition ? 'now selling' : '모집중' : memberTransData?.isCompleted ? lanKorCondition ? 'buy completed' : '거래완료' : lanKorCondition ? 'Requested Exchange' : `구매요청` */}
                            {
                                e?.isAdminApproved ?
                                    lanKorCondition ? 'now selling' : '모집중'
                                    : e?.isCompleted ?
                                        lanKorCondition ? 'buy completed' :
                                            '거래완료' : lanKorCondition ? 'Requested Exchange' : `구매요청`
                            }
                            {/* {
                                e?.isCanceled ?
                                    lanKorCondition ? 'Cancel Request' : '공유취소' :
                                    e?.isSaled && e?.type === 'SHARE TICKET' ?
                                        lanKorCondition ? 'Completed sharing request ' : '공유완료' :
                                        e?.isCompleted && e?.requestType === 'Exchange Request' ?
                                            lanKorCondition ? 'Completed Exchange request ' : '교환완료 ' :
                                            !e?.isAdminApproved && !e?.isCompleted ?
                                                lanKorCondition ? 'Request Sell' : '모집요청' :
                                                lanKorCondition ? 'Selling' : '모집중'
                            } */}
                        </a>
                    </span>
                </div>
        },
        {
            title: '',
            width: 100,
            render: (e) => <span onClick={() => { setData(e) }} className='des_card'> <a> {lanKorCondition ? 'View More' : '더보기'} <FaGreaterThan /></a></span>
        }
    ]
    const ticketShareTwo = [
        {
            title: lanKorCondition ? 'Golfclub name' : '골프장명',
            // dataIndex: ['ticketId' || 'userTicketId', 'golfClub', 'clubName'],
            width: 150,
            render: (e) => {
                let ID = e?.ticketId ? e?.ticketId : e?.userTicketId
                return ID?.golfClub?.clubName
            }
        },
        {
            title: lanKorCondition ? 'Type' : '회원권종류',
            // dataIndex: ['ticketId' || 'userTicketId', 'membershipType'],
            width: 120,
            // render: (e) => <p>{lanKorCondition ? e : typesKr[types?.indexOf(e)]?.typesKr}</p>
            render: (e) => {
                let ID = e?.ticketId ? e?.ticketId : e?.userTicketId
                return <p>{lanKorCondition ? ID?.membershipType : typesKr[types?.indexOf(ID?.membershipType)]?.typesKr}</p>
            }
        },
        {
            title: lanKorCondition ? 'Right price' : '즉시공유/구매가격',
            dataIndex: ['rightPrice'],
            width: 120,
            render: (e) => {
                return <p>{e + ' ' + language?.KRW}</p>
            }
        },
        {
            title: lanKorCondition ? 'Member Fee' : '회원가',
            // dataIndex: ['ticketId' || 'userTicketId', 'memberFee'],
            width: 120,
            // render: (e) => <p>{e ? e + ' ' + language?.KRW : '---'}</p>
            render: (e) => {
                let ID = e?.ticketId ? e?.ticketId : e?.userTicketId
                return <p>{ID?.memberFee ? ID?.memberFee + ' ' + language?.KRW : '---'}</p>
            }
        },
        {
            title: lanKorCondition ? 'Non Member Fee' : '비회원 이용가',
            // dataIndex: ['ticketId' || 'userTicketId', 'NONmemberFee'],
            width: 120,
            // render: (e) => <p>{e ? e + ' ' + language?.KRW : '---'}</p>
            render: (e) => {
                let ID = e?.ticketId ? e?.ticketId : e?.userTicketId
                return <p>{ID?.NONmemberFee ? ID?.NONmemberFee + ' ' + language?.KRW : '---'}</p>
            }
        },
        {
            title: lanKorCondition ? 'Expiration Date' : '마감일',
            // dataIndex: ['ticketId' || 'userTicketId', 'expirationDate'],
            // dataIndex: ,
            width: 120,
            // render: (e) => <p>{moment(e).format('YYYY-MM-DD')}</p>
            render: (e) => {
                let ID = e?.ticketId ? e?.ticketId : e?.userTicketId
                return <p>{moment(ID?.expirationDate).format('YYYY-MM-DD')}</p>
            }
        },
        {
            title: lanKorCondition ? 'Status' : '진행상황',
            // dataIndex: ,
            width: 120,
            render: (e) =>
                <div style={{ width: '200px', }}>
                    <span onClick={() => { setData(e) }} className='des_card'>
                        <a style={{ width: '160px' }}>
                            {/* {
                                e?.isCanceled ?
                                    lanKorCondition ? 'Cancel Request' : '공유취소' :
                                    e?.isSaled && e?.type === 'SHARE TICKET' ?
                                        lanKorCondition ? 'Completed sharing request ' : '공유완료' :
                                        e?.isCompleted && e?.requestType === 'Exchange Request' ?
                                            lanKorCondition ? 'Completed Exchange request ' : '교환완료 ' :
                                            !e?.isAdminApproved && !e?.isCompleted ?
                                                lanKorCondition ? 'Request Sell' : '모집요청' :
                                                lanKorCondition ? 'Selling' : '모집중'
                            } */}
                            {e?.isAdminApproved ?
                                lanKorCondition ? 'now selling' : '모집중' :
                                e?.isCompleted ?
                                    lanKorCondition ? 'buy completed' : `공유완료` : lanKorCondition ? 'Requested Exchange' : '모집요청'}

                        </a>
                    </span>
                </div>
        },
        {
            title: '',
            width: 100,
            render: (e) => <span onClick={() => { setData(e) }} className='des_card'> <a> {lanKorCondition ? 'View More' : '더보기'} <FaGreaterThan /></a></span>
        }
    ]
    const ticketShareThree = [
        {
            title: lanKorCondition ? 'Golfclub name' : '골프장명',
            dataIndex: ['userTicketId', 'golfClub', 'clubName'],
            width: 150,
        },
        {
            title: lanKorCondition ? 'Type' : '회원권종류',
            // dataIndex: ,
            dataIndex: ['userTicketId', 'membershipType'],
            width: 120,
            render: (e) => <p>{lanKorCondition ? e : typesKr[types?.indexOf(e)]?.typesKr}</p>
        },
        {
            title: lanKorCondition ? 'Right price' : '즉시공유/구매가격',
            dataIndex: ['ticketShareId', 'rightPrice'],
            // dataIndex: ,
            width: 120,
            render: (e) => <p>{e ? e + ' ' + language?.KRW : '---'}</p>

        },
        {
            title: lanKorCondition ? 'Member Fee' : '회원가',
            dataIndex: ['userTicketId', 'memberFee'],
            width: 120,
            render: (e) => <p>{e ? e + ' ' + language?.KRW : '---'}</p>

        },
        {
            title: lanKorCondition ? 'Non Member Fee' : '비회원 이용가',
            dataIndex: ['userTicketId', 'NONmemberFee'],
            width: 120,
            render: (e) => <p>{e ? e + ' ' + language?.KRW : '---'}</p>

        },
        {
            title: lanKorCondition ? 'Expiration Date' : '마감일',
            dataIndex: ['userTicketId', 'expirationDate'],
            // dataIndex: ,
            width: 120,
            render: (e) => <p>{moment(e).format('YYYY-MM-DD')}</p>

        },
        {
            title: lanKorCondition ? 'Status' : '진행상황',
            // dataIndex: ,
            width: 120,
            render: (e) =>
                <div style={{ width: '200px', }}>
                    <span onClick={() => { setData(e) }} className='des_card'>
                        {/* {console.log(e,"==============33")} */}
                        <a style={{ width: '160px' }}>
                            {/* {'33333333333333'
                        // e?.isAdminApproved ? language?.rentongoing : e?.isCompleted ? language?.rentcompleted : language?.Requestedrent
                    } */}
                            {
                                e?.isCanceled ?
                                    lanKorCondition ? 'Cancel Request' : '공유취소' :
                                    e?.isSaled && e?.type === 'SHARE TICKET' ?
                                        lanKorCondition ? 'Completed sharing request ' : '공유완료' :
                                        e?.isCompleted && e?.requestType === 'Request Rent' ?
                                            lanKorCondition ? 'Completed rent request ' : '대여요청 완료' :
                                            !e?.isAdminApproved && !e?.isCompleted ?
                                                lanKorCondition ? 'Request Sell' : '모집요청' :
                                                lanKorCondition ? 'Selling' : '모집중'
                            }
                        </a>
                    </span>
                </div>
        },
        {
            title: '',
            width: 100,
            render: (e) => <span onClick={() => { setData(e) }} className='des_card'> <a> {lanKorCondition ? 'View More' : '더보기'} <FaGreaterThan /></a></span>
        }
    ]
    const ticketShareFour = [
        {
            title: lanKorCondition ? 'Golfclub name' : '골프장명',
            dataIndex: ['userTicketId', 'golfClub', 'clubName'],
            width: 150,
        },
        {
            title: lanKorCondition ? 'Type' : '회원권종류',
            // dataIndex: ,
            dataIndex: ['userTicketId', 'membershipType'],
            width: 120,
            render: (e) => <p>{lanKorCondition ? e : typesKr[types?.indexOf(e)]?.typesKr}</p>
        },
        {
            title: lanKorCondition ? 'Right price' : '즉시공유/구매가격',
            dataIndex: ['ticketShareId', 'rightPrice'],
            // dataIndex: ,
            width: 120,
            render: (e) => <p>{e ? e + ' ' + language?.KRW : '---'}</p>

        },
        {
            title: lanKorCondition ? 'Member Fee' : '회원가',
            dataIndex: ['userTicketId', 'memberFee'],
            width: 120,
            render: (e) => <p>{e ? e + ' ' + language?.KRW : '---'}</p>

        },
        {
            title: lanKorCondition ? 'Non Member Fee' : '비회원 이용가',
            dataIndex: ['userTicketId', 'NONmemberFee'],
            width: 120,
            render: (e) => <p>{e ? e + ' ' + language?.KRW : '---'}</p>

        },
        {
            title: lanKorCondition ? 'Expiration Date' : '마감일',
            dataIndex: ['userTicketId', 'expirationDate'],
            // dataIndex: ,
            width: 120,
            render: (e) => <p>{moment(e).format('YYYY-MM-DD')}</p>

        },
        {
            title: lanKorCondition ? 'Status' : '진행상황',
            // dataIndex: ,
            width: 120,
            render: (e) =>
                <div style={{ width: '200px', }}>
                    <span onClick={() => { setData(e) }} className='des_card'>
                        {/* {console.log(e,"==============44")} */}
                        <a style={{ width: '160px' }}>
                            {/* 44444444444444444 */}
                            {/* {
                        e?.isAdminApproved ? language?.rentongoing : e?.isCompleted ? language?.rentcompleted : lanKorCondition ? 'Waiting for admin to approve my post' : '회원권 게시 승인 대기중'
                    } */}
                            {
                                e?.isCanceled ?
                                    lanKorCondition ? 'Cancel Request' : '공유취소' :
                                    e?.isSaled && e?.type === 'SHARE TICKET' ?
                                        lanKorCondition ? 'Completed sharing request ' : '공유완료' :
                                        e?.isCompleted && e?.requestType === 'Request Rent' ?
                                            lanKorCondition ? 'Completed rent request ' : '대여요청 완료' :
                                            !e?.isAdminApproved && !e?.isCompleted ?
                                                lanKorCondition ? 'Request Sell' : '모집요청' :
                                                lanKorCondition ? 'Selling' : '모집중'
                            }
                        </a>
                    </span>
                </div>
        },
        {
            title: '',
            width: 100,
            render: (e) => <span onClick={() => { setData(e) }} className='des_card'> <a> {lanKorCondition ? 'View More' : '더보기'} <FaGreaterThan /></a></span>
        }
    ]

    const ticketSellOne = [
        {
            title: lanKorCondition ? 'Golfclub name' : '골프장명',
            dataIndex: ['userTicketId', 'golfClub', 'clubName'],
            width: 150,
        },
        {
            title: lanKorCondition ? 'Type' : '회원권종류',
            // dataIndex: ,
            dataIndex: ['userTicketId', 'membershipType'],
            width: 120,
            render: (e) => <p>{lanKorCondition ? e : typesKr[types?.indexOf(e)]?.typesKr}</p>
        },
        {
            title: lanKorCondition ? 'Right price' : '즉시공유/구매가격',
            dataIndex: ['userTicketId', 'rightPrice'],
            // dataIndex: ,
            width: 120,
            render: (e) => <p>{e ? e + ' ' + language?.KRW : '---'}</p>

        },
        {
            title: lanKorCondition ? 'Member Fee' : '회원가',
            dataIndex: ['userTicketId', 'memberFee'],
            width: 120,
            render: (e) => <p>{e ? e + ' ' + language?.KRW : '---'}</p>

        },
        {
            title: lanKorCondition ? 'Status' : '진행상황',
            // dataIndex: ,
            width: 120,
            render: (e) =>
                <div style={{ width: '200px', }}>
                    <span onClick={() => { setData(e) }} className='des_card'>
                        <a style={{ width: '160px' }}>
                            {
                                e?.isCanceled && e?.type === 'SALE TICKET' ?
                                    lanKorCondition ? 'Canceled Request' : '매매취소' :
                                    e?.isCanceled && e?.requestType === 'Request Buy' ?
                                        lanKorCondition ? 'Canceled buy request' : '거래 취소' :
                                        e?.isSaled && e?.type === 'SALE TICKET' ?
                                            lanKorCondition ? 'Completed sell request' : '매매완료' :
                                            e?.isCompleted && e?.requestType === 'Request Buy' ?
                                                lanKorCondition ? 'Completed Request buy ' : '교환완료 ' :
                                                !e?.isAdminApproved && !e?.isCompleted && e?.type === 'SALE TICKET' ?
                                                    lanKorCondition ? 'Request Sell' : '모집요청' :
                                                    !e?.isAdminApproved && !e?.isCompleted && e?.requestType === 'Request Buy' ?
                                                        lanKorCondition ? 'Request buy' : '구매요청' :
                                                        e?.type === 'SALE TICKET' ?
                                                            lanKorCondition ? 'Selling ticket sell' : '모집중' :
                                                            lanKorCondition ? 'Selling request buy' : '문의중'
                            }
                            {/* {
                        e?.isAdminApproved ? language?.buyongoing : e?.isCompleted ? language?.buycompleted : lanKorCondition ? 'Requested Exchange' : '교환신청'
                    } */}
                        </a>
                    </span>
                </div>
        },
        {
            title: '',
            width: 100,
            render: (e) => <span onClick={() => { setData(e) }} className='des_card'> <a> {lanKorCondition ? 'View More' : '더보기'} <FaGreaterThan /></a></span>
        }
    ]
    const ticketSellTwo = [
        {
            title: lanKorCondition ? 'Golfclub name' : '골프장명',
            dataIndex: ['ticketId', 'golfClub', 'clubName'],
            width: 150,
        },
        {
            title: lanKorCondition ? 'Type' : '회원권종류',
            // dataIndex: ,
            dataIndex: ['ticketId', 'membershipType'],
            width: 120,
            render: (e) => <p>{lanKorCondition ? e : typesKr[types?.indexOf(e)]?.typesKr}</p>
        },
        {
            title: lanKorCondition ? 'Right price' : '즉시공유/구매가격',
            dataIndex: ['ticketId', 'sellingPrice'],
            // dataIndex: ,
            width: 120,
            render: (e) => <p>{e ? e + ' ' + language?.KRW : '---'}</p>

        },
        {
            title: lanKorCondition ? 'Member Fee' : '회원가',
            dataIndex: ['ticketId', 'memberFee'],
            width: 120,
            render: (e) => <p>{e ? e + ' ' + language?.KRW : '---'}</p>

        },
        {
            title: lanKorCondition ? 'Status' : '진행상황',
            // dataIndex: ,
            width: 120,
            render: (e) =>
                <div style={{ width: '200px', }}>
                    <a style={{ width: '160px' }}>
                        {
                            e?.isCanceled && e?.type === 'SALE TICKET' ?
                                lanKorCondition ? 'Canceled Request' : '매매취소' :
                                e?.isCanceled && e?.requestType === 'Request Buy' ?
                                    lanKorCondition ? 'Canceled buy request' : '거래 취소' :
                                    e?.isSaled && e?.type === 'SALE TICKET' ?
                                        lanKorCondition ? 'Completed sell request' : '매매완료' :
                                        e?.isCompleted && e?.requestType === 'Request Buy' ?
                                            lanKorCondition ? 'Completed Request buy ' : '교환완료 ' :
                                            !e?.isAdminApproved && !e?.isCompleted && e?.type === 'SALE TICKET' ?
                                                lanKorCondition ? 'Request Sell' : '모집요청' :
                                                !e?.isAdminApproved && !e?.isCompleted && e?.requestType === 'Request Buy' ?
                                                    lanKorCondition ? 'Request buy' : '구매요청' :
                                                    e?.type === 'SALE TICKET' ?
                                                        lanKorCondition ? 'Selling ticket sell' : '모집중' :
                                                        lanKorCondition ? 'Selling request buy' : '문의중'
                        }
                        {/* {
                        e?.isAdminApproved ? language?.sellongoing : e?.isSaled ? language?.sellcompleted : lanKorCondition ? 'Waiting for admin to approve my post' : '회원권 게시 승인 대기중'
                    } */}
                    </a>
                </div>
        },
        {
            title: '',
            width: 100,
            render: (e) => <span onClick={() => { setData(e) }} className='des_card'> <a> {lanKorCondition ? 'View More' : '더보기'} <FaGreaterThan /></a></span>
        }
    ]

    const groupBuyOne = [
        {
            title: lanKorCondition ? 'Golfclub name' : '골프장명',
            dataIndex: ['groupBuyId', 'golfClub', 'clubName'],
            width: 150,
        },
        {
            title: lanKorCondition ? 'Type' : '회원권종류',
            // dataIndex: ,
            dataIndex: ['groupBuyId', 'membershipType'],
            width: 120,
            render: (e) => <p>{lanKorCondition ? e : typesKr[types?.indexOf(e)]?.typesKr}</p>
        },
        {
            title: lanKorCondition ? 'Seats' : '희망 구좌',
            dataIndex: 'totalSeats',
            width: 180,
        },
        {
            title: lanKorCondition ? 'Fee' : '금액',
            dataIndex: 'totalSeats',
            width: 180,
            render: (e) => <p>{e ? e + ' ' + language?.KRW : '---'}</p>

        },
        {
            title: lanKorCondition ? 'Status' : '진행상황',
            // dataIndex: ,
            width: 200,
            render: (e) =>
                <div style={{ width: '200px', }}>
                    <a style={{ width: '160px' }}>
                        {/* {e?.isClosed ?
                            lanKorCondition ? 'group buy not avilible' : '동구매 불가' :
                            e?.isCanceled ?
                                lanKorCondition ? 'closed Request' : '공동구매 마감' :
                                e?.isCompleted ?
                                    lanKorCondition ? 'Completed group buy ' : '거래완료 ' :
                                    !e?.isAdminApproved && !e?.isCompleted ?
                                        lanKorCondition ? 'Requested group  ticket' : '진행 요청 중 ' :
                                        lanKorCondition ? 'group buy avilible' : '공동구매 가능'
                        } */}
                        {e?.isCanceled ?
                            lanKorCondition ? 'Canceled Request' : '매매취소'
                            :
                            !e?.isCompleted ?
                                lanKorCondition ? 'Waiting for participate' : '참여 요청중'
                                :
                                e?.isCompleted && e?.paymentId ?
                                    lanKorCondition ? 'Paid' : '지불 완료'
                                    :
                                    e?.isCompleted ?
                                        lanKorCondition ? 'Participation confirmed' : '참여 승인' : null
                        }
                        {/* {
                        e?.isCompleted ? lanKorCondition ? 'Approved' : '승인완료' ? e?.isCanceled : language?.Failed : language?.pending
                    } */}
                    </a>
                </div>
        },
        {
            title: '',
            width: 100,
            render: (e) => <span onClick={() => { setData(e) }} className='des_card'> <a> {lanKorCondition ? 'View More' : '더보기'} <FaGreaterThan /></a></span>
        }
    ]
    const groupBuyTwo = [
        {
            title: lanKorCondition ? 'Golfclub name' : '골프장명',
            dataIndex: ['golfClub', 'clubName'],
            width: 150,
        },
        {
            title: lanKorCondition ? 'Type' : '회원권종류',
            // dataIndex: ,
            dataIndex: ['membershipType'],
            width: 120,
            render: (e) => <p>{lanKorCondition ? e : typesKr[types?.indexOf(e)]?.typesKr}</p>
        },
        {
            title: lanKorCondition ? 'Seats' : '희망 구좌',
            dataIndex: 'totalSeats',
            width: 180,
        },
        {
            title: lanKorCondition ? 'Fee' : '금액',
            dataIndex: 'totalSeats',
            // width: 100,
            render: (e) => <p>{e ? e + ' ' + language?.KRW : '---'}</p>

        },
        {
            title: lanKorCondition ? 'Status' : '진행상황',
            // dataIndex: ,
            width: 160,
            render: (e) =>
                <div style={{ width: '200px', }}>
                    <a>
                        {e?.isClosed ?
                            lanKorCondition ? 'group buy not avilible' : '동구매 불가' :
                            e?.isCanceled ?
                                lanKorCondition ? 'closed Request' : '공동구매 마감' :
                                e?.isCompleted ?
                                    lanKorCondition ? 'Completed group buy ' : '거래완료 ' :
                                    !e?.isAdminApproved && !e?.isCompleted ?
                                        lanKorCondition ? 'Requested group  ticket' : '진행 요청 중 ' :
                                        lanKorCondition ? 'group buy avilible' : '공동구매 가능'
                        }
                        {/* {
                        e?.isCompleted ? lanKorCondition ? 'Approved' : '승인완료' ? e?.isCanceled : language?.Failed : language?.pending
                    } */}
                    </a>
                </div>
        },
        {
            title: '',
            width: 100,
            render: (e) => <span onClick={() => { setData(e) }} className='des_card'> <a> {lanKorCondition ? 'View More' : '더보기'} <FaGreaterThan /></a></span>
        }
    ]

    return (
        <div className='cont_mem_trans'>
            <div className='tab_cont'>
                <div /* className='row_mem_trans' */ style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className='column_mem_trans' style={{ width: '33%', marginLeft: '1px' }}>
                        <a href="#ticketShare">
                            <div className='card_trans'>
                                <AiOutlineShareAlt className='icon_trans' />
                                <p className='card_head_trans'>
                                    {lanKorCondition ? 'Ticket  Sharing' : ' 회원권 공유'}
                                </p>
                                <p className='des_card'>{lanKorCondition ? 'Exchange Sell' : '교환 판매'}  ({exchangeSellCount})</p>
                                <p className='des_card'>{lanKorCondition ? 'Exchange Buy' : ' 교환 구매'}  ({exchangeBuyCount})</p>
                                <p className='des_card'>{lanKorCondition ? 'Rent Sell' : ' 대여 판매'} ({rentSellCount})</p>
                                <p className='des_card'>{lanKorCondition ? 'Rent Buy' : ' 대여 구매'}  ({rentBuyCount})</p>
                            </div>
                        </a>
                    </div>
                    <div className='column_mem_trans' style={{ width: '33%', marginLeft: '1px' }}>
                        <a href="#ticketSale">
                            <div className='card_trans'>
                                <BiShoppingBag className='icon_trans' />
                                <p className='card_head_trans'>
                                    {lanKorCondition ? 'Ticket Sale' : '회원권 매매'}
                                </p>
                                <p className='des_card'>{lanKorCondition ? 'Sell' : '판매'} ({ticketSellCount})</p>
                                <p className='des_card'>{lanKorCondition ? 'Buy' : '구매'} ({ticketBuyCount})</p>
                            </div>
                        </a>
                    </div>
                    <div className='column_mem_trans' style={{ width: '33%', marginLeft: '1px' }}>
                        <a href="#groupBuy">
                            <div className='card_trans'>
                                <HiOutlineUserGroup className='icon_trans' />
                                <p className='card_head_trans'>
                                    {lanKorCondition ? 'Ticket Group Buy' : '회원권 공동구매'}
                                </p>
                                <p className='des_card'>{lanKorCondition ? 'Gourp Sell' : '공동구매 판매'}({groupbuySellCount})</p>
                                <p className='des_card'>{lanKorCondition ? 'Group Buy' : '공동구매 '}({groupbuyBuyCount})</p>
                                <br />
                            </div>
                        </a>

                    </div>
                </div>
                {data?._id ?
                    <>
                        <TicketsInfo lanKorCondition={lanKorCondition} data={data} {...props} />
                        <div className='TransactionAmountInputDiv'>
                            <div className='saveTransactionButtonDiv '>
                                <Button onClick={() => { setData({}) }} className='saveTransactionBtnTic'>
                                    {language?.goBack}
                                </Button>
                            </div>
                        </div>
                    </> :
                    <div>
                        <div>
                            <div className='memberFilter' >
                                <Select
                                    style={{ width: '180px' }}
                                    showSearch
                                    placeholder={lanKorCondition ? 'Latest' : '최근'}
                                    optionFilterProp='children'
                                    onChange={onChange}
                                    onSearch={onSearch}
                                    value={filter}
                                >{
                                        arr?.map((v, i) => {
                                            return (
                                                <Option value={v?.val}>{v?.title}</Option>

                                            )
                                        })
                                    }
                                </Select>
                                {/* <Select
                                    style={{ width: '180px', marginLeft: '20px' }}
                                    showSearch
                                    placeholder={lanKorCondition ? 'All / Buy / Sell' : '전체 / 구매 / 판매'}
                                    optionFilterProp='children'
                                >
                                    <Option value={'all'}>{lanKorCondition ? 'all' : '전체'}</Option>
                                    <Option value={'buy'}>{lanKorCondition ? 'buy' : '구매'}</Option>
                                    <Option value={'sell'}>{lanKorCondition ? 'sell' : '판매'}</Option>
                                </Select> */}
                            </div>
                            <h2 id='ticketShare'>
                                {language?.TicketShare}
                            </h2>
                            <div>
                                <div>
                                    <Button className='upload_sharing_btn'>
                                        {lanKorCondition ? 'Exchange -  buy' : '공유 교환요청건'}
                                    </Button>
                                </div>
                                <div className='main_table_cont' style={{ marginTop: 20 }}>
                                    <Table
                                        scroll={{
                                            x: 1300,
                                        }}
                                        className='table-striped-rows table_home'
                                        columns={ticketShareOne}
                                        dataSource={exchangeBuy}
                                        loading={loading}
                                        pagination={false}
                                        bordered={false}
                                    />
                                    <hr className='_hr' />
                                </div>
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <div>
                                    <Button className='upload_sharing_btn'>
                                        {lanKorCondition ? 'Exchange post - sell' : '공유 교환게시건'}
                                    </Button>
                                </div>
                                <div className='main_table_cont' style={{ marginTop: 20 }}>
                                    <Table
                                        scroll={{
                                            x: 1300,
                                        }}
                                        className='table-striped-rows table_home'
                                        columns={ticketShareTwo}
                                        // dataSource={}
                                        dataSource={exchangeSell}
                                        loading={loading}
                                        pagination={false}
                                        bordered={false}
                                    />
                                    <hr className='_hr' />
                                </div>
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <div>
                                    <Button className='upload_sharing_btn'>
                                        {lanKorCondition ? 'Rent - buy' : '공유 대여요청건'}
                                    </Button>
                                </div>
                                <div className='main_table_cont' style={{ marginTop: 20 }}>
                                    <Table
                                        scroll={{
                                            x: 1300,
                                        }}
                                        className='table-striped-rows table_home'
                                        columns={ticketShareThree}
                                        loading={loading}
                                        dataSource={rentBuy}
                                        pagination={false}
                                        bordered={false}
                                    />
                                    <hr className='_hr' />
                                </div>
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <div>
                                    <Button className='upload_sharing_btn'>
                                        {lanKorCondition ? 'Rent post - sell' : '공유 대여게시건'}
                                    </Button>
                                </div>
                                <div className='main_table_cont' style={{ marginTop: 20 }}>
                                    <Table
                                        scroll={{
                                            x: 1300,
                                        }}
                                        className='table-striped-rows table_home'
                                        columns={ticketShareFour}
                                        loading={loading}
                                        dataSource={rentSell}
                                        pagination={false}
                                        bordered={false}
                                    />
                                    <hr className='_hr' />
                                </div>
                            </div>
                        </div>

                        <div style={{ marginTop: 30, borderTop: '2px solid black' }}>
                            <h2 id='ticketSale' style={{ marginTop: 30 }}>
                                {language?.TicketSale}
                            </h2>
                            <div>
                                <div>
                                    <Button className='upload_sharing_btn'>
                                        {lanKorCondition ? 'Ticket sale - buy' : '회원권 구매요청건'}
                                    </Button>
                                </div>
                                <div className='main_table_cont' style={{ marginTop: 20 }}>
                                    <Table
                                        className='table-striped-rows table_home'
                                        columns={ticketSellOne}
                                        dataSource={ticketBuy}
                                        loading={loading}
                                        pagination={false}
                                        bordered={false}
                                        scroll={{ x: 1000 }}
                                    />
                                    <hr className='_hr' />
                                </div>
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <div>
                                    <Button className='upload_sharing_btn'>
                                        {lanKorCondition ? 'Ticket sale post - sell' : '회원권 판매요청건'}
                                    </Button>
                                </div>
                                <div className='main_table_cont' style={{ marginTop: 20 }}>
                                    <Table
                                        className='table-striped-rows table_home'
                                        columns={ticketSellTwo}
                                        dataSource={ticketSell}
                                        loading={loading}
                                        pagination={false}
                                        bordered={false}
                                        scroll={{ x: 1000 }}

                                    />
                                    <hr className='_hr' />
                                </div>
                            </div>
                        </div>

                        <div style={{ marginTop: 30, borderTop: '2px solid black' }}>
                            <h2 id='groupBuy' style={{ marginTop: 30 }}>
                                {lanKorCondition ? 'Group buy' : '공동구매'}

                            </h2>
                            <div>
                                <div>
                                    <Button className='upload_sharing_btn'>
                                        {lanKorCondition ? 'Group buy - buy' : '공동구매 구매요청건'}
                                    </Button>
                                </div>
                                <div className='main_table_cont' style={{ marginTop: 20 }}>
                                    <Table
                                        className='table-striped-rows table_home'
                                        columns={groupBuyOne}
                                        dataSource={groupbuyBuy}
                                        loading={loading}
                                        pagination={false}
                                        bordered={false}
                                        scroll={{ x: 1000 }}

                                    />
                                    <hr className='_hr' />
                                </div>
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <div>
                                    <Button className='upload_sharing_btn'>
                                        {lanKorCondition ? 'Group buy  post -sell' : '공동구매 판매요청건'}
                                    </Button>
                                </div>
                                <div className='main_table_cont' style={{ marginTop: 20 }}>
                                    <Table
                                        className='table-striped-rows table_home'
                                        columns={groupBuyTwo}
                                        dataSource={groupbuySell}
                                        loading={loading}
                                        pagination={false}
                                        bordered={false}
                                        scroll={{ x: 800 }}
                                    />
                                    <hr className='_hr' />
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </div >
    )
}


export default MembershipTransactinTab