import { Button, Modal, Select, Tag } from 'antd'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { loginUser } from '../../Redux/actions/authActions';
import { ADMIN, AUTH, GET } from '../../utils/apis';
import { regions, regionsKr } from '../../utils/constants';
import { errorMessage, successMessage } from '../../utils/helpers';
const { CheckableTag } = Tag;

const FillOutPopupUser = (props) => {
    const { language, user, showFillOut, golfClub, setShowFillOut, selectedTags, setSelectedTags, selectedClubs, setSelectedClubs, lanKorCondition } = props
    const [loading, setLoading] = useState(false)
    const [selectedClubsId, setSelectedClubsId] = useState(user?._id ? user?.preferredGolfCourse : [])

    const dispatch = useDispatch()

    const handleChange = (tag, checked) => {
        const nextSelectedTags = checked
            ? [...selectedTags, tag]
            : selectedTags.filter((t) => t !== tag);
        // console.log('You are interested in: ', nextSelectedTags);
        setSelectedTags(nextSelectedTags);
    };

    const editPreferredRegion = () => {
        setLoading(true)
        let obj = {
            _id: user?._id,
            preferredRegion: selectedTags,
            preferredGolfCourse: selectedClubsId
        }
        console.log('obj', obj)

        axios.post(AUTH?.EDIT_PREFERRED_REGION, obj)
            .then((res) => {
                const { data } = res

                console.log('data**', data?.data)
                if (data.success) {
                    getUser()
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                else {
                    // setLoading(false)
                    setSelectedTags([])
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setSelectedTags([])
                setLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageKr)

            })

    }

    const getUser = () => {
        axios.get(`${GET.GET_USER}/${user?._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    updateUser(data?.user)
                    setShowFillOut(false)
                }
            })
            .catch((e) => {
            })
    }

    const updateUser = (data) => {
        dispatch(loginUser(data))
    }

    const handleCancel = () => {
        setShowFillOut(false)
    }

    const handleSelectChange = (value) => {

        setSelectedClubs(value)

        setSelectedClubsId(_.map(golfClub?.filter((v, i) => value?.includes(v?.clubName)), '_id'))
    }

    const onSave = () => {
        if (selectedClubs?.length > 5) {
            return errorMessage('You can select only 5 clubs')
        } else {
            editPreferredRegion()
        }

    }


    useEffect(() => {
        setSelectedClubs(_.map(golfClub?.filter((v, i) => selectedClubsId?.includes(v?._id)), 'clubName'))
    }, [golfClub])

    return (
        <Modal
            open={showFillOut}
            onCancel={handleCancel}
            footer={null}
            className='fillout'
        >
            <h3 style={{ textAlign: 'center' }}>{lanKorCondition ? 'Please fillout the info for optimization' : '최적화를 위해 아래 필터 정보를 입력해주세요.'}</h3>
            <br />
            <br />
            <h5>{lanKorCondition ? 'Preference Location' : '선호지역'}</h5>
            {regions?.slice(1)?.map((tag, i) => {
                return (
                    <CheckableTag
                        style={{ marginTop: '10px' }}
                        key={tag}
                        checked={selectedTags?.indexOf(tag) > -1}
                        onChange={(checked) => handleChange(tag, checked)}
                    >
                        {lanKorCondition ? tag : regionsKr[regions?.indexOf(tag)]?.TitleKr}
                    </CheckableTag>
                )
            })}

            <br />
            <br />
            <h5>{lanKorCondition ? 'Golf Club Preference' : '선호하는 골프장'}</h5>
            <Select
                mode='multiple'
                allowClear
                style={{
                    width: '100%',
                }}
                placeholder={lanKorCondition ? 'Golf club name' : '골프장 검색'}
                onChange={handleSelectChange}
                value={selectedClubs}

                options={golfClub?.map((v, i) => {
                    return {
                        value: v?.clubName,
                        label: v?.clubName,
                    }
                })}
            />

            <br />
            <br />
            <Button loading={loading} className='req_exc_btn' onClick={onSave} style={{ color: 'white' }}>{lanKorCondition ? 'SAVE ' : '저장'}</Button>



        </Modal >
    )
}

export default FillOutPopupUser