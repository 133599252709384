import { PlusOutlined } from '@ant-design/icons'
import { Button, Descriptions, Input, Space, Tabs, Tag, Tooltip, Table, Typography, Popover, Badge } from 'antd'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { GET } from '../../utils/apis'
import Header from '../Home/Header'
import moment from 'moment'
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io"

const { Search } = Input
const { TabPane } = Tabs
const { Title } = Typography
const { Column, ColumnGroup } = Table

const FAQ = (props) => {
    const { language } = props
    const [inputVisible, setInputVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [dataSource, setDataSource] = useState([])
    const inputRef = useRef(null)
    const editInputRef = useRef(null)
    const [searchValue, setSearchValue] = useState('')

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus()
        }
    }, [inputVisible])
    useEffect(() => {
        getAllFaqs()
    }, [])

    const getAllFaqs = () => {
        setLoading(true)
        axios.get(GET?.GET_ALL_FAQS)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setDataSource(data?.data)
                }
                setLoading(false)
            }).catch((e) => {
                setLoading(false)
                console.log('e', e)
            })
    }

    const columns = [
        {
            Title: language?.Title,
            dataIndex: 'question',
            key:'question',
            width: 400,
        },
        {
            Title: language?.Date,
            dataIndex: 'created',
            width: 100,
            render: (e) => moment(e?.created).format('YYYY.MM.DD')
        },
    ]


    let filterDataSource = dataSource?.length && dataSource.filter((val, i) => {
        if (searchValue === '') {
            return val
        } else if (val?.question?.toLowerCase()?.includes(searchValue?.toLowerCase()?.trim())) {
            return val
        }
    }).map((v) => {
        return {
            id: v?._id,
            key: v?.question,
            question: v?.question,
            created: moment(v?.created).format('YYYY.MM.DD'),
            answer: v?.answer
        }
    })

    return (
        <div className='home-main'>
            <Header {...props} />
            <center>
                <div className='div_text'>
                    <Title level={4}>{language?.FrequentlyAskedQuestions}</Title>
                </div>
            </center>
            <div className='card-parent2'>
                <div className='card3' style={{ marginTop: 0 }}>
                    <Search
                        placeholder={language?.ENterSearchTerm}
                        className='noti_search'
                        onChange={(e) => setSearchValue(e?.target?.value)}
                    />
                    <br />
                    <br />
                    <Table
                        dataSource={filterDataSource}
                        loading={loading}
                        expandable={{
                            expandedRowRender: (record) => (
                              <p
                                style={{
                                  margin: 0,
                                //   float:'right'
                                }}
                              >
                                {record?.answer}
                              </p>
                            ),
                            rowExpandable: (record) => record.answer !== 'Not Expandable',
                          }}
                        showHeader={false}
                        pagination={false}
                        rowClassName={"tableTextColor"}
                        columns={columns}
                        scroll={{ x: 800 }}
                        className='table-striped-rows table_home scroll_class'
                    />
                </div>
            </div >
        </div >
    )
}

export default FAQ