import { Select, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { GiBackwardTime } from 'react-icons/gi'
import { GET } from '../../utils/apis'
import { types, typesKr, typesOnlyKr } from '../../utils/constants'
const { Option } = Select

const RealTimeMarket = (props) => {
    const { language, lanKorCondition, ticketnameFilter, selectedMemberships, selectedClubs, setclickedViewMore, selectedTags } = props
    const [realTimeMarket, setRealTimeMarket] = useState([])
    const [filteredData, setfilteredData] = useState([])
    const [loading, setLoading] = useState(false)
    const [limit, setLimit] = useState(5)
    const [val, setVal] = useState({})
    useEffect(() => {
        getRealTimeMarket()
    }, [limit])
    useEffect(() => {
        setfilteredData(realTimeMarket.filter(checkMembershipType).filter(checkClubsData).filter(checkFilterData).filter(checkRegion))
    }, [selectedMemberships, selectedClubs, realTimeMarket, ticketnameFilter, selectedTags])

    const getRealTimeMarket = (query, lmt) => {
        setLoading(true)
        let ReqQuery = query || 'LowToHigh'
        axios.get(`${GET.GET_REAL_TIME_MARKET}?sortOrder=${ReqQuery}&limit=${limit}`)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data?.success) {
                    setRealTimeMarket(data?.data)
                    setfilteredData(data?.data)
                }
                else {
                    console.log('runing')
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log('err', e)
            })
    }

    const onChange = async (value) => {
        let filterOpt = options?.filter((e) => e?.shortOpt == value)
        if (filterOpt?.length > 0) {
            setVal(filterOpt[0] || options[0])
        }
        await getRealTimeMarket(value)
    }
    const checkRegion = (v) => {
        if (selectedTags?.length) {
            return ((selectedTags?.includes(v?.club?.region)))
        }
        else {
            return v
        }
    }
    const checkFilterData = (v) => {
        if (ticketnameFilter) {
            return ((v?.club?.clubName?.toLowerCase()?.includes(ticketnameFilter.trim().toLowerCase())) || (v?.ticketType?.toLowerCase()?.includes(ticketnameFilter.trim().toLowerCase())))
        }
        else {
            return v
        }
    }
    const checkClubsData = (v) => {

        if (selectedClubs?.length) {
            return selectedClubs.includes(v?.club?.clubName)
        }
        else {
            return v
        }
    }
    const checkMembershipType = (v) => {
        if (selectedMemberships?.length) {
            return selectedMemberships.includes(v?.ticketType)
        }
        else {
            return v
        }
    }

    const columns = [
        {
            title: language?.GolfCourse,
            dataIndex: ['club','clubName'],
            width: 200,
        },
        {
            title: language?.Type,
            dataIndex: 'ticketType',
            width: 200,
            render: (e) => `${lanKorCondition ? e : typesKr[typesOnlyKr?.indexOf(e)]?.typesKr ? typesKr[typesOnlyKr?.indexOf(e)]?.typesKr : typesKr[types?.indexOf(e)]?.typesKr}`
        },
        {
            title: language?.CurrentPrice,
            dataIndex: 'currentPrice',
            render: (e) => `${e} ${language?.KRW}`,
            width: 180,

        },
        {
            title: language?.HighestPrice,
            dataIndex: 'highestPrice',
            render: (e) => `${e} ${language?.KRW}`,
            width: 150,

        },
        {
            title: language?.LowestPrice,
            dataIndex: 'lowestPrice',
            render: (e) => `${e} ${language?.KRW}`,
            width: 150,

        },
        {
            title: language?.Fluctuations,
            dataIndex: 'percentage',
            render: (e) => `${e}%`,
            width: 150,

        },
        {
            title: language?.NoTransactions,
            dataIndex: 'transaction',
            width: 150,

        },
    ]

    const options = [
        {
            eng: '-From low to high (based on current price)',
            shortOpt: 'LowToHigh',
            kr: ' 저가순 '
        },
        {
            eng: '-From high to low (based on current price)',
            shortOpt: 'HighToLow',
            kr: '고가순'
        },
        {
            eng: 'Latest (based on current price)',
            shortOpt: 'ByCurrentValue',
            kr: '최신순'
        },
        {
            eng: 'Order of number of Transactions',
            shortOpt: 'ByTransaction',
            kr: '거래건이 많은 순'
        }]
    return (
        <div className='tab_table_cont'>
            <div className='tab_cont_head'>
                <div className='left_panel'>
                    <h2 className='col_primary'><GiBackwardTime style={{ verticalAlign: 'middle' }} />{language?.MemberTimeMarket}</h2>
                    <p style={{ color: '#1890ff', margin: '7px 0px 0px 9px', textDecoration: 'underLine', cursor: 'pointer' }} onClick={() => {
                        setclickedViewMore(true)
                        let lmt = limit + 5
                        // getRealTimeMarket(lmt),
                        setLimit(lmt)
                    }}> {language?.VeiwMore}</p>

                </div>
                <div className='selectrealTimediv'>
                    <Select
                        className='selectrealTime'
                        style={{}}
                        // showSearch
                        placeholder={language?.FromLowTo}
                        optionFilterProp='children'
                        onChange={onChange}
                        value={lanKorCondition ? val?.eng : val?.kr}
                        defaultValue={lanKorCondition ? val?.eng : val?.kr}
                        filterOption={(input, option) => option.children.toLowerCase()?.includes(input?.toLowerCase())}
                    >
                        {
                            options?.map((v, i) => {
                                return <Option value={v?.shortOpt}>{lanKorCondition ? v?.eng : v?.kr}</Option>
                            })

                        }
                    </Select>

                </div>
            </div>
            <div className='main_table_cont'>
                <Table
                    // className='table-striped-rows table_home scroll_class'
                    columns={columns}
                    dataSource={filteredData}
                    pagination={false}
                    bordered={false}
                    scroll={{ x: 800 }}
                    loading={loading}
                />
                <hr className='_hr' />
            </div>
        </div>
    )
}

export default RealTimeMarket 