import { PlusOutlined } from '@ant-design/icons'
import { Button, Descriptions, Input, Space, Tabs, Tag, Tooltip, Typography } from 'antd'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'

import { RiSoundModuleFill } from 'react-icons/ri'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AUTH } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import { errorMessage, successMessage } from '../../utils/helpers'
import Header from '../Home/Header'

const { Search } = Input;
const { TabPane } = Tabs;
const { Title } = Typography;


const FindPassword = (props) => {
    const history = useHistory()
    const { lanKorCondition, language } = props
    const [tags, setTags] = useState(['Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag',]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const id = new URLSearchParams(location.search).get('id')
    const email = new URLSearchParams(location.search).get('email')
    // console.log('id...', id, email)
    if (!email) {
        history.push(allPaths?.LOGIN)
        return errorMessage(lanKorCondition ? 'please complete pass verification first' : 'Pass 인증을 먼저 완료해주세요')
    }
    localStorage.setItem('email', email)
    const [loading, setLoading] = useState(false)

    const inputRef = useRef(null);
    const editInputRef = useRef(null);
    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);
    useEffect(() => {
        editInputRef.current?.focus();
    }, [inputValue]);

    const confirmPasswordFun = () => {
        setLoading(true)
        let obj = {
            id: localStorage.getItem('id'),
            email: localStorage.getItem('email'),
            password: password,
            confirmPassword: confirmPassword
        }

        if (obj?.password?.length < 8 || obj?.confirmPassword.length < 8) {
            return errorMessage(language?.PleaseEnterPasswordATLeastCharacters)
        }

        if (obj?.password !== obj?.confirmPassword) {
            return errorMessage(language?.PassworddidNotMatch)
            // return res.send({ success: false, message: 'Password did Not Match', messageKr: '.' })
        }

        axios.post(AUTH.NEW_PASSWORD, obj)
            .then((res) => {
                const { data } = res
                console.log('data', data)
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    history.push(allPaths.LOGIN)
                    setLoading(false)
                    setPassword('')
                    localStorage.removeItem('email')
                    setConfirmPassword('')
                }
                else {
                    setLoading(false)
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                    // history.push(allPaths.FINDPASSWORD,{data:'data'})
                }
            })
            .catch((e) => {
                setLoading(false)
                console.log('e ', e)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    return (
        <div className='home-main'>
            <Header {...props} />

            <div className='card-parent2'>
                <div className='card2'>
                    <Title level={3}>{language?.FindYourPassword}</Title>
                    <br /><br /><hr style={{ border: '0.5px solid gray', backgroundColor: 'black' }} /><br />
                    <p className='special_text'>{language?.PleaseResetYourPassword}</p>
                    <Descriptions bordered>
                        <Descriptions.Item label={language?.Password} span={3}>
                            <Input type='password' onChange={(e) => setPassword(e.target.value)} size="large" className='Signup_input1' />
                        </Descriptions.Item>

                        <Descriptions.Item label={language?.ConfirmPassword} span={3}>
                            <Input type='password' onChange={(e) => setConfirmPassword(e.target.value)} size="large" className='Signup_input1' />
                        </Descriptions.Item>


                    </Descriptions>
                    <br /><br />
                    <center>
                        <Button onClick={confirmPasswordFun} loading={loading} className='AuthButton' type="primary" size='large'>
                            {language?.conform}
                        </Button>
                    </center>

                </div>
            </div >
        </div >
    )
}

export default FindPassword