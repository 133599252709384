import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Input } from 'antd'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { tempUsers } from '../../Redux/actions/authActions'
import { AUTH, POST } from '../../utils/apis'
import { allPaths, passMID } from '../../utils/constants'
import { errorMessage, successMessage } from '../../utils/helpers'
import Header from '../Home/Header'

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
        md: {
            span: 7,
        },
        lg: {
            span: 7,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 19,
        },
        md: {
            span: 18,
        },
        lg: {
            span: 18,
        },
    },
}


let passVerOrigin = `https://securetech.vmvm.co.kr`
if (process.env.NODE_ENV === 'development') {
    passVerOrigin = `http://localhost:3000`
}

const CorporateSignup = (props) => {
    const { history, language, lanKorCondition } = props
    const dispatch = useDispatch()
    const [fieldsDisable, setfieldsDisable] = useState(true)
    const [upperFieldsDisable, setUpperFieldsDisable] = useState(false)
    const [verifyLoading, setVerifyLoading] = useState(false)
    const [confirmLoading, setconfirmLoading] = useState(false)
    const [verifyDisable, setverifyDisable] = useState(false)
    const [corporateDisable, setCorporateDisable] = useState(false)
    const [showTimer, setshowTimer] = useState(false)
    const [countdown, setcountdown] = useState(0)
    const [confirmDisable, setConfirmDisable] = useState(false)
    const [verifiedKeys, setVerifiedKeys] = useState([])
    const [verificationCode, setVerificationCode] = useState('')
    const [email, setEmail] = useState('')
    const [corpRegNum, setCorpRegNum] = useState('')
    const [tempUser, setTempUser] = useState({})
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const tempFormData = useSelector(state => state?.authReducer?.tempUser)
    const formData = useRef(null)
    const [instaId, setInstaId] = useState(null)

    let [userName, setuserName] = useState(new URLSearchParams(location.search).get('userName'))
    let [userBirthday, setuserBirthday] = useState(new URLSearchParams(location.search).get('userBirthday'))
    let [userPhone, setuserPhone] = useState(new URLSearchParams(location.search).get('userPhone'))

    useEffect(() => {
        if (instaId) {
            const domain = 'https://kssa.inicis.com'
            formData.current.action = `${domain}/init`
            formData.current.method = 'get'
            formData.current.submit()
        }
    }, [instaId])

    useEffect(() => {
        if (userName && userPhone && userBirthday) {
            // let tempManager = form?.getFieldValue('managers')
            let tempManager = tempFormData?.managers
            if (tempManager?.length) {
                tempManager[tempFormData?.index].userName = userName
                tempManager[tempFormData?.index].userBirthday = userBirthday
                tempManager[tempFormData?.index].userPhone = userPhone

                form.setFieldsValue({
                    email: tempFormData?.email,
                    certificateNumber: tempFormData?.certificateNumber,
                    password: tempFormData?.password,
                    confirm: tempFormData?.confirm || null,
                    corporateName: tempFormData?.corporateName || null,
                    corporateNo: tempFormData?.corporateNo || null,
                    managers: tempManager || [{ representativeManager: false, managerType: '', managerEmail: '' }],
                })
                setCorpRegNum(tempFormData?.corporateNo)
                setTempUser(tempFormData?.temporaryCorp)
                setVerifiedKeys(tempFormData?.verifiedKeys)
                setUpperFieldsDisable(true)
                setfieldsDisable(false)
            }
        } else {
            dispatch(tempUsers({}))
        }
    }, [userName, userBirthday, userPhone])

    const onFinish = (value) => {

        if (!value?.managers?.length) {
            return errorMessage('Add Managers')
        } else {
            value?.managers?.forEach((v, i) => {
                if (!v?.userName) {
                    return errorMessage('Please verify all managers')
                }
            })
        }

        let obj = {}
        obj.fullName = value.corporateName
        obj.email = value.email
        obj.password = value.password

        value.userType = 'corporate'
        // return true

        axios.post(AUTH.CORPORATE_SIGNUP, value)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    dispatch(tempUsers({}))
                    history.push(allPaths?.LOGIN)
                }
                else {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageKr)
            })
    }

    const confirmEmailVerification = () => {
        setconfirmLoading(true)
        let obj = {
            email: email,
            userType: 'corporate',
            verificationCode: verificationCode
        }
        axios.post(AUTH.VERIFY_EMAIL, obj)
            .then((res) => {
                const { data } = res
                setVerifyLoading(false)
                setconfirmLoading(false)
                if (data.success) {
                    setTempUser(data?.data[0])
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    setfieldsDisable(false)
                    setverifyDisable(true)
                    setConfirmDisable(true)
                    setshowTimer(false)
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                    // setverifyDisable(false)
                    setConfirmDisable(false)
                }
            })
            .catch((e) => {
                console.log('e ', e)
                setconfirmLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    const startTimer = () => {
        setshowTimer(true)
        var timeleft = 60
        var downloadTimer1 = setInterval(function () {
            timeleft--
            setcountdown(timeleft)
            if (timeleft <= 0) {
                clearInterval(downloadTimer1)
                setshowTimer(false)
                setverifyDisable(false)
            }
        }, 1000)
    }

    const sendEmailVerification = () => {
        setverifyDisable(true)
        setVerifyLoading(true)
        let obj = {
            email: email,
            userType: 'corporate'
        }
        axios.post(AUTH?.SEND_CORPORATE_VERIFICATION_CODE, obj)
            .then((res) => {
                const { data } = res
                setVerifyLoading(false)
                if (data.success) {
                    setshowTimer(true)
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    setverifyDisable(true)
                    startTimer()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                    setverifyDisable(false)
                }
            })
            .catch((e) => {
                console.log('e ', e)
                setverifyDisable(false)
                setVerifyLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    const sendCorporateRegistrationNumber = () => {

        if (!form?.getFieldsValue('email') && !form?.getFieldsValue('certificateNumber') && !form?.getFieldsValue('password') && !form?.getFieldsValue('corporateName') && !form?.getFieldsValue('confirm')) {
            return errorMessage('Please Fill above Fields')
        }

        let obj = {
            corporateNo: corpRegNum,
            _id: tempUser?._id
        }
        axios.post(AUTH?.CORPORATE_REGISTRATION_NUMBER, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    setCorporateDisable(true)
                    setUpperFieldsDisable(true)
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                    setCorporateDisable(false)
                }
            })
            .catch((e) => {
                console.log('e ', e)
                // setverifyDisable(false)
                // setVerifyLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    const verifyManager = (ind) => {

        if (!form?.getFieldsValue('managers')?.managers[ind]?.userName || !form?.getFieldsValue('managers')?.managers[ind]?.userPhone) {
            return errorMessage(language?.pleasePassVerifyManager)
        }

        let obj = {
            managerEmail: form?.getFieldsValue('managers')?.managers[ind]?.managerEmail,
            corporateUserId: tempUser?._id,
            managerType: form?.getFieldsValue('managers')?.managers[ind]?.managerType,
            representativeManager: Boolean(form?.getFieldsValue('managers')?.managers[ind]?.representativeManager),
            managerName: form?.getFieldsValue('managers')?.managers[ind]?.userName,
            managerPhone: form?.getFieldsValue('managers')?.managers[ind]?.userPhone
        }
        axios.post(AUTH?.VERIFY_MANAGER, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
            })
            .catch((e) => {
                console.log('e ', e)
                // setverifyDisable(false)
                // setVerifyLoading(false)
                // errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    const managerCodeVerification = (ind, key) => {
        let obj = {
            managerEmail: form?.getFieldsValue('managers')?.managers[ind]?.managerEmail,
            code: form?.getFieldsValue('managers')?.managers[ind]?.managerCode
        }

        let tempData = tempFormData

        let keys = verifiedKeys
        keys.push(key)

        axios.post(AUTH?.VERIFY_MANAGER_CODE, obj)
            .then((res) => {
                const { data } = res
                console.log('data', data)
                if (data.success) {
                    tempData.managers[ind]._id = data?.data[0]?._id
                    tempData.managers[ind].representativeManager = data?.data[0]?.representativeManager
                    tempData.managers[ind].managerEmail = data?.data[0]?.managerEmail
                    tempData.managers[ind].managerType = data?.data[0]?.managerType
                    tempData.managers[ind].managerCode = obj?.code

                    form?.setFieldValue('managers', tempData.managers)
                    console.log('tempData***', tempData)
                    dispatch(tempUsers(tempData))
                    setVerifiedKeys(keys)
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
            })
            .catch((e) => {
                console.log('e ', e)
                // setverifyDisable(false)
                // setVerifyLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    const callPassVerificationAuth = (index) => {
        let tempData = form?.getFieldValue()
        tempData.index = index
        tempData.temporaryCorp = tempUser
        tempData.verifiedKeys = verifiedKeys
        console.log('valueeee in callPassVerificationAuth', tempData)
        if (!tempData?.managers[index]) {
            tempData.managers[index] = { representativeManager: false, managerType: '', managerEmail: '' }
        }
        dispatch(tempUsers(tempData))

        let obj = {
            successUrl: `${passVerOrigin}${AUTH.GET_PASS_VERIFICATION_CORPORATE_RES}`
        }

        axios.post(POST.PASS_VERIFICATION, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    let result = data?.result
                    var parser = new DOMParser()
                    var doc = parser.parseFromString(result, 'text/html')
                    setInstaId(doc?.querySelector('#txId')?.value)
                }
                else {
                    errorMessage()
                }
            })
            .catch((e) => console.log('e', e))
    }

    const removeManager = () => {
        let tempData = tempFormData
        if (!(form?.getFieldValue('managers')?.length)) {
            form.setFieldValue('managers', [{ representativeManager: false, managerType: '', managerEmail: '' }])
        }
        tempData.managers = form?.getFieldValue('managers')

        dispatch(tempUsers(tempData))
        // dispatch()
    }

    return (
        <div className='home-main'>
            <Header  {...props} />
            <div className='card-parent2'>
                <div className='card2'>
                    <p className='heading greenColor'>{language?.CorporateSignup}</p>
                    <Form
                        {...formItemLayout}
                        form={form}
                        name='form'
                        onFinish={onFinish}
                        requiredMark={true}
                    >
                        <Form.Item
                            name='email'
                            label={`ID` + ' ' + language?.Email}
                            rules={[
                                {
                                    required: true,
                                    message: lanKorCondition ? 'Please enter your email address' : '이메일 주소를 입력해주세요'
                                }
                            ]}
                        >
                            <Input
                                size='large'
                                value={email}
                                disabled={!fieldsDisable || upperFieldsDisable}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder={'example@name.com'}
                                suffix={
                                    <Button
                                        className='input_field_buttons3'
                                        size='large'
                                        loading={verifyLoading}
                                        style={(!email?.length || verifyDisable || upperFieldsDisable || confirmDisable) ? { opacity: 0.3 } : null}
                                        disabled={!email?.length || verifyDisable || confirmDisable || upperFieldsDisable}
                                        onClick={sendEmailVerification}

                                    >
                                        {language?.GetCertificate}
                                    </Button>}
                            />
                        </Form.Item>

                        <Form.Item
                            name='certificateNumber'
                            label={language?.CertificateNumber}
                            rules={[
                                {
                                    required: true,
                                    message: lanKorCondition ? 'Please enter the verification code' : '인증번호를 입력해주세요'
                                }
                            ]}
                        >
                            <Input
                                size='large'
                                disabled={!fieldsDisable || upperFieldsDisable}
                                maxLength={6}
                                value={verificationCode}
                                onChange={(e) => setVerificationCode(e.target.value)}
                                placeholder={'1234567'} suffix={
                                    <Button
                                        className='input_field_buttons3'
                                        size='large'
                                        loading={confirmLoading}
                                        style={(!verificationCode?.length || confirmDisable || upperFieldsDisable || !fieldsDisable) ? { opacity: 0.3 } : null}
                                        disabled={!verificationCode?.length || confirmDisable || upperFieldsDisable || !fieldsDisable}
                                        onClick={confirmEmailVerification}
                                    >
                                        {language?.conform}
                                    </Button>}
                            />
                        </Form.Item>
                        {showTimer ? <p className='special_text1'><InfoCircleOutlined /> {language?.TimerCond} {countdown} {language?.seconds}</p> : null}

                        <Form.Item
                            name='password'
                            label={language?.Password}
                            rules={[
                                {
                                    required: true,
                                    message: language?.PleaseEnterPasswordATLeastCharacters
                                },
                                {
                                    min: 8,
                                    message: language?.PleaseEnterPasswordATLeastCharacters

                                }
                            ]}
                        >
                            <Input.Password
                                disabled={fieldsDisable || upperFieldsDisable}
                                autocomplete='new-password'
                                size='large'
                                visibilityToggle={false}
                                placeholder={lanKorCondition ? 'Enter Your Passowrd' : '비밀번호 입력'}
                            />
                        </Form.Item>

                        <Form.Item
                            label={language?.ConfirmPassword}
                            name='confirm'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: language?.Inputyour + ' ' + language?.ConfirmPassword,
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve()
                                        }

                                        return Promise.reject(language?.passCond)
                                    },
                                })
                            ]}
                        >
                            <Input.Password
                                disabled={fieldsDisable || upperFieldsDisable}
                                size='large'
                                visibilityToggle={false}
                                placeholder={lanKorCondition ? 'Confirm Your Passowrd' : '비밀번호 재입력'}
                            />
                        </Form.Item>

                        <Form.Item
                            name='corporateName'
                            label={language?.CorporateName}
                            rules={[
                                {
                                    required: true,
                                    message: language?.InputyourCorporateName
                                }
                            ]}
                        >
                            <Input disabled={fieldsDisable || upperFieldsDisable}
                            />
                        </Form.Item>

                        <Form.Item
                            name='corporateNo'
                            label={language?.CorporateRegistrationNumber}
                            rules={[
                                {
                                    required: true,
                                    message: language?.Inputyour + ' ' + language?.CorporateRegistrationNumber
                                },
                                {
                                    validator: async (_, names) => {
                                        if (corpRegNum?.length != 10) {
                                            return Promise.reject(new Error(lanKorCondition ? 'Registration no. must be of 10 digits' : '사업자등록번호는 10자리 입니다'))
                                        }
                                    }
                                }
                            ]}
                        >
                            <Input
                                disabled={fieldsDisable || corporateDisable || upperFieldsDisable}
                                size='large'
                                onChange={(e) => setCorpRegNum(e.target.value.replace(/[^0-9]/g, ''))}
                                value={corpRegNum}
                                maxLength={10}
                                suffix={
                                    <Button
                                        className='input_field_buttons3'
                                        size='large'
                                        loading={verifyLoading}
                                        disabled={fieldsDisable || corporateDisable || corpRegNum?.length != 10 || upperFieldsDisable}
                                        style={(fieldsDisable || corporateDisable || corpRegNum?.length != 10 || upperFieldsDisable) ? { opacity: 0.3 } : null}
                                        onClick={sendCorporateRegistrationNumber}

                                    >
                                        {language?.GetCertificate}
                                    </Button>}
                            />
                        </Form.Item>

                        <br />
                        <hr style={{ borderTop: '0.5px solid #E0E0E0' }} />
                        <br />

                        <Form.List name='managers'
                            initialValue={tempUsers?.managers || [{ representativeManager: false, managerType: '', managerEmail: '' }]}
                        >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <>
                                            <div key={key}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'representativeManager']}
                                                    valuePropName='checked'
                                                    label={language?.Manager + ' ' + (1 + name)}
                                                >

                                                    <div style={{ display: 'flex' }}>
                                                        <Checkbox defaultChecked={tempFormData?.managers?.length ? tempFormData?.managers[name]?.representativeManager : false} disabled={fieldsDisable || !upperFieldsDisable}>
                                                            {language?.RepresentativeManager}
                                                        </Checkbox>

                                                        <h4 onClick={() => { remove(name); removeManager() }} style={{ color: '#00bcd4', cursor: 'pointer' }}>{language?.DeleteINFO}</h4>
                                                    </div>
                                                </Form.Item>

                                                <Button
                                                    onClick={() => callPassVerificationAuth(name)}
                                                    style={fieldsDisable || !upperFieldsDisable ? { opacity: 0.3 } : null}
                                                    disabled={fieldsDisable || !upperFieldsDisable || (tempFormData?.managers?.length ? tempFormData?.managers[name]?.userPhone : false)}
                                                    type='primary' size='large' >{language?.GetCertificate}</Button>
                                                <br />
                                                <br />
                                                <div className='disabled_text'>
                                                    <p>{language?.PhoneNumber}: {tempFormData?.managers?.length ? tempFormData?.managers[name]?.userPhone : '-'}</p>
                                                    <p>{language?.Name}: {tempFormData?.managers?.length ? tempFormData?.managers[name]?.userName : '-'} </p>
                                                </div>
                                                <br />
                                                <br />

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'managerType']}
                                                    label={language?.Manager + ' ' + (1 + name) + ' ' + language?.rank}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: language?.Inputyour + ' ' + language?.Manager + ' ' + (1 + name) + ' ' + language?.rank

                                                        },
                                                    ]}
                                                >
                                                    <Input disabled={fieldsDisable || !upperFieldsDisable}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'managerEmail']}
                                                    label={language?.Manager + ' ' + (1 + name) + ' ' + language?.Email}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: language?.EnterAdmin1Email
                                                        }
                                                    ]}
                                                >
                                                    <Input
                                                        disabled={fieldsDisable || (verifiedKeys?.includes(key) && form?.getFieldValue('managers')[name]?.managerEmail) || !upperFieldsDisable}
                                                        size='large'
                                                        // className='Signup_input'
                                                        placeholder={'example@name.com'}
                                                        suffix={
                                                            <Button
                                                                className='input_field_buttons3'
                                                                size='large'
                                                                style={(fieldsDisable || (verifiedKeys?.includes(key) && form?.getFieldValue('managers')[name]?.managerEmail) || !upperFieldsDisable) ? { opacity: 0.3 } : null}
                                                                disabled={(fieldsDisable || (verifiedKeys?.includes(key) && form?.getFieldValue('managers')[name]?.managerEmail) || !upperFieldsDisable)}
                                                                onClick={() => verifyManager(name)}
                                                            >
                                                                {language?.Validate}
                                                            </Button>}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'managerCode']}
                                                    // label={language?.Manager + ' ' + (1 + name) + ' ' + language?.Email}
                                                    label={language?.CertificationNumber}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: language?.EnterAdmin1Email
                                                        }
                                                    ]}
                                                >
                                                    <Input
                                                        disabled={fieldsDisable || (verifiedKeys?.includes(key) && form?.getFieldValue('managers')[name]?.managerCode) || !upperFieldsDisable}
                                                        size='large'
                                                        placeholder={'1234567'}
                                                        suffix={
                                                            <Button
                                                                className='input_field_buttons3'
                                                                size='large'
                                                                style={(fieldsDisable || (verifiedKeys?.includes(key) && form?.getFieldValue('managers')[name]?.managerCode) || !upperFieldsDisable) ? { opacity: 0.3 } : null}
                                                                disabled={fieldsDisable || (verifiedKeys?.includes(key) && form?.getFieldValue('managers')[name]?.managerCode) || !upperFieldsDisable}
                                                                onClick={() => managerCodeVerification(name, key)}
                                                            >
                                                                {language?.conform}
                                                            </Button>}
                                                    />
                                                </Form.Item>

                                                <br />
                                                <hr style={{ borderTop: '0.5px solid #E0E0E0' }} />
                                                <br />
                                            </div>
                                        </>
                                    ))}
                                    <Button
                                        block
                                        type='primary'
                                        className='req_exc_btn'
                                        style={fieldsDisable || !upperFieldsDisable ? { opacity: 0.3 } : null}
                                        disabled={fieldsDisable || fields?.length > 4 || !upperFieldsDisable}
                                        onClick={() => add()}
                                        size='large' >{language?.AddContactInformation}</Button>
                                </>
                            )}
                        </Form.List>

                        <br />
                        <br />

                        <Form.Item
                            name="TermsConditions1"
                            valuePropName="checked"
                            label={language?.TermCondition}
                            rules={[
                                {
                                    validator: (_, value) =>
                                        value ? Promise.resolve() : Promise.reject(new Error(language?.AgreeTermsConditions)),
                                }
                            ]}
                        >
                            <Checkbox disabled={fieldsDisable} />
                        </Form.Item>

                        <div className='agreement scroll_class'>
                            <p>{language?.TAC1}</p>
                            <p>{language?.TAC2}</p>
                            <p>{language?.TAC3}</p>
                            <p>{language?.TAC4}</p>
                            <p>{language?.TAC5}</p>
                            <p>{language?.TAC6}</p>
                            <p>{language?.TAC7}</p>
                        </div>
                        <br />
                        <br />

                        <Button
                            size='large'
                            type='primary'
                            className='req_exc_btn'
                            loading={loading}
                            htmlType='submit'
                            style={fieldsDisable ? { opacity: 0.3 } : null}
                            disabled={fieldsDisable}
                        >
                            {language?.Signup}
                        </Button>
                    </Form>
                </div>
            </div >

            <form name="requestForm" action="" ref={formData}>
                <input type="hidden" name="mid" value={passMID} />
                <input type="hidden" name="reqSvcCd" value="01" />
                <input type="hidden" name="mTxId" value="mTxId" />
                <input type="hidden" name="nmComp" value="KG이니시스" />
                <input type="hidden" name="noComp" value="2208155597" />
                <input type="hidden" id="myOS" name="myOS" value="" />
                <input type="hidden" id="myBrowser" name="myBrowser" value="" />
                < input type="hidden" name="successUrl" value={passVerOrigin + AUTH.GET_PASS_VERIFICATION_CORPORATE_RES} />
                <input type="hidden" name="failUrl" value={passVerOrigin + '/login'} />
                <input type="hidden" name="identifier" value="" />

                <input type="hidden" id="txId" name="txId" value={instaId} />
                <input type="hidden" id="flgFixedUser" name="flgFixedUser" value="N" />
                <input type="hidden" id="userName" name="userName" value="" />
                <input type="hidden" id="userPhone" name="userPhone" value="" />
                <input type="hidden" id="userBirth" name="userBirth" value="" />
                <input type="hidden" id="userHash" name="userHash" value="" />

                <input type="hidden" id="agencyList" name="agencyList" value="TOSS,PASS,SHINHAN,SAMSUNG,KB,NAVER,KAKAO,PAYCO,KFTC" />
                <input type="hidden" id="agencyListSize" name="agencyListSize" value="9" />

                <input type="hidden" id="isDirect" name="isDirect" value="false" />
                <input type="hidden" id="directAgency" name="directAgency" value="" />
                <input type="hidden" id="req_url" name="req_url" value={passVerOrigin + '/login'} />
            </form>

        </div >
    )
}

export default CorporateSignup