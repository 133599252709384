import { PlusCircleFilled } from '@ant-design/icons'
import { Button, DatePicker, Divider, Form, Input, Modal, Select, Checkbox } from 'antd'
import axios from 'axios'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { POST } from '../../utils/apis'
import { regions, regionsKr, types, typesKr } from '../../utils/constants'
import { errorMessage, MyInfoAlert, successMessage } from '../../utils/helpers'
import CoustomDatePicker from '../DatePicker/DatePicker'
import ChooseTicket from './ChooseTicket'

const { TextArea } = Input
const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
        md: {
            span: 8,
        },
        lg: {
            span: 7,
        }
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
        md: {
            span: 16,
        },
        lg: {
            span: 17,
        },
    },
}

const AuctionPostModal = (props) => {
    const [form] = Form.useForm();
    const { user, language, lanKorCondition, fun } = props
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [memberFee, setMemberFee] = useState(null)
    const [nonMemberFee, setNonMemberFee] = useState(null)
    const [rightPrice, setRightPrice] = useState(null)
    const [selectedTicket, setSelectedTicket] = useState({})
    const [expiry, setExpiry] = useState(null)
    const userID = user?._id



    const config = {
        rules: [
            {
                type: 'object',
                required: true,
                message: language?.Pleaseselectdate,
            },
        ],
    }

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const onFinish = (fieldsValue) => {
        if (!nonMemberFee) {
            return errorMessage(language?.PleaseEnterNon_MemberFee)
        }
        if (!memberFee) {
            return errorMessage(language?.PleaseEnterMemberFee)
        }

        setLoading(true)
        let joinDate = {
            date1: fieldsValue['usableDate']?.format('YYYY-MM-DD'),
            date2: fieldsValue['expirationDate']?.format('YYYY-MM-DD')
        }
        fieldsValue.userId = userID
        fieldsValue.ticketId = selectedTicket?._id
        fieldsValue.rightPrice = (+fieldsValue.rightPrice)
        fieldsValue.termsAndCondition = 'termsAndCondition'
        fieldsValue.auctionType = 'share rental auction'
        fieldsValue.usableDate = joinDate.date1
        fieldsValue.expirationDate = expiry
        fieldsValue.locationPreference = fieldsValue.locationPreference
        fieldsValue.memberFee = Number(memberFee)
        fieldsValue.nonMemberFee = Number(nonMemberFee)
        fieldsValue.advanceFee = Number(fieldsValue?.advanceFee)

        console.log('fieldsValue', fieldsValue)

        // values.locationPreference = ["a", "b", "c"]

        axios.post(POST.CREATE_AUCTION_RENTAL, fieldsValue)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    fun()
                    setSelectedTicket({})
                    form.resetFields()
                    setIsModalOpen(false)
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                form.resetFields()
                setSelectedTicket({})
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }
    useEffect(() => {
        setMemberFee(selectedTicket?.memberFee)
        setNonMemberFee(selectedTicket?.NONmemberFee)
    }, [selectedTicket])
    const setExpiryDate = (e) => {
        console.log(e)
        let date = moment(e?._d).add(-1, 'days').format('YYYY-MM-DD HH:MM')
        console.log('date', date)
        form.setFieldValue('usableDate', moment(date, 'YYYY-MM-DD HH:MM'))
        const weekday = moment(date).format('dddd')
        const isWeekend = weekday === 'Sunday' || weekday === 'Saturday'

        let setExpDate
        if (!isWeekend) {
            setExpDate = moment(date).add(3, 'days').format('YYYY-MM-DD HH:MM')
            form.setFieldValue('expirationDate', moment(setExpDate, 'YYYY-MM-DD HH:MM'))
            setExpiry(setExpDate)
        } else {
            console.log('setExpDate', setExpDate)
            setExpDate = moment(date).add(7, 'days').format('YYYY-MM-DD HH:MM')
            form.setFieldValue('expirationDate', moment(setExpDate, 'YYYY-MM-DD HH:MM'))
            setExpiry(setExpDate)
        }
    }


    // useEffect(() => {
    //     setMemberFee(selectedTicket?.memberFee)
    //     setNonMemberFee(selectedTicket?.NONmemberFee)
    // }, [selectedTicket])
    return (
        <>
            {user?._id && user?.telNo && user?.bank && user?.bankAccountNumber ?
                <Button className='upload_sharing_btn' onClick={showModal}>
                    {language?.RegisterAuctionPost}
                </Button> :
                <Button className='upload_sharing_btn' onClick={() => { MyInfoAlert(language) }}>
                    {language?.RegisterAuctionPost}
                </Button>}
            <center>
                <Modal
                    open={isModalOpen}
                    // open={true}
                    onCancel={handleCancel}
                    className='responsiveModal'
                    footer={null}
                    style={{ top: '10px', }}
                >
                    <div
                        className='scroll_class'
                        style={{
                            height: 'auto',
                            width: '100%',
                            maxHeight: '85vh',
                            overflowY: 'scroll',
                            marginTop: '12px'
                        }}>
                        <div className='modal_main_div' >
                            <center><h2>{language?.RegisterAuctionPost}</h2></center>
                            <br />

                            <Form requiredMark={false} form={form} name='time_related_controls'  {...formItemLayout} onFinish={onFinish}>

                                <Form.Item
                                    name='membership'
                                    label={language?.Membership}
                                    rules={[
                                        {
                                            validator: async (names) => {

                                                if (!selectedTicket?.golfClub?.clubName) {
                                                    console.log(selectedTicket == {})
                                                    return Promise.reject(new Error(language?.ChooseYourTicket))
                                                }
                                            }
                                        }
                                    ]}

                                >

                                    <ChooseTicket setSelectedTicket={setSelectedTicket}  {...props} />
                                </Form.Item>

                                <Form.Item
                                    name='Fee'
                                    label={language?.Fee}
                                    rules={[
                                        {
                                            required: true,
                                            validator: async (_, names) => {
                                                if (!memberFee) {
                                                    return Promise.reject(new Error(language?.PleaseEnterMemberFee))
                                                }
                                                if (!nonMemberFee) {
                                                    return Promise.reject(new Error(language?.PleaseEnterNon_MemberFee))
                                                }
                                            }
                                        }
                                    ]}
                                >
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '47%', textAlign: 'center' }}>
                                            <label style={{ marginRight: '4%' }} htmlFor=''>{language?.Member}</label>
                                            <Input
                                                suffix={language?.KRW}
                                                style={{
                                                    width: 'auto',
                                                    // marginLeft: '4%',
                                                }}
                                                maxLength={7}
                                                value={memberFee}
                                                onChange={(e) => setMemberFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                            />
                                        </div>

                                        <div style={{ width: '50%', marginLeft: 'auto', textAlign: 'center' }}>
                                            <label htmlFor='' style={{ marginRight: '4%' }}>{language?.NonMember}</label>

                                            <Input
                                                suffix={language?.KRW}
                                                style={{
                                                    width: 'auto',
                                                    // marginLeft: '4%',

                                                }}
                                                maxLength={7}
                                                value={nonMemberFee}
                                                onChange={(e) => setNonMemberFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                            />
                                        </div>
                                    </div>
                                </Form.Item>


                                <div className='disabled_text scroll_class'>
                                    <table>
                                        <tr>
                                            <td>{language?.GolfClubName}</td>
                                            <td>{selectedTicket?.golfClub?.clubName || '-----------------'}</td>
                                        </tr>
                                        <br />
                                        <tr>
                                            <td>{language?.address}</td>
                                            <td>{selectedTicket?.golfClub?.clubAddress || '-----------------'}</td>
                                        </tr>
                                        <br />
                                        <tr>
                                            <td>{language?.Type}</td>
                                            <td>{selectedTicket?.membershipType ? `${lanKorCondition ? selectedTicket?.membershipType : typesKr[types?.indexOf(selectedTicket?.membershipType)]?.typesKr}` : `-----------------`}</td>
                                        </tr>
                                    </table>
                                </div>

                                <Divider style={{ backgroundColor: '#bbb', marginBottom: 20 }} />


                                <Form.Item
                                    name='rightPrice'
                                    label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' /> {language?.RightPrice}</h4>}
                                    rules={[
                                        {
                                            required: true,
                                            message: language?.PleaseInputRightPrice,
                                        },
                                    ]}
                                >
                                    <div>
                                        <Input
                                            suffix={language?.KRW}
                                            maxLength={7}
                                            onChange={(e) => setRightPrice(e.target.value?.replace(/[^0-9]/g, ''))}
                                        />
                                    </div>
                                </Form.Item>

                                <Form.Item
                                    name='usableDate'
                                    label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' /> {language?.UsableDate}</h4>}
                                    {...config}
                                >
                                    <CoustomDatePicker placeholder={language?.Selectdate} setDate={setExpiryDate} />
                                </Form.Item>

                                {/* <Form.Item
                                name='expirationDate'
                                label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' />{language?.ExpirationDate}</h4>}
                                {...config}
                            >
                                <DatePicker placeholder={language?.Selectdate} style={{ width: '100%' }} />
                            </Form.Item> */}

                                <Form.Item
                                    name='locationPreference'
                                    label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' /> {language?.LocationPreference}</h4>}
                                    rules={[
                                        {
                                            required: true,
                                            message: language?.PleaseselectLocation,
                                        },
                                    ]}
                                >
                                    <Select mode='multiple' placeholder={language?.selectLocation}>
                                        {regionsKr?.slice(1)?.map((v, i) => (<Option value={v?.TitleEn}>{lanKorCondition ? v?.TitleEn : v?.TitleKr}</Option>))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name='advanceFee'
                                    label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' /> {language?.advanceFee}</h4>}
                                    rules={[
                                        {
                                            required: true,
                                            message: language?.advanceFeeAmountHere,
                                        },
                                    ]}
                                >
                                    <div>
                                        <Input
                                            min={0}
                                            maxLength={7}
                                            suffix={language?.KRW}
                                            placeholder={language?.advanceFeeAmountHere}
                                        />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    name='TermAndConditions'
                                    label={language?.TermCondition}
                                    valuePropName='checked'
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject(new Error(language?.Shouldacceptagreement)),
                                        }
                                    ]}
                                >
                                    <Checkbox />
                                </Form.Item>
                                <div className='agreement scroll_class' style={{ marginBottom: 20 }}>
                                    <p>{language?.TAC1}</p>
                                    <p>{language?.TAC2}</p>
                                    <p>{language?.TAC3}</p>
                                    <p>{language?.TAC4}</p>
                                    <p>{language?.TAC5}</p>
                                    <p>{language?.TAC6}</p>
                                    <p>{language?.TAC7}</p>
                                </div>
                                <Button loading={loading} className='req_exc_btn' type='primary' htmlType='submit'>{language?.Submit}</Button>
                                <Button style={{ width: '100%', marginTop: 10 }} onClick={handleCancel}>{language?.Cancel}</Button>
                            </Form>


                        </div>
                    </div>
                </Modal>
            </center>
        </>
    )
}

export default AuctionPostModal