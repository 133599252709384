import React, { useState } from 'react'
import { Select, Table, Button, Input, Card } from 'antd'
import { HiPlusCircle } from "react-icons/hi"
import RegisterForAuctionModal from './RegisterForAuctionModal'
import moment, { lang } from 'moment-timezone'
import noData from '../../assets/noData.gif'
import loadingImg from '../../assets/loading.gif'
import axios from 'axios'
import { POST } from '../../utils/apis'
import { FaStarOfLife } from "react-icons/fa"
import { errorMessage, successMessage } from '../../utils/helpers'
import { types, typesKr } from '../../utils/constants'

const SharedRentalAuctionTab = (props) => {
    const { auction, loading, language, lanKorCondition, user, getAuction } = props
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [amount, setAmount] = useState(0)
    const [kEy, setKEy] = useState(null)

    const updateAuctionAmount = (id) => {
        let obj = {
            amount, _id: id
        }

        if (amount == 0 || amount < 0) {
            return errorMessage(lanKorCondition ? 'Please Enter a bid price!' : '입찰가를 입력하세요!')
        }

        axios.post(POST.UPDATE_SHARE_AUCTION_BID_PRICE, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    getAuction()
                    setKEy(null)
                    setAmount(0)
                }
                else {
                    console.log('runing')
                    setAmount(0)
                }
            })
            .catch((e) => {
                console.log('err', e)
                setKEy(null)
                setAmount(0)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })

    }

    const cancelBid = (id) => {
        let obj = {
            bidId: id, userId: user?._id
        }
        if (!id) {
            return true
        }
        axios.post(POST.DELETE_AUCTION_RENTAL_BID, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    getAuction()
                }
                else {
                    console.log('runing')
                }
            })
            .catch((e) => {
                console.log('err', e)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })

    }

    return (
        <div className='share_rental_auction'>

            <RegisterForAuctionModal language={language} {...props} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
            <div className="row_new_cont">

                {!loading ?
                    auction?.length ?
                        auction?.map((v, i) => {
                            return (
                                <div key={i} className="column_new_cont"   >
                                    <div className='card_panel'>
                                        <h5>{language?.GolfClubName}</h5>
                                        <h5 className='not_bold'>{v?.auctionId?.ticketId?.golfClub?.clubName}</h5>
                                    </div>
                                    <div className='card_panel'>
                                        <h5>{language?.Type}</h5>
                                        <h5 className='not_bold'>
                                            {/* {v?.auctionId?.ticketId?.membershipType} */}
                                            {lanKorCondition ? v?.auctionId?.ticketId?.membershipType : typesKr[types?.indexOf(v?.auctionId?.ticketId?.membershipType)]?.typesKr}
                                        </h5>
                                    </div>
                                    <div className='card_panel'>
                                        <h5>{language?.MemberFee}</h5>
                                        <h5 className='not_bold'>{v?.auctionId?.ticketId?.memberFee ? v?.auctionId?.ticketId?.memberFee + ' ' + language?.Won : '---'}</h5>
                                    </div>
                                    <div className='card_panel'>
                                        <h5>{language?.NonMemberFee}</h5>
                                        <h5 className='not_bold'>{v?.auctionId?.ticketId?.NONmemberFee}</h5>
                                    </div>
                                    <div className='card_panel'>
                                        <h5><HiPlusCircle className='plus_icon' />{language?.RightPrice}</h5>
                                        <h5 className='not_bold'>{v?.auctionId?.rightPrice}</h5>
                                    </div>
                                    <div className='card_panel '>
                                        <h5><HiPlusCircle className='plus_icon' />{language?.ReservationDate}</h5>
                                        <h5 className='not_bold'>{moment(v?.auctionId?.ticketId?.joiningDate?.date1).format('YYYY-MM-DD')}</h5>
                                    </div>
                                    <div className='card_panel last_des'>
                                        <h5><HiPlusCircle className='plus_icon' />{language?.ExpirationDate}</h5>
                                        <h5 className='not_bold'>{moment(v?.auctionId?.ticketId?.joiningDate?.date2).format('YYYY-MM-DD')}</h5>
                                    </div>


                                    {/*  */}

                                    {
                                        !v?.isAccepted && !v?.isCanceled ? '' : !v?.isAccepted && v?.isCanceled ?
                                            '' :

                                            <Button disabled={true} className='success-auction-btn' style={{ fontSize: 12 }}>
                                                {language?.SuccessfulBid}
                                                {/*  */}
                                            </Button>
                                    }

                                    {
                                        v?.isParticipated && !v?.isParticipationAccepted ?
                                            <>
                                                <div className='btn_card'>
                                                    <Button key={i}
                                                        disabled
                                                        width={'100%'}
                                                        className='partcipation-auction-btn'
                                                        style={{ fontSize: 12 }}
                                                    >
                                                        {lanKorCondition ? ' Applied (Waiting for partcipation)' : '경매신청완료'}

                                                    </Button>
                                                </div>
                                                <div style={{ marginTop: 4 }}>
                                                    <h4 key={i}
                                                        width={'100%'}
                                                        className="fntSize"
                                                    >
                                                        <FaStarOfLife color='black' size={10} />
                                                        &nbsp;   {lanKorCondition ? 'Please wait until admin \n check your submission' : '선금입금 후 관리자의 참여승인을 기다려주세요. '}
                                                    </h4>
                                                </div>
                                            </>
                                            :
                                            <>
                                                {!v?.isAccepted && v?.isCanceled ?
                                                    <div className='btn_card' style={{ marginTop: 4 }}>
                                                        <Button style={{ width: '100%', backgroundColor: 'red', border: '1px solid red', color: 'white' }}>
                                                            {lanKorCondition ? 'Failed' : ' 보류'}
                                                        </Button>
                                                    </div>
                                                    : ''}

                                                <div className='btn_card' style={{ marginTop: 10 }}>
                                                    {v?.isParticipated && v?.isParticipationAccepted && v?.price == 0 && !v?.isAccepted && !v?.isCanceled ?
                                                        <Button disabled={true} className='start-auction-btn' style={{ fontSize: 12 }}>
                                                            {language?.Nowstartauction}
                                                        </Button>
                                                        :
                                                        <span style={{ display: 'flex', flexDirection: 'row' }} className={!v?.isAccepted && !v?.isCanceled ? '' : !v?.isAccepted && v?.isCanceled ? '' : 'display-none'}>
                                                            <Input disabled style={{}}
                                                                className='aucInpt49'
                                                                value={v?.price} placeholder='10,000,000' suffix={language?.KRW} />
                                                            {!v?.isAccepted && v?.isCanceled ?
                                                                <Button disabled={v?.isAccepted || v?.isCanceled} onClick={() => cancelBid(v?._id)} className='btn_cancel aucInpt49' style={{ marginLeft: 7, fontSize: 11, }}>
                                                                    {language?.Trynexttime}
                                                                </Button> :
                                                                <Button disabled={v?.isAccepted || v?.isCanceled} onClick={() => cancelBid(v?._id)} className='btn_cancel aucInpt49' style={{ marginLeft: 7, fontSize: 11, }}>
                                                                    {lanKorCondition ? 'Cancel this amount' : '입찰취소'}
                                                                    {/*  */}
                                                                </Button>}
                                                        </span>
                                                    }
                                                </div>
                                                {
                                                    !v?.isAccepted && v?.isCanceled ? '' :
                                                        <div className='btn_card' style={{ marginTop: 4 }}>
                                                            <Input disabled={v?.isAccepted || v?.isCanceled}
                                                                style={{ width: 120 }}
                                                                className='aucInpt49'
                                                                placeholder={v?.price} type='number'
                                                                onClick={() => { setKEy(i) }}
                                                                onChange={(e) => setAmount(e?.target?.value)} suffix={language?.KRW} />
                                                            <Button disabled={v?.isAccepted || v?.isCanceled} onClick={() => updateAuctionAmount(v?._id)} className={v?.isCanceled && !v?.isAccepted ? 'btn_failed_bid aucInpt49' : 'btn_successfull_bid aucInpt49'} style={{ fontSize: 11, marginLeft: '10px' }}>
                                                                {
                                                                    !v?.isAccepted && !v?.isCanceled ?
                                                                        i === kEy && amount > 0 && v?.price > 0 ? language?.changebidamount : language?.BidThisAmount :
                                                                        !v?.isAccepted && v?.isCanceled ?
                                                                            lanKorCondition ? 'Failed' : ' 보류' :
                                                                            lanKorCondition ? 'DealMade' : '성공'

                                                                }
                                                            </Button>
                                                        </div>
                                                }

                                            </>
                                    }


                                    {/*  */}
                                </div>
                            )
                        }) :
                        <div className='NodataDiv'>
                            <img src={noData} className='NodataImg' />
                            <h3>{language?.AuctionsNotFound}</h3>
                        </div> :
                    <div className='cards-lodaing-div'>
                        <Card className='loading-card' loading={true} />
                        <Card className='loading-card' loading={true} />
                        <Card className='loading-card' loading={true} />
                        <Card className='loading-card' loading={true} />
                    </div>}

            </div>
        </div>
    )
}

export default SharedRentalAuctionTab