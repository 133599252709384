import { PlusCircleFilled } from '@ant-design/icons'
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select, TimePicker } from 'antd'
import axios from 'axios'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { HiCheckCircle } from 'react-icons/hi'
import { Link, useHistory } from 'react-router-dom'
import { POST } from '../../utils/apis'
import { allPaths, regions, types, typesKr, typesOnlyKr } from '../../utils/constants'
import { errorMessage, MyInfoAlert, successMessage } from '../../utils/helpers'
import CoustomDatePicker from '../DatePicker/DatePicker'
import ChooseTicket from './ChooseTicket'

const config = {
    rules: [
        {
            type: 'object',
            required: true,
            message: 'Please select date!',
        },
    ],
}
const { TextArea } = Input

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
        md: {
            span: 8,
        }
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
        md: {
            span: 16,
        },
    },
}

const ExchangeModal = (props) => {
    const { ticketId, user, shareTicket, language, lanKorCondition } = props
    // const { ticketId, user ,language} = props
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isModalComp, setIsModalComp] = useState(false)
    const [rightPrice, setRightPrice] = useState(null)
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const [expiry, setExpiry] = useState(null)
    const [ticketInfo, setTicketInfo] = useState([])
    const [selectedTicket, setSelectedTicket] = useState({})

    useEffect(() => {
        getSingleTicket()
    }, [])

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleOk = () => {
        setIsModalOpen(false)
        setRightPrice(null)

    }

    const handleCancel = () => {
        setIsModalOpen(false)
        setRightPrice(null)
    }

    const handleCancelComp = () => {
        setIsModalComp(false)
    }

    const getSingleTicket = () => {
        let obj = {
            _id: ticketId,
            userId: user?._id
        }
        axios.post(`${POST.SINGLE_TICKET_INFO}`, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setTicketInfo(data?.data)
                }
                else {
                    console.log(lanKorCondition ? data?.message : data?.messageKr)
                }
            })
            .catch((e) => {
                console.log('e', e)
            })
    }
    console.log('ticketInfo', shareTicket)

    const onFinish = (fieldsValue) => {
        setLoading(true)
        let joinDate = {
            date1: fieldsValue['usableDate']?.format('YYYY-MM-DD HH:MM'),
            date2: fieldsValue['expirationDate']?.format('YYYY-MM-DD HH:MM'),
            // usableTime: fieldsValue['usableTime'].format('HH:mm')
        }
        let obj = {
            buyerId: user?._id,
            buyerTicketId: selectedTicket?._id,
            ticketShareId: shareTicket?._id,
            userTicketId: shareTicket?.ticketId?._id,
            userId: shareTicket?.userId,
            ownerAdminId: shareTicket?.adminId,
            rightPrice: fieldsValue?.rightPrice || rightPrice,
            // membershipCardName: fieldsValue?.membership,
            // locationPreference: fieldsValue?.locationPreference,
            termsAndCondition: fieldsValue?.termsConditions1,
            usableDate: joinDate?.date1,
            usableTime: new Date(joinDate?.date1).getTime(),
            expirationDate: expiry
        }
        console.log('obj===>', obj)
        axios.post(POST.EXCHANGE_REQUEST, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    setLoading(false)
                    setIsModalComp(true)
                    setIsModalOpen(false)
                }
                else {
                    setLoading(false)
                    errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
                }
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }
    const openChat = (e) => {
        window.open('http://pf.kakao.com/_xdxebxjxj/chat', '_blank')
    }

    const setExpiryDate = (e) => {
        let date = moment(e?._d).add(-1, 'days').format('YYYY-MM-DD HH:MM')
        form.setFieldValue('usableDate', moment(date, 'YYYY-MM-DD HH:MM'))
        const weekday = moment(date).format('dddd')
        const isWeekend = weekday === 'Sunday' || weekday === 'Saturday'

        let setExpDate
        if (!isWeekend) {
            setExpDate = moment(date).add(3, 'days').format('YYYY-MM-DD HH:MM')
            form.setFieldValue('expirationDate', moment(setExpDate, 'YYYY-MM-DD HH:MM'))
            setExpiry(setExpDate)
        } else {
            setExpDate = moment(date).add(7, 'days').format('YYYY-MM-DD HH:MM')
            form.setFieldValue('expirationDate', moment(setExpDate, 'YYYY-MM-DD HH:MM'))
            setExpiry(setExpDate)
        }
    }


    return (
        <>

            {user?._id && user?.telNo && user?.bank && user?.bankAccountNumber ?
                <Button className='btn_exchange' style={shareTicket?.ticketId?.userId === user?._id ? { opacity: 0.2 } : null} disabled={shareTicket?.ticketId?.userId === user?._id} onClick={showModal}>
                    {language?.Exchange}
                </Button> :
                <Button className='btn_exchange' style={shareTicket?.ticketId?.userId === user?._id ? { opacity: 0.2 } : null} disabled={shareTicket?.ticketId?.userId === user?._id}
                    onClick={() => { MyInfoAlert(language) }}>
                    {language?.Exchange}
                </Button>}

            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                width={'100%'}
                style={{ maxWidth: 800, top: '10px', }}
            >
                <div
                    className='scroll_class'
                    style={{
                        height: 'auto',
                        width: '100%',
                        maxHeight: '85vh',
                        overflowY: 'scroll',
                        marginTop: '12px'
                    }}>
                    <div className='paddongThirty' >
                        <div>
                            <h1 style={{ textAlign: 'center' }}>{language?.LetExchange}</h1>
                            <h3 style={{ textAlign: 'center', color: '#2196f3' }}>{language?.PlzFillBlack}</h3>
                        </div>
                        <div>
                            <h3 style={{ paddingLeft: 20 }} >{language?.Membershipwishtotrade}</h3>
                        </div>

                        <div className='disabled_text scroll_class'>
                            <table>
                                <tr>
                                    <td>{language?.GolfClubName}</td>
                                    <td>{(ticketInfo?.golfClub?.clubName || shareTicket?.ticketId?.golfClub?.clubName) || '-----------------'}</td>
                                </tr>
                                <br />
                                {/* <tr>
                                    <td>{language?.golfclubAddress}</td>
                                    <td>{ticketInfo?.golfClub?.clubAddress || shareTicket?.ticketId?.golfClub?.clubAddress || '-----------------'}</td>
                                </tr>
                                <br /> */}
                                <tr>
                                    <td>{language?.Type}</td>
                                    {/* <td>{ticketInfo?.membershipType || '-----------------'}</td> */}
                                    <td>{lanKorCondition ? (ticketInfo?.membershipType || shareTicket?.ticketId?.membershipType) :
                                        typesKr[typesOnlyKr?.indexOf(ticketInfo?.membershipType || shareTicket?.ticketId?.membershipType)]?.typesKr ?
                                            typesKr[typesOnlyKr?.indexOf(ticketInfo?.membershipType || shareTicket?.ticketId?.membershipType)]?.typesKr :
                                            typesKr[types?.indexOf(ticketInfo?.membershipType || shareTicket?.ticketId?.membershipType)]?.typesKr
                                            || '-----------------'}</td>
                                </tr>
                                <br />

                                <tr>
                                    <td>즉시공유/구매가격</td>
                                    <td>{(ticketInfo?.rightPrice || shareTicket?.ticketId?.rightPrice) ? (ticketInfo?.rightPrice || shareTicket?.ticketId?.rightPrice) + ' ' + language?.Won : '-------'} </td>
                                </tr>
                                <br />
                                <tr>
                                    <td>회원가</td>
                                    <td>{(ticketInfo?.memberFee || shareTicket?.ticketId?.memberFee) ? (ticketInfo?.memberFee || shareTicket?.ticketId?.memberFee) + ' ' + language?.Won : '-------'}</td>
                                </tr>
                                <br />
                                <tr>
                                    <td>비회원 이용가</td>
                                    <td>{(ticketInfo?.NONmemberFee || shareTicket?.ticketId?.NONmemberFee) ? (ticketInfo?.NONmemberFee || shareTicket?.ticketId?.NONmemberFee) + ' ' + language?.Won : '--------'}</td>
                                </tr>
                                <br />
                                <tr>
                                    <td>예약일시</td>
                                    <td>{!!ticketInfo?.expirationDate ? moment(ticketInfo?.expirationDate).format('YYYY-MM-DD') : moment(shareTicket?.ticketId?.expirationDate).format('YYYY-MM-DD')}</td>
                                </tr>
                                <br />
                                <tr>
                                    <td>마감일</td>
                                    <td>{!!ticketInfo?.usableDate ? moment(ticketInfo?.usableDate).format('YYYY-MM-DD') : moment(shareTicket?.ticketId?.usableDate).format('YYYY-MM-DD')}</td>
                                </tr>
                                <br />

                                <br />


                                {/* 골프장, golf club 
회원권종류, ticket type 
예약일시, reservation date
마감일, deadline
회원가, member fee
비회원 이용가, nonmember fee
즉시공유/구매가격 right price */}

                            </table>
                        </div>

                        <Form form={form} requiredMark={false} name='time_related_controls'  {...formItemLayout} onFinish={onFinish}>
                            <div className='second_panel'>
                                <Form.Item
                                    name='membership'
                                    label={language?.memberSelection}
                                    rules={[
                                        {
                                            validator: async (names) => {
                                                if (!ticketInfo?.golfClub?.clubName) {
                                                    return Promise.reject(new Error(language?.memberSelection))
                                                }
                                            }
                                        }
                                    ]}
                                >
                                    <ChooseTicket setSelectedTicket={setSelectedTicket}  {...props} />
                                </Form.Item>
                                {selectedTicket?._id && <div className='disabled_text scroll_class'>
                                    <table>
                                        <tr>
                                            <td>{language?.GolfClubName}</td>
                                            <td>{selectedTicket?.golfClub?.clubName || '-----------------'}</td>
                                        </tr>
                                        <br />
                                        <tr>
                                            <td>{language?.golfclubAddress}</td>
                                            <td>{selectedTicket?.golfClub?.clubAddress || '-----------------'}</td>
                                        </tr>
                                        <br />
                                        <tr>
                                            <td>{language?.Type}</td>
                                            {/* <td>{selectedTicket?.membershipType || '-----------------'}</td> */}
                                            <td>{lanKorCondition ? selectedTicket?.membershipType :
                                                typesKr[typesOnlyKr?.indexOf(selectedTicket?.membershipType)]?.typesKr ?
                                                    typesKr[typesOnlyKr?.indexOf(selectedTicket?.membershipType)]?.typesKr :
                                                    typesKr[types?.indexOf(selectedTicket?.membershipType)]?.typesKr
                                                    || '-----------------'}</td>
                                        </tr>
                                        <br />
                                    </table>
                                </div>}
                                <br />
                                <Form.Item
                                    name='rightPrice'
                                    label={<h4><PlusCircleFilled className='plus_with_text' /> {language?.RightPrice}</h4>}
                                    rules={[
                                        {
                                            required: true,
                                            message: language?.Inputyour + ' ' + language?.Ticket,
                                        },
                                    ]}
                                >
                                    <div>
                                        <Input
                                            suffix={language?.KRW}
                                            maxLength={7}
                                            value={rightPrice}
                                            onChange={(e) => setRightPrice(e.target.value?.replace(/[^0-9]/g, ''))}
                                        />
                                    </div>
                                </Form.Item>

                                <Form.Item
                                    name='usableDate'
                                    label={<h4><PlusCircleFilled className='plus_with_text' />{language?.Usabledate}</h4>}
                                    rules={[
                                        {
                                            required: true,
                                            message: language?.PleaseSelect + ' ' + language?.Usabledate,
                                        },
                                    ]}
                                >
                                    <CoustomDatePicker placeholder={language?.Usabledate}
                                        setDate={setExpiryDate} showTime={true} format={' YYYY-MM-DD h:mm a'} />
                                </Form.Item>
                                <Form.Item
                                    name='expirationDate'
                                    label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' /> {language?.ExpirationDate}</h4>}
                                    {...config}
                                    initialValue={expiry}
                                >
                                    <div>
                                        <Input value={expiry} disabled placeholder={language?.Selectdate} style={{ width: '100%' }} />
                                    </div>
                                </Form.Item>

                                <Form.Item
                                    name='termsConditions1'
                                    label={language?.TermCondition}
                                    valuePropName='checked'
                                    hasFeedback
                                    rules={[
                                        {
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject(new Error(language?.ShouldAccept)),
                                        }
                                    ]}
                                >
                                    <Checkbox />
                                </Form.Item>

                                <div className='agreement scroll_class' style={{ width: '100%' }}>
                                    {/* <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eum porro assumenda, in dicta ab fuga, vero quasi nostrum molestiae, corporis perferendis similique quis deleniti ullam. Recusandae sequi quam vitae cumque! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae, nihil cupiditate rerum mollitia, nemo vitae temporibus amet quos esse architecto quod repellat laboriosam explicabo similique, alias beatae odio possimus? Accusamus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque blanditiis eaque saepe odio voluptas obcaecati at voluptatum, aliquid omnis laborum libero numquam architecto sit! Architecto quas ratione alias debitis deserunt!</p> */}
                                    <p>{language?.TAC1}</p>
                                    <p>{language?.TAC2}</p>
                                    <p>{language?.TAC3}</p>
                                    <p>{language?.TAC4}</p>
                                    <p>{language?.TAC5}</p>
                                    <p>{language?.TAC6}</p>
                                    <p>{language?.TAC7}</p>
                                </div>
                            </div>
                            <Button loading={loading} htmlType='submit' className='req_exc_btn' type='primary'>{language?.RequestExchange}</Button>
                        </Form>

                    </div>
                </div>
            </Modal >
            <Modal
                open={isModalComp}
                // open={isModalOpen}
                // onCancel={handleCancel}
                onCancel={handleCancelComp}
                footer={[
                    <div className='message_modal'>
                        <Button className='message_modal_btn' type='primary'
                            onClick={() => {
                                history.push({
                                    pathname: allPaths?.MYPAGE,
                                    search: `?membershipTransaction`
                                })
                            }}>{lanKorCondition ? 'Go To My Page' : language?.GoTicketSalesRight}</Button>
                        {/* <Link to={`/chat?userId=${shareTicket?.userId?._id}`}> */}
                        <Button onClick={() => openChat(shareTicket)} className='message_modal_btn marginLeft' type='primary' >{lanKorCondition ? 'Chat Bot' : language?.TalkToUser}</Button>
                        {/* </Link> */}
                    </div>]}
            >
                <h2 style={{ textAlign: 'center' }}>{language?.Completion}</h2>
                <div style={{ padding: '20px' }}>

                    <center><HiCheckCircle className='tick_icon' /></center>
                    <h4 style={{ textAlign: 'center' }}>
                        {language?.ExchangeRentRequest}
                    </h4>
                    <h4 style={{ textAlign: 'center' }}>
                        {language?.PleaseWaitFor}
                    </h4>
                </div>
            </Modal>
        </>
    )
}

export default ExchangeModal