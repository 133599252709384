import { Button, Card, Input, Modal, Form } from 'antd'
import axios from 'axios'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import { HiPlusCircle } from 'react-icons/hi'
import noData from '../../assets/noData.gif'
import { POST } from '../../utils/apis'
import { errorMessage, loginAlert, MyInfoAlert, successMessage } from '../../utils/helpers'
import SellTheTicket from './SellTheTicket'
import { IoIosAlert } from 'react-icons/io'
import { LoginAlert } from '..'
import AuctionModal from './AuctionModal'
import { types, typesKr, typesOnlyKr } from '../../utils/constants'

const SaleAuction = (props) => {
    let { user, auction, ticket, fun, loader, language, lanKorCondition, adminBankDetail } = props
    let userId = user?._id
    const [price, setPrice] = useState(0)
    const [loading, setLoading] = useState(false)
    const [isModalOpen2, setIsModalOpen2] = useState(false)
    const [index, setIndex] = useState(null)
    const [auctionData, setAuctionData] = useState({})
    console.log('sale data', auction)
    return (
        <div className='ticket_share_panels'>
            <div className='container_tick_share'>
                <div className='layout_tick_share'>
                    <div className='col_tick_share col_main_tick_share'>
                        <div className='left_panel'>
                            <h2 className='col_primary'>{language?.SaleAuction}</h2>
                        </div>
                    </div>
                    <div className='col_tick_share col_complementary_tick_share' >
                        {
                            user?._id ?
                                <SellTheTicket language={language} {...props} ticket={ticket} /> :
                                // <Button className='upload_sharing_btn' onClick={() => loginAlert(language)}>
                                //     {language?.RegisterAuctionPost}
                                // </Button>
                                <LoginAlert clas={'upload_sharing_btn'} buttonName={language?.RegisterAuctionPost} {...props} />

                        }
                    </div>
                </div>
            </div>
            <AuctionModal apiRoute={POST.CREATE_AUCTION_SALE_BID} setLoading={setLoading} loading={loading} v={auctionData} i={index} isModalOpen2={isModalOpen2} setIsModalOpen2={setIsModalOpen2} {...props} />

            <div className='row_new_cont'>
                {!loader ?
                    auction?.length ?
                        auction?.map((v, i) => {
                            return (
                                <div key={i} className='column_new_cont' >
                                    <div className='card_panel'>
                                        <h5>{language?.GolfClubName}</h5>
                                        <h5 className='not_bold'>{v?.ticketId?.golfClub?.clubName ? v?.ticketId?.golfClub?.clubName : '-------'}</h5>
                                    </div>
                                    <div className='card_panel'>
                                        <h5>{language?.Type}</h5>
                                        <h5 className='not_bold'>
                                            {lanKorCondition ? v?.ticketId?.membershipType :
                                                typesKr[typesOnlyKr?.indexOf(v?.ticketId?.membershipType)]?.typesKr ?
                                                    typesKr[typesOnlyKr?.indexOf(v?.ticketId?.membershipType)]?.typesKr :
                                                    typesKr[types?.indexOf(v?.ticketId?.membershipType)]?.typesKr
                                            }
                                        </h5>
                                    </div>
                                    <div className='card_panel'>
                                        <h5>{language?.MemberFee}</h5>
                                        <h5 className='not_bold'>{v?.ticketId?.memberFee ? v?.ticketId?.memberFee + language?.Won : '----'}</h5>
                                    </div>
                                    <div className='card_panel'>
                                        <h5>{language?.NonMemberFee}</h5>
                                        <h5 className='not_bold'>{v?.ticketId?.NONmemberFee ? v?.ticketId?.NONmemberFee + language?.Won : '----'}</h5>
                                    </div>
                                    <div className='card_panel'>
                                        <h5>{language?.JoiningFee}</h5>
                                        <h5 className='not_bold'>{v?.advanceFee + language?.Won}</h5>
                                    </div>

                                    <div className='card_panel'>
                                        <h5><HiPlusCircle className='plus_icon' />{language?.RightPrice}</h5>
                                        <h5 className='not_bold'>{v?.price ? v?.price + language?.Won : '---'}</h5>
                                    </div>
                                    <div className='card_panel '>
                                        <h5><HiPlusCircle className='plus_icon' />{language?.ReservationDate}</h5>
                                        <h5 className='not_bold'>{v?.ticketId?.joiningDate?.date1 ? moment(v?.ticketId?.joiningDate?.date1).format('YYYY-MM-DD') : '----'}</h5>

                                    </div>
                                    <div className='card_panel last_des'>
                                        <h5><HiPlusCircle className='plus_icon' />{language?.ExpirationDate}</h5>
                                        <h5 className='not_bold'>{v?.ticketId?.joiningDate?.date2 ? moment(v?.ticketId?.joiningDate?.date2).format('YYYY-MM-DD') : '----'}</h5>

                                    </div>
                                    <div className='card_panel last_des'>
                                        <h5><HiPlusCircle className='plus_icon' />{language?.SpecialGiftTIKSALE}</h5>
                                    </div>
                                    <div className='card_panel last_des'>
                                        {lanKorCondition ?
                                            <h5 className='not_bold'> Weekday {v && v?.ticketId?.specialGiftWeekDay}  회/  {v && v?.ticketId?.specialGiftWeekDayKRW} KRW</h5>
                                            :
                                            <h5 className='not_bold'>주중{v && v?.ticketId?.specialGiftWeekDay}회/ {v && v?.ticketId?.specialGiftWeekDayKRW}만원</h5>
                                        }
                                    </div>
                                    <div className='card_panel last_des' style={{ textAlign: 'left' }}>
                                        {lanKorCondition ?
                                            <h5 className='not_bold'> Weekend {v && v?.ticketId?.specialGiftWeekEnd}  회/{v && v?.ticketId?.specialGiftWeekEndKRW}KRW</h5>
                                            :
                                            <h5 className='not_bold'> 주말  {v && v?.ticketId?.specialGiftWeekEnd}회/{v && v?.ticketId?.specialGiftWeekEndKRW}만원</h5>
                                        }
                                    </div>
                                    <div className='btn_card'>
                                        {user?._id ?
                                            user?._id && user?.telNo && user?.bank && user?.bankAccountNumber ?
                                                <Button key={i}
                                                    // loading={indexNum == i ? loading : false}
                                                    onClick={() => { setIsModalOpen2(true); setIndex(i); setAuctionData(v) }}
                                                    className='partcipation-auction-btn'
                                                    style={user?._id === v?.userId?._id ? { opacity: 0.5 } : null}
                                                    disabled={user?._id === v?.userId?._id}
                                                >
                                                    {user?._id === v?.userId?._id ? language?.MyTicket : language?.Apply}
                                                </Button> :
                                                <Button onClick={() => MyInfoAlert(language)} className='partcipation-auction-btn' >
                                                    {user?._id === v?.userId?._id ? language?.MyTicket : language?.Apply}
                                                </Button> :
                                            <LoginAlert clas={'partcipation-auction-btn'} buttonName={user?._id === v?.userId?._id ? language?.MyTicket : language?.Apply} {...props} />

                                        }
                                    </div>
                                </div>
                            )
                        }) :

                        <div className='NodataDiv'>
                            <img src={noData} className='NodataImg' />
                            <h3>{language?.AuctionsNotFound}</h3>
                        </div> :
                    <div style={{ width: '100%', textAlign: 'center', display: 'flex' }}>
                        <Card className='column_new_cont' loading={true} />
                        <Card className='column_new_cont' loading={true} />
                        <Card className='column_new_cont' loading={true} />
                        <Card className='column_new_cont' loading={true} />
                    </div>}

            </div>
        </div>
    )
}

export default SaleAuction