import React, { useState, useEffect, useRef } from 'react'
import { Button, Tabs } from 'antd'
import { } from '@ant-design/icons'
import SharedRentalAuctionTab from './SharedRentalAuction'
import GolfReservationAuctionMyPage from './GolfReservationTab'
import axios from 'axios'
import { GET } from '../../utils/apis'
import { errorMessage, successMessage } from '../../utils/helpers'
import SaleAuction from '../TicketShareTabs/SaleAuction'
import SaleRentalAuction from './SaleRentalAuction'
import ManageRental from './ManageRental'
import ManageSale from './ManageSale'
import ManageReservation from './ManageReservation'
const { TabPane } = Tabs

const AuctionTab = (props) => {
    let { user, language, lanKorCondition } = props

    let userId = user?._id
    const [auction, setAuction] = useState([])
    const [rentalData, setRentalData] = useState([])
    const [manageRentalData, setManageRentalData] = useState([])
    const [manageSaleData, setManageSaleData] = useState([])
    const [manageReservationData, setManageReservationData] = useState([])
    const [saleData, setSaleData] = useState([])
    const [manageData, setManageData] = useState(false)
    const [loading, setLoading] = useState(false)
    const [reservationData, setReservationData] = useState([])

    let getAuction = () => {
        setLoading(true)
        axios.get(`${GET.GET_USERS_PARTICIPATION_AUCTIONS}/${userId}`)
            .then((res) => {

                const { data } = res
                if (data.success) {
                    setReservationData(data?.golfRes)
                    setSaleData(data?.saleAuction)
                    setRentalData(data?.renetalAuction)
                }
                else {
                    console.log('')
                    console.log('runing', data)
                }
                setLoading(false)

            })
            .catch((e) => {
                console.log('err', e)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    let getManageAuction = () => {
        setLoading(true)

        axios.get(`${GET.GET_ALL_MANAGE_RENTAL_AUCTION}/${userId}`)
            .then((res) => {

                const { data } = res
                if (data.success) {
                    setManageRentalData(data?.data)
                }
                else {
                    console.log('')
                    console.log('runing', data)
                }
                setLoading(false
                )

            })
            .catch((e) => {
                setLoading(false)
                console.log('err', e)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })

        axios.get(`${GET.GET_ALL_MANAGE_SALE_AUCTION}/${userId}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setManageSaleData(data?.data)
                }
                else {
                    console.log('')
                    console.log('runing', data)
                }
                setLoading(false)
            })
            .catch((e) => {
                console.log('err', e)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
                setLoading(false)
            })

        axios.get(`${GET.GET_ALL_MANAGE_RESERVATION_AUCTION}/${userId}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setManageReservationData(data?.data)
                }
                else {
                    console.log('runing', data)
                }
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                console.log('err', e)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    useEffect(() => {
        getAuction()
        getManageAuction()
    }, [])

    const onChangeTab = (key) => {
        console.log(key)
    }

    return (
        <div className='my_page_cont'>
            <div className='tab_con_auction_child_main'>
                <div className='myPageAuctionBtn' style={{ display: 'flex', justifyContent: 'space-around', padding: 20 }}>
                    <h2>
                        {language?.MyAuctionParticipationList}
                    </h2>
                    <div style={{ textAlign: 'ends' }}>
                        <Button onClick={() => setManageData(!manageData)} className='upload_sharing_btn'>
                            {language?.ManageMyAuctionPosts}
                        </Button>
                    </div>
                </div>

                <Tabs tabBarGutter={20} className='tab_menu_auc_child tab_con_auction_child_tab tab_con_auction_child' defaultActiveKey='1' onChange={onChangeTab} centered={true}>
                    <TabPane className='pri_color' tab={language?.SharedRentalAuction} key='1'>
                        {!manageData ?
                            <SharedRentalAuctionTab getAuction={getAuction} lanKorCondition={lanKorCondition} language={language} loading={loading}    {...props} auction={rentalData} />
                            : <ManageRental loading={loading} language={language} {...props} getManageAuction={getManageAuction} auction={manageRentalData} />
                        }
                    </TabPane>
                    <TabPane tab={language?.SaleAuction} key='2'>
                        {!manageData ?
                            <SaleRentalAuction getAuction={getAuction} loading={loading}  {...props} language={language} auction={saleData} />
                            : <ManageSale {...props} loading={loading} language={language} getManageAuction={getManageAuction} auction={manageSaleData} />
                        }
                    </TabPane>
                    <TabPane tab={language?.GolfReservationAuction} key='3'>
                        {!manageData ?
                            <GolfReservationAuctionMyPage getAuction={getAuction} loading={loading} {...props} language={language} auction={reservationData} />
                            : <ManageReservation {...props} loading={loading} language={language} getManageAuction={getManageAuction} auction={manageReservationData} />
                        }
                    </TabPane>
                </Tabs>


            </div >
        </div >
    )
}


export default AuctionTab