import { SELL_TYPE } from '../types'

const initialTransactionType = {
    sellType: {
        transactionType: []
    }
}

const reducer = (state = initialTransactionType, action) => {
    switch (action.type) {
        case SELL_TYPE: {
            return { ...state, sellType: action.sellType }
        }
        default: {
            return state
        }
    }
}

export default reducer
