import { Button, Form, Input, Radio, Tabs, Typography, Modal } from 'antd'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import FacebookLogin from 'react-facebook-login'
import { GoogleLogin } from 'react-google-login'
import { AiFillGooglePlusCircle } from 'react-icons/ai'
import { BsFacebook } from 'react-icons/bs'
import { SiNaver } from 'react-icons/si'
import KakaoLogin from 'react-kakao-login'
import NaverLogin from 'react-naver-login'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import naver from '../../assets/Never.jpeg'
import { loginUser } from '../../Redux/actions/authActions'
import { AUTH, POST } from '../../utils/apis'
import { allPaths, googleClientId, kakaoToken, passMID } from '../../utils/constants'
import { errorMessage, facebookLogin, googleLogin, isUserLoginApi, kakaoLogin, naverLogin, requiredMessage, successNotification } from '../../utils/helpers'
import Header from '../../Screens/Home/Header'


let origin = `https://securetech.vmvm.co.kr/`
if (process.env.NODE_ENV === 'development') {
    origin = `http://localhost:3000/login`
}

let passVerOrigin = `https://securetech.vmvm.co.kr`
if (process.env.NODE_ENV === 'development') {
    passVerOrigin = `http://localhost:3000`
}

const { Title } = Typography

const { Search } = Input
const { TabPane } = Tabs



const LoginAlert = (props) => {
    let { clas,history, buttonName, language, lanKorCondition } = props

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    }
    const handleOk = () => {
        setIsModalOpen(false);
    }
    const handleCancel = () => {
        setIsModalOpen(false);
    }

    const dispatch = useDispatch()
    const [tags, setTags] = useState(['Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag',])
    const [inputVisible, setInputVisible] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [editInputIndex, setEditInputIndex] = useState(-1)
    const [spin, setSpin] = useState(false)
    const [editInputValue, setEditInputValue] = useState('')
    const [login, setLogin] = useState(false)
    const [data, setData] = useState({})
    const [picture, setPicture] = useState('')
    const [loading, setLoading] = useState(false)
    const inputRef = useRef(null)
    const editInputRef = useRef(null)
    const formData = useRef(null)
    const [instaId, setInstaId] = useState(null)
    const path = history?.location?.pathname
    const [pathType, setPathType] = useState('individual-signup')
    const [logout, setLogout] = useState(true)
    const [value, setValue] = useState(1)

    const onChange = (e) => {
        setValue(e.target.value);
    };
    const accessTokenNaver = new URLSearchParams(location.search).get('access_token')

    useEffect(() => {
        if (instaId) {
            const domain = 'https://kssa.inicis.com'
            formData.current.action = `${domain}/init`
            formData.current.method = 'get'
            formData.current.submit()
        }
    }, [instaId])

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus()
        }
    }, [inputVisible])

    useEffect(() => {
        if (accessTokenNaver) {
            const headers = {
                headers: {
                    Authorization:
                        "Bearer" + `${accessTokenNaver}`,
                },
            }
            axios.post(`https://openapi.naver.com/v1/nid/me`, headers)
                .then(async (res) => {
                    const { data } = res
                    if (data.success) {
                        setSpin(true)
                        const success = await naverLogin(data, history, loginUser, dispatch)
                        if (!success) {
                            setSpin(false)
                        }
                    }
                    else {
                        errorMessage(data?.message)
                    }
                }).catch((err) => {
                    console.log('err', err)
                    errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
                })
        }
    }, [])

    useEffect(() => {
        editInputRef.current?.focus()
    }, [inputValue])

    const responseFacebook = async (response) => {
        setData(response)
        setPicture(response?.picture?.data?.url)
        const success = await facebookLogin(response, history, loginUser, dispatch)

        if (!success) {
            setSpin(false)
        }
    }

    const callPassVerificationAuth = (path) => {
        setPathType(path)

        let obj = {
            successUrl: `${passVerOrigin}${AUTH.GET_PASS_VERIFICATION_RES}`
        }

        axios.post(POST.PASS_VERIFICATION, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    let result = data?.result
                    var parser = new DOMParser()
                    var doc = parser.parseFromString(result, 'text/html')
                    setInstaId(doc?.querySelector('#txId')?.value)
                }
                else {
                    errorMessage()
                }
            })
            .catch((e) => console.log('e', e))
    }

    const responseGoogle = (response) => {
    }

    const updateGoogleLogin = async (e) => {
        setSpin(true)
        const success = await googleLogin(e, history, loginUser, dispatch)

        if (!success) {
            setSpin(false)
        }
    }

    const kakaoLoginController = async (result) => {
        setSpin(true)
        const success = await kakaoLogin(result, history, loginUser, dispatch)
        if (!success) {
            setSpin(false)
        }
    }
    const naverLoginController = async (result) => {
        setSpin(true)
        const success = await naverLogin(result, history, loginUser, dispatch)
        if (!success) {
            setSpin(false)
        }
    }

    return (
        <>
            <Button className={clas} onClick={showModal}>
                {buttonName}
            </Button>
            <Modal open={isModalOpen} onOk={handleOk} footer={null} onCancel={handleCancel}>
                <p style={{ textAlign: 'center' }}>{lanKorCondition ? "This is User's only Service" : '회원이용서비스'}</p>
                <br />
                <br />
                <h2 style={{ textAlign: 'center' ,fontWeight:900}}>{lanKorCondition ? "User only Service" : '회원이용 서비스 입니다'}</h2>
                <h3 style={{ textAlign: 'center',fontWeight:900 }}>{lanKorCondition ? "Please Signup or Login" : '회원가입 또는 로그인 후 이용해 주세요'}</h3>
                <p>{lanKorCondition ? "SNS Icons" : 'SNS 간편로그인'}</p>

                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <NaverLogin
                            clientId='s9MxxPSvVwV4lLxLSjO7'
                            callbackUrl={origin}
                            onSuccess={naverLoginController}
                            onFailure={(res) => console.log("error N", res)}
                            style={{ display: 'inline', padding: '0px', display: 'none', backgroundColor: 'transparent', borderRadius: 'none' }}
                            render={(props) =>
                                <SiNaver className='icon_socials  col_naver' style={{ cursor: 'pointer' }} onClick={() => { props?.onClick() }} />
                            }
                        />

                        <FacebookLogin
                            textButton={false}
                            appId='625806318922570'
                            autoLoad={false}
                            fields='name,email,picture'
                            scope='public_profile,user_friends'
                            callback={responseFacebook}
                            icon={<BsFacebook className='icon_socials  col_fb' />}
                            style={{ display: 'inline', padding: '0px', display: 'none', backgroundColor: 'transparent', borderRadius: 'none' }}
                            render={renderProps => (
                                <button onClick={renderProps.onClick}><BsFacebook className='icon_socials  col_fb' /></button>
                            )}
                        />
                        <GoogleLogin
                            clientId={googleClientId}
                            className='googleBtn'
                            onSuccess={updateGoogleLogin}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                            style={{ display: 'inline', padding: '0px', display: 'none' }}
                            // style={{ display: 'inline', padding: '0px', display: 'none', backgroundColor: 'transparent', borderRadius: 'none' }}
                            render={renderProps => (
                                <a onClick={renderProps.onClick} ><AiFillGooglePlusCircle className='col_google' /></a>
                            )}
                        />
                        <KakaoLogin
                            token={kakaoToken}
                            // onSuccess={console.log}
                            onSuccess={kakaoLoginController}
                            onFail={responseGoogle}
                            onLogout={console.info}
                            render={({ onClick }) => {
                                return (
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault()
                                            onClick()
                                        }}
                                        style={{ backgroundColor: 'white', border: 'none', cursor: 'pointer', marginLeft: '5px' }}
                                    >
                                        <img src={naver} className='color_kakao' style={{ width: '4.4vw', borderRadius: '100%' }} />
                                    </button>
                                )
                            }}
                        />
                    </div>
                </div>

                <Button onClick={() => history?.replace(allPaths?.LOGIN)} className='req_exc_btn' type="primary">{lanKorCondition ? 'Login' : '로그인하기'}</Button>
                <br />
                <br />
                <div className='row_golf2'>
                    <Button onClick={() => history?.replace(allPaths?.INDIVIDUAL_SIGNUP)} className='req_exc_btn2' type="primary">{lanKorCondition ? 'Individual Signup' : '일반 회원가입'}</Button>
                    <Button onClick={() => history?.replace(allPaths?.CORPORATE_SIGNUP)} className='req_exc_btn2' type="primary">{lanKorCondition ? 'Corporate Signup' : '법인 회원가입'}</Button>
                </div>
            </Modal>
        </>
    );
};
export default LoginAlert;