import React, { useState } from 'react';
import { Button, Modal, Select, Input, DatePicker } from 'antd';
import { HiPlusCircle, HiCheckCircle } from "react-icons/hi";
import { RiCheckboxBlankFill } from "react-icons/ri";
import { CheckCircleOutlined } from '@ant-design/icons'

const { TextArea } = Input;
const ChatModel = (props) => {
    const { language, } = props
    const options = [{
        eng: '-From low to high (based on current price)',
        kr: ' 저가순 '
    },
    {
        eng: '-From high to low (based on current price)',
        kr: '고가순'
    },
    {
        eng: 'Latest (based on current price)',
        kr: '최신순'
    },
    {
        eng: 'Order of number of Transactions',
        kr: '거래건이 많은 순'
    }]
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Content of the modal');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = () => {
        setModalText('The modal will be closed after two seconds');
        setConfirmLoading(true);
        setIsModalOpen(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
        }, 2000);
    };
    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };

    const onChange = (value) => {
        console.log(`selected ${value}`);
    };

    const onSearch = (value) => {
        console.log('search:', value);
    };
    const onChangeDate = (date, dateString) => {
        console.log(date, dateString);
    };

    const showModalMessage = () => {
        setIsModalOpen(true);
    };

    const handleCancelMessage = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <Button className='btn_chat_golf' onClick={showModal}>
                1:1 {language?.Chat}
            </Button>
            <Modal
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                footer={[<Button className='req_exc_btn' type="primary" onClick={handleOk}>{language?.RequestExchange}</Button>]}
                width={800}
            >
                <div >
                    <div>
                        <h1 style={{ textAlign: 'center' }}>{language?.LetExchange}</h1>
                        <h3 style={{ textAlign: 'center', color: '#2196f3' }}>{language?.PlzFillBlack}</h3>
                    </div>
                    <div className='main_modal_con'>
                        <div className='modal_first_panel'>
                            <div className="column-1 box">
                                <h4>{language?.GolfClubName}</h4>
                            </div>
                            <div className="column-2 box">
                                <p>East Valley CC</p>
                            </div>
                        </div>
                        <div className='modal_first_panel'>
                            <div className="column-1 box">
                                <h4>{language?.Type}</h4>
                            </div>
                            <div className="column-2 box">
                                <p>Company</p>
                            </div>
                        </div>
                        <div className='modal_first_panel'>
                            <div className="column-1 box">
                                <h4>{language?.MemberFee}/{language?.NonMemberFee}</h4>
                            </div>
                            <div className="column-2 box">
                                <p>Member Fee 5 million won/ Non-Member Fee 30 millio won</p>
                            </div>
                        </div>
                        <div className='modal_first_panel'>
                            <div className="column-1 box">
                                <h4>{language?.RightPrice}</h4>
                            </div>
                            <div className="column-2 box">
                                <p>80 million won</p>
                            </div>
                        </div>
                        <div className='modal_first_panel'>
                            <div className="column-1 box">
                                <h4>{language?.ReservedDate}</h4>
                            </div>
                            <div className="column-2 box">
                                <p>2022.4.22 | 13:30</p>
                            </div>
                        </div>
                        <div className='modal_first_panel'>
                            <div className="column-1 box">
                                <h4>{language?.ExpirationDate}</h4>
                            </div>
                            <div className="column-2 box">
                                <p>2022.4.22</p>
                            </div>
                        </div>
                    </div>
                    <div className='second_panel'>
                        <div className='modal_second_panel'>
                            <div className="column-1 box">
                                <h4 className='font_modal'>{language?.Membership}</h4>
                            </div>
                            <div className="column-2 box">
                                <Select
                                    style={{ width: 400 }}
                                    showSearch
                                    placeholder={language?.ChooseYourTicket}
                                    optionFilterProp="children"
                                    onChange={onChange}
                                    onSearch={onSearch}
                                    filterOption={(input, option) => option.children.toLowerCase()?.includes(input?.toLowerCase())}
                                >
                                    {
                                        options?.map((v, i) => {
                                            return <Option value={v?.eng}>{language?.login === "Login" ? v?.eng : v?.kr}</Option>
                                        })

                                    }
                                </Select>
                            </div>
                        </div>
                        <div className='modal_second_panel'>
                            <div className="column-1 box">
                                <h4 className='font_modal'><HiPlusCircle className='plus_icon' />{language?.RightPrice}</h4>
                            </div>
                            <div className="column-2 box">
                                <Input style={{ width: 400 }} suffix="RMB" />
                            </div>
                        </div>
                        <div className='modal_second_panel'>
                            <div className="column-1 box">
                                <h4 className='font_modal'><HiPlusCircle className='plus_icon' />{language?.UsableDate}</h4>
                            </div>
                            <div className="column-2 box">
                                <DatePicker style={{ width: 400, textAlign: 'center' }} onChange={onChangeDate} />
                            </div>
                        </div>
                        <div className='modal_second_panel'>
                            <div className="column-1 box">
                                <h4 className='font_modal'><HiPlusCircle className='plus_icon' />{language?.ExpirationDate}</h4>
                            </div>
                            <div className="column-2 box">
                                <DatePicker style={{ width: 400 }} onChange={onChangeDate} />
                            </div>
                        </div>
                        <div className='modal_second_panel'>
                            <div className="column-1 box">
                                <h4 className='font_modal'><HiPlusCircle className='plus_icon' />{language?.LocationPreference}</h4>
                            </div>
                            <div className="column-2 box">

                            </div>
                        </div>
                        <div className="items">
                            <div className="item"><Button type="primary">{language?.SeoulGyeonggi} {language?.North}</Button></div>
                            <div className="item"><Button type="primary">{language?.SeoulGyeonggi} {language?.South}</Button></div>
                            <div className="item"><Button type="primary">{language?.SeoulGyeonggi} {language?.West}</Button></div>
                            <div className="item"><Button type="primary">{language?.SeoulGyeonggi} {language?.East}</Button></div>
                            <div className="item"><Button type="primary">{language?.Chungbuk}</Button></div>
                        </div>
                        <div>
                            <h4 className='font_modal'>{language?.TermCondition}<RiCheckboxBlankFill className='' style={{ verticalAlign: 'middle' }} /></h4>
                            <TextArea className='text_area' rows={4} />
                        </div>
                        <>
                            <Modal open={isModalOpen} onCancel={handleCancelMessage} footer={null}>
                                <h2 style={{ textAlign: 'center' }}>{language?.Completion}</h2>
                                <center><HiCheckCircle className='tick_icon' /></center>
                                <h4 style={{ textAlign: 'center' }}>
                                    {language?.ExchangeRentRequest}
                                </h4>
                                <h4 style={{ textAlign: 'center' }}>
                                    {language?.PleaseWaitFor}
                                </h4>
                            </Modal>
                        </>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ChatModel;