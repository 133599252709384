import { LOGIN_USER, REMOVE_USER, TEMP_USER } from '../types'

const loginUser = (user) => {
    return {
        type: LOGIN_USER,
        user
    }
}

const removeUser = () => {
    return {
        type: REMOVE_USER
    }
}

const tempUsers = (tempUser) => {
    return {
        type: TEMP_USER,
        tempUser
    }
}

export {
    loginUser,
    removeUser,
    tempUsers
}