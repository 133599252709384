import { LANGUAGE } from '../types'

const reducer = (state = { language: 'korean' }, action) => {
    switch (action.type) {
        case LANGUAGE: {
            return { ...state, language: action?.language }
        }
        default: {
            return state
        }
    }
}

export default reducer