import { SELECTED_CHAT, PIN, TICKET, CHAT_HEADER, PRICE_FILTER,SELECTED_CHAT_HEAD } from '../types'

const setPin = (pin) => {
    return {
        type: PIN,
        pin
    }
}
const setTicket = (ticket) => {
    return {
        type: TICKET,
        ticket
    }
}
const setSelectedChat = (selectedChat) => {
    return {
        type: SELECTED_CHAT,
        selectedChat
    }
}
const setSelectedChatHead = (selectedChatHead) => {
    return {
        type: SELECTED_CHAT_HEAD,
        selectedChatHead
    }
}

const setChatHeader = (chatHeader) => {
    return {
        type: CHAT_HEADER,
        chatHeader
    }
}


export {
    setPin,
    setTicket,
    setSelectedChat,
    setChatHeader,
    setSelectedChatHead
}