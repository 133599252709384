import React, { useState } from 'react'
import { Button, Modal, Select, Input, DatePicker, Form } from 'antd'
import { allPaths, regions, types, typesKr, typesOnlyKr } from '../../utils/constants'
import moment from 'moment'
import { errorMessage, MyInfoAlert, successMessage } from '../../utils/helpers'
import { POST } from '../../utils/apis'
import axios from 'axios'
import { HiCheckCircle } from 'react-icons/hi'
import { useHistory } from 'react-router-dom'

const { TextArea } = Input
const GroupBuyParticipate = (props) => {
    const { user, participateData, language, lanKorCondition } = props
    const [form] = Form.useForm()
    const history = useHistory()

    const options = [
        {
            eng: '-From low to high (based on current price)',
            kr: ' 저가순 '
        },
        {
            eng: '-From high to low (based on current price)',
            kr: '고가순'
        },
        {
            eng: 'Latest (based on current price)',
            kr: '최신순'
        },
        {
            eng: 'Order of number of Transactions',
            kr: '거래건이 많은 순'
        }]
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [modalText, setModalText] = useState('Content of the modal')
    const [isModalOpen, setIsModalOpen] = useState(false)

    let seatsAvailable = _.range(1 + participateData?.totalSeats - participateData?.usedSeats?.length)
    let seatPrice = participateData?.totalSeatsPrice / participateData?.totalSeats

    const showModal = () => {
        setOpen(true)
    }
    const handleOk = () => {
        setModalText('The modal will be closed after two seconds')
        setConfirmLoading(true)
        setIsModalOpen(true)
        setTimeout(() => {
            setOpen(false)
            setConfirmLoading(false)
        }, 2000)
    }
    const handleCancel = () => {
        setOpen(false)
        setOpen2(false)
    }

    const openChat = (e) => {
        // let receiverId = e?.userId?._id || e?.userId
        // let chatType = 'participateRequest'
        // e.chatType = chatType
        // history.push({
        //     pathname: '/chat',
        //     search: `?userId=${receiverId}&ticketId=${e?._id}&chatType=${chatType}`,
        //     state: e
        // })
        window.open('http://pf.kakao.com/_xdxebxjxj/chat', '_blank')

    }

    const onFinish = (fieldsValue) => {
        setLoading(true)

        let obj = {
            userId: participateData?.userId?._id,
            ownerAdminId: participateData?.adminId,
            buyerId: user?._id,
            groupBuyId: participateData?._id,
            totalSeats: fieldsValue.totalSeats,
            totalPrice: fieldsValue.totalSeats * seatPrice,
            groupBuyType: participateData?.type
        }


        // console.log('fieldsValue', fieldsValue)
        console.log('obj', obj)

        axios.post(POST.PARTICIPATE_GROUP_BUY, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    // setIsModalOpen(false)
                    setOpen(false)
                    setOpen2(true)
                    form.resetFields()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }
    return (
        <>{user?._id && user?.telNo && user?.bank && user?.bankAccountNumber ?
            <Button className='btn_participate' /* disabled={participateData?.userId?._id === user?._id} */ style={/* participateData?.userId?._id === user?._id ? { width: '100%', opacity: 0.3 } :  */{ width: '100%' }} onClick={showModal}>
                {language?.Participate}
            </Button> :
            <Button className='btn_participate' /* disabled={participateData?.userId?._id === user?._id} */ style={/* participateData?.userId?._id === user?._id ? { width: '100%', opacity: 0.3 } :  */{ width: '100%' }} onClick={() => { MyInfoAlert(language) }}>
                {language?.Participate}
            </Button>}
            <Modal
                confirmLoading={confirmLoading}
                open={open}
                onCancel={handleCancel}
                footer={null}
                // width={500}
                // style={{ maxWidth: 500 }}

                style={{ top: '10px', }}
            >
                <div
                    className='scroll_class'
                    style={{
                        height: 'auto',
                        width: '100%',
                        maxHeight: '85vh',
                        overflowY: 'scroll',
                        marginTop: '12px'
                    }}>
                    <div className='modal_main_div' >
                        <center>
                            <h2>{language?.GroupBuyApplication}</h2>
                        </center>
                        <br />
                        <label style={{ fontWeight: 600 }}>{language?.MembershipWishTo}</label>
                        <div className='main_modal_con' style={{ padding: 10 }}>
                            <div className='modal_first_panel purchaseDir'>
                                <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                    <h4>{language?.GolfClubName}</h4>
                                </div>
                                <div className='column-2 box'>
                                    <p>{participateData?.golfClub?.clubName}</p>
                                </div>
                            </div>
                            <div className='modal_first_panel purchaseDir'>
                                <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                    <h4>{language?.Type}</h4>
                                </div>
                                <div className='column-2 box'>
                                    <p> {lanKorCondition ? participateData?.membershipType :
                                        typesKr[typesOnlyKr?.indexOf(participateData?.membershipType)]?.typesKr ?
                                            typesKr[typesOnlyKr?.indexOf(participateData?.membershipType)]?.typesKr :
                                            typesKr[types?.indexOf(participateData?.membershipType)]?.typesKr
                                    }</p>
                                </div>
                            </div>
                            <div className='modal_first_panel purchaseDir'>
                                <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                    <h4>{language?.JoiningFee}</h4>
                                </div>
                                <div className='column-2 box'>
                                    <p>{participateData?.joiningFee}{' ' + language?.Won}</p>
                                </div>
                            </div>
                            <div className='modal_first_panel purchaseDir'>
                                <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                    <h4>{language?.JoiningDate}</h4>
                                </div>
                                <div className='column-2 box'>
                                    <p>{`${moment(participateData && participateData?.joiningDate?.date1).format('YYYY-MM-DD')} ~ ${moment(participateData && participateData?.joiningDate?.date2).format('YYYY-MM-DD')}`}</p>
                                </div>
                            </div>
                            <div className='modal_first_panel purchaseDir'>
                                <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                    <h4>{language?.TotalSeatsAvailable}</h4>
                                </div>
                                <div className='column-2 box'>
                                    <p>{participateData?.totalSeats - participateData?.usedSeats?.length}{' ' + language?.Account}</p>
                                </div>
                            </div>
                            <div className='modal_first_panel purchaseDir'>
                                <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                    <h4>{language?.SeatPrice}</h4>
                                </div>
                                <div className='column-2 box'>
                                    <p>{participateData?.totalSeatsPrice / participateData?.totalSeats}{' ' + language?.Won}</p>
                                </div>
                            </div>

                            <div className='modal_first_panel purchaseDir'>
                                <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                    <h4>{language?.SpecialGift}</h4>
                                </div>
                                {lanKorCondition ? <div className='column-2 box'>
                                    {participateData?.specialGiftWeekDay} times a week (2 times)/ KRW {participateData?.specialGiftWeekDayKRW}
                                    <br />
                                    Weekend {participateData?.specialGiftWeekEnd} times (2 times)/{participateData?.specialGiftWeekEndKRW}
                                </div> :
                                    <div className='column-2 box'>
                                        주중{participateData?.specialGiftWeekDay}회(2회)/ {participateData?.specialGiftWeekDayKRW}만원
                                        <br />
                                        주말  {participateData?.specialGiftWeekEnd} 회 (2회) /{participateData?.specialGiftWeekEndKRW}만원
                                    </div>
                                }

                            </div>

                        </div>
                        <br />
                        <Form requiredMark={false} form={form} name='time_related_controls' onFinish={onFinish}>
                            <label>{language?.ChooseOption}</label>
                            <Form.Item
                                name='totalSeats'
                                rules={[
                                    {
                                        required: true,
                                        message: language?.PleaseSelectNumberSeats,
                                    },
                                ]}
                            >
                                <Select placeholder={language?.PleaseSelectNumberSeats}>
                                    {seatsAvailable?.slice(1).map((v, i) => (<Option value={v}>{v} {language?.Account} {v * seatPrice} {language?.KRW} </Option>))}
                                </Select>
                            </Form.Item>
                            <Button loading={loading} className='req_exc_btn' type='primary' htmlType='submit'>{language?.IWantParticipateInGroup}</Button>
                        </Form>
                    </div>
                </div>
            </Modal>
            <Modal
                // open={open}
                // onCancel={handleCancel}
                open={open2}
                onCancel={handleCancel}
                footer={[<div className='message_modal'><Button className='message_modal_btn' type='primary'
                    onClick={() => {
                        history.push({
                            pathname: allPaths?.MYPAGE,
                            search: `?membershipTransaction`
                        })
                    }}>{lanKorCondition ? 'Go To My Page' : language?.GoTicketSalesRight}</Button>
                    <Button
                        className='message_modal_btn marginLeft'
                        type='primary'
                        onClick={() => openChat(participateData)}
                    >{lanKorCondition ? 'Chat Bot' : language?.TalkToUser}</Button>
                </div>]}
            >
                <h2 style={{ textAlign: 'center' }}>{language?.GroupBuy}</h2>
                <center><HiCheckCircle className='tick_icon' /></center>
                <div style={{ padding: '20px' }}>

                    <h4 style={{ textAlign: 'center' }}>
                        {language?.GroupBuyDeliveredUser}
                    </h4>
                    <h4 style={{ textAlign: 'center' }}>
                        {language?.PleaseWaitFor}
                    </h4>
                </div>
            </Modal>
        </>
    )
}

export default GroupBuyParticipate