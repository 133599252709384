import React, { useEffect, useState } from 'react'
import { Map, MapMarker } from 'react-kakao-maps-sdk'
import { useLocation } from 'react-router-dom'

const AppMap = ({ history }) => {
    const [state, setstate] = useState({
        lat: null,
        lng: null
    })
    let latitude
    let longitude
    useEffect(() => {
        latitude = new URLSearchParams(history.location.search).get('lat')
        longitude = new URLSearchParams(history.location.search).get('log')
        setstate({
            lat: latitude,
            lng: longitude
        })
        console.log(longitude, latitude, "===latlon", history)
    }, [latitude, longitude])

    return (
        <>
            {
                state?.lat && state?.lng ?
                    <h5 className='' style={{ marginTop: 10 }}>
                        <div id="map" ></div>
                        <Map
                            center={{ lat: state?.lat, lng: state.lng }}
                            style={{ width: "100%", height: "1000px" }}
                        >
                            <MapMarker position={{ lat: state?.lat, lng: state?.lng }}>
                            </MapMarker>
                        </Map>
                    </h5>
                    : <h1>Map Loading Error</h1>
            }
        </>
    )
}

export default AppMap