import React, { useState, useEffect } from 'react'
import { Button, Card, Input, Modal, Form } from 'antd'
import { HiPlusCircle } from 'react-icons/hi'
import AuctionPostModal from './AuctionPostModal'
import axios from 'axios'
import { GET, POST } from '../../utils/apis'
import { errorMessage, loginAlert, MyInfoAlert, successMessage } from '../../utils/helpers'
import SharedRentalAuctionModal from '../MyPageTabs/SharedRentalAuctionModal'
import moment from 'moment-timezone'
import noData from '../../assets/noData.gif'
import loadingImg from '../../assets/loading.gif'
import { IoIosAlert } from 'react-icons/io'
import { LoginAlert } from '..'
import AuctionModal from './AuctionModal'
import { types, typesKr, typesOnlyKr } from '../../utils/constants'

const SharedRentalAuction = (props) => {
    let { user, auction, ticket, fun, loader, language, lanKorCondition, adminBankDetail } = props
    let userId = user?._id
    const [price, setPrice] = useState(0)
    const [indexNum, setIndexNum] = useState(null)
    const [loading, setLoading] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [showEditHolding, setshowEditHolding] = useState(false)
    const [showHoldingModal, setShowHoldingModal] = useState(false)
    const [bankDetail, setBankDetail] = useState({})
    const [bankDetailLoading, setBankDetailLoading] = useState(false)
    const [isModalOpen2, setIsModalOpen2] = useState(false)
    const [index, setIndex] = useState(null)
    const [auctionData, setAuctionData] = useState({})


    const handleCancel2 = () => {
        setIsModalOpen2(false)
    }

    const applyForAuction = (vs, i) => {
        let obj = {
            price: 0,
            buyerId: userId,
            auctionId: vs?._id,
            bankDetail: bankDetail,
            isParticipated: true
        }

        if (!bankDetail?.name || !bankDetail?.bankName || !bankDetail?.bankAccountNo) {
            return errorMessage(lanKorCondition ? 'Please provide All fields!' : '모든 항목을 작성해주세요!')
        }
        setIndexNum(i)
        setLoading(true)

        axios.post(POST.CREATE_AUCTION_RENTAL_BID, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setLoading(false)
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    fun()
                    setIsModalOpen2(false)
                }
                else {
                    setLoading(false)
                    console.log('runing')
                    setIsModalOpen2(false)
                }
            })
            .catch((e) => {
                console.log('err', e)
                setIsModalOpen2(false)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })

    }
    const onFinish = (values) => {
        setBankDetailLoading(true)
        let obj = {
            name: values?.name,
            bankName: values?.bankName,
            bankAccountNo: values?.bankAccNo,
        }
        if (obj?.name && obj?.bankName && obj?.bankAccountNo) {
            setBankDetail(obj)
            setTimeout(() => {
                setBankDetailLoading(false)
            }, 1000)
        }
    }

    // console.log('user?._id',auction)
    return (
        <div className='ticket_share_panels'>
            <div className='container_tick_share'>
                <div className='layout_tick_share'>
                    <div className='col_tick_share col_main_tick_share'>
                        <div className='left_panel'>
                            <h2 className='col_primary'>{language?.SharedRentalAuction}</h2>
                        </div>
                    </div>
                    <div className='col_tick_share col_complementary_tick_share' >
                        {
                            user?._id ?
                                <AuctionPostModal language={language} {...props} ticket={ticket} /> :
                                <LoginAlert clas={'upload_sharing_btn'} buttonName={language?.RegisterAuctionPost} {...props} />
                        }
                    </div>
                </div>
            </div>
            <AuctionModal apiRoute={POST.CREATE_AUCTION_RENTAL_BID} setLoading={setLoading} loading={loading} v={auctionData} i={index} isModalOpen2={isModalOpen2} setIsModalOpen2={setIsModalOpen2} {...props} />

            <div className='row_new_cont'>
                {!loader ?
                    auction?.length ?
                        auction?.map((v, i) => {
                            return (
                                <div key={i} className='column_new_cont'>
                                    <span onClick={() => {
                                        setshowEditHolding(true)
                                        console.log('v', v)
                                        // console.log('showHoldingModal', showHoldingModal)
                                    }}>
                                        <div className='card_panel'>
                                            <h5>{language?.GolfClubName}</h5>
                                            <h5 className='not_bold'>{v?.ticketId?.golfClub?.clubName}</h5>
                                        </div>
                                        <div className='card_panel'>
                                            <h5>{language?.Type}</h5>
                                            <h5 className='not_bold'>
                                                {lanKorCondition ? v?.ticketId?.membershipType :
                                                    typesKr[typesOnlyKr?.indexOf(v?.ticketId?.membershipType)]?.typesKr ?
                                                        typesKr[typesOnlyKr?.indexOf(v?.ticketId?.membershipType)]?.typesKr :
                                                        typesKr[types?.indexOf(v?.ticketId?.membershipType)]?.typesKr
                                                }
                                            </h5>
                                        </div>
                                        <div className='card_panel'>
                                            <h5>{language?.MemberFee}</h5>
                                            <h5 className='not_bold'>{v?.memberFee ? v?.memberFee + language?.Won : '----'}</h5>
                                        </div>
                                        <div className='card_panel'>
                                            <h5>{language?.NonMemberFee}</h5>
                                            <h5 className='not_bold'>{v?.nonMemberFee ? v?.nonMemberFee + language?.Won : '----'}</h5>
                                        </div>
                                        <div className='card_panel'>
                                            <h5><HiPlusCircle className='plus_icon' />{language?.RightPrice}</h5>
                                            <h5 className='not_bold'>{v?.rightPrice ? v?.rightPrice + language?.Won : '----'}</h5>
                                        </div>
                                        <div className='card_panel '>
                                            <h5><HiPlusCircle className='plus_icon' />{language?.ReservationDate}</h5>
                                            <h5 className='not_bold'>{moment(v?.usableDate).format('YYYY-MM-DD')}</h5>

                                        </div>
                                        <div className='card_panel last_des'>
                                            <h5><HiPlusCircle className='plus_icon' />{language?.ExpirationDate}</h5>
                                            <h5 className='not_bold'>{moment(v?.expirationDate).format('YYYY-MM-DD')}</h5>
                                        </div>
                                    </span>
                                    <div className='btn_card'>
                                        {user?._id ?
                                            user?._id && user?.telNo && user?.bank && user?.bankAccountNumber ?
                                                <Button key={i}
                                                    // loading={indexNum == i ? loading : false}
                                                    onClick={() => { setIsModalOpen2(true); setIndex(i); setAuctionData(v) }}
                                                    className='partcipation-auction-btn'
                                                    style={user?._id === v?.userId?._id ? { opacity: 0.5 } : null}
                                                    disabled={user?._id === v?.userId?._id}
                                                >
                                                    {user?._id === v?.userId?._id ? language?.MyTicket : language?.Apply}
                                                </Button> :
                                                <Button onClick={() => MyInfoAlert(language)} className='partcipation-auction-btn' >
                                                    {user?._id === v?.userId?._id ? language?.MyTicket : language?.Apply}
                                                </Button> :
                                            <LoginAlert clas={'partcipation-auction-btn'} buttonName={user?._id === v?.userId?._id ? language?.MyTicket : language?.Apply} {...props} />

                                        }
                                    </div>
                                </div>
                            )
                        }) :

                        <div className='NodataDiv'>
                            <img src={noData} className='NodataImg' />
                            <h3>{language?.AuctionsNotFound}</h3>
                        </div> :
                    <div className='cards-lodaing-div'>
                        <Card className='column_new_cont' loading={true} />
                        <Card className='column_new_cont' loading={true} />
                        <Card className='column_new_cont' loading={true} />
                        <Card className='column_new_cont' loading={true} />
                    </div>}

            </div>
        </div>
    )
}

export default SharedRentalAuction