import HomeOutlined from '@ant-design/icons/HomeOutlined'
import allPaths from '../Config/paths'

const color = '#184849'
const bgColor = '#0adc00'

// const googleClientId = '275184240226-gv5fdm56bjdbf5jr7gd7520vqfimfqgv.apps.googleusercontent.com'
const googleClientId = '1093943387531-sut2415eo36iv4capfstrunii744er9o.apps.googleusercontent.com'
// const kakaoToken = '61825ab16330cf50aad486bdc188d71a'
const kakaoToken = '53b03e36fca24ac61a8b80a9860065ee'
const tossPayClientKey = 'test_ck_7XZYkKL4MrjLJg2PQok30zJwlEWR'
const tossPaySecretKey = "test_sk_YZ1aOwX7K8mEZg5N1408yQxzvNPG"
const passApiKey = 'TGdxb2l3enJDWFRTbTgvREU3MGYwUT09'
const passMID = 'INIiasTest'
const passMTxId = 'mTxId1'
const passVerificationRoute = 'https://cors-anywhere.herokuapp.com/https://sa.inicis.com/auth'
const constantImage = 'https://golf-ticket.s3.amazonaws.com/Banner/e3a0ed…47279b5077217e51e792dfd2&X-Amz-SignedHeaders=host'
const constantBanner = 'https://res.cloudinary.com/abbas-docs/image/upload/v1671185728/documents/WhatsApp_Image_2022-12-16_at_3.15.06_PM_pmodah.jpg'
// 'https://golf-ticket.s3.amazonaws.com/Banner/b1bdcb3a-d904-4854-8bfc-90dc93e5e7a9-bgHome.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2UYN26Q4ZPINR663%2F20221213%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20221213T130212Z&X-Amz-Expires=172800&X-Amz-Signature=f833d8553e726cf720ffa22e81117c7d2e49fcfe2a5b481d2ce76bfe008514fe&X-Amz-SignedHeaders=host'

const drawerRoutes = [
    {
        title: 'Home',
        route: allPaths.HOME,
        icon: <HomeOutlined />
    }
]
const types = ['Individual member', 'Family member', 'Individual weekday', 'Family weekday', 'Company']
const typesOnlyKr = ['개인회원', '가족회원', '개인주중', '가족주중', '법인회원']
const typesKr = [
    {
        typesEn: 'Individual member',
        typesKr: '개인회원',
    },
    {
        typesEn: 'Family member',
        typesKr: '가족회원',
    },
    {
        typesEn: 'Individual weekday',
        typesKr: '개인주중',
    },
    {
        typesEn: 'Family weekday',
        typesKr: '가족주중',
    },
    {
        typesEn: 'Company',
        typesKr: '법인회원',
    },
]

const regions = [
    'All regions',
    'Seoul and Gyeonggi northern',
    'Seoul and Gyeonggi southern',
    'Seoul and Gyeonggi western',
    'Seoul and Gyeonggi eastern',
    'Gangwon',
    'Chungbuk',
    'Chungnam (Daejeon)',
    'Gyeongnam',
    'Jeonbuk',
    'Jeonnam',
    'Gyeongbuk',
    'Jeju'
]

const regionsOnlyKr = ['모든 지역', '서울경기 북부', '서울경기 남부', '서울경기 서부', '서울경기 동부', '강원', '충북', '충남(대전)', ' 경남', '전북', '전남', '경북', '제주']

const regionsKr = [
    {
        TitleKr: '모든 지역',
        TitleEn: 'All regions'
    },
    {
        TitleKr: '서울경기 북부',
        TitleEn: 'Seoul and Gyeonggi northern'
    },
    {
        TitleKr: '서울경기 남부',
        TitleEn: 'Seoul and Gyeonggi southern'
    },
    {
        TitleKr: '서울경기 서부',
        TitleEn: 'Seoul and Gyeonggi western'
    }
    , {
        TitleKr: '서울경기 동부',
        TitleEn: 'Seoul and Gyeonggi eastern'
    },
    {
        TitleKr: '강원',
        TitleEn: 'Gangwon'
    },
    {
        TitleKr: '충북',
        TitleEn: 'Chungbuk'
    },
    {
        TitleKr: '충남(대전)',
        TitleEn: 'Chungnam (Daejeon)'
    },
    {
        TitleKr: ' 경남',
        TitleEn: 'Gyeongnam'
    },
    {
        TitleKr: '전북',
        TitleEn: 'Jeonbuk'
    },
    {
        TitleKr: '전남',
        TitleEn: 'Jeonnam'
    },
    {
        TitleKr: '경북',
        TitleEn: 'Gyeongbuk',
    },
    {
        TitleKr: '제주',
        TitleEn: 'Jeju'
    }]
const ticketTypes = ['SHARE TICKET', 'SALE TICKET', 'GROUP BUY TICKET']

const socialLink = {
    naver: 'https://nid.naver.com/user2/help/idInquiry.nhn?menu=idinquiry',
    kakao: 'https://accounts.kakao.com/login/?continue=https%3A%2F%2Fcs.kakao.com%2Fhelps%3Fservice%3D52%26category%3D166%26locale%3Dko',
    facebook: 'https://www.facebook.com/help/1503421039731588',
    google: 'https://support.google.com/accounts/answer/40560?hl=ko&ref_topic=7188671',
    apple: 'https://support.apple.com/ko-kr/HT201354'
}

const breadCrumbsKr = [
    {
        TitleKr: '실시간 시세',
        TitleEn: 'real-time-market'
    },
    {
        TitleKr: '맞춤 공유거래 추천',
        TitleEn: 'ticket-share-recommendations'
    },
    {
        TitleKr: 'Today Hot',
        TitleEn: 'today-hot'
    },
    {
        TitleKr: '회원권 매매',
        TitleEn: 'ticket-sharing'
    }
    , {
        TitleKr: '회원권 공동구매',
        TitleEn: 'group-buy'
    },
    {
        TitleKr: '회원권 매매',
        TitleEn: 'ticket-sale'
    },
    {
        TitleKr: '골프 예약',
        TitleEn: 'golf-reservation'
    },
    {
        TitleKr: '경매',
        TitleEn: 'auction'
    }
]

export {
    color,
    bgColor,
    drawerRoutes,
    allPaths,
    regions,
    googleClientId,
    kakaoToken,
    ticketTypes,
    regionsKr,
    types,
    typesKr,
    tossPayClientKey,
    tossPaySecretKey,
    constantBanner,
    passApiKey,
    passMID,
    passMTxId,
    passVerificationRoute,
    socialLink,
    breadCrumbsKr,
    constantImage,
    typesOnlyKr,
    regionsOnlyKr
}

