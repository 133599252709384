import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import moment from 'moment-timezone'
import { HiCheckCircle } from 'react-icons/hi';
import axios from 'axios';
import { errorMessage, MyInfoAlert, successMessage } from '../../utils/helpers';
import { POST } from '../../utils/apis';
import { useHistory } from 'react-router-dom';
import { allPaths, regionsKr, types, typesKr, typesOnlyKr } from '../../utils/constants';
const RentModal = (props) => {
    const { purchaseData, user, language, lanKorCondition } = props
    const userId = user?._id
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory()
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Content of the modal');
    const [isModalOpen3, setIsModalOpen3] = useState(false);


    console.log('purchaseData==',purchaseData )
    const showModal = () => {
        setOpen(true);
    };

    const handleOk = () => {
        setModalText('The modal will be closed after two seconds.');
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
        }, 2000);
    };

    const handleCancel3 = () => {
        setIsModalOpen3(false);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleOk3 = () => {
        setIsModalOpen3(false);

    };

    const completess = () => {
        setLoading(true)

        const obj = {
            // userId: user?._id,old work
            userId: purchaseData?.userId?._id ,
            ownerAdminId: purchaseData?.adminId,
            userTicketId: purchaseData?.ticketId?._id,
            buyerId: userId,
            ticketShareId: purchaseData?._id
        }


        // console.log(obj, "obj")
        axios.post(POST.RENT_REQUEST, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    // successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    console.log(lanKorCondition ? data?.message : data?.messageKr)
                    setLoading(false)
                    setOpen(false)
                    setIsModalOpen3(true);

                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                    console.log(lanKorCondition ? data?.message : data?.messageKr)
                    setLoading(false)
                }
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageKr)
            })
    }

    const openChat = (e) => {
        // let receiverId = e?.userId?._id || e?.userId
        // let chatType = 'rentRequest'
        // e.chatType = chatType
        // history.push({
        //     pathname: '/chat',
        //     search: `?userId=${receiverId}&ticketId=${e?.ticketId?._id}&chatType=${chatType}`,
        //     state: e
        // })
        window.open('http://pf.kakao.com/_xdxebxjxj/chat', '_blank')
    }

    return (
        <>
            {user?._id && user?.telNo && user?.bank && user?.bankAccountNumber ?
                <Button className='btn_exchange1' style={purchaseData?.ticketId?.userId === user?._id ? { opacity: 0.2 } : null} disabled={purchaseData?.ticketId?.userId === user?._id} onClick={showModal}>
                    {language?.Rent}
                </Button> :
                <Button className='btn_exchange1' style={purchaseData?.ticketId?.userId === user?._id ? { opacity: 0.2 } : null} disabled={purchaseData?.ticketId?.userId === user?._id} onClick={() => { MyInfoAlert(language) }}>
                    {language?.Rent}
                </Button>}
            <Modal
                open={open}
                // onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                footer={[<Button loading={loading} onClick={completess} className='req_exc_btn' type="primary">{language?.Rent}</Button>]}

                style={{ top: '10px', }}
            >
                <div
                    className='scroll_class'
                    style={{
                        height: 'auto',
                        width: '100%',
                        maxHeight: '85vh',
                        overflowY: 'scroll',
                        marginTop: '12px'
                    }}>
                    <h2 style={{ textAlign: 'center' }}>{language?.Rent}</h2>
                    <div className='main_modal_con' /* style={{ padding: 10 }} */>
                        <p
                        // style={{ paddingLeft: 14 }}
                        >
                            {language?.MembershipWishTo}
                        </p>
                        <div className='modal_first_panel purchaseDir'>
                            <div className="column-1 box" style={{ paddingLeft: 15 }}>
                                <h4>{language?.GolfCourseName}</h4>
                            </div>
                            <div className="column-2 box">
                                <p>{purchaseData?.golfClub?.clubName || purchaseData?.ticketId?.golfClub?.clubName}</p>
                            </div>
                        </div>
                        <div className='modal_first_panel purchaseDir'>
                            <div className="column-1 box" style={{ paddingLeft: 15 }}>
                                <h4>{language?.Type}</h4>
                            </div>
                            <div className="column-2 box">
                                <p>{
                                    purchaseData?.membershipType ?
                                        purchaseData?.membershipType ? lanKorCondition ? purchaseData?.membershipType :
                                            typesKr[types?.indexOf(purchaseData?.membershipType)]?.typesKr : '----'
                                        :
                                        purchaseData?.ticketId?.membershipType ? lanKorCondition ? purchaseData?.ticketId?.membershipType :
                                            typesKr[typesOnlyKr?.indexOf(purchaseData?.ticketId?.membershipType)]?.typesKr ?
                                                typesKr[typesOnlyKr?.indexOf(purchaseData?.ticketId?.membershipType)]?.typesKr :
                                                typesKr[types?.indexOf(purchaseData?.ticketId?.membershipType)]?.typesKr
                                            : '----'
                                    // purchaseData?.membershipType
                                    // || purchaseData?.ticketId?.membershipType
                                }</p>
                            </div>
                        </div>
                        <div className='modal_first_panel purchaseDir'>
                            <div className="column-1 box" style={{ paddingLeft: 15 }}>
                                <h4>{lanKorCondition ? 'Member' : language?.MemberFee}/{lanKorCondition ? 'nonMember' : language?.NonMemberFee}</h4>
                            </div>
                            <div className="column-2 box">
                                <p>{language?.MemberFee} {purchaseData && purchaseData?.memberFee || purchaseData?.ticketId?.memberFee} {language?.MillonWon}/ {language?.NonMemberFee} {purchaseData && purchaseData?.NONmemberFee || purchaseData?.ticketId?.NONmemberFee} {language?.MillonWon}</p>
                            </div>
                        </div>
                        <div className='modal_first_panel purchaseDir'>
                            <div className="column-1 box" style={{ paddingLeft: 15 }}>
                                <h4>{language?.InstantPrice}</h4>
                            </div>
                            <div className="column-2 box">
                                <p>{purchaseData ? purchaseData?.rightPrice + '만원' : '--'}</p>
                            </div>
                        </div>
                        <div className='modal_first_panel purchaseDir'>
                            <div className="column-1 box" style={{ paddingLeft: 15 }}>
                                <h4>{language?.Reserveddate}</h4>
                            </div>
                            <div className="column-2 box">
                                <p>{moment(purchaseData && purchaseData?.joiningDate?.date1 || purchaseData?.ticketId?.joiningDate?.date2).format('YYYY-MM-DD')}</p>
                            </div>
                        </div>
                        <div className='modal_first_panel purchaseDir'>
                            <div className="column-1 box" style={{ paddingLeft: 15 }}>
                                <h4>{language?.ExpirationDate}</h4>
                            </div>
                            <div className="column-2 box">
                                <p>{moment(purchaseData && purchaseData?.joiningDate?.date2).format('YYYY-MM-DD')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                open={isModalOpen3}
                // open={open}
                // onCancel={handleCancel}
                onCancel={handleCancel3}
                footer={[<div className='message_modal'>
                    <Button className='message_modal_btn' type="primary"
                        onClick={() => {
                            handleOk3();
                            history.push({
                                pathname: allPaths?.MYPAGE,
                                search: `?membershipTransaction`
                            })
                        }}>{lanKorCondition ? 'Go To My Page' : language?.GoTicketSalesRight}
                    </Button>
                    <Button

                        className='message_modal_btn marginLeft'
                        type="primary"
                        onClick={() => openChat(purchaseData)}>
                        {lanKorCondition ? 'Chat Bot' : language?.TalkToUser}
                    </Button>
                </div>]}
            // width={800}
            >
                <h2 style={{ textAlign: 'center' }}>{language?.Completion}</h2>
                <div style={{ padding: '20px' }}>
                    <center><HiCheckCircle className='tick_icon' /></center>
                    <h4 style={{ textAlign: 'center' }}>
                        {language?.ExchangeRentRequest}
                    </h4>
                    <h4 style={{ textAlign: 'center' }}>
                        {language?.PleaseWaitFor}
                    </h4>
                </div>
            </Modal>
        </>
    );
};

export default RentModal;