import { ReloadOutlined } from '@ant-design/icons'
import { Button, DatePicker, Empty, Select, Space, Table, Input, Tooltip } from 'antd'
import axios from 'axios'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { BsBoxArrowUpRight } from 'react-icons/bs'
import { FaDirections } from 'react-icons/fa'
import { ImPushpin } from 'react-icons/im'
import { Map, MapMarker } from 'react-kakao-maps-sdk'
import { useDispatch, useSelector } from 'react-redux'
import { setPin } from '../../Redux/actions/userActions'
import { GET, POST } from '../../utils/apis'
import { regions, regionsKr } from '../../utils/constants'
import { errorMessage, hideMiddleName, successMessage, stringLimiter } from '../../utils/helpers'
import CoustomRangePicker from '../RangePicker/RengePicker'
import ChatModel from './ChatModel'
import ConfirmationRegistration from './ConfirmationRegistration'
import EditGolfReservation from './EditGolfReservationModal'
import GolfReservationPaymentInfo from './GolfReservationPaymentInfo'
import { FaGreaterThan } from 'react-icons/fa'
import GolfReservationRequest from './MyReservationRequest'
const { RangePicker } = DatePicker
const { Option } = Select

const GolfReservation = (props) => {
    const { user, language, lanKorCondition } = props
    const [dateRange, setDateRange] = useState({
        date1: moment().add(-6, 'days').format('YYYY-MM-DD'),
        date2: moment().format('YYYY-MM-DD')
    })
    const [dateRange1, setDateRange1] = useState([
        moment().add(-6, 'days'),
        moment()
    ])
    const [loading, setLoading] = useState(false)
    const [correction, setCorrection] = useState(false)
    const [viewReservationRequest, setViewReservationRequest] = useState(false)
    const [golfData, setGolfData] = useState({})
    const [newRegions, setNewRegions] = useState(regions[0])
    const [dataSource, setDataSource] = useState([])
    const [latitude, setLatitude] = useState(null)
    const [longitude, setLongitude] = useState(null)
    const [data, setData] = useState({})
    let dispatch = useDispatch()
    const pin = useSelector(state => state?.userReducer?.pin)
    let geocoder = new kakao.maps.services.Geocoder()

    useEffect(() => {
        getGolfReservation()
    }, [dateRange, newRegions, correction])


    const openChat = (e) => {
        // console.log('e', e)
        // let receiverId = e?.userId?._id

        // e.chatType = 'inquiryReservation'

        // history.push({
        //     pathname: '/chat',
        //     search: `?userId=${receiverId}`,
        //     state: e
        // })
        window.open('http://pf.kakao.com/_xdxebxjxj/chat', '_blank')

    }

    const columns = [
        {
            title: language?.RegistrationDate,
            dataIndex: 'createdAt',
            width: 120,
            render: (e) => <p style={{ width: 120 }}>{moment(e).format('YYYY-MM-DD')}</p>
        },
        {
            title: language?.Region,
            dataIndex: ['golfClub', 'region'],
            width: 220,
            render: (e) => <p style={{ width: 220 }}>{lanKorCondition ? e : regionsKr[regions?.indexOf(e)]?.TitleKr}</p>
        },
        {
            title: language?.GolfCourse,
            dataIndex: ['golfClub', 'clubName'],
            key: 'golfClubName',
            width: 130,
            render: (e) => <p style={{ width: 200 }}>{e ? e : '----'}</p>


        },
        {
            title: language?.ReservationDate,
            dataIndex: 'reservedDate',
            width: 130,
            render: (e) => <p style={{ width: 200 }}>{moment(e).add(-1, 'days').format('YYYY-MM-DD')}</p>
        },
        {
            title: language?.GreenFee,
            dataIndex: 'greenFee',
            width: 130,
            render: (e) => <p style={{ width: 200 }}>{e ? e + language?.KRW : '----'}</p>


        },
        {
            title: language?.TransferFee,
            dataIndex: 'transferFee',
            width: 130,
            render: (e) => <p style={{ width: 200 }}>{e ? e + language?.KRW : '----'}</p>


        },
        {
            title: language?.Registrant,
            dataIndex: ['userId', 'fullName'],
            width: 130,
            render: (e) => <p style={{ width: 150 }}>{e ? e : '----'}</p>


        },
        {
            title: language?.Situation,
            // dataIndex: 'reservationCompleted',
            width: 130,
            render: (e) => <p style={{ width: 200 }}>{e?.paymentId ? (lanKorCondition ? 'Reservation Completed' : '예약완료') : (lanKorCondition ? 'Reservation not completed' : '예약 대기중')}</p>

        },
        {
            title: '',
            key: 'action',
            render: (e) => (
                <>
                    {
                        e?.pin?.includes(user?._id) ?
                            <>
                                <Space size="middle">
                                    <Button type="primary" onClick={() => {
                                        let id = e?._id
                                        setPinGolfRes(id, false)
                                    }} shape="circle" icon={<ImPushpin />} />
                                </Space>
                            </> :
                            <Space size="middle">
                                <Button onClick={() => {
                                    let id = e?._id
                                    setPinGolfRes(id, true)
                                    console.log('e', e)
                                }} shape="circle" icon={<ImPushpin />} />
                            </Space>
                    }

                </>
            ),
        },
        {
            // title: 'Action',
            key: 'action',
            render: (e) => (<>

                <span style={{ display: 'flex' ,}}>
                    <Button
                        onClick={() => { setCorrection(true), setGolfData(e) }}
                        className='correction_button'>{language?.Correction}</Button>
                    <Button
                        onClick={() => deleteReservation(e)}
                        className='correction_button'>{language?.Delete}</Button>
                    <Button onClick={() => { setData(e) }} className='correction_button'> {lanKorCondition ? 'View More' : '더보기'} <FaGreaterThan /></Button></span>
                <span>
                </span>
            </>
            )
        },

    ]

    const deleteReservation = (e) => {

        let obj = {
            _id: e?._id,
            userId: user?._id
        }
        axios.post(POST?.DELETE_GOLF_RESERVATION, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    console.log('data', data)
                    // setDataSource(data?.data)
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    getGolfReservation()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })

    }
    let selectePinData = dataSource && dataSource?.filter((v, i) => v?._id === pin?._id)
    let sortData = dataSource.sort((a, b) => b?.pin?.includes(user?._id) - a?.pin?.includes(user?._id))
    const setPinGolfRes = (id, pinGolfRes) => {
        let obj = {
            userId: user?._id,
            _id: id,
            pinGolfRes: pinGolfRes,
        }
        axios.post(POST?.SET_PIN_GOLF_RES, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    // setDataSource(data?.data)
                    getGolfReservation()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    const getGolfReservation = () => {
        setLoading(true)
        let obj = {
            region: newRegions,
            date1: dateRange?.date1,
            date2: dateRange?.date2,
            userId: user?._id,
        }

        axios.post(POST?.GET_GOLF_RESERVATION, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    console.log('data', data)
                    setDataSource(data?.data)
                    // successMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    const handleChange = (value) => {
        console.log(`Selected: ${value}`)
        setNewRegions(value)
    }

    const openMap = () => {
        // window?.location?.replace(`https://map.kakao.com/link/roadview/${latitude},${longitude}`)
        window?.open(`https://map.kakao.com/link/roadview/${latitude},${longitude}`, '_blank')
    }

    console.log('sortData', sortData)

    const setTopHeaderData = (record) => {
        // console.log('RECORD', record)
        let address = record?.golfClub?.clubAddress
        geocoder.addressSearch(address, (result, status) => {
            if (status === kakao.maps.services.Status.OK) {
                console.log('RESULT', result)
                setLongitude(result[0]?.x)
                setLatitude(result[0]?.y)
            }
        })
        dispatch(setPin(record))
    }


    useEffect(() => {
        setDateRange({
            date1: dateRange1[0].format('YYYY-MM-DD'),
            date2: dateRange1[1].format('YYYY-MM-DD')
        })
    }, [dateRange1])
   
    return (
        <div className='ticket_share_panels_Golf_RES'>
            {
                data?._id ?
                    <>
                        <GolfReservationPaymentInfo lanKorCondition={lanKorCondition} data={data} {...props} />
                        <div className='TransactionAmountInputDiv'>
                            <div className='saveTransactionButtonDiv '>
                                <Button onClick={() => { setData({}) }} className='saveTransactionBtnTic'>
                                    {language?.goBack}
                                </Button>
                            </div>
                        </div>
                    </>
                    : !viewReservationRequest ?
                        <>
                            <div className='container_tick_share'>
                                <div className='layout_tick_share'>
                                    <div className='col_tick_share '>
                                        <div className='left_panel'>
                                            <h2 className='col_primary'>{language?.GolfReservation}</h2>
                                            {/* <p style={{ color: '#1890ff', margin: '7px 0px 0px 9px', textDecoration: 'underLine' }}> {language?.VeiwMore}</p> */}
                                        </div>
                                    </div>
                                    <div className='col_tick_share col_complementary_tick_share' >
                                        <ConfirmationRegistration getGolfReservation={getGolfReservation} language={language} {...props} />

                                        {golfData?._id ?
                                            <EditGolfReservation fun={getGolfReservation} setGolf={setGolfData} golfData={golfData} correction={correction} setCorrection={setCorrection} {...props} />
                                            : null}

                                        <a ><button onClick={() => setViewReservationRequest(true)} className='upload_sharing_btn' style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '5px', height: '45px', width: '200px' }} >
                                            {lanKorCondition ? 'golf reservation ticket Check the application list' : '골프 예약권 신청목록 확인'}
                                        </button></a>
                                    </div>
                                </div>
                            </div>
                            <div className='row_golf'>
                                <div className='column_golf ' >
                                    <h5 className='left_golf_card'>{language?.ReservationPossible} ({dataSource?.length ? dataSource?.length : 0}  {lanKorCondition ? 'Pieces' : '건'})</h5>
                                    {

                                        selectePinData?.length ?
                                            <h5 className='' style={{ marginTop: 10 }}>
                                                <Map
                                                    center={{ lat: latitude, lng: longitude }}
                                                    style={{ width: '100%', height: '360px' }}
                                                >
                                                    <MapMarker position={{ lat: latitude, lng: longitude }}>
                                                    </MapMarker>
                                                </Map>
                                            </h5> : <Empty />
                                    }
                                </div>
                                <div className='column_golf ' >
                                    <div className='right_card_golf'>
                                        <h5 style={{ textAlign: 'left' }}>{language?.Address}</h5>
                                        <span className='map_panel'>
                                            <input style={{ width: '100%', border: 'none' }} value={selectePinData?.length ? selectePinData[0]?.golfClub?.clubAddress : language?.ReservationLocationDetails} disabled placeholder={language?.ReservationLocationDetails} />
                                            <h4><Button loading={loading} type='text' onClick={openMap} icon={<FaDirections color='black' />} disabled={selectePinData?.length ? false : true}></Button></h4>
                                        </span>
                                        {
                                            selectePinData?.length ?
                                                selectePinData.map((v, i) => {
                                                    return (
                                                        <>

                                                            <div key={i} className='main_modal_con' style={{ padding: 10 }}>
                                                                <div className='modal_first_panel purchaseDir'>
                                                                    <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                                                        <h5>{language?.ReservedDate}</h5>
                                                                    </div>
                                                                    <div className='column-2 box'>
                                                                        <p>{moment(v?.reservedDate?.date1).format('YYYY-MM-DD')}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='modal_first_panel purchaseDir'>
                                                                    <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                                                        <h5>{language?.GolfClubName} </h5>
                                                                    </div>
                                                                    <div className='column-2 box'>
                                                                        <p>{v?.golfClub?.clubName}</p>
                                                                        {/* <p>{GroupBuyInfoData && GroupBuyInfoData?.membershipType || GroupBuyInfoData?.ticketId?.membershipType}</p> */}
                                                                    </div>
                                                                </div>
                                                                <div className='modal_first_panel purchaseDir'>
                                                                    <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                                                        <h5>{language?.GreenFee}</h5>
                                                                    </div>
                                                                    <div className='column-2 box'>
                                                                        <p>{v?.greenFee} 만원</p>

                                                                        {/* <p>{GroupBuyInfoData && GroupBuyInfoData?.ticketId?.joiningFee}</p> */}
                                                                    </div>
                                                                </div>
                                                                <div className='modal_first_panel purchaseDir'>
                                                                    <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                                                        <h5>{language?.TransferFee}</h5>
                                                                    </div>
                                                                    <div className='column-2 box widthh'>
                                                                        <Tooltip placement='top' title={v?.transferFee}>
                                                                            <p className="elipsis-mem0">{stringLimiter(v?.transferFee) + ' ' + language?.Won}</p>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                                <div className='modal_first_panel purchaseDir'>
                                                                    <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                                                        <h5>{language?.SellerName}</h5>
                                                                    </div>
                                                                    <div className='column-2 box'>
                                                                        <p>{hideMiddleName(v?.userId?.fullName)}</p>
                                                                        {/* <p>{`${moment(GroupBuyInfoData && GroupBuyInfoData?.ticketId?.joiningDate?.date1).format('YYYY-MM-DD')} ~ ${moment(GroupBuyInfoData && GroupBuyInfoData?.ticketId?.joiningDate?.date2).format('YYYY-MM-DD')}`}</p> */}
                                                                    </div>
                                                                </div>
                                                                <div className='modal_first_panel purchaseDir'>
                                                                    <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                                                        <h5>{language?.Memo}</h5>
                                                                    </div>
                                                                    {/* <div className='column-2 box widthh'>
                                                                    <p className='elipsis-mem0'>{v?.memo}</p>
                                                                </div> */}
                                                                    <div className='column-2 box widthh'>
                                                                        <Tooltip placement='top' title={v?.memo}>
                                                                            <p className="elipsis-mem0">{stringLimiter(v?.memo)}</p>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }) : <Empty />
                                        }
                                    </div>
                                    <div className='btn_card'>
                                        <Button disabled={selectePinData[0]?.userId?._id ? false : true} className='btn_chat_golf' onClick={() => openChat(selectePinData[0])}>
                                            1:1 {language?.Chat}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className='mid_panel_golf'>
                                <div style={{}} className='golfdateSelect'>
                                    <Select
                                        defaultValue={regions[0]}
                                        onChange={handleChange}
                                        style={{
                                            width: 'fit-content',
                                        }}
                                    >
                                        {regionsKr?.map((v, i) => (
                                            <Option key={i} value={v?.TitleEn}>{lanKorCondition ? v?.TitleEn : v?.TitleKr}</Option>
                                        ))}
                                    </Select>

                                    <CoustomRangePicker format={'YYYY-MM-DD'} date={dateRange1} setDate={setDateRange1} />

                                </div>
                                <h3 style={{ color: '#2196f3' }}>
                                    <Button loading={loading} type='text' style={{ color: '#2196f3' }} onClick={getGolfReservation} icon={<ReloadOutlined />}>{language?.Refresh}</Button>
                                </h3>
                            </div>
                            <div className='main_table_cont' style={{ marginTop: 20 }}>
                                <Table
                                    onRow={(record, rowIndex) => ({
                                        onClick: () => {
                                            setTopHeaderData(record)
                                        },
                                    })
                                    }
                                    id='application'
                                    // className='table-striped-rows1234 table_home'
                                    columns={columns}
                                    dataSource={sortData}
                                    pagination={false}
                                    bordered={false}
                                    scroll={{ x: 1200 }}
                                    loading={loading}
                                />
                                <hr className='_hr' />
                            </div>
                        </> : <GolfReservationRequest setViewReservationRequest={setViewReservationRequest} {...props} />
            }

        </div>
    )
}

export default GolfReservation