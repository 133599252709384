import { PlusCircleFilled } from '@ant-design/icons'
import { Button, Form, Input, Modal } from 'antd'
import React from 'react'
import { errorMessage, successMessage } from '../../utils/helpers'
import { GET, POST } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import axios from 'axios'
import { useState } from 'react'
import moment from 'moment'
import { number } from 'prop-types'

const { TextArea } = Input

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
        md: {
            span: 6,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
        md: {
            span: 18,
        },
    },
}
const PaymentRequestModal = (props) => {
    const { GroupBuyInfoData, CancelGroupInfo, close, open, language, popupDetail, user, getPaymentFun } = props
    const [loading, setLoading] = useState(false)
    const [amount, setAmount] = useState(null)

    const onFinish = (fieldsValue) => {
        console.log(fieldsValue)

        let obj = {
            userId: user?._id,
            buyerId: popupDetail?.userId?._id,
            amount: Number(fieldsValue?.amount),
            requestType: popupDetail?.chatType

        }

        axios.post(`${POST?.CREATE_PAYMENT_REQUEST}`, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    getPaymentFun()
                    setLoading(false)
                    setAmount(null)
                    successMessage(data?.message)
                    close()
                }
                else {
                    setLoading(false)
                }
            }).catch((err) => {
                console.log('err', err)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    return (
        <>
            <Modal
                open={open}
                // open={true}
                onCancel={close}
                footer={null}
            >
                <h2 style={{ textAlign: 'center' }}>{language?.PaymentRequest}</h2>
                <p style={{ paddingLeft: 14 }}>
                    {language?.MembershipToTrade}
                </p>
                <div className='main_modal_con' style={{ padding: 10 }}>
                    <div className='modal_first_panel purchaseDir'>
                        <div className='column-1 box' style={{ paddingLeft: 15 }}>
                            <h4>{language?.GolfClubName}</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>{popupDetail?.ticketId?.golfClub?.clubName || popupDetail?.golfClub?.clubName}</p>
                        </div>
                    </div>
                    <div className='modal_first_panel purchaseDir'>
                        <div className='column-1 box' style={{ paddingLeft: 15 }}>
                            <h4>{language?.Type}</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>{popupDetail?.ticketId?.membershipType}</p>
                        </div>
                    </div>
                    <div className='modal_first_panel purchaseDir'>
                        <div className='column-1 box' style={{ paddingLeft: 15 }}>
                            <h4>{language?.MemberFee} / {language?.NonMemberFee}</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>{popupDetail?.ticketId?.memberFee || popupDetail?.memberFee} / {popupDetail?.NONmemberFee || popupDetail?.ticketId?.NONmemberFee}</p>
                        </div>
                    </div>
                    <div className='modal_first_panel purchaseDir'>
                        <div className='column-1 box' style={{ paddingLeft: 15 }}>
                            <h4>{language?.RightPrice}</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>{popupDetail?.ticketId?.rightPrice || popupDetail?.rightPrice}</p>
                        </div>
                    </div>
                    <div className='modal_first_panel purchaseDir'>
                        <div className='column-1 box' style={{ paddingLeft: 15 }}>
                            <h4>{language?.ReservedDate}</h4>
                        </div>
                        <div className='column-2 box'>
                            {/* <p>{moment(popupDetail?.ticketId?.openingDate || popupDetail?.openingDate)}</p> */}
                            <p>{moment(popupDetail?.ticketId?.openingDate || popupDetail?.openingDate).format('YYYY-MM-DD')}</p>
                        </div>
                    </div>
                    <div className='modal_first_panel purchaseDir'>
                        <div className='column-1 box' style={{ paddingLeft: 15 }}>
                            <h4>{language?.ExpirationDate}</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>{moment(popupDetail?.ticketId?.expirationDate || popupDetail?.expirationDate).format('YYYY-MM-DD')}</p>

                        </div>
                    </div>

                    <Form requiredMark={false} name='time_related_controls'  {...formItemLayout} onFinish={onFinish}>

                        <Form.Item
                            name='amount'
                            label={<h4> {language?.PaymentRequest}</h4>}
                            rules={[
                                {
                                    required: true,
                                    message: language?.Inputyour + ' ' + language?.PaymentRequest,
                                },
                            ]}
                        >
                            <div>
                                <Input
                                    suffix={language?.KRW}
                                    maxLength={7}
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value?.replace(/[^0-9]/g, ''))}
                                />
                            </div>
                        </Form.Item>
                        {/* <p className='Estameted'>Estameted Revanue excluding commisi:{"KRW 121,212"}</p> */}
                        <Button loading={loading} className='req_exc_btn' type='primary' htmlType='submit'>{language?.PaymentRequest}</Button>
                    </Form>


                </div>
            </Modal>
        </>
    )
}

export default PaymentRequestModal