import { PlusOutlined } from '@ant-design/icons'
import { Button, Descriptions, Input, Space, Tabs, Tag, Tooltip, Table, Typography,Popover, Badge } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import { RiSoundModuleFill, RiChat3Line } from 'react-icons/ri';

import { useDispatch } from 'react-redux'
import Header from '../Home/Header'

const { Search } = Input;
const { TabPane } = Tabs;
const { Title } = Typography;
const { Column, ColumnGroup } = Table;


const content = (
    <div>
        <div className='drop_down_chat'>
            <h5>
                Notification
            </h5>
            <h5>
                (7)
            </h5>
        </div>
        <div className='drop_down_chat'>
            <h5>
                Event
            </h5>
        </div>
        <div className='drop_down_chat'>
            <h5>
                Notice
            </h5>
        </div>
        <div className='drop_down_chat'>
            <h5>
                Service
            </h5>
            <h5>
                Center
            </h5>
        </div>

    </div>
);
const buttonWidth = 70;

const ServiceCenter = (props) => {
    const {language}=props
    const [tags, setTags] = useState(['Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag',]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');

    const inputRef = useRef(null);
    const editInputRef = useRef(null);
    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);
    useEffect(() => {
        editInputRef.current?.focus();
    }, [inputValue]);

    const handleClose = (removedTag) => {
        const newTags = tags.filter((tag) => tag !== removedTag);
        console.log(newTags);
        setTags(newTags);
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        if (inputValue && tags?.indexOf(inputValue) === -1) {
            setTags([...tags, inputValue]);
        }

        setInputVisible(false);
        setInputValue('');
    };

    const handleEditInputChange = (e) => {
        setEditInputValue(e.target.value);
    };

    const handleEditInputConfirm = () => {
        const newTags = [...tags];
        newTags[editInputIndex] = editInputValue;
        setTags(newTags);
        setEditInputIndex(-1);
        setInputValue('');
    };

    const onChange = (value) => {
        console.log(value);
    };

    const onSearch = (value) => console.log(value);
    const onChangeTab = (key) => {
        console.log(key);
    };

    const data = [
        {
            key: '1',
            title: 'Title',
            date: '2021.01.25',
            name: 'Name',
        },
        {
            key: '2',
            title: 'Title',
            date: '2021.01.25',
            name: 'Name',
        },
        {
            key: '3',
            title: 'Title',
            date: '2021.01.25',
            name: 'Name',
        },
        {
            key: '4',
            title: 'Title',
            date: '2021.01.25',
            name: 'Name',
        },
        {
            key: '5',
            title: 'Title',
            date: '2021.01.25',
            name: 'Name',
        },
        {
            key: '6',
            title: 'Title',
            date: '2021.01.25',
            name: 'Name',
        },
    ];


    const dispatch = useDispatch()

    const onFinish = (value) => {

    }
    // const onSearch = (value) => console.log(value);


    return (
        <div className='home-main'>
            <Header {...props}/>
            
            <center>
                <div className='div_text'>
                    <Title level={4}>{language?.ServiceCenter}</Title>
                </div>
            </center>
            <div className='card-parent2'>
                <div className='card3' style={{ marginTop: 0 }}>
                    <Search
                        placeholder={language?.ENterSearchTerm}
                        onSearch={onSearch}
                        className='noti_search'
                    />
                    <br />
                    <br />
                    <Table dataSource={data} showHeader={false} pagination={false}
                        rowClassName={"tableTextColor"}
                        scroll={{ x: 800 }}
                        className='table-striped-rows table_home scroll_class'
                        >

                        <Column dataIndex="title" key="title" align='left' />
                        <Column dataIndex="date" key="date" align='right' />
                        <Column dataIndex="name" key="name" align='right' width='5%' />
                    </Table>

                </div>
            </div >
        </div >
    )
}

export default ServiceCenter