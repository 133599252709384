import { Button, Form, Input, Radio, Tabs, Typography } from 'antd'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import FacebookLogin from 'react-facebook-login'
import { GoogleLogin } from 'react-google-login'
import { AiFillGooglePlusCircle } from 'react-icons/ai'
import { BsFacebook } from 'react-icons/bs'
import { SiNaver } from 'react-icons/si'
import KakaoLogin from 'react-kakao-login'
import NaverLogin from 'react-naver-login'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import naver from '../../assets/Never.jpeg'
import { loginUser } from '../../Redux/actions/authActions'
import { AUTH, POST } from '../../utils/apis'
import { allPaths, googleClientId, kakaoToken, passMID } from '../../utils/constants'
import { errorMessage, facebookLogin, googleLogin, isUserLoginApi, kakaoLogin, naverLogin, requiredMessage, successNotification } from '../../utils/helpers'
import Header from '../Home/Header'


let origin = `https://securetech.vmvm.co.kr/login`
if (process.env.NODE_ENV === 'development') {
    origin = `http://localhost:3000/login`
}

let passVerOrigin = `https://securetech.vmvm.co.kr`
if (process.env.NODE_ENV === 'development') {
    passVerOrigin = `http://localhost:3000`
}

const { Title } = Typography

const { Search } = Input
const { TabPane } = Tabs


const Login = (props) => {
    let { history, language, lanKorCondition } = props
    const dispatch = useDispatch()
    const [tags, setTags] = useState(['Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag',])
    const [inputVisible, setInputVisible] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [editInputIndex, setEditInputIndex] = useState(-1)
    const [spin, setSpin] = useState(false)
    const [editInputValue, setEditInputValue] = useState('')
    const [login, setLogin] = useState(false)
    const [data, setData] = useState({})
    const [picture, setPicture] = useState('')
    const [loading, setLoading] = useState(false)
    const inputRef = useRef(null)
    const editInputRef = useRef(null)
    const formData = useRef(null)
    const [instaId, setInstaId] = useState(null)
    const path = history?.location?.pathname
    const [pathType, setPathType] = useState('individual-signup')
    const [logout, setLogout] = useState(true)
    const [value, setValue] = useState(1);

    const onChange = (e) => {
        console.log("radio checked", e.target.value);
        setValue(e.target.value);
    };
    const accessTokenNaver = new URLSearchParams(location.search).get('access_token')

    useEffect(() => {
        if (instaId) {
            const domain = 'https://kssa.inicis.com'
            formData.current.action = `${domain}/init`
            formData.current.method = 'get'
            formData.current.submit()
        }
    }, [instaId])

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus()
        }
    }, [inputVisible])

    useEffect(() => {
        if (accessTokenNaver) {
            const headers = {
                headers: {
                    Authorization:
                        "Bearer" + `${accessTokenNaver}`,
                },
            }
            axios.post(`https://openapi.naver.com/v1/nid/me`, headers)
                .then(async (res) => {
                    const { data } = res
                    if (data.success) {
                        setSpin(true)
                        console.log('RESULT', result)
                        const success = await naverLogin(data, history, loginUser, dispatch)
                        if (!success) {
                            setSpin(false)
                        }
                    }
                    else {
                        errorMessage(data?.message)
                    }
                }).catch((err) => {
                    console.log('err', err)
                    errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
                })
        }
    }, [])

    useEffect(() => {
        editInputRef.current?.focus()
    }, [inputValue])

    const responseFacebook = async (response) => {
        setData(response)
        setPicture(response?.picture?.data?.url)
        const success = await facebookLogin(response, history, loginUser, dispatch, lanKorCondition)

        if (!success) {
            setSpin(false)
        }
    }

    const callPassVerificationAuth = (path) => {
        setPathType(path)

        let obj = {
            successUrl: `${passVerOrigin}${AUTH.GET_PASS_VERIFICATION_RES}`
        }

        axios.post(POST?.PASS_VERIFICATION, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    let result = data?.result
                    var parser = new DOMParser()
                    var doc = parser.parseFromString(result, 'text/html')
                    setInstaId(doc?.querySelector('#txId')?.value)
                }
                else {
                    errorMessage()
                }
            })
            .catch((e) => console.log('e', e))
    }

    const responseGoogle = (response) => {
        console.log(response)
    }

    const updateGoogleLogin = async (e) => {
        setSpin(true)
        const success = await googleLogin(e, history, loginUser, dispatch, lanKorCondition)

        if (!success) {
            setSpin(false)
        }
    }

    const kakaoLoginController = async (result) => {
        setSpin(true)
        const success = await kakaoLogin(result, history, loginUser, dispatch, lanKorCondition)
        // console.log('success', success)
        if (!success) {
            setSpin(false)
        }
    }
    const naverLoginController = async (result) => {
        setSpin(true)
        // console.log('RESULT', result)
        const success = await naverLogin(result, history, loginUser, dispatch, lanKorCondition)
        if (!success) {
            setSpin(false)
        }
    }

    const onFinish = (value) => {
        setLoading(true)
        axios.post(AUTH?.LOGIN, value)
            .then(async (res) => {
                setLogout(false)
                const { data } = res
                if (data.success) {
                    dispatch(loginUser(data?.user))
                    setLoading(false)
                    successNotification(language?.SuccessfullyLoggedIn)
                    await isUserLoginApi(data?.user?._id, true)
                    return setTimeout(() => {
                        history?.replace(allPaths?.HOME)
                    }, 300)
                }
                else {
                    setLoading(false)
                    console.log(data?.message)
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
            }).catch((err) => {
                console.log('err', err)
                setLoading(false)
                errorMessage(lanKorCondition ? err?.message : err?.messageKr)
            })
    }

    return (
        <div className='home-main'>
            <Header {...props} />
            <div className='card-parent'>
                <div className='card'>
                    <p className='heading head-center'><b>{language?.login}</b></p>
                    <Form
                        name='form'
                        onFinish={onFinish}
                        layout={'vertical'}
                        requiredMark={false}
                    >
                        <Form.Item
                            name='email'
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage(lanKorCondition ? 'Please enter your email address' : '이메일주소를 입력해주세요')
                                }
                            ]}
                        >
                            <Input
                                prefix={<Title level={5} style={{ marginRight: 10, marginTop: '10px' }}>ID</Title>}
                                className='form-input remover-border'
                                placeholder={language?.Email}
                                size='large'
                            />
                        </Form.Item>
                        <Form.Item
                            style={{}}
                            name='password'
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage(lanKorCondition ? 'Please enter your password' : '비밀번호를 입력해주세요')
                                },
                                {
                                    min: 8,
                                    message: language?.PleaseEnterPasswordATLeastCharacters

                                }
                            ]}
                        >
                            <Input.Password
                                size='large'
                                visibilityToggle={false}
                                prefix={<Title level={5} style={{ marginRight: 10, marginTop: '10px' }}>PW</Title>}
                                className='form-input remover-border'
                                placeholder={language?.Password}
                            />
                        </Form.Item>
                        <Form.Item
                            // label={lanKorCondition ? "login Type" : "회원 유형"}

                            name='userType'
                            rules={[
                                {
                                    required: true,
                                    message: requiredMessage(lanKorCondition ? "login Type" : "회원 유형")
                                }
                            ]}
                            initialValue={'individual'}
                        >
                            <Radio.Group /* value={value} */>
                                <Radio value={'individual'}>{lanKorCondition ? 'Individual' : '일반회원'}</Radio>
                                <Radio value={'corporate'}>{lanKorCondition ? 'Corporate' : '법인회원'}</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <div className='forgot-links'>
                            <div className='email-forgot-link'>
                                <Link className='link' to={allPaths?.FIND}>{language?.ForgotYourEmail}</Link>
                            </div>
                            <div className=''>
                                <b className='vertical-line'>
                                    |
                                </b>
                            </div>

                            <div className='pass-forgot-link'>
                                <Link to={allPaths?.FIND} className='link'>{language?.ForgotYourPassword}</Link>
                            </div>
                        </div>

                        <Form.Item>
                            <Button
                                loading={loading}
                                type='primary'
                                className='login-button'
                                block
                                htmlType='submit'
                            >
                                {language?.login}
                            </Button>
                        </Form.Item>
                        <div className='signup-link'>
                            <div >
                                <Link onClick={() => callPassVerificationAuth('individual-signup')} className='' /* to={allPaths?.INDIVIDUAL_SIGNUP} */><b>{language?.IndividualMembership}</b></Link>
                            </div>
                            <div >
                                <Link /* onClick={() => callPassVerificationAuth('corporate-signup')} */ to={allPaths?.CORPORATE_SIGNUP} className=''><b>{language?.CompanySignUp}</b></Link>
                            </div>
                        </div>
                    </Form>
                </div>
                <div className='login-bottom'>
                    <div style={{ textAlign: 'center', color: 'gray' }}>
                        {language?.SNSSimpleStart}
                    </div>
                    <div style={{ marginBottom: '40px' }}>
                        <div style={{ marginTop: 15, display: 'flex', alignItems: 'center', backgroundColor: 'white', height: '60px' }}>
                            <NaverLogin
                                clientId='s9MxxPSvVwV4lLxLSjO7'
                                callbackUrl={origin}
                                onSuccess={naverLoginController}
                                onFailure={(res) => console.log("error N", res)}
                                style={{ display: 'inline', padding: '0px', display: 'none', backgroundColor: 'transparent', borderRadius: 'none' }}
                                render={(props) =>
                                    <SiNaver className='icon_socials  col_naver' style={{ cursor: 'pointer' }} onClick={() => { props?.onClick() }} />
                                }
                            />

                            <FacebookLogin
                                textButton={false}
                                appId='919975602563588'
                                // old one
                                // appId='1268122217448460'
                                autoLoad={false}
                                fields='name,email,picture'
                                scope='public_profile,user_friends'
                                callback={responseFacebook}
                                icon={<BsFacebook className='icon_socials  col_fb' />}
                                style={{ display: 'inline', padding: '0px', display: 'none', backgroundColor: 'transparent', borderRadius: 'none' }}
                                render={renderProps => (
                                    <button onClick={renderProps.onClick}><BsFacebook className='icon_socials  col_fb' /></button>
                                )}
                            />
                            <GoogleLogin
                                clientId={googleClientId}
                                className='googleBtn'
                                onSuccess={updateGoogleLogin}
                                onFailure={responseGoogle}
                                cookiePolicy={'single_host_origin'}
                                style={{ display: 'inline', padding: '0px', display: 'none' }}
                                // style={{ display: 'inline', padding: '0px', display: 'none', backgroundColor: 'transparent', borderRadius: 'none' }}
                                render={renderProps => (
                                    <a onClick={renderProps.onClick} ><AiFillGooglePlusCircle className='col_google' /></a>
                                )}
                            />
                            <KakaoLogin
                                token={kakaoToken}
                                // onSuccess={console.log}
                                onSuccess={kakaoLoginController}
                                onFail={responseGoogle}
                                onLogout={console.info}
                                render={({ onClick }) => {
                                    return (
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault()
                                                onClick()
                                            }}
                                            style={{ backgroundColor: 'white', border: 'none', cursor: 'pointer', marginLeft: '5px' }}
                                        >
                                            <img src={naver} className='color_kakao' style={{ width: '4.4vw', borderRadius: '100%' }} />
                                        </button>
                                    )
                                }}
                            />
                        </div>
                    </div>

                </div>
            </div>

            <form name="requestForm" action="" ref={formData}>
                <input type="hidden" name="mid" value={passMID} />
                <input type="hidden" name="reqSvcCd" value="01" />
                <input type="hidden" name="mTxId" value="mTxId" />
                <input type="hidden" name="nmComp" value="KG이니시스" />
                <input type="hidden" name="noComp" value="2208155597" />
                <input type="hidden" id="myOS" name="myOS" value="" />
                <input type="hidden" id="myBrowser" name="myBrowser" value="" />
                < input type="hidden" name="successUrl" value={passVerOrigin + AUTH.GET_PASS_VERIFICATION_RES} />
                {/* {
                    pathType === 'individual-signup' ?
                        : <input type="hidden" name="successUrl" value={passVerOrigin + AUTH.GET_PASS_VERIFICATION_CORPORATE_RES} />
                } */}
                <input type="hidden" name="failUrl" value={passVerOrigin + AUTH.FAIL_URL} />
                <input type="hidden" name="identifier" value="" />

                <input type="hidden" id="txId" name="txId" value={instaId} />
                <input type="hidden" id="flgFixedUser" name="flgFixedUser" value="N" />
                <input type="hidden" id="userName" name="userName" value="" />
                <input type="hidden" id="userPhone" name="userPhone" value="" />
                <input type="hidden" id="userBirth" name="userBirth" value="" />
                <input type="hidden" id="userHash" name="userHash" value="" />

                <input type="hidden" id="agencyList" name="agencyList" value="TOSS,PASS,SHINHAN,SAMSUNG,KB,NAVER,KAKAO,PAYCO,KFTC" />
                <input type="hidden" id="agencyListSize" name="agencyListSize" value="9" />

                <input type="hidden" id="isDirect" name="isDirect" value="false" />
                <input type="hidden" id="directAgency" name="directAgency" value="" />
                <input type="hidden" id="req_url" name="req_url" value={passVerOrigin + '/login'} />
            </form>
        </div >
    )
}

export default Login
