import { Checkbox, Divider, Tag, Typography } from 'antd';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { POST } from '../../utils/apis';
const { Text } = Typography;
// import React, { useEffect, useState } from 'react';
import { Button, Modal, Select, Input, DatePicker, Form } from 'antd';
import { HiPlusCircle, HiCheckCircle } from 'react-icons/hi';
import { RiCheckboxBlankFill } from 'react-icons/ri';
import { CheckCircleOutlined, PlusCircleFilled } from '@ant-design/icons'
// import { POST } from '../../utils/apis';
// import axios from 'axios';
import moment from 'moment-timezone'
import ChooseTicket from './ChooseTicket';
import { regions, regionsKr, types, typesKr, typesOnlyKr } from '../../utils/constants'
import { errorMessage, successMessage } from '../../utils/helpers';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';



const TicketShareInfoModal = (props) => {
    const { language, All_Data, setshowTicketShare, showTicketShare, lanKorCondition } = props
    const [ticketInfo, setTicketInfo] = useState([])
    const [selectedTicket, setSelectedTicket] = useState({})
    const handleCancel = () => {
        setshowTicketShare(false)
    }
    const { TextArea } = Input;

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 8,
            },
            md: {
                span: 8,
            }
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 16,
            },
            md: {
                span: 16,
            },
        },
    }
    const config = {
        rules: [
            {
                type: 'object',
                required: true,
                message: 'Please select date!',
            },
        ],
    }
    const onFinish = (fieldsValue) => { console.log(fieldsValue) }
    return (
        <>
            <Modal
                open={showTicketShare}
                onCancel={handleCancel}
                className='paymentModal'
                footer={null}
            >
                <div >
                    <div>
                        <h1 style={{ textAlign: 'center' }}>{language?.LetExchange}</h1>

                        <h3 style={{ textAlign: 'center', color: '#2196f3' }}>{language?.PlzFillBlack}</h3>
                    </div>

                    <Form requiredMark={false} name='time_related_controls'  {...formItemLayout}>
                        <div className='second_panel'>
                            <Form.Item
                                name='fee'
                                label={language?.Fee}
                                rules={[
                                    {
                                        required: true,
                                        validator: async (_, names) => {
                                            if (!memberFee) {
                                                return Promise.reject(new Error(language?.PleaseEnterMemberFee))
                                            }
                                            if (!nonMemberFee) {
                                                return Promise.reject(new Error('Please Enter Non-Member Fee'))
                                            }
                                        }
                                    }
                                ]}
                            >
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '47%', textAlign: 'center' }}>
                                        <label style={{ marginRight: '4%' }} htmlFor=''>{language?.Member}</label>
                                        <Input
                                            disabled
                                            suffix={language?.KRW}
                                            defaultValue={All_Data && All_Data?.ticketId?.memberFee}
                                            style={{
                                                width: 'auto',
                                                // marginLeft: '4%',
                                            }}
                                            maxLength={10}
                                        />
                                    </div>

                                    <div style={{ width: '50%', marginLeft: 'auto', textAlign: 'center' }}>
                                        <label htmlFor='' style={{ marginRight: '4%' }}>{language?.NonMember}</label>

                                        <Input
                                            disabled
                                            suffix={language?.KRW}
                                            defaultValue={All_Data && All_Data?.ticketId?.NONmemberFee}
                                            style={{
                                                width: 'auto',
                                                // marginLeft: '4%',

                                            }}
                                            maxLength={10}
                                        />
                                    </div>
                                </div>
                            </Form.Item>
                        </div>
                        <div className='main_modal_con' >
                            <div className='modal_first_panel purchaseDir'>
                                <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                    <h4>{language?.GolfClubName}</h4>
                                </div>
                                <div className='column-2 box'>
                                    <p>{All_Data && All_Data?.ticketId?.golfClub?.clubName}</p>
                                </div>
                            </div>
                            <div className='modal_first_panel purchaseDir'>
                                <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                    <h4>{language?.Type}</h4>
                                </div>
                                <div className='column-2 box'>
                                    <p>
                                        {All_Data?.ticketId?.membershipType ? lanKorCondition ? All_Data?.ticketId?.membershipType :
                                            typesKr[typesOnlyKr?.indexOf(All_Data?.ticketId?.membershipType)]?.typesKr ?
                                                typesKr[typesOnlyKr?.indexOf(All_Data?.ticketId?.membershipType)]?.typesKr :
                                                typesKr[types?.indexOf(All_Data?.ticketId?.membershipType)]?.typesKr
                                            : '----'}
                                    </p>
                                </div>
                            </div>
                            <div className='modal_first_panel purchaseDir'>
                                <div className='column-1 box' style={{ paddingLeft: 15 }}>
                                    <h4>{language?.address}</h4>
                                </div>
                                <div className='column-2 box'>
                                    <p>{All_Data && All_Data?.ticketId?.golfClub?.clubAddress}</p>
                                </div>
                            </div>

                        </div>


                        <div className='second_panel'>

                            <Form.Item
                                name='rightPrice'
                                label={<h4><PlusCircleFilled className='plus_with_text' /> {language?.RightPrice}</h4>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input Right Price!',
                                    },
                                ]}
                            >
                                <Input
                                    disabled={true}
                                    type='number'
                                    suffix={language?.KRW}
                                    min={0}
                                    maxLength={7}
                                    defaultValue={All_Data && All_Data?.ticketId?.rightPrice}
                                />
                            </Form.Item>

                            <Form.Item
                                name='usableDate'
                                label={<h4><PlusCircleFilled className='plus_with_text' />{language?.UsableDate}</h4>}
                                {...config}
                            >
                                <DatePicker defaultValue={moment(moment(All_Data && All_Data?.ticketId?.usableDate).format('YYYY-MM-DD'), 'YYYY-MM-DD')} disabled={true} style={{ width: '100%' }} />
                            </Form.Item>

                            <Form.Item
                                name='expirationDate'
                                label={<h4><PlusCircleFilled className='plus_with_text' />{language?.ExpirationDate}</h4>}
                                {...config}
                            >
                                <DatePicker defaultValue={moment(moment(All_Data && All_Data?.ticketId?.expirationDate).format('YYYY-MM-DD'), 'YYYY-MM-DD')} disabled={true} style={{ width: '100%' }} />
                            </Form.Item>

                            <Form.Item
                                name='locationPreference'
                                label={<h4><PlusCircleFilled className='plus_with_text' />{language?.LocationPreference}</h4>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select location!',
                                    },
                                ]}
                            >
                                {All_Data && All_Data?.ticketId?.locationPreference.map((val, i) => <Tag>
                                    {val ? lanKorCondition ? val : regionsKr[regions?.indexOf(val)]?.TitleKr : '----'}
                                </Tag>)}
                            </Form.Item>

                            <Form.Item
                                name='termsConditions1'
                                label={language?.TermCondition}
                                valuePropName='checked'
                                hasFeedback
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            value ? Promise.resolve() : Promise.reject(new Error(language?.ShouldAccept)),
                                    }
                                ]}
                            >
                                <Checkbox checked={true} disabled={true} />
                            </Form.Item>

                            <div className='agreement scroll_class' style={{ width: '100%' }}>
                                {/* <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eum porro assumenda, in dicta ab fuga, vero quasi nostrum molestiae, corporis perferendis similique quis deleniti ullam. Recusandae sequi quam vitae cumque! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae, nihil cupiditate rerum mollitia, nemo vitae temporibus amet quos esse architecto quod repellat laboriosam explicabo similique, alias beatae odio possimus? Accusamus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque blanditiis eaque saepe odio voluptas obcaecati at voluptatum, aliquid omnis laborum libero numquam architecto sit! Architecto quas ratione alias debitis deserunt!</p> */}
                                <p>{language?.TAC1}</p>
                                <p>{language?.TAC2}</p>
                                <p>{language?.TAC3}</p>
                                <p>{language?.TAC4}</p>
                                <p>{language?.TAC5}</p>
                                <p>{language?.TAC6}</p>
                                <p>{language?.TAC7}</p>
                            </div>
                        </div>
                        <Button htmlType='submit' disabled={true} className='req_exc_btn' type='primary'>{language?.UploadSharing}</Button>
                    </Form>

                </div>

            </Modal >

        </>
    )
}

export default TicketShareInfoModal