import { LOGIN_USER, REMOVE_USER, ALL_USERS, TEMP_USER } from '../types'

const reducer = (state = {}, action) => {
    switch (action.type) {
        case LOGIN_USER: {
            return { ...state, user: action.user }
        }
        case ALL_USERS: {
            return { ...state, allUsers: action.allUsers }
        }
        case REMOVE_USER: {
            return {}
        }
        case TEMP_USER: {
            return { ...state, tempUser: action.tempUser }
        }
        default: {
            return state
        }
    }
}

export default reducer