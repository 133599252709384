import { SELECTED_CHAT, PIN, TICKET, CHAT_HEADER, PRICE_FILTER, SELECTED_CHAT_HEAD } from '../types'

const reducer = (state = {}, action) => {
    switch (action.type) {
        case PIN: {
            return { ...state, pin: action.pin }
        }
        case TICKET: {
            return { ...state, ticket: action.ticket }
        }
        case SELECTED_CHAT: {
            return { ...state, selectedChat: action.selectedChat }
        }
        case SELECTED_CHAT_HEAD: {
            return { ...state, selectedChatHead: action.selectedChatHead }
        }
        case CHAT_HEADER: {
            return { ...state, chatHeader: action.chatHeader }
        }
        
        default: {
            return state
        }
    }
}


export default reducer