import { PRICE_FILTER } from '../types'

const initialTicketPrice = {
    ticketPrice: {
        start: 0,
        end: 0
    }
}

const reducer = (state = initialTicketPrice, action) => {
    switch (action.type) {
        case PRICE_FILTER: {
            return { ...state, ticketPrice: action.ticketPrice }
        }
        default: {
            return state
        }
    }
}

export default reducer
