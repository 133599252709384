import { PlusOutlined } from '@ant-design/icons'
import InfoCircleFilled from '@ant-design/icons/InfoCircleFilled'
import { Button, Card, Input, Space, Tabs, Tag, Tooltip, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { RiSoundModuleFill } from 'react-icons/ri'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { allPaths } from '../../utils/constants'

import Header from '../Home/Header'

const { Title } = Typography;

const { Search } = Input;
const { TabPane } = Tabs;


const gridStyle = {
    width: '100%',
    textAlign: 'center',
};
const anotherStyle = {
    width: '100%',
    textAlign: 'center',
    innerHeight: '2px'
};


const FindUsernamePassword = (props) => {
    const { language } = props
    console.log(language, "----")
    const [tags, setTags] = useState(['Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag',]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');

    const inputRef = useRef(null);
    const editInputRef = useRef(null);
    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);
    useEffect(() => {
        editInputRef.current?.focus();
    }, [inputValue]);

    const handleClose = (removedTag) => {
        const newTags = tags.filter((tag) => tag !== removedTag);
        console.log(newTags);
        setTags(newTags);
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        if (inputValue && tags?.indexOf(inputValue) === -1) {
            setTags([...tags, inputValue]);
        }

        setInputVisible(false);
        setInputValue('');
    };

    const handleEditInputChange = (e) => {
        setEditInputValue(e.target.value);
    };

    const handleEditInputConfirm = () => {
        const newTags = [...tags];
        newTags[editInputIndex] = editInputValue;
        setTags(newTags);
        setEditInputIndex(-1);
        setInputValue('');
    };

    const onChange = (value) => {
        console.log(value);
    };

    const onSearch = (value) => console.log(value);
    const onChangeTab = (key) => {
        console.log(key);
    };


    const dispatch = useDispatch()

    const onFinish = (value) => {

    }

    return (
        <div className='home-main'>
            <Header {...props} />

            <div className='card-parent2'>
                <div className='card2'>
                    <Title level={3}>{language?.FindIDANdPassword}</Title>
                    <br /><br /><hr style={{ border: '0.5px solid gray', backgroundColor: 'black' }} /><br />
                    <p className='special_text'>{language?.YouFindYourIDInformation}</p>

                    <br /><br />

                    <div className='card_divs'>
                        <Card className='cards_width'>
                            <Card.Grid hoverable={false} style={gridStyle}>
                                <Title level={5}>{language?.IndividualMembers}</Title>
                            </Card.Grid>
                            <br />
                            <Link to={allPaths?.FIND_ID_GUIDE} style={{ width: '50%' }}>
                                <Card.Grid /* onClick={} */ style={anotherStyle}>
                                    <Title level={5}>{language?.FindyourID}</Title>
                                </Card.Grid>
                            </Link>
                            <Link to={allPaths?.FIND_PW_GUIDE} style={{ width: '50%' }} >
                                <Card.Grid style={anotherStyle}>
                                    <Title level={5}>{language?.FindYourPassword}</Title>
                                </Card.Grid>
                            </Link>
                        </Card>
                        <Card className='cards_width'>
                            <Card.Grid hoverable={false} style={gridStyle}>
                                <Title level={5}>{language?.CorporateMembership}</Title>
                            </Card.Grid>
                            <br />
                            <Link to={allPaths?.FIND_ID_GUIDE} style={{ width: '50%' }}>
                                <Card.Grid style={anotherStyle}>
                                    <Title level={5}>{language?.FindyourID}</Title>
                                </Card.Grid>
                            </Link>
                            <Link to={allPaths?.FIND_PW_GUIDE} style={{ width: '50%' }}>
                                <Card.Grid style={anotherStyle}>
                                    <Title level={5}>{language?.FindYourPassword}</Title>
                                </Card.Grid>
                            </Link>
                        </Card>
                    </div>

                    <div className='divs_buttons'>
                        <Link to={allPaths?.LOGIN}>
                            <Button className='description_buttons'>{language?.login}</Button>
                        </Link>
                        <Link to={allPaths?.EASY_LOGIN}>
                            <Button className='description_buttons'>{language?.FindSocialAccount}</Button>
                        </Link>
                        <Link to={allPaths?.INDIVIDUAL_SIGNUP}>
                            <Button type="primary" className='gray_buttons'>{language?.Signup}</Button>
                        </Link>
                        <Link to={allPaths?.FAQ}>
                            <Button type="primary" className='gray_buttons'>{language?.FrequentlyAskedQuestions}</Button>
                        </Link>
                        <p className='special_text1' style={{ margin: '10px 0 0 5px ' }}>
                            <InfoCircleFilled style={{ marginRight: 5 }} />
                            {language?.ContactCoustomer}</p>
                    </div>
                </div>
            </div >
        </div>
    )
}

export default FindUsernamePassword