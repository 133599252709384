import React, { useState } from 'react'
import { Button, Modal, Select, Input, Checkbox, Form, DatePicker, Image } from 'antd'
import { HiPlusCircle, HiCheckCircle } from "react-icons/hi"
import { RiCheckboxBlankFill } from "react-icons/ri"
import SearchGolfClub from './SearchGolfClub'
import axios from 'axios'
import { POST } from '../../utils/apis'
import { successMessage, errorMessage, MyInfoAlert } from '../../utils/helpers'
import { PlusCircleFilled } from '@ant-design/icons'
import CoustomRangePicker from '../RangePicker/RengePicker'

const { RangePicker } = DatePicker
const { TextArea } = Input
const { Option } = Select
const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
        md: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
        md: {
            span: 16,
        },
    },
}
const config = {
    rules: [
        {
            type: 'object',
            required: true,
            message: 'Please select data!',
        },
    ],
}

const ReservationModal = (props) => {

    const { user, language, lanKorCondition, fun } = props
    const rangeConfig = {
        rules: [
            {
                type: 'array',
                required: true,
                message: language?.PleaseselectdataRange,
            },
        ],
    }

    const nums = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
    const mins = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60']
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [am, setAm] = useState('am')
    const [hr, setHr] = useState('1')
    const [min, setMin] = useState('1')
    const [totalTime, setTotalTime] = useState(hr + ':' + min + ' ' + am)
    const [showGolfClub, setShowGolfClub] = useState(false)
    const [golfClubData, setGolfClubData] = useState(null)
    const [greenFee, setgreenFee] = useState(null)
    const [transferFee, settransferFee] = useState('0')
    const [address, setAddress] = useState(null)
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }
    // console.log('golfClubData', golfClubData)
    const onFinish = (fieldsValue) => {
        setLoading(true)
        // Should format date value before submit.
        const rangeValue = fieldsValue['reservedDate']
        const values = {
            ...fieldsValue,
            // 'reservedDate': [rangeValue[0].format('YYYY-MM-DD'), rangeValue[1].format('YYYY-MM-DD')],
            'reservedDate': { date1: rangeValue[0].format('YYYY-MM-DD'), date2: rangeValue[1].format('YYYY-MM-DD') },
        }

        values.userId = user?._id
        values.region = golfClubData?.region
        values.golfClub = golfClubData?._id
        values.greenFee = +values.greenFee
        values.auctionType = 'golfReservation'
        delete values.address
        delete values.TermAndConditions
        delete values.region
        values.advanceFee = Number(values?.advanceFee)

        console.log('values', values)

        axios.post(POST?.CREATE_AUCTION_GOLF_RESERVATION, values)
            .then((res) => {
                const { data } = res
                console.log('data', data)
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    fun()
                    setIsModalOpen(false)
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    return (
        <>
            {user?._id && user?.telNo && user?.bank && user?.bankAccountNumber ?
                <Button className='upload_sharing_btn' onClick={showModal}>
                    {language?.RegisterAuctionPost}
                </Button> :
                <Button className='upload_sharing_btn' onClick={() => { MyInfoAlert(language) }}>
                    {language?.RegisterAuctionPost}
                </Button>}
            <Modal
                open={isModalOpen}
                onCancel={handleCancel}
                className='responsiveModal'
                footer={null}
                style={{ top: '10px', }}
            >
                <div
                    className='scroll_class'
                    style={{
                        height: 'auto',
                        width: '100%',
                        maxHeight: '85vh',
                        overflowY: 'scroll',
                        marginTop: '12px'
                    }}>
                    <div className='modal_main_div'>
                        {/* <center><h2>{language?.GolfReservationRegistration}</h2></center> */}
                        <center><h2>{language?.RegisterAuctionPost}</h2></center>

                        <div className='row_fields' style={{ marginBottom: 10, alignItems: 'baseline' }} >
                            <p >{language?.GolfClubName}</p>
                            <Button
                                className='input_field_buttons2'
                                size='middle'
                                onClick={() => setShowGolfClub(true)}
                            >{language?.Search}</Button>
                        </div>

                        <SearchGolfClub setAddress={setAddress} golfClubData={golfClubData} setGolfClubData={setGolfClubData} showGolfClub={showGolfClub} setShowGolfClub={setShowGolfClub} />

                        {golfClubData?._id ?
                            <div className='row_fields1' style={{ maxWidth: 300, marginBottom: 20 }}>
                                <Image width={100} height={100} src={golfClubData?.imgUrl} />
                                <div style={{ marginTop: -10 }}>
                                    <h2>{golfClubData?.clubName}</h2>
                                    <p>{golfClubData?.region}</p>
                                    <Button onClick={() => { setGolfClubData(null); setAddress(null) }}>{language?.Delete}</Button>
                                </div>
                            </div> : null}

                        <Form name='time_related_controls' requiredMark={false} {...formItemLayout} onFinish={onFinish}>
                            <Form.Item name='reservedDate' label={language?.ReservedDate} {...rangeConfig}>
                                <RangePicker placeholder={[language?.Startdate, language?.Enddate]} style={{ width: '100%' }} />

                            </Form.Item>

                            <Form.Item
                                name='reservedTime'
                                label={language?.ReservedTime}
                                initialValue={totalTime}
                                rules={[
                                    {
                                        required: true,
                                        // message: 'Please input your username!',
                                    },
                                ]}
                            >
                                <div className='row_fields1'>
                                    <Select
                                        // defaultValue='A.M'
                                        className='special_width'
                                        value={am}
                                        onChange={(value) => { setAm(value); setTotalTime(hr + ':' + min + ' ' + value) }}
                                    >
                                        <Option value='am'>A.M.</Option>
                                        <Option value='pm'>P.M.</Option>
                                    </Select>
                                    <Select
                                        style={{
                                            width: '35%'
                                        }}
                                        value={hr}
                                        onChange={(value) => { setHr(value); setTotalTime(value + ':' + min + ' ' + am) }}
                                    >
                                        {nums?.map((v, i) => (<Option key={i} value={v}>{v}</Option>))}
                                    </Select>
                                    <Select
                                        style={{
                                            width: '35%'
                                        }}
                                        value={min}
                                        onChange={(value) => { setMin(value); setTotalTime(hr + ':' + value + ' ' + am) }}
                                    >
                                        {mins?.map((v, i) => (<Option key={i} value={v}>{v}</Option>))}
                                    </Select>
                                </div>
                            </Form.Item>

                            <Form.Item
                                name='greenFee'
                                label={language?.GreenFee}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.Pleaseinputgreenfee,
                                    },
                                ]}
                            >
                                <div>
                                    <Input
                                        suffix={language?.KRW}
                                        placeholder={language?.GreenFee}
                                        maxLength={7}
                                        value={greenFee}
                                        onChange={(e) => setgreenFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item
                                name='transferFee'
                                label={language?.TransferFee}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.PleaseinputTransferFee,
                                    },
                                ]}
                                // initialValue={0}
                            >
                                <div className='row_fields1'>

                                    <Input
                                        min={0}
                                        maxLength={7}
                                        suffix={language?.KRW}
                                        placeholder={language?.TransferFee}
                                        value={transferFee}
                                        onChange={(e) => settransferFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                    />
                                    <Button onClick={() => (settransferFee('0'), form?.setFieldValue({transferFee:'0'}))} style={{ width: 150, marginLeft: 10, backgroundColor: '#f4f4f4' }}>{lanKorCondition ? 'No Transfer Fee' : '양도비 없음'}</Button>
                                </div>
                            </Form.Item>

                            <Form.Item
                                name='advanceFee'
                                label={<h4 style={{ marginBottom: 0 }}>{language?.advanceFee}</h4>}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.advanceFeeAmountHere,
                                    },
                                ]}
                            >
                                <div>
                                    <Input
                                        min={0}
                                        suffix={language?.KRW}
                                        maxLength={7}
                                        placeholder={language?.advanceFeeAmountHere}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item
                                name='memo'
                                label={language?.Memo}
                            >
                                <div className='row_fields1'>
                                    <TextArea rows={4} />
                                </div>
                            </Form.Item>

                            <Form.Item
                                name='TermAndConditions'
                                label={language?.TermCondition}
                                valuePropName='checked'
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        validator: (_, value) =>
                                            value ? Promise.resolve() : Promise.reject(new Error(language?.Shouldacceptagreement)),
                                    }
                                ]}
                            >
                                <Checkbox />
                            </Form.Item>

                            <div className='agreement scroll_class' style={{ marginBottom: 20 }}>
                                {/* <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eum porro assumenda, in dicta ab fuga, vero quasi nostrum molestiae, corporis perferendis similique quis deleniti ullam. Recusandae sequi quam vitae cumque! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae, nihil cupiditate rerum mollitia, nemo vitae temporibus amet quos esse architecto quod repellat laboriosam explicabo similique, alias beatae odio possimus? Accusamus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque blanditiis eaque saepe odio voluptas obcaecati at voluptatum, aliquid omnis laborum libero numquam architecto sit! Architecto quas ratione alias debitis deserunt!</p> */}
                                <p>{language?.TAC1}</p>
                                <p>{language?.TAC2}</p>
                                <p>{language?.TAC3}</p>
                                <p>{language?.TAC4}</p>
                                <p>{language?.TAC5}</p>
                                <p>{language?.TAC6}</p>
                                <p>{language?.TAC7}</p>
                            </div>


                            <Button loading={loading} className='req_exc_btn' type='primary' htmlType="submit" /* type="primary" / / onClick={handleCancel} */>{language?.Register}</Button>
                        </Form>


                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ReservationModal