import { Button, Card, Typography } from 'antd'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { AUTH, POST } from '../../utils/apis'
import { passMID } from '../../utils/constants'
import Header from '../Home/Header'

const { Title } = Typography;

let passVerOrigin = `https://securetech.vmvm.co.kr`
if (process.env.NODE_ENV === 'development') {
    passVerOrigin = `http://localhost:3000`
}

const gridStyle = {
    width: '100%',
    textAlign: 'center',
}

const FindPWGuide = (props) => {
    const { language, history } = props
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [instaId, setInstaId] = useState(null)
    const path = history?.location?.pathname
    const [pathType, setPathType] = useState('individual-signup')
    const formData = useRef(null)
    const id = new URLSearchParams(location.search).get('id')
    console.log('id...', id)
    if (id == 'NOT-FOUNd') {
        return errorMessage(lanKorCondition ? language?.ThereIsNoAccount : language?.ThereIsNoAccount)
    }

    useEffect(() => {
        if (instaId) {
            const domain = 'https://kssa.inicis.com'
            formData.current.action = `${domain}/init`
            formData.current.method = 'get'
            formData.current.submit()
        }
    }, [instaId])

    const inputRef = useRef(null);
    const editInputRef = useRef(null);
    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);
    useEffect(() => {
        editInputRef.current?.focus();
    }, [inputValue]);

    const okFunc = () => {
        setPathType(path)

        let obj = {
            successUrl: `${passVerOrigin}${AUTH.GET_PASS_VERIFICATION_FIND_YOUR_PW_RES}`
        }

        axios.post(POST.PASS_VERIFICATION, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    let result = data?.result
                    var parser = new DOMParser()
                    var doc = parser.parseFromString(result, 'text/html')
                    setInstaId(doc?.querySelector('#txId')?.value)
                }
                else {
                    errorMessage()
                }
            })
            .catch((e) => console.log('e', e))
    }

    return (
        <div className='home-main'>
            <Header {...props} />

            <div className='card-parent2'>
                <div className='card2'>
                    <Title level={3}>{language?.findPw}</Title>
                    <br /><br /><hr style={{ border: '0.5px solid gray', backgroundColor: 'black' }} /><br />
                    <br /><br />

                    <div className='card_divs'>
                        <Card className='cards_width_find_id'>
                            <Card.Grid hoverable={false} style={gridStyle}>
                                <Title level={5}>{language?.Mobilephoneauthentication}</Title>
                            </Card.Grid>
                            <Card.Grid hoverable={false} style={gridStyle}>
                                <Title level={5}>{language?.findIdGuide}</Title>
                            </Card.Grid>
                        </Card>
                    </div>
                    <br />
                    <div className='burttonDiv'>
                        <Button onClick={okFunc} className="buttonOK"/*  className='AuthButton'  */ type="primary" size='large'>
                            {language?.OK}
                        </Button>
                    </div>
                </div>
            </div >
            {/* IS HTML KO IGORE KAR DO IS KE CSS NHN KARNI HA */}
            <form name="requestForm" action="" ref={formData}>
                <input type="hidden" name="mid" value={passMID} />
                <input type="hidden" name="reqSvcCd" value="01" />
                <input type="hidden" name="mTxId" value="mTxId" />
                <input type="hidden" name="nmComp" value="KG이니시스" />
                <input type="hidden" name="noComp" value="2208155597" />
                <input type="hidden" id="myOS" name="myOS" value="" />
                <input type="hidden" id="myBrowser" name="myBrowser" value="" />
                < input type="hidden" name="successUrl" value={passVerOrigin + AUTH.GET_PASS_VERIFICATION_FIND_YOUR_PW_RES} />
                <input type="hidden" name="failUrl" value={passVerOrigin + AUTH.FAIL_URL} />
                <input type="hidden" name="identifier" value="" />

                <input type="hidden" id="txId" name="txId" value={instaId} />
                <input type="hidden" id="flgFixedUser" name="flgFixedUser" value="N" />
                <input type="hidden" id="userName" name="userName" value="" />
                <input type="hidden" id="userPhone" name="userPhone" value="" />
                <input type="hidden" id="userBirth" name="userBirth" value="" />
                <input type="hidden" id="userHash" name="userHash" value="" />

                <input type="hidden" id="agencyList" name="agencyList" value="TOSS,PASS,SHINHAN,SAMSUNG,KB,NAVER,KAKAO,PAYCO,KFTC" />
                <input type="hidden" id="agencyListSize" name="agencyListSize" value="9" />

                <input type="hidden" id="isDirect" name="isDirect" value="false" />
                <input type="hidden" id="directAgency" name="directAgency" value="" />
                <input type="hidden" id="req_url" name="req_url" value={passVerOrigin + '/login'} />
            </form>
        </div>
    )
}

export default FindPWGuide