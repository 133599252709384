import { Button, Checkbox, DatePicker, Form, Image, Input, Modal, Select } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { HiCheckCircle } from 'react-icons/hi'
import { useHistory } from 'react-router-dom'
import { ADMIN, POST } from '../../utils/apis'
import { allPaths, regions, regionsKr } from '../../utils/constants'
import { errorMessage, MyInfoAlert, successMessage } from '../../utils/helpers'
import CoustomDatePicker from '../DatePicker/DatePicker'
import SearchGolfClub from './SearchGolfClub'

const { RangePicker } = DatePicker


const { TextArea } = Input
const { Option } = Select


const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
        md: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
        md: {
            span: 16,
        },
    },
}

const ConfirmationRegistration = (props) => {
    const { user, language, lanKorCondition, getGolfReservation } = props
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [am, setAm] = useState('am')
    const [hr, setHr] = useState('1')
    const [min, setMin] = useState('1')
    const [totalTime, setTotalTime] = useState(hr + ':' + min + ' ' + am)
    const [isModalOpen2, setIsModalOpen2] = useState(false)
    const [isModalOpen3, setIsModalOpen3] = useState(false)
    const [showGolfClub, setShowGolfClub] = useState(false)
    const [golfClubData, setGolfClubData] = useState(null)
    const [greenFee, setGreenFee] = useState(null)
    const [transferFee, setTransferFee] = useState(null)
    const [address, setAddress] = useState(null)
    const [loading, setLoading] = useState(false)
    // const [selectedTicket, setSelectedTicket] = useState({})
    const [golfClub, setGolfClubName] = useState(null)
    const [golfClubRequest, setGolfClubRequest] = useState(false)
    const history = useHistory()

    const rangeConfig = {
        rules: [
            {
                type: 'array',
                required: true,
                message: language?.Pleaseselectdate,
            },
        ],
    }
    const config = {
        rules: [
            {
                type: 'object',
                required: true,
                message: language?.Pleaseselectdate,
            },
        ],
    }
    const options = [
        {
            eng: '-From low to high (based on current price)',
            kr: ' 저가순 '
        },
        {
            eng: '-From high to low (based on current price)',
            kr: '고가순'
        },
        {
            eng: 'Latest (based on current price)',
            kr: '최신순'
        },
        {
            eng: 'Order of number of Transactions',
            kr: '거래건이 많은 순'
        }]
    const nums = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
    const mins = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60']

    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }
    const showModal2 = () => {
        setIsModalOpen2(true)
        setIsModalOpen(false)
    }
    const handleCancel2 = () => {
        setIsModalOpen2(false)
    }
    const showModal3 = () => {
        setIsModalOpen3(true)
        setIsModalOpen2(false)
    }
    const handleOk3 = () => {
        setIsModalOpen3(false)
    }
    const handleCancel3 = () => {
        setIsModalOpen3(false)
    }

    // console.log('golfClubData', golfClubData)
    const onFinish = (fieldsValue) => {
        setLoading(true)
        const values = {
            ...fieldsValue
        }

        values.userId = user?._id
        values.address = address
        values.region = golfClubData?.region
        values.golfClub = golfClubData?._id
        values.greenFee = +values.greenFee
        values.reservedTime = totalTime

        !golfClubRequest && (values.region = golfClubData?.region || null)
        !golfClubRequest && (values.golfClub = golfClubData?._id || null)
        golfClubRequest && (values.golfClubRequest = fieldsValue?.golfClubRequest || null)

        console.log('values', values)
        // return true
        axios.post(POST?.GOLF_RESERVATION_REGISTRATION, values)
            .then((res) => {
                const { data } = res
                console.log('data', data)
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    setIsModalOpen(false)
                    setGolfClubData(null)
                    setGreenFee(null)
                    setTransferFee(null)
                    getGolfReservation()
                    form.resetFields()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }


    const onChanges = (value) => {
        let new_value = golfClub?.find((v) => v?._id === value)
        setGolfClubData(new_value)
        setAddress(new_value?.clubAddress)
    }

    const onSearch = (value) => {
        console.log('search:', value)
    }

    let golfClubName = () => {
        axios.get(ADMIN.GOLF_CLUB)
            .then((res) => {

                const { data } = res
                // console.log('data====>',data)
                if (data.success) {
                    setGolfClubName(data?.data)
                }
                else {
                    console.log('runing')
                }
            })
            .catch((e) => {
                console.log('err', e)
            })
    }


    const setExpiryDate = (e) => {
        let date = moment(e?._d).add(1, 'days').format('YYYY-MM-DD')
        form.setFieldValue('reservedDate', moment(date, 'YYYY-MM-DD'))
    }

    useEffect(() => {
        golfClubName()
    }, [])


    return (
        <>
            {user?._id && user?.telNo && user?.bank && user?.bankAccountNumber ?
                <button className='upload_sharing_btn' style={{ height: '45px', width: '200px', marginTop: '10px', cursor: 'pointer' }} onClick={showModal}>
                    {lanKorCondition ? "golf reservation ticket Confirmation of registration" : "골프 예약권 등록하기"}
                </button> :
                <button className='upload_sharing_btn' style={{ height: '45px', width: '200px', marginTop: '10px', cursor: 'pointer' }} onClick={() => { MyInfoAlert(language) }}>
                    {lanKorCondition ? "golf reservation ticket Confirmation of registration" : "골프 예약권 등록하기"}
                </button>
            }
            <Modal
                open={isModalOpen}
                onCancel={handleCancel}
                className='responsiveModal'
                footer={null}
                style={{ top: '10px', }}
            >
                <div
                    className='scroll_class'
                    style={{
                        height: 'auto',
                        width: '100%',
                        maxHeight: '85vh',
                        overflowY: 'scroll',
                        marginTop: '12px'
                    }}>
                    <div className='modal_main_div'>
                        <center><h2>{language?.GolfReservation}</h2></center>

                        {!golfClubRequest &&
                            <div className='row_fields' style={{ marginBottom: 10, alignItems: 'baseline' }} >
                                <p style={{ marginLeft: 0 }}>{language?.GolfClubName}</p>
                                <Button
                                    className='input_field_buttons2'
                                    size='middle'
                                    onClick={() => setShowGolfClub(true)}
                                >{language?.Search}</Button>
                            </div>}
                        <SearchGolfClub language={language} setAddress={setAddress} golfClubData={golfClubData} setGolfClubData={setGolfClubData} showGolfClub={showGolfClub} setShowGolfClub={setShowGolfClub} />
                        {golfClubData?._id && !golfClubRequest ?
                            <div className='row_fields1' style={{ maxWidth: 300, marginBottom: 20 }}>
                                <Image width={100} height={100} src={golfClubData?.imgUrl} />
                                <div style={{ marginTop: -10 }}>
                                    <h2>{golfClubData?.clubName}</h2>
                                    {/* <p>{golfClubData?.region}</p> */}
                                    <p>{lanKorCondition ? golfClubData?.region : regionsKr[regions?.indexOf(golfClubData?.region)]?.TitleKr}</p>

                                    <Button onClick={() => { setGolfClubData(null); setAddress(null) }}>{lanKorCondition ? 'Delete' : '삭제'}</Button>
                                </div>
                            </div> : null}

                        <Form name='time_related_controls' form={form} requiredMark={false} {...formItemLayout} onFinish={onFinish}>

                            {/* <p style={{ marginLeft: 10 }}>{language?.GolfClubName}</p> */}
                            {!golfClubRequest ?
                                <Form.Item
                                    name='address'
                                    label={language?.golfclubAddress}
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'Please input Address!',
                                    //     },
                                    // ]}
                                    initialValue={address}
                                >
                                    <div className='row_fields1'>

                                        <Input
                                            placeholder={language?.golfclubAddress}
                                            type='text'
                                            style={{ width: '100%' }}
                                            value={address}
                                            disabled
                                        // onChange={(e) => setAddress(e?.target?.value)}
                                        />
                                    </div>
                                </Form.Item> :
                                <Form.Item
                                    name='golfClubRequest'
                                    label={'Club name'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'please enter club name!',
                                        },
                                    ]}
                                >
                                    <div className='row_fields1'>

                                        <Input
                                            placeholder={'Club name'}
                                            type='text'
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </Form.Item>}
                            <br />
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                {!golfClubRequest ?
                                    <Button onClick={() => setGolfClubRequest(true)} type='primary' style={{ backgroundColor: '#727171', border: 'none', color: 'white' }}>골프장 추가 요청
                                    </Button>
                                    :
                                    <Button onClick={() => setGolfClubRequest(false)} type='primary' style={{ backgroundColor: '#727171', border: 'none', color: 'white' }}>골프장 검색
                                    </Button>
                                }
                            </div>
                            <br />
                            <Form.Item name='reservedDate' label={language?.ReservedDate}{...config}>
                                <CoustomDatePicker placeholder={language?.Selectdate} setDate={setExpiryDate} />
                            </Form.Item>
                            <Form.Item
                                name='reservedTime'
                                label={language?.ReservationTime}
                                initialValue={totalTime}
                                rules={[
                                    {
                                        required: true,
                                        // message: 'Please input your username!',
                                    },
                                ]}
                            >
                                <div className='row_fields1'>
                                    <Select
                                        // defaultValue='A.M'
                                        className='special_width'
                                        value={am}
                                        onChange={(value) => { setAm(value); setTotalTime(hr + ':' + min + ' ' + value) }}
                                    >

                                        <Option value='am'>{lanKorCondition ? 'AM' : '오전'}</Option>
                                        <Option value='pm'>{lanKorCondition ? 'PM' : '오후'}</Option>
                                    </Select>
                                    <Select
                                        style={{
                                            width: '35%'
                                        }}
                                        value={hr}
                                        onChange={(value) => { setHr(value); setTotalTime(value + ':' + min + ' ' + am) }}
                                    >
                                        {nums?.map((v, i) => (<Option key={i} value={v}>{v}</Option>))}
                                    </Select>
                                    <Select
                                        style={{
                                            width: '35%'
                                        }}
                                        value={min}
                                        onChange={(value) => { setMin(value); setTotalTime(hr + ':' + value + ' ' + am) }}
                                    >
                                        {mins?.map((v, i) => (<Option key={i} value={v}>{v}</Option>))}
                                    </Select>
                                </div>
                            </Form.Item>

                            <Form.Item
                                name='greenFee'
                                label={language?.GreenFee}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.Pleaseinputgreenfee,
                                    },
                                ]}
                            >
                                <div>
                                    <Input
                                        suffix={language?.KRW}
                                        min={0}
                                        maxLength={10}
                                        value={greenFee}
                                        onChange={(e) => setGreenFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item
                                name='transferFee'
                                label={language?.transferFee}
                                rules={[
                                    {
                                        // required: true,
                                        // message: language?.PleaseinputTransferFee,
                                        validator: async () => {
                                            if (!transferFee) {
                                                return Promise.reject(new Error(language?.PleaseinputTransferFee))
                                            }
                                        }
                                    },
                                ]}
                            >
                                <div className='row_fields1'>
                                    <Input
                                        suffix={language?.KRW}
                                        // min={0}
                                        maxLength={10}
                                        value={transferFee}
                                        onChange={(e) => setTransferFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                    />
                                    <Button onClick={() => setTransferFee('0')} style={{ width: 150, marginLeft: 10, backgroundColor: '#f4f4f4' }}>{lanKorCondition ? 'No Transfer Fee' : '양도비 없음'}</Button>
                                </div>
                            </Form.Item>

                            <Form.Item
                                name='memo'
                                label={language?.Memo}
                            >
                                <div className='row_fields1'>
                                    <TextArea maxLength={200} rows={4} />
                                </div>
                            </Form.Item>

                            <Form.Item
                                name='TermAndConditions'
                                label={language?.TermCondition}
                                valuePropName='checked'
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        validator: (_, value) =>
                                            value ? Promise.resolve() : Promise.reject(new Error(language?.Shouldacceptagreement)),
                                    }
                                ]}
                            >
                                <Checkbox />
                            </Form.Item>

                            <div className='agreement scroll_class' style={{ marginBottom: 20 }}>
                                <p>{language?.TAC1}</p>
                                <p>{language?.TAC2}</p>
                                <p>{language?.TAC3}</p>
                                <p>{language?.TAC4}</p>
                                <p>{language?.TAC5}</p>
                                <p>{language?.TAC6}</p>
                                <p>{language?.TAC7}</p>
                            </div>


                            <Button loading={loading} className='req_exc_btn' type='primary' htmlType='submit' /* type='primary' */ /* onClick={handleCancel} */>{language?.Register}</Button>
                        </Form>


                    </div>
                </div>
            </Modal>
            <Modal
                open={isModalOpen2}
                onCancel={handleCancel2}
                footer={[<Button className='req_exc_btn' type='primary' onClick={showModal3}>{language?.BuyThisTicket}</Button>]}
                width={800}
            >
                <h2 style={{ textAlign: 'center' }}>{language?.Purchase}</h2>
                <p style={{ textAlign: 'left' }}>
                    {language?.MembershipToTrade}
                </p>
                <div className='main_modal_con'>
                    <div className='modal_first_panel'>
                        <div className='column-1 box'>
                            <h4>{language?.GolfClubName}</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>East Valley CC</p>
                        </div>
                    </div>
                    <div className='modal_first_panel'>
                        <div className='column-1 box'>
                            <h4>{language?.Type}</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>Company</p>
                        </div>
                    </div>
                    <div className='modal_first_panel'>
                        <div className='column-1 box'>
                            <h4>{lanKorCondition ? 'Member' : language?.MemberFee}/{lanKorCondition ? 'nonMember' : language?.NonMemberFee}</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>Member Fee 5 million won/ Non-Member Fee 30 millio won</p>
                        </div>
                    </div>
                    <div className='modal_first_panel'>
                        <div className='column-1 box'>
                            <h4>{language?.RightPrice}</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>80 million won</p>
                        </div>
                    </div>
                    <div className='modal_first_panel'>
                        <div className='column-1 box'>
                            <h4>{language?.ReservedDate}</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>2022.4.22 | 13:30</p>
                        </div>
                    </div>
                    <div className='modal_first_panel'>
                        <div className='column-1 box'>
                            <h4>{language?.ExpirationDate}</h4>
                        </div>
                        <div className='column-2 box'>
                            <p>2022.4.22</p>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                open={isModalOpen3}
                onCancel={handleCancel3}
                footer={[<div className='message_modal'><Button className='message_modal_btn' type='primary' onClick={() => {
                    handleOk3(); history.push({
                        pathname: allPaths?.MYPAGE,
                        search: `?membershipTransaction`
                    })
                }}>{lanKorCondition ? 'Go To My Page' : language?.GoTicketSalesRight}</Button>
                    <Button className='message_modal_btn marginLeft' type='primary' onClick={handleOk3}>{lanKorCondition ? 'Chat Bot' : language?.TalkUser}</Button></div>]}
                width={800}
            >
                <h2 style={{ textAlign: 'center' }}>{language?.Completion}</h2>
                <center><HiCheckCircle className='tick_icon' /></center>
                <h4 style={{ textAlign: 'center' }}>
                    {language?.ExchangeRentRequest}
                </h4>
                <h4 style={{ textAlign: 'center' }}>
                    {language?.PleaseWaitFor}
                </h4>
            </Modal>
        </>
    )
}

export default ConfirmationRegistration