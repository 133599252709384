import React, { useEffect } from 'react'
import { hot } from 'react-hot-loader/root'
import AOS from 'aos'
import { Routes } from './Config/routes'
import './App.css'
import 'antd/dist/antd.min.css'

// import LocaleProvider from 'antd/lib/locale-provider'
// import ko_KR from 'antd/lib/locale-provider/ko_KR';


AOS.init()

const App = () => {
  return (
    <div>
      {/* <LocaleProvider locale={ko_KR}> */}
        <Routes />
      {/* </LocaleProvider> */}
    </div>
  )
}

export default hot(App)