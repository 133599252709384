import React, { useState, useEffect, useRef } from 'react'
import { Tabs } from 'antd'
import Header from '../Home/Header'
import MyPageTab from '../../Components/MyPageTabs/MyPageTab'
import MemberTransTab from '../../Components/MyPageTabs/MemberTransactionTab'
import GolfReservation from '../../Components/TicketShareTabs/GolfReservation'
import AuctionTab from '../../Components/MyPageTabs/AuctionTab'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { ChatMenu } from '../../Components'
import MembershipTransactinTab from '../../Components/MyPageTabs/MembershipTransactionTab'
import { allPaths } from '../../utils/constants'

const { TabPane } = Tabs
const MyPage = (props) => {
    const { language, user,location,history } = props
    const userName = new URLSearchParams(location.search).get('userName')
    const userBirthday = new URLSearchParams(location.search).get('userBirthday')
    const userPhone = new URLSearchParams(location.search).get('userPhone')
    const membershipTransaction = new URLSearchParams(location.search).get('membershipTransaction')
    const width = window.innerWidth

    // const membershipTransactionRoute = new URLSearchParams(location)
    // console.log('membershipTransactionRoute', location?.search?.includes('membershipTransaction'))

    const onChangeTab = (key) => {
        history.replace(allPaths?.MYPAGE)
    }

    if (!user) {
        return (<Redirect to={'/login'} />)
    }

    return (
        // console.log(width, "=========="),
        <div className='my_page_cont'>
            <Header language={language} goto={'home'} {...props} />
            <ChatMenu language={language}  {...props} />

            {width > 500 ?
                <div className='tab_con_mypage' >
                    <Tabs tabBarGutter={20} className='tab_menu' defaultActiveKey={location?.search?.includes('membershipTransaction') ? '2' : '1'} onChange={onChangeTab} centered={true}>
                        <TabPane className='pri_color' tab={language?.MyPage} key='1'>
                            <MyPageTab language={language} {...props} />
                        </TabPane>
                        <TabPane tab={language?.MemberTransaction} key='2'>
                            <MembershipTransactinTab language={language} {...props} />
                        </TabPane>
                        <TabPane tab={language?.AuctionDeal} key='3'>
                            <AuctionTab language={language} {...props} />
                        </TabPane>
                        <TabPane tab={language?.GolfReservation} key='4'>
                            <GolfReservation language={language} {...props} />
                        </TabPane>
                    </Tabs>
                </div>
                :
                <div >
                    <Tabs tabBarGutter={10} className='tab_menu_auc_child tab_con_auction_child_tab tab_con_auction_child' defaultActiveKey='1' onChange={onChangeTab} centered={true}>

                        <TabPane className='pri_color' tab={language?.MyPage} key='1'>
                            <MyPageTab language={language} {...props} />
                        </TabPane>
                        <TabPane tab={language?.MemberTransaction} key='2'>
                            <MembershipTransactinTab language={language} {...props} />
                        </TabPane>
                        <TabPane tab={language?.AuctionDeal} key='3'>
                            <AuctionTab language={language} {...props} />
                        </TabPane>
                        <TabPane tab={language?.GolfReservation} key='4'>
                            <GolfReservation language={language} {...props} />
                        </TabPane>
                    </Tabs>
                </div>}
        </div >
    )
}


export default MyPage