import { Button, Input } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { useState } from 'react'
// import { ButtonLogout } from '../../Components'
import { Radio } from 'antd';
import moment from 'moment-timezone';
import axios from 'axios';
import { HiUserCircle } from "react-icons/hi"

const GolfReservationPaymentInfo = (props) => {
    const { data, lanKorCondition, language } = props
    console.log("data**", data)
    const [value, setValue] = useState(data?.paymentId?.transactionType);
    const [ticketData, setTicketData] = useState(data);

    const options = [
        { label: lanKorCondition ? 'Bank Wire' : '계좌이체', value: 'Bank Wire' },
        { label: lanKorCondition ? 'Cash' : '현금', value: 'Cash' },
        { label: lanKorCondition ? 'ETC' : '기타', value: 'ETC' },
    ];

    const onChange = ({ target: { value } }) => {
        console.log('radio4 checked', value);
        setValue(value);
    };
    console.log(ticketData, "=ticketData")
    return (
        <>
            <div className='settingDiv'>
                {/* Name Email ID etc */}
                <div className='nameEmailDiv'>
                    <div className='divHeader'>
                        <div className='headerInner'>
                            <h3 className='headerText'>
                                {lanKorCondition ? 'View details' : '회원권 거래정보'}
                            </h3>
                        </div>
                        <div className='borderBottom'></div>
                    </div>

                    <div className='ViewTransactionCardsDiv '>
                        <div className='ViewTransactionCard paddingTopnone'>
                            <h4 className='ViewTransactionCardTextBold size'>
                                {lanKorCondition ? 'Seller Info' : '판매자 정보'}
                            </h4>
                            <div className='ViewTransactionCardTextDiv'>
                                <div className='ViewTransactionCardTextBoldDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        {'ID'}
                                    </h5>
                                </div>
                                <div className='ViewTransactionCardTextNotBoldDiv'>
                                    <h5 className='ViewTransactionCardTextNotBold textElipsis'>
                                        {data?.paymentId?.userId?._id ? data?.paymentId?.userId?._id : '----'}
                                    </h5>
                                </div>
                            </div>
                            <div className='ViewTransactionCardTextDiv'>
                                <div className='ViewTransactionCardTextBoldDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        {language?.Name}
                                    </h5>
                                </div>
                                <div className='ViewTransactionCardTextNotBoldDiv'>
                                    <h5 className='ViewTransactionCardTextNotBold textElipsis'>
                                        {data?.paymentId?.userId?.fullName ? data?.paymentId?.userId?.fullName : '----'}

                                    </h5>
                                </div>
                            </div>
                            <div className='ViewTransactionCardTextDiv'>
                                <div className='ViewTransactionCardTextBoldDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        {language?.Email}
                                    </h5>
                                </div>
                                <div className='ViewTransactionCardTextNotBoldDiv'>
                                    <h5 className='ViewTransactionCardTextNotBoldEmail textElipsis'>
                                        {data?.paymentId?.userId?.email ? data?.paymentId?.userId?.email : '----'}

                                    </h5>
                                </div>
                            </div>
                            <div className='ViewTransactionCardTextDiv'>
                                <div className='ViewTransactionCardTextBoldDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        {language?.PhoneNumber}
                                    </h5>
                                </div>
                                <div className='ViewTransactionCardTextNotBoldDiv'>
                                    <h5 className='ViewTransactionCardTextNotBold textElipsis'>
                                        {data?.paymentId?.userId?.telNo ? data?.paymentId?.userId?.telNo : '----'}
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className='ViewTransactionCardCenter paddingTopnone'>
                            <h4 className='ViewTransactionCardTextBold size'>
                                {lanKorCondition ? 'Buyer Info' : '구매자 정보'}
                            </h4>
                            <div className='ViewTransactionCardTextDiv'>
                                <div className='ViewTransactionCardTextBoldDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        {"ID"}
                                    </h5>
                                </div>
                                <div className='ViewTransactionCardTextNotBoldDiv'>
                                    <h5 className='ViewTransactionCardTextNotBold textElipsis'>
                                        {data?.paymentId?.buyerId?._id ? data?.paymentId?.buyerId?._id : '----'}
                                    </h5>
                                </div>
                            </div>
                            <div className='ViewTransactionCardTextDiv'>
                                <div className='ViewTransactionCardTextBoldDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        {language?.Name}
                                    </h5>
                                </div>
                                <div className='ViewTransactionCardTextNotBoldDiv'>
                                    <h5 className='ViewTransactionCardTextNotBold textElipsis'>
                                        {data?.paymentId?.buyerId?.fullName ? data?.paymentId?.buyerId?.fullName : '----'}

                                    </h5>
                                </div>
                            </div>
                            <div className='ViewTransactionCardTextDiv'>
                                <div className='ViewTransactionCardTextBoldDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        {language?.Email}
                                    </h5>
                                </div>
                                <div className='ViewTransactionCardTextNotBoldDiv'>
                                    <h5 className='ViewTransactionCardTextNotBoldEmail textElipsis '>
                                        {data?.paymentId?.buyerId?.email ? data?.paymentId?.buyerId?.email : '----'}
                                    </h5>
                                </div>
                            </div>
                            <div className='ViewTransactionCardTextDiv'>
                                <div className='ViewTransactionCardTextBoldDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        {language?.PhoneNumber}
                                    </h5>
                                </div>
                                <div className='ViewTransactionCardTextNotBoldDiv'>
                                    <h5 className='ViewTransactionCardTextNotBold textElipsis'>
                                        {data?.paymentId?.buyerId?.telNo ? data?.paymentId?.buyerId?.telNo : '----'}
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className='ViewTransactionCardLast'>
                            <div className='TransactionAmountInputDiv'>
                                <div className='inputTransactionAmountLabelDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        01){lanKorCondition ? 'Person in charge' : '담당자 이름'}
                                    </h5>
                                </div>
                                <div className='inputTransactionAmountDiv'>
                                    <Input disabled value={data?.paymentId?.paymentIncharge} className='inputTransactionAmount' />
                                </div>
                            </div>
                            <div className='TransactionAmountInputDiv'>
                                <div className='inputTransactionAmountLabelDiv'>
                                    <h5 className='ViewTransactionCardTextBold'>
                                        02){language?.Memo}
                                    </h5>
                                </div>
                                <div className='inputTransactionAmountDiv'>
                                    <TextArea
                                        disabled
                                        value={data?.paymentId?.memo}
                                        autoSize={{ minRows: 4, maxRows: 4 }}
                                        className='inputTransactionAmount'
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='nameEmailDiv'>
                    <div className='ticketsInfoCards'>
                        <div className='ticketsInfoCard'>
                            <div className='divHeader'>
                                <h3 className='headerText' style={{ marginBottom: '40px' }}>
                                    {lanKorCondition ? 'Payment received details' : '결제 정보'}
                                </h3>
                                <div className='borderBottom'></div>
                            </div>
                            <div className='TicketForm'>
                                <div>
                                    <h4>{lanKorCondition ? 'Date of transaction' : '거래일자'}</h4>
                                    <Input
                                        disabled
                                        value={data?.paymentId?.dateOfTransaction}
                                        className='inputTransactionAmount' />
                                </div>
                                <div>
                                    <h4>{lanKorCondition ? 'Transaction Number' : '거래금액'}</h4>
                                    <Input
                                        disabled
                                        value={data?.paymentId?.transactionNumber}
                                        className='inputTransactionAmount' suffix={language?.KRW} />
                                </div>
                                {/* <div>
                                    <h4>{lanKorCondition ? 'Admin Commission' : '가치가게 수수료'}</h4>
                                    <Input
                                        disabled
                                        value={data?.paymentId?.paymentIncharge}
                                        className='inputTransactionAmount' suffix={language?.KRW} />
                                </div> */}
                                <div>
                                    <h4>{lanKorCondition ? 'Transaction Type' : '거래방법'}</h4>
                                    <Radio.Group
                                        disabled
                                        options={options}
                                        onChange={onChange}
                                        value={value}
                                        optionType="button"
                                        buttonStyle="solid"
                                    />
                                </div>
                            </div>

                        </div>
                        {

                        }
                        <div className='ticketsInfoCard'>
                            <div className='divHeader'>
                                <h3 className='headerText' style={{ marginBottom: '40px' }}>
                                    {lanKorCondition ? 'GolfReservation Info' : '골프예약 정보 '}
                                </h3>
                                <div className='borderBottom'></div>
                            </div>
                            <div className='extraMargina' style={{ marginTop: '30px' }} >
                                <div style={{ display: "flex" }}>
                                    <h5 className='participantKeyBold'>{language?.GolfClubName}</h5>
                                    <h5 className='participantKeyTic'>
                                        {ticketData?.golfClub?.clubName}
                                    </h5>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <h5 className='participantKeyBold'>{language?.golfclubAddress}</h5>
                                    <h5 className='participantKeyTic tableEmail'>
                                        {ticketData?.golfClub?.clubAddress}
                                    </h5>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <h5 className='participantKeyBold'>{language?.Region}</h5>
                                    <h5 className='participantKeyTic tableEmail'>
                                        {ticketData?.golfClub?.region}
                                    </h5>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <h5 className='participantKeyBold'>{language?.Reserveddate}</h5>
                                    <h5 className='participantKeyTic'>
                                        <h5 className='participantKey'>{moment(ticketData?.reservedDate).format('YYYY-MM-DD')}</h5>
                                    </h5>
                                </div>
                            </div>
                            <div className=' extraMargina'  >
                                <div style={{ display: "flex" }}>
                                    <h5 className='participantKeyBold'>{language?.GreenFee}</h5>
                                    <h5 className='participantKeyTic'>
                                        {ticketData?.greenFee + ' ' + language?.KRW}
                                    </h5>
                                </div>
                            </div>
                            <div className=' extraMargina'  >
                                <div style={{ display: "flex" }}>
                                    <h5 className='participantKeyBold'>{language?.TransferFee}</h5>
                                    <h5 className='participantKeyTic'>
                                        {ticketData?.transferFee + ' ' + language?.KRW}
                                    </h5>
                                </div>
                            </div>
                            {/* ///////// */}
                            {
                                data?.buyerId?._id ?
                                    <>
                                        <div className='divHeader ' style={{ marginTop: '40px' }}>
                                            <h3 className='headerText' style={{ marginBottom: '40px' }}>
                                                {lanKorCondition ? 'Buyer’s bank info' : '판매자 계좌정보'}
                                            </h3>
                                            <div className='borderBottom'></div>
                                        </div>
                                        <div className='extraMargina' style={{ marginTop: '40px' }} >
                                            <div style={{ display: "flex" }}>
                                                <h5 className='participantKeyBold'>{lanKorCondition ? 'Bank Name' : '은행명'}</h5>
                                                <h5 className='participantKeyTic'>{data?.buyerId?.bank
                                                }</h5>
                                            </div>
                                            <div style={{ display: "flex" }}>
                                                <h5 className='participantKeyBold'>{lanKorCondition ? 'Name' : '예금주'}</h5>
                                                <h5 className='participantKeyTic'>{data?.buyerId?.fullName}</h5>
                                            </div>
                                            <div style={{ display: "flex" }}>
                                                <h5 className='participantKeyBold'>{lanKorCondition ? 'Bank Account Numnber' : '계좌번호'}</h5>
                                                <h5 className='participantKeyTic'>{data?.buyerId?.bankAccountNumber}</h5>
                                            </div>
                                        </div>
                                    </> : null}
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default GolfReservationPaymentInfo
