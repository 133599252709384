import { Button, Checkbox, DatePicker, Form, Image, Input, Modal, Select, Tag, Popconfirm, Divider } from 'antd'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { POST, AUTH, GET, ADMIN } from '../../utils/apis'
import { errorMessage, successMessage, requiredMessage, inputPlace, isUserLoginApi } from '../../utils/helpers'
import moment, { lang } from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser, removeUser, tempUsers } from '../../Redux/actions/authActions'
import { setRouteBreadCrumbs, clearRouteBreadCrumbs } from '../../Redux/actions/routeActions'
import { regions, regionsKr, passMID } from '../../utils/constants'
import CheckableTag from 'antd/lib/tag/CheckableTag'
import DaumPostcodeEmbed from 'react-daum-postcode'
import { useSearchParams } from 'react-router-dom';


const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
        md: {
            span: 7,
        },
        lg: {
            span: 7,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 19,
        },
        md: {
            span: 18,
        },
        lg: {
            span: 18,
        },
    },
}


let passVerOrigin = `https://securetech.vmvm.co.kr`
if (process.env.NODE_ENV === 'development') {
    passVerOrigin = `http://localhost:3000`
}
const EditCorporateMemberInfo = (props) => {
    const { user, showEditModal, setShowEditModal, language, lanKorCondition } = props
    const [passwordForm] = Form.useForm();
    const [changePassModal, setChangePassModal] = useState(false)
    const [changePhoneNumModal, setChangePhoneNumModal] = useState(false)
    const [changeaccModal, setChangeAccModal] = useState(false)
    const [changeaddressModal, setChangeAddressModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [deleteAddressLoading, setDeleteAddressLoading] = useState(false)
    const [deleteAdditionalInfoLoading, setDeleteAdditionalInfoLoading] = useState(false)
    const [phoneValue, setPhoneValue] = useState('')
    const [searchAddress, setSearchAddress] = useState(user?.address)
    const [searchAddressShow, setSearchAddressShow] = useState(true)
    const [selectedTags, setSelectedTags] = useState(user?.preferredRegion)
    const [selectedGolfClub, setSelectedGolfClub] = useState(user?.preferredGolfCourse);
    const [preferredRegion, setPreferredRegion] = useState(false)
    const [preferredGolfClub, setPreferredGolfClub] = useState(false)
    const [corporateDisable, setCorporateDisable] = useState(false)
    const [corpRegNum, setCorpRegNum] = useState(user?.corporateNo || '')
    const [manager, setManager] = useState(user?.managers || [])
    const formData = useRef(null)
    const [instaId, setInstaId] = useState(null)
    const tempFormData = useSelector(state => state?.authReducer?.tempUser)
    const [verifiedKeys, setVerifiedKeys] = useState([])

    let [userName, setuserName] = useState(new URLSearchParams(location.search).get('userName'))
    let [userBirthday, setuserBirthday] = useState(new URLSearchParams(location.search).get('userBirthday'))
    let [userPhone, setuserPhone] = useState(new URLSearchParams(location.search).get('userPhone'))

    const [golfClub, setGolfClubName] = useState(null)
    const dispatch = useDispatch()
    const [form] = Form.useForm()


    let golfClubName = () => {
        axios.get(ADMIN?.GOLF_CLUB)
            .then((res) => {

                const { data } = res
                if (data.success) {
                    setGolfClubName(data?.data)
                }
                else {
                }
            })
            .catch((e) => {
                console.log('err', e)
            })
    }
    // ////////////////
    const getUser = () => {
        axios.get(`${GET.GET_USER}/${user?._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    updateUser(data?.user)
                    setShowFillOut(false)
                }
            })
            .catch((e) => {
            })
    }

    const updateUser = (data) => {
        dispatch(loginUser(data))
    }
    // ////////////////


    const handleChange = (tag, checked) => {
        const nextSelectedTags = checked
            ? [...selectedTags, tag]
            : selectedTags.filter((t) => t !== tag);
        setSelectedTags(nextSelectedTags);
    }

    const handleClubChange = (tag, checked, id) => {

        const nextSelectedTags = checked ? [...selectedGolfClub, tag] : selectedGolfClub.filter((t) => t !== tag);
        setSelectedGolfClub(nextSelectedTags);
    }

    useEffect(() => {
        setSelectedTags(user?.preferredRegion)
        golfClubName()

    }, [])


    const handleComplete = (data) => {
        let fullAddress = data?.address;
        let extraAddress = '';

        if (data?.addressType === 'R') {
            if (data?.bname !== '') {
                extraAddress += data?.bname;
            }
            if (data?.buildingName !== '') {
                extraAddress += extraAddress !== '' ? `, ${data?.buildingName}` : data?.buildingName;
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        setSearchAddress(fullAddress)
        // onFinishEditAdd(fullAddress)
    }

    const handleCancel = () => {
        setSelectedTags(user?.preferredRegion)
        setSelectedGolfClub(user?.preferredGolfCourse)
        setSearchAddress(user?.address)
        setShowEditModal(false)
        setCorpRegNum(user?.corporateNo)
        dispatch(tempUsers({}))
        form.setFieldValue('corporateName', user?.corporateName)
    }

    const handleCancel1 = () => {
        setChangePassModal(false)
        setChangePhoneNumModal(false)
        setChangeAddressModal(false)
        setChangeAccModal(false)
    }

    const onFinish = () => {

        let checkVerifiedManagers = form?.getFieldValue('managers')?.length === form?.getFieldValue('managers')?.filter((v) => v?._id)?.length

        if (!checkVerifiedManagers) {
            return errorMessage('Managers must be verified')
        }

        setLoading(true)
        let obj = {
            _id: user?._id,
            address: searchAddress,
            preferredGolfCourse: selectedGolfClub,
            preferredRegion: selectedTags,
            corporateNo: corpRegNum,
            corporateName: form?.getFieldValue('corporateName'),
            managers: form?.getFieldValue('managers'),
            // managers: tempFormData?.managers?.length ? form?.getFieldValue('managers'): tempFormData?.managers ,
        }

        axios.post(AUTH?.EDIT_PREFERRED_GOLF_CLUB, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    // console.log('data', data?.data)
                    // updateUser(data?.data)
                    handleCancel()
                    getUser()

                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageKr)

            })
    }
    const openPreferredRegion = () => {
        setPreferredRegion(true)
    }
    const closePreferredRegion = () => {
        setPreferredRegion(false)
        setSelectedTags(user?.preferredRegion)
    }
    const openGolfEdit = () => {
        setPreferredGolfClub(true)
    }
    const closeGolfEdit = () => {
        setSelectedGolfClub(user?.preferredGolfCourse)
        setPreferredGolfClub(false)
        // setGolfClubName(golfClubs)
        // golfClubName()
        // getUser()
        // set
    }

    const onFinishEditPhone = (values) => {
        setLoading(true)

        values._id = user?._id
        // values.address = values?.address
        // console.log('values', values)

        axios.post(AUTH?.EDIT_PHONE, values)
            .then((res) => {
                const { data } = res
                // console.log('data', data)
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    setPhoneValue('')
                    handleCancel1()
                    getUser()

                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageKr)

            })

    }

    const deleteAdditionalInfo = () => {
        setDeleteAdditionalInfoLoading(true)

        axios.post(AUTH?.DELETE_ADDITIONAL_INFO, { _id: user?._id })
            .then((res) => {
                const { data } = res
                // console.log('data', data)
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    getUser()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setDeleteAdditionalInfoLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setDeleteAdditionalInfoLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageKr)
            })
    }

    const onFinishAddInfo = (values) => {
        setLoading(true)
        values._id = user?._id
        values.bankAccountNumber = values?.bankAccountNumber
        values.newPassword = values?.bank

        axios.post(AUTH?.EDIT_ADDITIONAL_INFO, values)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    passwordForm.resetFields()
                    setChangeAccModal(false)
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageKr)

            })
    }
    const DeletePrefeardRegioin = (vv) => {
        let newSelectedGolfClub = selectedGolfClub?.filter((v) => {
            return vv?._id !== v
        })

        setSelectedGolfClub(newSelectedGolfClub)
    }

    const onFinishPassword = (values) => {
        setLoading(true)

        values._id = user?._id
        values.password = values?.oldPassword
        values.newPassword = values?.confirm

        axios.post(AUTH?.CHANGE_PASSWORD, values)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    passwordForm.resetFields()
                    setChangePassModal(false)
                    getUser()

                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageKr)

            })
    }

    const handlePhoneInput = (e) => {
        let value = e?.target?.value?.replace(/[^0-9-]/g, '')
        let event = e?.nativeEvent?.data
        if (event && ((value?.length === 3 || value?.length === 8) || (phoneValue?.length === 3 || phoneValue?.length === 8))) {
            if (phoneValue?.length === 3 || phoneValue?.length === 9) {
                value = `${phoneValue}-${event}`
            }
            else {
                value = `${value}-`
            }
        }
        setPhoneValue(value)
    }
    const handleClickMap = () => {
        open({ onComplete: handleComplete });
    }
    const deleteAccount = () => {
        setLoading(true)
        let obj = { _id: user?._id }

        axios.post(AUTH?.DELETE_ACCOUNT, obj)
            .then(async (res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    await isUserLoginApi(user?._id, false)
                    dispatch(removeUser())
                    dispatch(clearRouteBreadCrumbs())
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageKr)
            })
    }

    const sendCorporateRegistrationNumber = () => {
        let obj = {
            corporateNo: form?.getFieldValue('corporateNo'),
            _id: user?._id
        }
        // return true
        axios.post(AUTH?.CORPORATE_REGISTRATION_NUMBER, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    setCorporateDisable(true)
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                    setCorporateDisable(false)
                }
            })
            .catch((e) => {
                console.log('e ', e)
                // setverifyDisable(false)
                // setVerifyLoading(false)
                // errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    const callPassVerificationAuth = (index) => {

        // corpRegNum
        // form.getFieldValue('corporateName')
        // searchAddress
        // selectedGolfClub
        // selectedTags

        let tempData = form?.getFieldValue()
        tempData.index = index
        tempData.temporaryCorp = user
        tempData.searchAddress = searchAddress
        tempData.selectedGolfClub = selectedGolfClub
        tempData.selectedTags = selectedTags
        tempData.corpRegNum = corpRegNum
        tempData.corporateDisable = corporateDisable
        tempData.verifiedKeys = verifiedKeys
        tempData.corporateName = form.getFieldValue('corporateName')
        if (!tempData?.managers[index]) {
            tempData.managers[index] = { representativeManager: false, managerType: '', managerEmail: '' }
        }
        dispatch(tempUsers(tempData))

        let obj = {
            successUrl: `${passVerOrigin}${AUTH.GET_PASS_VERIFICATION_CORPORATE_RES}`
        }
        // return true
        axios.post(POST.PASS_VERIFICATION, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    let result = data?.result
                    var parser = new DOMParser()
                    var doc = parser.parseFromString(result, 'text/html')
                    setInstaId(doc?.querySelector('#txId')?.value)
                }
                else {
                    errorMessage()
                }
            })
            .catch((e) => console.log('e', e))
    }

    const checkManagerEmail = (email) => {

        let cond = !user?.managers?.filter((v) => v?.managerEmail === email)?.length
        return cond

    }

    const verifyManager = (ind) => {

        if (!form?.getFieldsValue('managers')?.managers[ind]?.managerName || !form?.getFieldsValue('managers')?.managers[ind]?.managerPhone) {
            return errorMessage('Please Pass Verify Manager')
        }

        let obj = {
            managerEmail: form?.getFieldsValue('managers')?.managers[ind]?.managerEmail,
            corporateUserId: user?._id,
            managerType: form?.getFieldsValue('managers')?.managers[ind]?.managerType,
            representativeManager: Boolean(form?.getFieldsValue('managers')?.managers[ind]?.representativeManager),
            managerName: form?.getFieldsValue('managers')?.managers[ind]?.managerName,
            managerPhone: form?.getFieldsValue('managers')?.managers[ind]?.managerPhone
        }

        // return true
        axios.post(AUTH?.VERIFY_MANAGER, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
            })
            .catch((e) => {
                console.log('e ', e)
                // setverifyDisable(false)
                // setVerifyLoading(false)
                // errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    const managerCodeVerification = (ind, key) => {
        let obj = {
            managerEmail: form?.getFieldsValue('managers')?.managers[ind]?.managerEmail,
            code: form?.getFieldsValue('managers')?.managers[ind]?.managerCode
        }

        let tempData = tempFormData

        let keys = verifiedKeys
        keys.push(form?.getFieldsValue('managers')?.managers[ind]?.managerEmail)
        axios.post(AUTH?.VERIFY_MANAGER_CODE, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    tempData.managers[ind]._id = data?.data[0]?._id
                    tempData.managers[ind].representativeManager = data?.data[0]?.representativeManager
                    tempData.managers[ind].managerEmail = data?.data[0]?.managerEmail
                    tempData.managers[ind].managerType = data?.data[0]?.managerType
                    tempData.managers[ind].managerCode = obj?.code

                    form?.setFieldValue('managers', tempData.managers)
                    dispatch(tempUsers(tempData))
                    setVerifiedKeys(keys)
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
            })
            .catch((e) => {
                console.log('e ', e)
                // setverifyDisable(false)
                // setVerifyLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    useEffect(() => {
        form.setFieldValue('managers', user?.managers)
    }, [])

    useEffect(() => {
        if (instaId) {
            const domain = 'https://kssa.inicis.com'
            formData.current.action = `${domain}/init`
            formData.current.method = 'get'
            formData.current.submit()
        }
    }, [instaId])

    useEffect(() => {
        if (userName && userPhone && userBirthday) {
            let tempManager = tempFormData?.managers
            if (tempManager?.length) {
                tempManager[tempFormData?.index].managerName = userName
                tempManager[tempFormData?.index].userBirthday = userBirthday
                tempManager[tempFormData?.index].managerPhone = userPhone

                form.setFieldsValue({
                    managers: tempManager || [{ representativeManager: false, managerType: '', managerEmail: '' }],
                })
                setSearchAddress(tempFormData?.searchAddress)
                setSelectedTags(tempFormData?.selectedTags)
                setSelectedGolfClub(tempFormData?.selectedGolfClub)
                setCorpRegNum(tempFormData?.corpRegNum)
                setCorporateDisable(tempFormData?.corporateDisable)
                form.setFieldValue('corporateName', tempFormData?.corporateName)
                setShowEditModal(true)
                // setTempUser(tempFormData?.temporaryCorp)
                setVerifiedKeys(tempFormData?.verifiedKeys)
            }
        } else {
            dispatch(tempUsers({}))
        }
    }, [userName, userBirthday, userPhone])

    return (
        <>
            <Modal
                open={showEditModal}
                onCancel={handleCancel}
                className='responsiveModal'
                footer={null}
                style={{ top: '10px', }}
            >
                <div
                    className='scroll_class'
                    style={{
                        height: 'auto',
                        width: '100%',
                        maxHeight: '85vh',
                        overflowY: 'scroll',
                        marginTop: '12px'
                    }}>
                    <div className='modal_main_div'>
                        <center><h2>{language?.CorporateMember}</h2></center>

                        <div className='row_fields2' style={{ marginBottom: 10, alignItems: 'baseline' }} >
                            <p className='p_class'>{language?.Password}</p>
                            <Button
                                className='input_field_buttons3'
                                size='middle'
                                onClick={() => setChangePassModal(true)}
                            >{language?.ChangePassword}</Button>
                        </div>

                        <Form
                            {...formItemLayout}
                            form={form}
                            name='form'
                            onFinish={onFinish}
                            requiredMark={false}
                        >

                            <Form.Item
                                name='corporateName'
                                label={language?.CompanyName}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.InputyourCorporateName
                                    },
                                ]}
                                initialValue={user?.corporateName}
                            >
                                <Input
                                // className='Signup_input'
                                />
                            </Form.Item>

                            <Form.Item
                                name='corporateNo'
                                label={language?.CompanyNumberr}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.Inputyour + ' ' + language?.CorporateRegistrationNumber
                                    },
                                    {
                                        validator: async (_, names) => {
                                            if (corpRegNum?.length != 10) {
                                                return Promise.reject(new Error(lanKorCondition ? 'Registration no. must be of 10 digits' : '사업자등록번호는 10자리 입니다'))
                                            }
                                        }
                                    }
                                ]}
                                initialValue={user?.corporateNo}
                            >
                                <Input
                                    disabled={corporateDisable}
                                    size='middle'
                                    // className='Signup_input'
                                    onChange={(e) => setCorpRegNum(e.target.value.replace(/[^0-9]/g, ''))}
                                    value={corpRegNum}
                                    maxLength={10}
                                    suffix={
                                        <Button
                                            // className='input_field_buttons3'
                                            className='input_field_buttons4'
                                            size='middle'
                                            // loading={verifyLoading}
                                            disabled={(corpRegNum === user?.corporateNo) || corporateDisable || corpRegNum?.length != 10}
                                            style={(corporateDisable || corpRegNum?.length != 10 || (corpRegNum === user?.corporateNo)) ? { opacity: 0.3 } : null}
                                            onClick={sendCorporateRegistrationNumber}

                                        >
                                            {language?.check}
                                        </Button>}
                                />
                            </Form.Item>

                            <Divider style={{ backgroundColor: '#bbb', marginBottom: 20 }} />

                            <Form.List name='managers'
                                initialValue={tempFormData?.managers?.length ? tempFormData?.managers : user?.managers}
                            >
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <>

                                                <div key={key}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'representativeManager']}
                                                        valuePropName='checked'
                                                        label={language?.Manager + ' ' + (1 + name)}
                                                    >

                                                        <div style={{ display: 'flex' }}>
                                                            <Checkbox
                                                                defaultChecked={form.getFieldValue('managers')[name]?.representativeManager}
                                                            >
                                                                {language?.Representative}
                                                            </Checkbox>

                                                            <h4 onClick={() => remove(name)} style={{ color: '#00bcd4', cursor: 'pointer' }}>{language?.Delete}</h4>
                                                        </div>
                                                    </Form.Item>
                                                    <Button
                                                        onClick={() => callPassVerificationAuth(name)}
                                                        // style={ !upperFieldsDisable ? { opacity: 0.3 } : null}
                                                        disabled={form.getFieldValue('managers')[name]?.managerPhone}
                                                        type='primary'
                                                        style={{ width: '100%' }}
                                                        // size='large' >{language?.GetCertificate}</Button>
                                                        size='middle'>{language?.identifyVerification}</Button>
                                                    <br />
                                                    <br />
                                                    <div style={{ marginBottom: 20 }} className='disabled_text scroll_class'>
                                                        <table>
                                                            <tr>
                                                                <td className='first_td td_content'>{language?.PhoneNumber}:</td>
                                                                <td className='td_content'>{form.getFieldValue('managers')?.length ? form.getFieldValue('managers')[name]?.managerPhone : '-'}</td>
                                                            </tr><br />
                                                            <tr>
                                                                <td className='first_td td_content'>{language?.Name}:</td>
                                                                <td className='td_content'>{form.getFieldValue('managers')?.length ? form.getFieldValue('managers')[name]?.managerName : '-'}</td>
                                                            </tr><br />
                                                        </table>
                                                    </div>
                                                    <br />
                                                    <br />

                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'managerType']}
                                                        // label={language?.Manager + ' ' + (1 + name) + ' ' + language?.rank}
                                                        label={language?.managerPosition}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: language?.Inputyour + ' ' + language?.Manager + ' ' + (1 + name) + ' ' + language?.rank

                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                        // disabled={ !upperFieldsDisable}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'managerEmail']}
                                                        // label={language?.Manager + ' ' + (1 + name) + ' ' + language?.Email}
                                                        label={language?.managerEmail}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: language?.EnterAdmin1Email
                                                            }
                                                        ]}
                                                    >
                                                        <Input
                                                            disabled={verifiedKeys?.includes(form?.getFieldValue('managers')[name]?.managerEmail)}
                                                            size='middle'
                                                            placeholder={'example@name.com'}
                                                            suffix={
                                                                checkManagerEmail(form?.getFieldValue('managers')[name]?.managerEmail) ?
                                                                    <Button
                                                                        className='input_field_buttons3'
                                                                        size='middle'
                                                                        style={verifiedKeys?.includes(form?.getFieldValue('managers')[name]?.managerEmail) ? { opacity: 0.3 } : null}
                                                                        disabled={verifiedKeys?.includes(form?.getFieldValue('managers')[name]?.managerEmail)}
                                                                        onClick={() => verifyManager(name)}
                                                                    >
                                                                        {language?.Verification}
                                                                    </Button> : null}
                                                        />
                                                    </Form.Item>
                                                    {checkManagerEmail(form?.getFieldValue('managers')[name]?.managerEmail) ?

                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'managerCode']}
                                                            // label={language?.Manager + ' ' + (1 + name) + ' ' + language?.Email}
                                                            label={language?.verificationCode}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: language?.EnterAdmin1Email
                                                                }
                                                            ]}
                                                        >
                                                            <Input
                                                                disabled={verifiedKeys?.includes(form?.getFieldValue('managers')[name]?.managerEmail)}
                                                                size='large'
                                                                placeholder={'1234567'}
                                                                suffix={

                                                                    <Button
                                                                        className='input_field_buttons3'
                                                                        size='middle'
                                                                        style={verifiedKeys?.includes(form?.getFieldValue('managers')[name]?.managerEmail) ? { opacity: 0.3 } : null}
                                                                        disabled={verifiedKeys?.includes(form?.getFieldValue('managers')[name]?.managerEmail)}
                                                                        onClick={() => managerCodeVerification(name, key)}
                                                                    >
                                                                        {language?.confirm}
                                                                    </Button>}
                                                            />
                                                        </Form.Item> : null
                                                    }
                                                    <br />
                                                    <Divider style={{ backgroundColor: '#bbb', marginBottom: 20 }} />

                                                    <br />
                                                </div>
                                            </>
                                        ))}
                                        <Button
                                            block
                                            type='primary'
                                            className='req_exc_btn'
                                            style={fields?.length > 4 ? { opacity: 0.3 } : null}
                                            disabled={fields?.length > 4}
                                            onClick={() => add()}
                                            size='middle' >{language?.AddContactInformation}</Button>
                                        {/* <Form.Item> */}

                                        {/* </Form.Item> */}
                                    </>
                                )}
                            </Form.List>
                        </Form>

                        <br />

                        <p className='p_class' style={{ width: 145 }}>{language?.AdditionalInformation}</p>

                        <div className='row_fields2' style={{ marginBottom: 10, alignItems: 'baseline' }} >
                            <p className='p_class'>{language?.AccountInformation}</p>
                            <div>
                                <Button
                                    className='input_field_buttons4'
                                    size='middle'
                                    loading={deleteAdditionalInfoLoading}
                                    onClick={deleteAdditionalInfo}
                                >{language?.Delete}</Button>
                                <Button
                                    className='input_field_buttons4'
                                    size='middle'
                                    onClick={() => setChangeAccModal(true)}
                                >{language?.Edit}</Button>
                            </div>
                        </div>

                        <div style={{ marginBottom: 20 }} className='disabled_text scroll_class'>
                            <table>
                                <tr>
                                    <td className='first_td td_content'>{language?.Bank}:</td>
                                    <td className='td_content'>{user?.bank ? user?.bank : '-'}</td>
                                </tr><br />
                                <tr>
                                    <td className='first_td td_content'>{language?.BankAccountNumber}:</td>
                                    <td className='td_content'>{user?.bankAccountNumber ? user?.bankAccountNumber : '-'}</td>
                                </tr><br />
                                <tr>
                                    <td className='first_td td_content'>{language?.Name}:</td>
                                    <td className='td_content'>{user?.fullName ? user?.fullName : '-'}</td>
                                </tr><br />
                            </table>
                        </div>

                        <div className='row_fields2' style={{ marginBottom: 10, alignItems: 'baseline' }} >
                            <p className='p_class'>{language?.addressMyPage}</p>
                            <div>
                                <Button
                                    className='input_field_buttons4'
                                    size='middle'
                                    loading={deleteAddressLoading}
                                    onClick={() => setSearchAddress('')}
                                >{language?.Delete}</Button>
                                <Button
                                    className='input_field_buttons4'
                                    size='middle'
                                    onClick={() => {
                                        handleClickMap,
                                            setChangeAddressModal(true)
                                    }}
                                >{language?.Edit}</Button>
                            </div>
                        </div>

                        <div style={{ marginBottom: 20 }} className='disabled_text scroll_class'>
                            <table>
                                <tr>
                                    <td className='first_td td_content'>{language?.addressMyPage}:</td>
                                    <td className='td_content'> {searchAddress ? searchAddress : '-'}</td>
                                </tr>
                            </table>
                        </div>

                        <p className='p_class2'>{language?.PreferredGolfCourse}</p>
                        <div style={{ padding: '10px 15px', marginBottom: 20, backgroundColor: "#F4F4F4" }} >
                            {
                                golfClub?.map((vv, i) => {
                                    if (selectedGolfClub?.includes(vv?._id)) {
                                        return (
                                            <div className='row_fields2' style={{ marginBottom: 20, alignItems: 'baseline' }} >
                                                {/* <p className='p_class2'>{language?.GolfClubName}</p> */}
                                                <div className='row_fields'>
                                                    <p style={{ marginLeft: 5, width: '100px' }}>
                                                        {vv?.clubName}
                                                    </p>
                                                    <Button
                                                        className='input_field_buttons4'
                                                        size='middle'
                                                        onClick={() => DeletePrefeardRegioin(vv)}
                                                    >{language?.Delete}</Button>
                                                    <Button
                                                        className='input_field_buttons4'
                                                        size='middle'
                                                        onClick={openGolfEdit}
                                                    >{language?.Edit}</Button>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }

                        </div>


                        <Button disabled={selectedGolfClub?.length > 4 ? true : false} onClick={openGolfEdit} style={{ width: '100%', backgroundColor: '#f4f4f4', marginBottom: 20 }}>{language?.AddpreferredCourse}{selectedGolfClub?.length}/5</Button>

                        <div className='row_fields2' style={{ marginBottom: 10, alignItems: 'baseline' }} >
                            <p className='p_class'>{language?.PreferredRegionmypage}</p>
                            <div>
                                <Button
                                    className='input_field_buttons4'
                                    size='middle'
                                    onClick={() => setSelectedTags([])}
                                >{language?.Delete}</Button>
                                <Button
                                    className='input_field_buttons4'
                                    size='middle'
                                    onClick={openPreferredRegion}
                                >{language?.Edit}</Button>
                            </div>
                        </div>

                        <div style={{ marginBottom: 20, backgroundColor: "#F4F4F4" }} className='disabled_text2 scroll_class'>
                            {/* {user && user?.preferredRegion?.length ?
                            user?.preferredRegion?.map((v) => { */}
                            {user && selectedTags ?
                                selectedTags?.map((v) => {
                                    return (
                                        <div style={{ backgroundColor: 'white', color: 'black' }} className='tags_div'>{lanKorCondition ? v : regionsKr[regions?.indexOf(v)]?.TitleKr}</div>
                                    )
                                }) :
                                <div style={{ textAlign: 'center' }}>
                                    <h5>{language?.PreferredRegionNotFound}</h5>
                                    <button style={{ border: '2px solid #2DB7F5', backgroundColor: '#2DB7F5', color: 'white' }} onClick={openPreferredRegion}>{language?.TaptoAdd}</button>
                                </div>
                            }
                        </div>

                        <div className='RightAlign'>
                            <Popconfirm loading={loading} title={language?.AreYouSureYouWantDelete} okText={language?.Yes} cancelText={language?.No} onConfirm={deleteAccount}>
                                <Button type="link">{language?.DeletemyAccount}</Button>
                            </Popconfirm>
                        </div>

                        <Button loading={loading} onClick={onFinish} style={{ width: '100%', color: 'white', backgroundColor: 'gray' }}>{language?.Save}</Button>
                    </div>
                </div>
            </Modal>
            {/* regions */}
            <Modal open={preferredRegion} footer={null} onCancel={closePreferredRegion}>
                <h3 style={{ textAlign: 'center' }}>{language?.ChooseYourPreferredRegion}</h3>

                {lanKorCondition ?
                    regions?.slice(1)?.map((tag, i) => {
                        return (
                            <CheckableTag
                                style={{ marginTop: '10px' }}
                                key={tag}
                                checked={selectedTags?.indexOf(tag) > -1}
                                onChange={(checked) => handleChange(tag, checked)}
                            >
                                {tag}
                            </CheckableTag>
                        )
                    }) :
                    regionsKr?.slice(1)?.map((tag, i) => {
                        return (
                            <CheckableTag
                                style={{ marginTop: '10px' }}
                                key={tag?.TitleEn}
                                checked={selectedTags?.indexOf(tag?.TitleEn) > -1}
                                onChange={(checked) => handleChange(tag?.TitleEn, checked)}
                            >
                                {tag?.TitleKr}
                            </CheckableTag>
                        )
                    })
                }

                <Button htmlType='submit' onClick={() => setPreferredRegion(false)} loading={loading} style={{ marginTop: '20px', width: '100%', backgroundColor: 'gray', color: 'white' }}>{language?.Check}</Button>

            </Modal>

            <Modal open={preferredGolfClub} footer={null} onCancel={closeGolfEdit}>
                <h3 style={{ textAlign: 'center' }}>{language?.ChooseYourPreferredGolfClub}</h3>
                {golfClub?.map((tag, i) => {
                    return (
                        <CheckableTag
                            style={{ marginTop: '10px' }}
                            key={tag?._id}
                            checked={selectedGolfClub?.indexOf(tag?._id) > -1}
                            onChange={(checked) => handleClubChange(tag?._id, checked)}
                        >
                            {tag?.clubName}
                        </CheckableTag>
                    )
                })}

                <Button htmlType='submit' disabled={selectedGolfClub?.length > 5} loading={loading} onClick={() => setPreferredGolfClub(false)} style={{ marginTop: '20px', width: '100%', backgroundColor: 'gray', color: 'white' }}>{language?.Check}</Button>
            </Modal>

            <Modal title={language?.ChangePassword} open={changePassModal} footer={null} onCancel={handleCancel1}>
                <Form
                    name='changePass'
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    form={passwordForm}
                    onFinish={onFinishPassword}
                    autoComplete='off'
                >
                    <Form.Item
                        label={language?.OldPassword}
                        name='oldPassword'
                        rules={[
                            {
                                required: true,
                                message: language?.Pleaseinputyouroldpassword,
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name='password'
                        label={language?.Password}
                        rules={[
                            {
                                required: true,
                                message: language?.InputyourpasswordHere
                            },
                            {
                                min: 8
                            }
                        ]}
                    >
                        <Input.Password
                            autocomplete="new-password"
                            // size="middle"
                            // visibilityToggle={false}
                            // className='Signup_input'
                            placeholder={language?.InputyourpasswordHere}
                        />
                    </Form.Item>
                    <Form.Item
                        label={language?.ConfirmPassword}
                        name='confirm'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: language?.InputyourconfirmpasswordHere,
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve()
                                    }

                                    return Promise.reject(language?.passCond)
                                },
                            })
                        ]}
                    >
                        <Input.Password
                            // size="middle"
                            // visibilityToggle={false}
                            placeholder={language?.InputyourconfirmpasswordHere}
                        // className='Signup_input'
                        />
                    </Form.Item>
                    <Button htmlType='submit' loading={loading} style={{ width: '100%', backgroundColor: 'gray', color: 'white' }}>{language?.SubmitMyPage}</Button>
                </Form>
            </Modal>

            <Modal title={language?.ChangePhoneNumber} open={changePhoneNumModal} footer={null} onCancel={handleCancel1}>
                <Form
                    name='changePass'
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    form={passwordForm}
                    onFinish={onFinishEditPhone}
                    autoComplete='off'
                >
                    <Form.Item
                        name='telNo'
                        label={language?.PhoneNumber}
                        rules={[
                            {
                                required: true,
                                message: requiredMessage(language?.PhoneNumber)
                            }
                        ]}
                    >
                        <div>
                            <Input
                                required
                                maxLength={13}
                                minLength={13}
                                value={phoneValue}
                                onChange={handlePhoneInput}
                                placeholder='e.g: 456-786-ab6'
                            />
                        </div>
                    </Form.Item>
                    <Button htmlType='submit' loading={loading} style={{ width: '100%', backgroundColor: 'gray', color: 'white' }}>{language?.Submit}</Button>
                </Form>
            </Modal>

            <Modal title={language?.ChangeAccountInformation} open={changeaccModal} footer={null} onCancel={handleCancel1}>
                <Form
                    name='changeAcc'
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    form={passwordForm}
                    onFinish={onFinishAddInfo}
                    autoComplete='off'
                >
                    <Form.Item
                        label={language?.AccNumber}
                        name='bankAccountNumber'
                        rules={[
                            {
                                required: true,
                                message: language?.PleaseinputBankacc,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={language?.BankName}
                        name='bank'
                        rules={[
                            {
                                required: true,
                                message: language?.PleaseinputBank,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Button loading={loading} htmlType='submit' style={{ width: '100%', backgroundColor: 'gray', color: 'white' }}>{language?.Submit}</Button>
                </Form>
            </Modal>

            <Modal title={language?.ChangeAddress} open={changeaddressModal} footer={null} onCancel={handleCancel1}>

                <DaumPostcodeEmbed autoClose={false} loading={true} style={{ width: '100%', height: 400 }} onComplete={handleComplete} {...props} />
            </Modal>

            <form name="requestForm" action="" ref={formData}>
                <input type="hidden" name="mid" value={passMID} />
                <input type="hidden" name="reqSvcCd" value="01" />
                <input type="hidden" name="mTxId" value="mTxId" />
                <input type="hidden" name="nmComp" value="KG이니시스" />
                <input type="hidden" name="noComp" value="2208155597" />
                <input type="hidden" id="myOS" name="myOS" value="" />
                <input type="hidden" id="myBrowser" name="myBrowser" value="" />
                < input type="hidden" name="successUrl" value={passVerOrigin + AUTH.GET_PASS_VERIFICATION_CORPORATE_RES} />
                <input type="hidden" name="failUrl" value={passVerOrigin + '/login'} />
                <input type="hidden" name="identifier" value="" />

                <input type="hidden" id="txId" name="txId" value={instaId} />
                <input type="hidden" id="flgFixedUser" name="flgFixedUser" value="N" />
                <input type="hidden" id="userName" name="userName" value="" />
                <input type="hidden" id="userPhone" name="userPhone" value="" />
                <input type="hidden" id="userBirth" name="userBirth" value="" />
                <input type="hidden" id="userHash" name="userHash" value="" />

                <input type="hidden" id="agencyList" name="agencyList" value="TOSS,PASS,SHINHAN,SAMSUNG,KB,NAVER,KAKAO,PAYCO,KFTC" />
                <input type="hidden" id="agencyListSize" name="agencyListSize" value="9" />

                <input type="hidden" id="isDirect" name="isDirect" value="false" />
                <input type="hidden" id="directAgency" name="directAgency" value="" />
                <input type="hidden" id="req_url" name="req_url" value={passVerOrigin + '/login'} />
            </form>
        </>
    )
}

export default EditCorporateMemberInfo