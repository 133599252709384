import { Button, Modal, Select, Tag } from 'antd'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { ADMIN, AUTH } from '../../utils/apis';
import { regions, regionsKr } from '../../utils/constants';
import { errorMessage, successMessage } from '../../utils/helpers';
const { CheckableTag } = Tag;

const FillOutPopup = (props) => {
    const { language, user, showFillOut, golfClub, setGolfClubName, setShowFillOut, selectedTags, setSelectedTags, selectedClubs, setSelectedClubs, lanKorCondition } = props
    const [loading, setLoading] = useState(false)
    // const [selectedClubs, setSelectedClubs] = useState([])


    const dispatch = useDispatch()

    const handleChange = (tag, checked) => {
        const nextSelectedTags = checked
            ? [...selectedTags, tag]
            : selectedTags.filter((t) => t !== tag);
        // console.log('You are interested in: ', nextSelectedTags);
        setSelectedTags(nextSelectedTags);
    };

    const handleCancel = () => {
        // setSelectedTags([])
        // setSelectedClubs([])
        setShowFillOut(false)
    }

    const handleSelectChange = (value) => {
        setSelectedClubs(value)
    }

    const onSave = () => {
        if (selectedClubs?.length > 5) {
            return errorMessage('You can select only 5 clubs')
        } else {
            setShowFillOut(false)
        }

    }

    return (
        <Modal
            open={showFillOut}
            // onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            className='fillout'
        >
            <h3 style={{ textAlign: 'center' }}>{lanKorCondition ? 'Please fillout the info for optimization' : '최적화를 위해 아래 필터 정보를 입력해주세요.'}</h3>
            {/* <h3 style={{ textAlign: 'center' }}>{language?.ChooseYourPreferredRegion}</h3> */}
            <br />
            <br />
            <h5>{lanKorCondition ? 'Preference Location' : '선호지역'}</h5>
            {regions?.slice(1)?.map((tag, i) => {
                return (
                    <CheckableTag
                        style={{ marginTop: '10px' }}
                        key={tag}
                        checked={selectedTags?.indexOf(tag) > -1}
                        onChange={(checked) => handleChange(tag, checked)}
                    >
                        {lanKorCondition ? tag : regionsKr[regions?.indexOf(tag)]?.TitleKr}
                    </CheckableTag>
                )
            })}

            <br />
            <br />
            <h5>{lanKorCondition ? 'Golf Club Preference' : '선호하는 골프장'}</h5>

            {/* <div className='row_fields2' style={{ marginBottom: 10, alignItems: 'baseline' }} >
                <p className='p_class'>{language?.PreferredRegion}</p>
                <div>
                    <Button
                        className='input_field_buttons4'
                        size='middle'
                    // onClick={() => setShowGolfClub(true )}
                    >{language?.Search}</Button>
                </div>
            </div> */}

            <Select
                mode="multiple"
                allowClear
                style={{
                    width: '100%',
                }}
                placeholder={lanKorCondition ? 'Golf club name' : '골프장 검색'}
                // defaultValue={['a10', 'c12']}
                onChange={handleSelectChange}
                value={selectedClubs}

                options={golfClub?.map((v, i) => {
                    return {
                        value: v?.clubName,
                        label: v?.clubName,
                    }
                })}
            />

            <br />
            <br />
            {/* {golfClub?.map((v, i) => (<Option key={v?._id} val={v?.clubName}>{v?.clubName}</Option>))

                }
             </Select> */}


            <Button className='req_exc_btn' onClick={onSave} style={{ color: 'white' }}>{lanKorCondition ? 'SAVE ' : '저장'}</Button>



        </Modal >
    )
}

export default FillOutPopup