import { PlusOutlined } from '@ant-design/icons';
import { Card, Input, Space, Tabs, Tag, Tooltip, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { RiSoundModuleFill } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import apple from '../../assets/apple.png';
import facebook from '../../assets/facebook.png';
import google from '../../assets/google.png';
import Kakao from '../../assets/Kakao.png';
import naver from '../../assets/naver.png';
import { socialLink } from '../../utils/constants';
import Header from '../Home/Header';

const { Title } = Typography;

const { Search } = Input;
const { TabPane } = Tabs;

const gridStyle = {
    width: '100%',
    textAlign: 'center',
};
const anotherStyle = {
    width: '50%',
    textAlign: 'center',
};


const FindEasyLogin = (props) => {
    const { language } = props
    const [tags, setTags] = useState(['Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag',]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');

    const inputRef = useRef(null);
    const editInputRef = useRef(null);
    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);
    useEffect(() => {
        editInputRef.current?.focus();
    }, [inputValue]);

    return (
        <div className='home-main'>
            <Header {...props} />

            <div className='card-parent2'>
                <div className='card2'>
                    <Title level={3}>{language?.EasyLoginIDPasswordSearch}</Title>
                    <br /><br /><hr style={{ border: '0.5px solid gray', backgroundColor: 'black' }} /><br />
                    <p className='special_text'>{language?.YoucanfindtheIDandPasswordofEasy}</p>

                    <br /><br />
                    <div className='card_divs'>
                        <Card className='cards_width' onClick={() => {
                            window.open(socialLink?.naver, '_blank')
                        }}>
                            <Card.Grid style={gridStyle} ><img src={naver} width='57%' alt="" /></Card.Grid>
                            <br />
                            <Card.Grid style={anotherStyle} className='small_cards' ><Title level={5}>{language?.FindyourID}</Title></Card.Grid>
                            <Card.Grid style={anotherStyle}><Title level={5}>{language?.FindYourPassword}</Title></Card.Grid>
                        </Card>

                        <Card className='cards_width' onClick={() => {
                            window.open(socialLink?.kakao, '_blank')
                        }}>
                            <Card.Grid style={gridStyle}><img src={Kakao} width='51%' alt="" /></Card.Grid>
                            <br />
                            <Card.Grid style={anotherStyle}><Title level={5}>{language?.FindyourID}</Title></Card.Grid>
                            <Card.Grid style={anotherStyle}><Title level={5}>{language?.FindYourPassword}</Title></Card.Grid>
                        </Card>
                    </div>

                    <div className='card_divs'>
                        <Card className='cards_width' onClick={() => {
                            window.open(socialLink?.facebook, '_blank')
                        }}>
                            <Card.Grid style={gridStyle} ><img src={facebook} width='58%' alt="" /></Card.Grid>
                            <br />
                            <Card.Grid style={anotherStyle} className='small_cards' ><Title level={5}>{language?.FindyourID}</Title></Card.Grid>
                            <Card.Grid style={anotherStyle}><Title level={5}>{language?.FindYourPassword}</Title></Card.Grid>
                        </Card>
                        <Card className='cards_width' onClick={() => {
                            window.open(socialLink?.google, '_blank')
                        }}>
                            <Card.Grid style={gridStyle}><img src={google} width='35%' alt="" /></Card.Grid>
                            <br />
                            <Card.Grid style={anotherStyle}><Title level={5}>{language?.FindyourID}</Title></Card.Grid>
                            <Card.Grid style={anotherStyle}><Title level={5}>{language?.FindYourPassword}</Title></Card.Grid>
                        </Card>
                    </div>

                    <div className='card_divs'>
                        <Card className='cards_width' onClick={() => {
                            window.open(socialLink?.apple, '_blank')
                        }}>
                            <Card.Grid style={gridStyle}><img src={apple} width='12%' alt="" /></Card.Grid>
                            <br />
                            <Card.Grid style={anotherStyle}><Title level={5}>{language?.FindyourID}</Title></Card.Grid>
                            <Card.Grid style={anotherStyle}><Title level={5}>{language?.FindYourPassword}</Title></Card.Grid>
                        </Card>
                    </div>


                </div>
            </div >
        </div >
    )
}

export default FindEasyLogin