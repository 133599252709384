import { Button, Form, Input, Modal, Popconfirm } from 'antd'
import CheckableTag from 'antd/lib/tag/CheckableTag'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import DaumPostcodeEmbed from 'react-daum-postcode'
import { useDispatch } from 'react-redux'
import { loginUser, removeUser } from '../../Redux/actions/authActions'
import { clearRouteBreadCrumbs } from '../../Redux/actions/routeActions'
import { ADMIN, AUTH, GET, POST } from '../../utils/apis'
import { allPaths, passMID, regions, regionsKr } from '../../utils/constants'
import { errorMessage, isUserLoginApi, requiredMessage, successMessage } from '../../utils/helpers'

let passVerOrigin = `https://securetech.vmvm.co.kr`
if (process.env.NODE_ENV === 'development') {
    passVerOrigin = `http://localhost:3000`
}

const EditPersonalInfo = (props) => {
    const { user, showEditModal, setShowEditModal, language, lanKorCondition, history } = props
    const [passwordForm] = Form.useForm();
    const [changePassModal, setChangePassModal] = useState(false)
    const [changePhoneNumModal, setChangePhoneNumModal] = useState(false)
    const [changeaccModal, setChangeAccModal] = useState(false)
    const [changeaddressModal, setChangeAddressModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [deleteAddressLoading, setDeleteAddressLoading] = useState(false)
    const [deleteAdditionalInfoLoading, setDeleteAdditionalInfoLoading] = useState(false)
    const [phoneValue, setPhoneValue] = useState('')
    const [searchAddress, setSearchAddress] = useState(user?.address)
    const [searchAddressShow, setSearchAddressShow] = useState(true)
    const [selectedTags, setSelectedTags] = useState(user?.preferredRegion)
    const [selectedGolfClub, setSelectedGolfClub] = useState(user?.preferredGolfCourse);
    const [preferredRegion, setPreferredRegion] = useState(false)
    const [preferredGolfClub, setPreferredGolfClub] = useState(false)
    const [golfClub, setGolfClubName] = useState(null)
    const [userData, setUserData] = useState(user || {})
    const formData = useRef(null)
    const [instaId, setInstaId] = useState(null)
    const [pathType, setPathType] = useState('individual-signup')
    const userName = new URLSearchParams(location.search).get('userName')
    const userBirthday = new URLSearchParams(location.search).get('userBirthday')
    const userPhone = new URLSearchParams(location.search).get('userPhone')
    useEffect(() => {
        if (instaId) {
            const domain = 'https://kssa.inicis.com'
            formData.current.action = `${domain}/init`
            formData.current.method = 'get'
            formData.current.submit()
        }
    }, [instaId])
    useEffect(() => {
        if (userName && userBirthday && userPhone) {
            setLoading(true)
            let obj = {
                _id: user?._id,
                fullName: userName,
                telNo: userPhone,
                DOB: moment(userBirthday).format('YYYY-MM-DD'),
            }
            axios.post(AUTH?.EDIT_PREFERRED_GOLF_CLUB, obj)
                .then((res) => {
                    const { data } = res
                    if (data.success) {
                        successMessage(lanKorCondition ? data?.message : data?.messageKr)
                        getUser()
                        history.push(allPaths?.MYPAGE)
                    }
                    else {
                        errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                    }
                    setLoading(false)
                })
                .catch((e) => {
                    console.log('e ', e)
                    setLoading(false)
                    errorMessage(lanKorCondition ? e?.message : e?.messageKr)

                })
        }
    }, [userName, userBirthday, userPhone])
    useEffect(() => {
        setSelectedTags(user?.preferredRegion)
        golfClubName()

    }, [])
    let golfClubName = () => {

        axios.get(ADMIN?.GOLF_CLUB)
            .then((res) => {

                const { data } = res
                if (data.success) {
                    setGolfClubName(data?.data)
                }
                else {
                    console.log('runing')
                }
            })
            .catch((e) => {
                console.log('err', e)
            })
    }

    // ////////////////
    const getUser = () => {
        axios.get(`${GET.GET_USER}/${user?._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    updateUser(data?.user)
                    // setShowFillOut(false)
                }
            })
            .catch((e) => {
            })
    }

    const updateUser = (data) => {
        dispatch(loginUser(data))
    }
    // ////////////////


    const handleChange = (tag, checked) => {
        const nextSelectedTags = checked
            ? [...selectedTags, tag]
            : selectedTags.filter((t) => t !== tag);
        setSelectedTags(nextSelectedTags);
    };

    const handleClubChange = (tag, checked, id) => {

        const nextSelectedTags = checked ? [...selectedGolfClub, tag] : selectedGolfClub.filter((t) => t !== tag);
        setSelectedGolfClub(nextSelectedTags);
    };
    const dispatch = useDispatch()



    const handleComplete = (data) => {
        let fullAddress = data?.address;
        let extraAddress = '';

        if (data?.addressType === 'R') {
            if (data?.bname !== '') {
                extraAddress += data?.bname;
            }
            if (data?.buildingName !== '') {
                extraAddress += extraAddress !== '' ? `, ${data?.buildingName}` : data?.buildingName;
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        setSearchAddress(fullAddress)
        setChangeAddressModal(false)
        // onFinishEditAdd(fullAddress)
    }

    const handleCancel = () => {
        setSelectedTags(user?.preferredRegion)
        setSelectedGolfClub(user?.preferredGolfCourse)
        setSearchAddress(user?.address)
        setShowEditModal(false)
        history?.replace(allPaths?.MYPAGE)
        updateUser(userData)
    }

    const handleCancel1 = () => {
        setChangePassModal(false)
        setChangePhoneNumModal(false)
        setChangeAddressModal(false)
        setChangeAccModal(false)
    }

    const onFinish = () => {

        setLoading(true)
        let obj = {
            _id: user?._id,
            address: searchAddress || fullAddress,
            preferredGolfCourse: selectedGolfClub,
            preferredRegion: selectedTags,
        }

        axios.post(AUTH?.EDIT_PREFERRED_GOLF_CLUB, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    getUser()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageKr)

            })
    }
    const openPreferredRegion = () => {
        setPreferredRegion(true)
    }
    const closePreferredRegion = () => {
        setPreferredRegion(false)
        setSelectedTags(user?.preferredRegion)
    }
    const openGolfEdit = () => {
        setPreferredGolfClub(true)
    }
    const closeGolfEdit = () => {
        setSelectedGolfClub(user?.preferredGolfCourse)
        setPreferredGolfClub(false)
        // setGolfClubName(golfClubs)
        // golfClubName()
        // getUser()
        // set
    }

    const onFinishEditPhone = (values) => {
        setLoading(true)

        values._id = user?._id
        // values.address = values?.address
        // console.log('values', values)

        axios.post(AUTH?.EDIT_PHONE, values)
            .then((res) => {
                const { data } = res
                // console.log('data', data)
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    setPhoneValue('')
                    handleCancel1()
                    getUser()

                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageKr)

            })

    }

    const deleteAdditionalInfo = () => {
        setDeleteAdditionalInfoLoading(true)

        axios.post(AUTH?.DELETE_ADDITIONAL_INFO, { _id: user?._id })
            .then((res) => {
                const { data } = res
                // console.log('data', data)
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    getUser()
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setDeleteAdditionalInfoLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setDeleteAdditionalInfoLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageKr)
            })
    }

    const onFinishAddInfo = (values) => {
        setLoading(true)
        values._id = user?._id
        axios.post(AUTH?.EDIT_ADDITIONAL_INFO, values)
        .then((res) => {
            const { data } = res
            setLoading(false)
            if (data.success) {
                successMessage(lanKorCondition ? data?.message : data?.messageKr)
                userData.bankAccountNumber = values?.bankAccountNumber
                userData.bank = values?.bank
                setChangeAccModal(false)
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageKr)

            })
    }
    const DeletePrefeardRegioin = (vv) => {
        let newSelectedGolfClub = selectedGolfClub?.filter((v) => {
            return vv?._id !== v
        })

        setSelectedGolfClub(newSelectedGolfClub)
    }

    const onFinishPassword = (values) => {
        setLoading(true)

        values._id = user?._id
        values.password = values?.oldPassword
        values.newPassword = values?.confirm

        axios.post(AUTH?.CHANGE_PASSWORD, values)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    passwordForm.resetFields()
                    setChangePassModal(false)
                    getUser()

                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageKr)

            })
    }

    const handlePhoneInput = (e) => {
        let value = e?.target?.value?.replace(/[^0-9-]/g, '')
        let event = e?.nativeEvent?.data
        if (event && ((value?.length === 3 || value?.length === 8) || (phoneValue?.length === 3 || phoneValue?.length === 8))) {
            if (phoneValue?.length === 3 || phoneValue?.length === 9) {
                value = `${phoneValue}-${event}`
            }
            else {
                value = `${value}-`
            }
        }
        setPhoneValue(value)
    }
    const handleClickMap = () => {
        open({ onComplete: handleComplete });
    }
    const deleteAccount = () => {
        setLoading(true)
        let obj = { _id: user?._id }

        axios.post(AUTH?.DELETE_ACCOUNT, obj)
            .then(async (res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    await isUserLoginApi(user?._id, false)
                    dispatch(removeUser())
                    dispatch(clearRouteBreadCrumbs())
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageKr)
            })
    }
    const callPassVerificationAuth = (path) => {
        setPathType(path)

        let obj = {
            successUrl: `${passVerOrigin}${AUTH.GET_PASS_VERIFICATION_EDIT_PERSONAL_INFO_RES}`
        }

        axios.post(POST.PASS_VERIFICATION, obj)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    let result = data?.result
                    var parser = new DOMParser()
                    var doc = parser.parseFromString(result, 'text/html')
                    setInstaId(doc?.querySelector('#txId')?.value)
                }
                else {
                    errorMessage()
                }
            })
            .catch((e) => console.log('e', e))
    }
    return (
        <>
            <Modal
                open={showEditModal}
                // open={true}
                onCancel={handleCancel}
                className='paymentModal'
                footer={null}
                style={{ top: '10px', }}
            >
                <div
                    className='scroll_class'
                    style={{
                        height: 'auto',
                        width: '100%',
                        maxHeight: '85vh',
                        overflowY: 'scroll',
                        marginTop: '12px'
                    }}>
                    <div className='modal_main_div'>
                        <center><h2>{language?.IndividualMember}</h2></center>

                        <div className='row_fields2' style={{ marginBottom: 10, alignItems: 'baseline' }} >
                            <p className='p_class'>{language?.Password}</p>
                            <Button
                                className='input_field_buttons3'
                                size='middle'
                                onClick={() => setChangePassModal(true)}
                            >{language?.ChangePassword}</Button>
                        </div>

                        <div className='row_fields2' style={{ marginBottom: 10, alignItems: 'baseline' }} >
                            <p className='p_class'>{language?.PhoneNumber}</p>
                            <Button
                                className='input_field_buttons3'
                                size='middle'
                                onClick={() => {
                                    // setChangePhoneNumModal(true)
                                    callPassVerificationAuth()
                                }}
                            >{language?.ChangePhoneNumber}</Button>
                        </div>


                        <div style={{ marginBottom: 20 }} className='disabled_text scroll_class'>
                            <table>
                                <tr>
                                    <td className='first_td td_content'>{language?.MobilePhoneNumber}:</td>
                                    <td className='td_content'>{user?.telNo ? user?.telNo : '-'}</td>
                                </tr><br />
                                <tr>
                                    <td className='first_td td_content'>{language?.DOB}:</td>
                                    <td className='td_content'>{user?.DOB ? moment(user?.DOB).format('YYYY-MM-DD') : '-'}</td>
                                </tr><br />
                                <tr>
                                    <td className='first_td td_content'> {language?.Name}:</td>
                                    <td className='td_content'>{user?.fullName ? user?.fullName : '-'}</td>
                                </tr><br />
                                <tr>
                                    <td className='first_td td_content'>{language?.Gender}:</td>
                                    <td className='td_content'>{user?.gender ? (user?.gender === 'male' ? language?.Male : language?.Female) : '-'}</td>
                                </tr>
                            </table>
                        </div>

                        <hr style={{ marginBottom: 30 }} />

                        <p className='p_class' style={{ width: 145 }}>{language?.AdditionalInformation}</p>

                        <div className='row_fields2' style={{ marginBottom: 10, alignItems: 'baseline' }} >
                            <p className='p_class'>{language?.AccountInformation}</p>
                            <div>
                                <Button
                                    className='input_field_buttons4'
                                    size='middle'
                                    loading={deleteAdditionalInfoLoading}
                                    onClick={deleteAdditionalInfo}
                                >{language?.Delete}</Button>
                                <Button
                                    className='input_field_buttons4'
                                    size='middle'
                                    onClick={() => setChangeAccModal(true)}
                                >{language?.Edit}</Button>
                            </div>
                        </div>

                        <div style={{ marginBottom: 20 }} className='disabled_text scroll_class'>
                            <table>
                                <tr>
                                    <td className='first_td td_content'>{language?.Bank}:</td>
                                    <td className='td_content'>{userData?.bank ? userData?.bank : '-'}</td>
                                </tr><br />
                                <tr>
                                    <td className='first_td td_content'>{language?.BankAccountNumber}:</td>
                                    <td className='td_content'>{userData?.bankAccountNumber ? userData?.bankAccountNumber : '-'}</td>
                                </tr><br />
                                <tr>
                                    <td className='first_td td_content'>{language?.Name}:</td>
                                    <td className='td_content'>{userData?.fullName ? userData?.fullName : '-'}</td>
                                </tr><br />
                            </table>
                        </div>

                        <div className='row_fields2' style={{ marginBottom: 10, alignItems: 'baseline' }} >
                            <p className='p_class'>{language?.addressMyPage}</p>
                            <div>
                                <Button
                                    className='input_field_buttons4'
                                    size='middle'
                                    loading={deleteAddressLoading}
                                    onClick={() => setSearchAddress('')}
                                >{language?.Delete}</Button>
                                <Button
                                    className='input_field_buttons4'
                                    size='middle'
                                    onClick={() => {
                                        handleClickMap,
                                            setChangeAddressModal(true)
                                    }}
                                >{language?.Edit}</Button>
                            </div>
                        </div>

                        <div style={{ marginBottom: 20 }} className='disabled_text scroll_class'>
                            <table>
                                <tr>
                                    <td className='first_td td_content'>{language?.addressMyPage}:</td>
                                    <td className='td_content'> {searchAddress ? searchAddress : '-'}</td>
                                </tr>
                            </table>
                        </div>

                        <p className='p_class2'>{language?.PreferredGolfCourse}</p>
                        <div style={{ padding: '10px 15px', marginBottom: 20, backgroundColor: "#F4F4F4" }} >
                            {
                                golfClub?.map((vv, i) => {
                                    if (selectedGolfClub?.includes(vv?._id)) {
                                        return (
                                            <div className='row_fields2' style={{ marginBottom: 20, alignItems: 'baseline' }} >
                                                {/* <p className='p_class2'>{language?.GolfClubName}</p> */}
                                                <div className='row_fields'>
                                                    <p style={{ marginLeft: 5, width: '100px' }}>
                                                        {vv?.clubName}
                                                    </p>
                                                    <Button
                                                        className='input_field_buttons4'
                                                        size='middle'
                                                        onClick={() => DeletePrefeardRegioin(vv)}
                                                    >{language?.Delete}</Button>
                                                    <Button
                                                        className='input_field_buttons4'
                                                        size='middle'
                                                        onClick={openGolfEdit}
                                                    >{language?.Edit}</Button>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }

                        </div>


                        <Button disabled={selectedGolfClub?.length > 4 ? true : false} onClick={openGolfEdit} style={{ width: '100%', backgroundColor: '#f4f4f4', marginBottom: 20 }}>{language?.AddpreferredCourse}{selectedGolfClub?.length}/5</Button>

                        <div className='row_fields2' style={{ marginBottom: 10, alignItems: 'baseline' }} >
                            <p className='p_class'>{language?.PreferredRegionmypage}</p>
                            <div>
                                <Button
                                    className='input_field_buttons4'
                                    size='middle'
                                    onClick={() => setSelectedTags([])}
                                >{language?.Delete}</Button>
                                <Button
                                    className='input_field_buttons4'
                                    size='middle'
                                    onClick={openPreferredRegion}
                                >{language?.Edit}</Button>
                            </div>
                        </div>

                        <div style={{ marginBottom: 20, backgroundColor: "#F4F4F4" }} className='disabled_text2 scroll_class'>
                            {/* {user && user?.preferredRegion?.length ?
                            user?.preferredRegion?.map((v) => { */}
                            {user && selectedTags ?
                                selectedTags?.map((v) => {
                                    return (
                                        <div style={{ backgroundColor: 'white', color: 'black' }} className='tags_div'>{lanKorCondition ? v : regionsKr[regions?.indexOf(v)]?.TitleKr}</div>
                                    )
                                }) :
                                <div style={{ textAlign: 'center' }}>
                                    <h5>{language?.PreferredRegionNotFound}</h5>
                                    <button style={{ border: '2px solid #2DB7F5', backgroundColor: '#2DB7F5', color: 'white' }} onClick={openPreferredRegion}>{language?.TaptoAdd}</button>
                                </div>
                            }
                        </div>

                        <div className='RightAlign'>
                            <Popconfirm loading={loading} title={language?.AreYouSureYouWantDelete} okText={language?.Yes} cancelText={language?.No} onConfirm={deleteAccount}>
                                <Button type="link">{language?.DeletemyAccount}</Button>
                            </Popconfirm>
                        </div>

                        <Button loading={loading} onClick={onFinish} style={{ width: '100%', color: 'white', backgroundColor: 'gray' }}>{language?.Save}</Button>
                    </div>
                    {/* /////////////////// */}

                </div>
            </Modal >


            {/* regions */}
            < Modal open={preferredRegion} footer={null} onCancel={closePreferredRegion} >
                <h3 style={{ textAlign: 'center' }}>{language?.ChooseYourPreferredRegion}</h3>

                {
                    lanKorCondition ?
                        regions?.slice(1)?.map((tag, i) => {
                            return (
                                <CheckableTag
                                    style={{ marginTop: '10px' }}
                                    key={tag}
                                    checked={selectedTags?.indexOf(tag) > -1}
                                    onChange={(checked) => handleChange(tag, checked)}
                                >
                                    {tag}
                                </CheckableTag>
                            )
                        }) :
                        regionsKr?.slice(1)?.map((tag, i) => {
                            return (
                                <CheckableTag
                                    style={{ marginTop: '10px' }}
                                    key={tag?.TitleEn}
                                    checked={selectedTags?.indexOf(tag?.TitleEn) > -1}
                                    onChange={(checked) => handleChange(tag?.TitleEn, checked)}
                                >
                                    {tag?.TitleKr}
                                </CheckableTag>
                            )
                        })
                }

                <Button htmlType='submit' onClick={() => setPreferredRegion(false)} loading={loading} style={{ marginTop: '20px', width: '100%', backgroundColor: 'gray', color: 'white' }}>{language?.Check}</Button>

            </Modal >

            <Modal open={preferredGolfClub} footer={null} onCancel={closeGolfEdit}>
                <h3 style={{ textAlign: 'center' }}>{language?.ChooseYourPreferredGolfClub}</h3>
                {golfClub?.map((tag, i) => {
                    return (
                        <CheckableTag
                            style={{ marginTop: '10px' }}
                            key={tag?._id}
                            checked={selectedGolfClub?.indexOf(tag?._id) > -1}
                            onChange={(checked) => handleClubChange(tag?._id, checked)}
                        >
                            {tag?.clubName}
                        </CheckableTag>
                    )
                })}

                <Button htmlType='submit' disabled={selectedGolfClub?.length > 5} loading={loading} onClick={() => setPreferredGolfClub(false)} style={{ marginTop: '20px', width: '100%', backgroundColor: 'gray', color: 'white' }}>{language?.Check}</Button>
            </Modal>

            <Modal title={language?.ChangePassword} open={changePassModal} footer={null} onCancel={handleCancel1}>
                <Form
                    name='changePass'
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    form={passwordForm}
                    onFinish={onFinishPassword}
                    autoComplete='off'
                >
                    <Form.Item
                        label={language?.OldPassword}
                        name='oldPassword'
                        rules={[
                            {
                                required: true,
                                message: language?.Pleaseinputyouroldpassword,
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name='password'
                        label={language?.Password}
                        rules={[
                            {
                                required: true,
                                message: language?.InputyourpasswordHere
                            },
                            {
                                min: 8
                            }
                        ]}
                    >
                        <Input.Password
                            autocomplete="new-password"
                            // size="large"
                            // visibilityToggle={false}
                            // className='Signup_input'
                            placeholder={language?.InputyourpasswordHere}
                        />
                    </Form.Item>
                    <Form.Item
                        label={language?.ConfirmPassword}
                        name='confirm'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: language?.InputyourconfirmpasswordHere,
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve()
                                    }

                                    return Promise.reject(language?.passCond)
                                },
                            })
                        ]}
                    >
                        <Input.Password
                            // size="large"
                            // visibilityToggle={false}
                            placeholder={language?.InputyourconfirmpasswordHere}
                        // className='Signup_input'
                        />
                    </Form.Item>
                    <Button htmlType='submit' loading={loading} style={{ width: '100%', backgroundColor: 'gray', color: 'white' }}>{language?.SubmitMyPage}</Button>
                </Form>
            </Modal>

            <Modal title={language?.ChangePhoneNumber} open={changePhoneNumModal} footer={null} onCancel={handleCancel1}>
                <Form
                    name='changePass'
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    form={passwordForm}
                    onFinish={onFinishEditPhone}
                    autoComplete='off'
                >
                    <Form.Item
                        name='telNo'
                        label={language?.PhoneNumber}
                        rules={[
                            {
                                required: true,
                                message: requiredMessage(language?.PhoneNumber)
                            }
                        ]}
                    >
                        <div>
                            <Input
                                required
                                maxLength={13}
                                minLength={13}
                                value={phoneValue}
                                onChange={handlePhoneInput}
                                placeholder='e.g: 456-786-ab6'
                            />
                        </div>
                    </Form.Item>
                    <Button htmlType='submit' loading={loading} style={{ width: '100%', backgroundColor: 'gray', color: 'white' }}>{language?.Submit}</Button>
                </Form>
            </Modal>

            <Modal title={language?.ChangeAccountInformation} open={changeaccModal} footer={null} onCancel={handleCancel1}>
                <Form
                    name='changeAcc'
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    // form={passwordForm}
                    onFinish={onFinishAddInfo}
                    autoComplete='off'
                >
                    <Form.Item
                        label={language?.AccNumber}
                        name='bankAccountNumber'
                        rules={[
                            {
                                required: true,
                                message: lanKorCondition ? 'Please input your account number!' : '계좌번호를 입력해주세요.',
                            },
                        ]}
                        initialValue={userData?.bankAccountNumber || null}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={language?.BankName}
                        name='bank'
                        rules={[
                            {
                                required: true,
                                message: lanKorCondition ? 'Please input your bank name!' : ' 은행명을 입력해주세요'

                            },
                        ]}
                        initialValue={userData?.bank || null}
                    >
                        <Input />
                    </Form.Item>

                    <Button loading={loading} htmlType='submit' style={{ width: '100%', backgroundColor: 'gray', color: 'white' }}>{language?.Submit}</Button>
                </Form>
            </Modal>

            <Modal title={language?.ChangeAddress} open={changeaddressModal} footer={null} onCancel={handleCancel1}>
                <DaumPostcodeEmbed autoClose={false} loading={true} style={{ width: '100%', height: 400 }} onComplete={handleComplete} {...props} />
            </Modal>
            <>
                <form name="requestForm" action="" ref={formData}>
                    <input type="hidden" name="mid" value={passMID} />
                    <input type="hidden" name="reqSvcCd" value="01" />
                    <input type="hidden" name="mTxId" value="mTxId" />
                    <input type="hidden" name="nmComp" value="KG이니시스" />
                    <input type="hidden" name="noComp" value="2208155597" />
                    <input type="hidden" id="myOS" name="myOS" value="" />
                    <input type="hidden" id="myBrowser" name="myBrowser" value="" />
                    < input type="hidden" name="successUrl" value={passVerOrigin + AUTH.GET_PASS_VERIFICATION_EDIT_PERSONAL_INFO_RES} />
                    {/* {
                    pathType === 'individual-signup' ?
                        : <input type="hidden" name="successUrl" value={passVerOrigin + AUTH.GET_PASS_VERIFICATION_CORPORATE_RES} />
                } */}
                    <input type="hidden" name="failUrl" value={passVerOrigin + AUTH.FAIL_URL} />
                    <input type="hidden" name="identifier" value="" />

                    <input type="hidden" id="txId" name="txId" value={instaId} />
                    <input type="hidden" id="flgFixedUser" name="flgFixedUser" value="N" />
                    <input type="hidden" id="userName" name="userName" value="" />
                    <input type="hidden" id="userPhone" name="userPhone" value="" />
                    <input type="hidden" id="userBirth" name="userBirth" value="" />
                    <input type="hidden" id="userHash" name="userHash" value="" />

                    <input type="hidden" id="agencyList" name="agencyList" value="TOSS,PASS,SHINHAN,SAMSUNG,KB,NAVER,KAKAO,PAYCO,KFTC" />
                    <input type="hidden" id="agencyListSize" name="agencyListSize" value="9" />

                    <input type="hidden" id="isDirect" name="isDirect" value="false" />
                    <input type="hidden" id="directAgency" name="directAgency" value="" />
                    <input type="hidden" id="req_url" name="req_url" value={passVerOrigin + '/login'} />
                </form>
            </>
        </>
    )
}

export default EditPersonalInfo