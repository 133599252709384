import { CloseOutlined } from '@ant-design/icons'
import { Breadcrumb, Carousel, Input, Select, Tabs, Tag } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { RiSoundModuleFill } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import GolfReservationHome from '../../Components/HomeTabs/GolfReservationHome'
import RealTimeMarket from '../../Components/HomeTabs/RealTimeMarket'
import Auction from '../../Components/TicketShareTabs/Auction'
import GroupBuy from '../../Components/TicketShareTabs/GroupBuy'
import TicketSale from '../../Components/TicketShareTabs/TicketSale'
import TicketShareTab from '../../Components/TicketShareTabs/TicketShareRec'
import TicketSharing from '../../Components/TicketShareTabs/TicketSharing'
import TodayHotTab from '../../Components/TicketShareTabs/TodayHot'
import { setRouteBreadCrumbs } from '../../Redux/actions/routeActions'
import { ADMIN } from '../../utils/apis'
import { constantBanner, regions, regionsKr, regionsOnlyKr, types, typesKr } from '../../utils/constants'
import { errorMessage, translateBreadcrumb } from '../../utils/helpers'
import FillOutPopup from './FillOutPopup'
import FillOutPopupUser from './FillOutPopupUser'
import Filter from './Filter'
import Footer from './Footer'
import Header from './Header'
import contantImage from "../../assets/contantImage.jpg"
const { Search } = Input
const { TabPane } = Tabs

const Home = (props) => {

    const { language, user, lanKorCondition } = props
    const dispatch = useDispatch()
    const ticketPrice = useSelector(state => state?.ticketPriceReducer?.ticketPrice)
    const sellType = useSelector(state => state?.sellTypeReducer?.sellType)
    const [showFillOut, setShowFillOut] = useState(false)
    const [inputVisible, setInputVisible] = useState(false)
    const [selectedMemberships, setSelectedMemberships] = useState([])
    const [allTags, setallTags] = useState([])
    const [ticketnameFilter, setTicketnameFilter] = useState('')
    const [golfClub, setGolfClubName] = useState([])
    const breadCrumbs = useSelector(state => state?.routeReducer?.breadCrumbs)
    const [selectedClubs, setSelectedClubs] = useState([])
    const [startPrice, setStartPrice] = useState(ticketPrice?.start)
    const [endPrice, setEndPrice] = useState(ticketPrice?.end)
    let [selectedTags, setSelectedTags] = useState(user?._id ? user?.preferredRegion : [])
    const [transactionType, setTransactionType] = useState(sellType?.transactionType)
    const [adminBankDetail, setAdminBankDetail] = useState({})
    const [carouselImage, setCarouselImage] = useState(constantBanner)
    const [imgNumber, setImgNumber] = useState(0)
    const [images, setImages] = useState([])
    const [clickedViewMore, setclickedViewMore] = useState(false)
    const onChangeTab = (key) => {
        setclickedViewMore(false)
        dispatch(setRouteBreadCrumbs(`${key}`))
    }
    const removeTags = (v) => {
        const newTags = selectedTags.filter((a) => a !== v);
        const newClubs = selectedClubs.filter((a) => a !== v);
        setSelectedTags(newTags);
        setSelectedClubs(newClubs);
    }

    const handleSelectChange = (value) => {
        setSelectedMemberships(value)
    }

    let golfClubName = () => {
        axios.get(ADMIN?.GOLF_CLUB)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setGolfClubName(data?.data)
                }
                else {
                    console.log('runing')
                }
            })
            .catch((e) => {
                console.log('err', e)
            })
    }

    let getAdminBankInfo = () => {
        axios.get(ADMIN?.GET_BANK_INFO)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setAdminBankDetail(data?.data)
                }
                else {
                    // console.log('runing')
                }
            })
            .catch((e) => {
                console.log('err', e)
            })

        axios.get(ADMIN?.GET_HOME_BANNERS)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setImages(data?.data)
                    changeImg()
                }
                else {
                    // console.log('runing')
                }
            })
            .catch((e) => {
                console.log('err', e)
            })
    }

    if (user?._id) {
        useEffect(() => {
            setSelectedClubs(_.map(golfClub?.filter((v, i) => user?.preferredGolfCourse?.includes(v?._id)), 'clubName'))
        }, [golfClub])
    }

    useEffect(() => {
        setallTags(selectedTags?.concat(selectedClubs))

    }, [selectedTags, selectedClubs])

    const showInput = () => {
        setInputVisible(true)
    }
    const changeImg = () => {
        setCarouselImage(images[imgNumber]?.imageUrl)
    }

    useEffect(() => {
        golfClubName()
        getAdminBankInfo()
    }, [])

    useEffect(() => {
        if ((images?.length - 1) === imgNumber) {
            setImgNumber(0)
        }
        else {
            setImgNumber(imgNumber + 1)
        }
        setTimeout(changeImg, 1000)
    }, [carouselImage])
    return (
        <>
            <div className='home-main'>
                <Header language={language} goto={'my-page'}{...props} />

                <Carousel autoplay={false} dots={true} className='carosel-new'>
                    {images?.length ? images?.map((v, i) => {
                        return (
                            <div key={i} style={{ backgroundColor: 'red', width: '100%', minHeight: '' }}>
                                <img src={v?.imageUrl} className={clickedViewMore ? 'caroselImgSmall' : 'caroselImg'} style={{ width: '100%', minHeight: 200 }} />
                            </div>
                        )
                    })
                        :
                        <div style={{ backgroundColor: 'red', width: '100%' }}>
                            <img src={contantImage} className={clickedViewMore ? 'caroselImgSmall' : 'caroselImg'} style={{ width: '100%', minHeight: 200 }} />
                        </div>
                    }
                </Carousel>

                <div
                    className='mid_panel'>
                    {/* <div className='home_drops'>
                        <div className='drop'>
                            <span className='drop_menu' >
                                <Select style={{ width: '95%' }} size='large' bordered={false} placeholder={translateBreadcrumb(breadCrumbs[breadCrumbs?.length - 1], lanKorCondition) || language?.RealTimeQoutes} >
                                    {breadCrumbs?.map((bread, index) => {
                                        return (
                                            <span key={index}>
                                                <Breadcrumb>
                                                    < Breadcrumb.Item > {language?.home}</Breadcrumb.Item>
                                                    <Breadcrumb.Item>
                                                        <a >{translateBreadcrumb(bread, lanKorCondition)}</a>
                                                    </Breadcrumb.Item>
                                                </Breadcrumb>
                                            </span>
                                        )
                                    })
                                    }
                                </Select>
                            </span>
                        </div>

                    </div> */}
                    <div className='ticket_share_cont' style={{ backgroundColor: 'transparent', marginTop: 15 }}>
                        <div className='search_filter_width'>
                            <div className='search_div'>
                                <Search
                                    maxLength={41}
                                    bordered={false}
                                    placeholder={language?.PleaseEnterSearchTerm}
                                    onChange={(e) => {
                                        e.target.value?.length > 40 ?
                                            errorMessage(lanKorCondition ? '40 max length' : '글자수초과') :
                                            setTicketnameFilter(e.target.value)
                                    }}
                                    className='search_width'
                                    style={{
                                        backgroundColor: 'white',
                                        borderRadius: 2
                                    }}
                                />
                            </div>
                            <div
                                onClick={() => setShowFillOut(true)}
                                style={{ marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                <span onClick={() => setShowFillOut(true)} style={{ cursor: 'pointer', fontWeight: 'bold', width: 80, paddingTop: 5, paddingLeft: 10, backgroundColor: '#C5C5C5' }}>
                                    <RiSoundModuleFill className='filter_icon' />
                                    {language?.Filter}
                                </span>
                                <Select

                                    disabled
                                    mode='multiple'
                                    placeholder={language?.SelectTheMembership}
                                    allowClear
                                    style={{
                                        width: '100%',
                                        cursor: 'pointer'
                                    }}
                                    onChange={handleSelectChange}
                                    value={selectedMemberships}
                                    options={typesKr?.map((v, i) => {
                                        return {
                                            value: v?.typesEn,
                                            label: lanKorCondition ? v?.typesEn : v?.typesKr
                                        }
                                    })}
                                />
                            </div>

                            {user?._id && (!user?.preferredRegion?.length || !user?.preferredGolfCourse?.length) ?
                                <FillOutPopupUser golfClub={golfClub} selectedTags={selectedTags} setSelectedTags={setSelectedTags} selectedClubs={selectedClubs} setSelectedClubs={setSelectedClubs} showFillOut={showFillOut} setShowFillOut={setShowFillOut} {...props} />

                                : !user?._id ?
                                    <FillOutPopup golfClub={golfClub} setGolfClubName={setGolfClubName} selectedTags={selectedTags} setSelectedTags={setSelectedTags} selectedClubs={selectedClubs} setSelectedClubs={setSelectedClubs} showFillOut={showFillOut} setShowFillOut={setShowFillOut} {...props} />
                                    :
                                    <Filter sellType={sellType} ticketPrice={ticketPrice} endPrice={endPrice} setEndPrice={setEndPrice} startPrice={startPrice} setStartPrice={setStartPrice} transactionType={transactionType} setTransactionType={setTransactionType} golfClub={golfClub} selectedTags={selectedTags} setSelectedTags={setSelectedTags} selectedClubs={selectedClubs} setSelectedClubs={setSelectedClubs} showFillOut={showFillOut} setShowFillOut={setShowFillOut} {...props} />
                            }

                            <div className='tags_filter1' style={{ padding: allTags?.length ? '6px' : '0px' }} >
                                {/* <span onClick={() => setShowFillOut(true)} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                                    <RiSoundModuleFill className='filter_icon' />
                                    {language?.Filter}
                                </span> */}
                                <span className='tags_width scroll_class'>
                                    {allTags?.map((tag, index) =>
                                    (<Tag className='site-tag-plus' onClick={showInput}>
                                        <CloseOutlined className='closeIcon' onClick={() => removeTags(tag)} />
                                        {lanKorCondition ? tag : regionsKr[regionsOnlyKr?.indexOf(tag)]?.TitleKr ? regionsKr[regionsOnlyKr?.indexOf(tag)]?.TitleKr : regionsKr[regions?.indexOf(tag)]?.TitleKr || tag}
                                    </Tag>)

                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tab_con'>
                    <Tabs className='tab_menu ' defaultActiveKey='real-time-market' onChange={onChangeTab} centered={true}>
                        <TabPane className='pri_color' tab={language?.RealTimeMarket} key='real-time-market'>
                            <RealTimeMarket setclickedViewMore={setclickedViewMore} selectedMemberships={selectedMemberships} selectedClubs={selectedClubs} selectedTags={selectedTags} ticketnameFilter={ticketnameFilter} {...props} />
                        </TabPane>
                        <TabPane tab={language?.TicketShareRecommendat} key='ticket-share-recommendations'>
                            <TicketShareTab setclickedViewMore={setclickedViewMore} sellType={sellType} ticketPrice={ticketPrice} selectedMemberships={selectedMemberships} selectedClubs={selectedClubs} selectedTags={selectedTags} ticketnameFilter={ticketnameFilter} {...props} />
                        </TabPane>
                        <TabPane tab={language?.TodayHot} key='today-hot'>
                            <TodayHotTab setclickedViewMore={setclickedViewMore} sellType={sellType} ticketPrice={ticketPrice} selectedMemberships={selectedMemberships} selectedClubs={selectedClubs} selectedTags={selectedTags} ticketnameFilter={ticketnameFilter} {...props} />
                        </TabPane>
                        <TabPane tab={language?.MemberShipSharing} key='ticket-sharing'>
                            <TicketSharing setclickedViewMore={setclickedViewMore} sellType={sellType} ticketPrice={ticketPrice} selectedMemberships={selectedMemberships} selectedClubs={selectedClubs} selectedTags={selectedTags} ticketnameFilter={ticketnameFilter} {...props} />
                        </TabPane>
                        <TabPane tab={language?.JointPurchase} key='group-buy'>
                            <GroupBuy setclickedViewMore={setclickedViewMore} sellType={sellType} ticketPrice={ticketPrice} selectedMemberships={selectedMemberships} selectedClubs={selectedClubs} selectedTags={selectedTags} ticketnameFilter={ticketnameFilter} {...props} />
                        </TabPane>
                        <TabPane tab={language?.TicketSale} key='ticket-sale'>
                            <TicketSale setclickedViewMore={setclickedViewMore} sellType={sellType.transactionType} ticketPrice={ticketPrice} selectedMemberships={selectedMemberships} selectedClubs={selectedClubs} selectedTags={selectedTags} ticketnameFilter={ticketnameFilter} {...props} />
                        </TabPane>
                        <TabPane tab={language?.GolfReservation} key='golf-reservation'>
                            <GolfReservationHome setclickedViewMore={setclickedViewMore} selectedMemberships={selectedMemberships} selectedClubs={selectedClubs} selectedTags={selectedTags} ticketnameFilter={ticketnameFilter} {...props} />
                        </TabPane>
                        <TabPane tab={language?.Auction} key='auction'>
                            <Auction setclickedViewMore={setclickedViewMore} adminBankDetail={adminBankDetail} selectedMemberships={selectedMemberships} selectedClubs={selectedClubs} selectedTags={selectedTags} ticketnameFilter={ticketnameFilter} {...props} />
                        </TabPane>
                    </Tabs>
                </div >
                <Footer language={language} />
            </div >
        </>

    )
}

export default Home