import { Button, Result } from 'antd'
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Home, Login, IndividualSignup, CorporateSignup, TicketShareRec, FindUsernamePassword, MobileMap, FindIndividualMember, FindPersonalMemberPassword, FindCorporateIDMember, FindCorpPassword, FindEasyLogin, Notification, FAQ, ServiceCenter, Event, Notice, MyPage, Chat, FindPassword, FindID, FindIdGuide, FindPWGuide, MobilePassVerification, IndividualSignupAdmin } from '../Screens'
import allPaths from './paths'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser, removeUser } from '../Redux/actions/authActions'
import { setPin, setSelectedChatHead, setTicket } from '../Redux/actions/userActions'
import { setPriceFilter } from '../Redux/actions/ticketPriceActions'
import { setLanguage } from '../Redux/actions/generalActions'
import axios from 'axios'
import { useEffect } from 'react'
import { GET } from '../utils/apis'
import englishLanguage from '../utils/englishLanguage'
import koreanLanguage from '../utils/koreanLanguage'
import { setSellType } from '../Redux/actions/sellTypeAction'
import momentTz from 'moment-timezone'
// import FindIDGuide from '../Screens/Auth/FindIdGuide'

const Page404 = (props) => {
    const { history } = props
    return (
        <Result
            status='404'
            title='404'
            subTitle='Sorry, the page you visited does not exist.'
            extra={<Button
                type='primary'
                className='form-button'
                onClick={() => history.push('/')}
            >Back Home</Button>}
        />
    )
}

const Routes = () => {
    const user = useSelector(state => state?.authReducer?.user)
    const userSelectedLanguage = useSelector(state => state?.generalReducer?.language)
    const language = userSelectedLanguage && userSelectedLanguage === 'english' ? englishLanguage?.[0] : koreanLanguage?.[0]
    const lanKorCondition = userSelectedLanguage === 'english'
    useEffect(() => {
        let timeZone = momentTz.tz.zonesForCountry(user?.country || 'KR')
        momentTz.tz.setDefault(timeZone?.[0] || 'Asia/Seoul')
    }, [])
    const dispatch = useDispatch()
    const authActions = {
        loginUser: (e) => dispatch(loginUser(e)),
        removeUser: () => dispatch(removeUser())
    }
    const userActions = {
        setPin: (e) => dispatch(setPin(e)),
        setTicket: (e) => dispatch(setTicket(e)),
        setSelectedChat: (e) => dispatch(setSelectedChat(e)),
        setChatHeader: (e) => dispatch(setChatHeader(e)),
        setPriceFilter: (e) => dispatch(setPriceFilter(e)),
        setSellType: (e) => dispatch(setSellType(e)),
        setSelectedChatHead: (e) => dispatch(setSelectedChatHead(e)),
    }
    const generalActions = {
        setLanguage: (e) => dispatch(setLanguage(e))
    }

    const getUser = () => {
        // axios.get(`${GET.USER_BY_ID}/${user._id}`)
        //     .then((res) => {
        //         const { data } = res
        //         if (data.success) {
        //             console.log('user ha yahan per', data?.user)
        //             dispatch(loginUser(data.user))
        //         }
        //         else {
        //             history?.replace(allPaths?.LOGIN)
        //             dispatch(removeUser())
        //         }
        //     })
        //     .catch((e) => {
        //         dispatch(removeUser())
        //     })
    }

    return (
        <Router>
            <Switch>
                <Route path={allPaths?.MOBILE_PASS_VERIFICATION} exact component={(props) => <MobilePassVerification {...props} />} />
                <Route path={`${allPaths?.MOBILE_MAP}`} exact component={(props) => <MobileMap {...props} />} />
                <Route path={allPaths?.LOGIN} exact component={(props) => <Login {...props} getUser={getUser} user={user} authActions={authActions} userActions={userActions} language={language} userSelectedLanguage={userSelectedLanguage} lanKorCondition={lanKorCondition} />} />
                <Route path={allPaths?.HOME} exact component={(props) => <Home {...props} getUser={getUser} user={user} authActions={authActions} userActions={userActions} language={language} userSelectedLanguage={userSelectedLanguage} lanKorCondition={lanKorCondition} />} />
                <Route path={`${allPaths?.CORPORATE_SIGNUP}`} exact component={(props) => <CorporateSignup {...props} getUser={getUser} user={user} authActions={authActions} userActions={userActions} language={language} userSelectedLanguage={userSelectedLanguage} lanKorCondition={lanKorCondition} />} />
                <Route path={`${allPaths?.INDIVIDUAL_SIGNUP}`} exact component={(props) => <IndividualSignup {...props} getUser={getUser} user={user} authActions={authActions} userActions={userActions} language={language} userSelectedLanguage={userSelectedLanguage} lanKorCondition={lanKorCondition} />} />
                <Route path={`${allPaths?.ADMIN_INDIVIDUAL_SIGNUP}`} exact component={(props) => <IndividualSignupAdmin {...props} getUser={getUser} user={user} authActions={authActions} userActions={userActions} language={language} userSelectedLanguage={userSelectedLanguage} lanKorCondition={lanKorCondition} />} />
                <Route path={`${allPaths?.ADMIN_SIGNUP}`} exact component={(props) => <IndividualSignup {...props} getUser={getUser} user={user} authActions={authActions} userActions={userActions} language={language} userSelectedLanguage={userSelectedLanguage} lanKorCondition={lanKorCondition} />} />
                <Route path={allPaths?.TICKETSHAREREC} exact component={(props) => <TicketShareRec {...props} getUser={getUser} user={user} authActions={authActions} userActions={userActions} language={language} userSelectedLanguage={userSelectedLanguage} lanKorCondition={lanKorCondition} />} />
                <Route path={allPaths?.FIND} exact component={(props) => <FindUsernamePassword {...props} getUser={getUser} user={user} authActions={authActions} userActions={userActions} language={language} userSelectedLanguage={userSelectedLanguage} lanKorCondition={lanKorCondition} />} />
                <Route path={allPaths?.FINDIID} exact component={(props) => <FindIndividualMember {...props} getUser={getUser} user={user} authActions={authActions} userActions={userActions} language={language} userSelectedLanguage={userSelectedLanguage} lanKorCondition={lanKorCondition} />} />
                <Route path={allPaths?.FINDIP} exact component={(props) => <FindPersonalMemberPassword {...props} getUser={getUser} user={user} authActions={authActions} userActions={userActions} language={language} userSelectedLanguage={userSelectedLanguage} lanKorCondition={lanKorCondition} />} />
                <Route path={allPaths?.FINDCID} exact component={(props) => <FindCorporateIDMember {...props} getUser={getUser} user={user} authActions={authActions} userActions={userActions} language={language} userSelectedLanguage={userSelectedLanguage} lanKorCondition={lanKorCondition} />} />
                <Route path={allPaths?.FINDCP} exact component={(props) => <FindCorpPassword {...props} getUser={getUser} user={user} authActions={authActions} userActions={userActions} language={language} userSelectedLanguage={userSelectedLanguage} lanKorCondition={lanKorCondition} />} />
                <Route path={allPaths?.EASY_LOGIN} exact component={(props) => <FindEasyLogin {...props} getUser={getUser} user={user} authActions={authActions} userActions={userActions} language={language} userSelectedLanguage={userSelectedLanguage} lanKorCondition={lanKorCondition} />} />
                <Route path={allPaths?.NOTIFICATION} exact component={(props) => <Notification {...props} getUser={getUser} user={user} authActions={authActions} userActions={userActions} language={language} userSelectedLanguage={userSelectedLanguage} lanKorCondition={lanKorCondition} />} />
                <Route path={allPaths?.FAQ} exact component={(props) => <FAQ {...props} getUser={getUser} user={user} authActions={authActions} userActions={userActions} language={language} userSelectedLanguage={userSelectedLanguage} lanKorCondition={lanKorCondition} />} />
                <Route path={allPaths?.SERVICE_CENTER} exact component={(props) => <ServiceCenter {...props} getUser={getUser} user={user} authActions={authActions} userActions={userActions} language={language} userSelectedLanguage={userSelectedLanguage} lanKorCondition={lanKorCondition} />} />
                <Route path={allPaths?.EVENTS} exact component={(props) => <Event {...props} getUser={getUser} user={user} authActions={authActions} userActions={userActions} language={language} userSelectedLanguage={userSelectedLanguage} lanKorCondition={lanKorCondition} />} />
                <Route path={allPaths?.NOTICE} exact component={(props) => <Notice {...props} getUser={getUser} user={user} authActions={authActions} userActions={userActions} language={language} userSelectedLanguage={userSelectedLanguage} lanKorCondition={lanKorCondition} />} />
                <Route path={`${allPaths?.MYPAGE}`} exact component={(props) => <MyPage {...props} getUser={getUser} user={user} authActions={authActions} userActions={userActions} language={language} userSelectedLanguage={userSelectedLanguage} lanKorCondition={lanKorCondition} />} />
                <Route path={`${allPaths?.CHAT}`} exact component={(props) => <Chat {...props} getUser={getUser} user={user} authActions={authActions} userActions={userActions} language={language} userSelectedLanguage={userSelectedLanguage} lanKorCondition={lanKorCondition} />} />
                <Route path={`${allPaths?.FINDPASSWORD}`} exact component={(props) => <FindPassword {...props} getUser={getUser} user={user} authActions={authActions} userActions={userActions} language={language} userSelectedLanguage={userSelectedLanguage} lanKorCondition={lanKorCondition} />} />
                <Route path={`${allPaths?.FIND_ID_PASS_VERIFICATION}`} exact component={(props) => <FindID {...props} getUser={getUser} user={user} authActions={authActions} userActions={userActions} language={language} userSelectedLanguage={userSelectedLanguage} lanKorCondition={lanKorCondition} />} />
                <Route path={`${allPaths?.FIND_ID_GUIDE}`} exact component={(props) => <FindIdGuide {...props} getUser={getUser} user={user} authActions={authActions} userActions={userActions} language={language} userSelectedLanguage={userSelectedLanguage} lanKorCondition={lanKorCondition} />} />
                <Route path={`${allPaths?.FIND_PW_GUIDE}`} exact component={(props) => <FindPWGuide {...props} getUser={getUser} user={user} authActions={authActions} userActions={userActions} language={language} userSelectedLanguage={userSelectedLanguage} lanKorCondition={lanKorCondition} />} />


                <Route path='/:page404' exact component={Page404} />
                <Route path='/:page404/:token' exact component={Page404} />
                <Route path='admin/:page404' exact component={Page404} />
            </Switch >
        </Router >
    )
}

export {
    Routes,
    Page404
}
