import React, { useState } from 'react'
import { Button, Input, Table } from 'antd'
import moment from 'moment'
import axios from 'axios'
import { POST } from '../../utils/apis'
import { errorMessage, successMessage } from '../../utils/helpers'

const ManageReservation = (props) => {
    let { user, auction, getManageAuction, loading, language, lanKorCondition } = props
    let userId = user?._id
    const [price, setPrice] = useState(0)

    const bidsData = auction?.map((v, i) =>
        v?.bids?.map((x, i) => {
            return {
                ...v,
                bids: x
            }
        })
    )

    const applyForAuction = (vs) => {
        let obj = {
            bidId: vs?.bids?._id,
            userId: user?._id,
        }
        axios.post(POST.ACCEPT_MANAGE_AUCTION_RESERVATION_GOLF_BID, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    getManageAuction()
                }
                else {
                    console.log('runing')
                }
            })
            .catch((e) => {
                console.log('err', e)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })

    }

    const deleteAuction = (v) => {

        const obj = {
            userId: user?._id,
            bidId: v?.bids?._id
        }
        axios.post(POST.CANCEL_MANAGE_AUCTION_RESERVATION_GOLF_BID, obj)
            .then((res) => {
                const { data } = res
                console.log('data', data)

                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    getManageAuction()

                }
                else {
                    console.log('runing')
                }
            })
            .catch((e) => {
                console.log('err', e)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })


    }

    const columns = [
        {
            title: language?.RegistrationDate,
            dataIndex: 'createdAt',
            width: 150,
            render: (e) => <p style={{ marginBottom: 0 }}>{moment(e).format('YYYY-MM-DD')}</p>
        },
        {
            title: language?.Region,
            dataIndex: ['golfClub', 'region'],
            width: 150,
        },
        {
            title: language?.GolfCourse,
            dataIndex: ['golfClub', 'clubName'],
            key: 'golfClubName',
            width: 150,

        },
        {
            title: language?.ReservationDate,
            dataIndex: 'reservedDate',
            width: 150,
            render: (e) => <p style={{ marginBottom: 0 }}>{moment(e).format('YYYY-MM-DD')}</p>
        },
        {
            title: language?.GreenFee,
            dataIndex: ['greenFee'],
            width: 150,

        },
        {
            title: language?.TransferFee,
            dataIndex: ['transferFee'],
            width: 150,

        },
        {
            title: language?.Registrant,
            dataIndex: ['bids', 'buyerId', 'fullName'],
            key: 'fullName',
            width: 150,
            // render: (e)=>console.log('e',e)

        },
        {
            title: language?.Action,
            key: 'action',
            render: (v) => (<>
                <span style={{ display: 'flex' }}>
                    <Input disabled value={v?.bids?.price} style={{ width: 150 }} suffix={language?.KRW} />
                    <Button onClick={() => applyForAuction(v)} className='btn_apply'>{language?.Accept}</Button>
                    <Button onClick={() => deleteAuction(v)} className='btn_apply'>{language?.Cancel}</Button>
                </span>
            </>
            ),
        },

    ]

    return (

        <div className='auction_golf_res_table'>
            <div className='tab_con_auction_child_main'>
                <h2>{language?.ManageMyAuctionPosts}</h2>
            </div>

            <div className='main_table_cont' style={{ marginTop: 20 }}>
                <Table
                    className='table-striped-rows table_home'
                    columns={columns}
                    loading={loading}
                    dataSource={bidsData?.flat()}
                    pagination={false}
                    bordered={false}
                    scroll={{
                        x: 1300,
                    }}
                />
                <hr className='_hr' />
            </div>
        </div>
    )
}

export default ManageReservation