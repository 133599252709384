import { PlusCircleFilled } from '@ant-design/icons'
import { Button, Checkbox, Form, Input, InputNumber, Modal, Radio } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useState } from 'react'
import { HiCheckCircle } from "react-icons/hi"
import { useHistory } from 'react-router-dom'
import { LoginAlert } from '..'
import { POST } from '../../utils/apis'
import { allPaths, types, typesKr } from '../../utils/constants'
import englishLanguage from '../../utils/englishLanguage.json'
import { errorMessage, loginAlert, MyInfoAlert, successMessage } from '../../utils/helpers'
import ChooseTicket from './ChooseTicket'

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 12,
        },
        md: {
            span: 10,
        }
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 12,
        },
        md: {
            span: 14,
        },
    },
}

const MembershipTicketModal = (props) => {
    const { user, fun, language, lanKorCondition } = props
    const [form] = Form.useForm()

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isModalOpen2, setIsModalOpen2] = useState(false)
    const [isModalOpen3, setIsModalOpen3] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedTicket, setSelectedTicket] = useState({})
    const [sellingPrice, setSellingPrice] = useState(null)
    const [show, setShow] = useState(true)
    const [seats, setSeats] = useState(null)
    const [seatPrice, setSeatPrice] = useState(0)
    const history = useHistory()


    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }
    const showModal2 = () => {
        setIsModalOpen2(true)
        setIsModalOpen(false)

    }

    const handleCancel2 = () => {
        setIsModalOpen2(false)
    }
    const showModal3 = () => {
        setIsModalOpen3(true)
        setIsModalOpen2(false)

    }

    const handleOk3 = () => {
        setIsModalOpen3(false)
    }

    const handleCancel3 = () => {
        setIsModalOpen3(false)
    }
    const onChange = (value) => {
        console.log(`selected ${value}`)
    }

    const onSearch = (value) => {
        console.log('search:', value)
    }
    const onChangeDate = (date, dateString) => {
        console.log(date, dateString)
    }

    const onFinish = (fieldsValue) => {
        setLoading(true)

        fieldsValue._id = user?._id
        fieldsValue.ticketId = selectedTicket?._id
        fieldsValue.totalSeatsPrice = seatPrice
        fieldsValue.sellingType = fieldsValue?.category === 'both' ? [englishLanguage[0]?.NormalSell, englishLanguage[0].GroupBuySell] : fieldsValue?.category === 'normal' ? [englishLanguage[0]?.NormalSell] : [englishLanguage[0]?.GroupBuySell]
        // fieldsValue.sellingPrice = sellingPrice
        // show ? fieldsValue.seatPrice = seatPrice : ''

        // return true
        console.log('fieldsValue', fieldsValue)
        axios.post(POST.SALE_TICKET, fieldsValue)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    fun()
                    setIsModalOpen2(false)
                    setIsModalOpen(false)
                    setSellingPrice('')
                    form.resetFields()

                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }

                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    return (
        <>
            {
                user?._id ?
                    user?._id && user?.telNo && user?.bank && user?.bankAccountNumber ?
                        <Button className='upload_sharing_btn' onClick={showModal}>
                            {language?.SellMembershipTicket}
                        </Button> :
                        <Button className='upload_sharing_btn' onClick={() => { MyInfoAlert(language) }}>
                            {language?.SellMembershipTicket}
                        </Button> :
                    // <Button className='upload_sharing_btn' onClick={() => loginAlert(language)}>
                    //     {language?.SellMembershipTicket}
                    // </Button>
                    <LoginAlert clas={'upload_sharing_btn'} buttonName={language?.SellMembershipTicket} {...props} />

            }
            <Modal
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                className='responsiveModal'
                style={{ top: '10px', }}
            >
                <div
                    className='scroll_class'
                    style={{
                        height: 'auto',
                        width: '100%',
                        maxHeight: '85vh',
                        overflowY: 'scroll',
                        marginTop: '12px'
                    }}>
                    <div>
                        <h1 style={{ textAlign: 'center' }}>{lanKorCondition ? 'Ticket Sales' : '판매 신청'}</h1>
                        <h5 style={{ textAlign: 'center', color: '#2196f3' }}>{language?.EnterMembershipInformation}</h5>

                        <div className='paddongThirty'>
                            <Form
                                form={form}
                                requiredMark={false}
                                name='time_related_controls'
                                {...formItemLayout}
                                onFinish={onFinish}
                                onValuesChange={(e) => {
                                    setSeatPrice(Number(form.getFieldValue('sellingPrice')) / Number(form.getFieldValue('totalSeats')))
                                }}
                            >

                                <Form.Item
                                    name='membership'
                                    label={lanKorCondition ? 'Membership' : '회원권 선택'}
                                    rules={[
                                        {
                                            validator: async (names) => {

                                                if (!selectedTicket?.golfClub?.clubName) {
                                                    console.log(selectedTicket == {})
                                                    return Promise.reject(new Error(language?.ChooseYourticket))
                                                }
                                            }
                                        }
                                    ]}

                                >

                                    <ChooseTicket setSelectedTicket={setSelectedTicket}  {...props} />
                                </Form.Item>

                                <div className='disabled_text scroll_class'>
                                    <table>
                                        <tr>
                                            <td>{language?.GolfClubName}</td>
                                            <td>{selectedTicket && selectedTicket?.golfClub?.clubName || '-----------------'}</td>
                                        </tr>
                                        <br />

                                        <tr>
                                            <td>{language?.addresss}</td>
                                            <td>{selectedTicket && selectedTicket?.golfClub?.clubAddress || '-----------------'}</td>
                                        </tr>
                                        <br />

                                        <tr>
                                            <td>{language?.Type}</td>
                                            {/* <td>{selectedTicket && selectedTicket?.membershipType || '-----------------'}</td> */}
                                            <td>{selectedTicket?.membershipType ? `${lanKorCondition ? selectedTicket?.membershipType : typesKr[types?.indexOf(selectedTicket?.membershipType)]?.typesKr}` : `-----------------`}</td>

                                        </tr>
                                        <br />

                                        <tr>
                                            <td>{language?.JoiningFee} </td>
                                            <td>{selectedTicket && selectedTicket?.joiningFee || '-----------------'}</td>
                                        </tr>
                                        <br />

                                        <tr>
                                            <td>{language?.JoiningDate}</td>
                                            {/* <td>{selectedTicket && (selectedTicket?.joiningDate?.date1)|| '-----------------'}</td> */}
                                            <td>{selectedTicket?._id ? `${moment(selectedTicket?.joiningDate?.date1).format('YYYY-MM-DD')} / ${moment(selectedTicket?.joiningDate?.date2).format('YYYY-MM-DD')}` : '-----------------'}</td>
                                        </tr>
                                        <br />

                                        <tr>
                                            <td>{language?.SpecialGift}</td>
                                            {selectedTicket?._id ?
                                                // 주중  2회  50 만원  | 주말  1회  80만원
                                                // Weekday   2times 50만원  | weekend c 80만원
                                                lanKorCondition ?
                                                    <td>Weekday {selectedTicket && selectedTicket?.specialGiftWeekDay} times  {selectedTicket && selectedTicket?.specialGiftWeekDayKRW} milion won | weekend {selectedTicket && selectedTicket?.specialGiftWeekEnd} times  {selectedTicket && selectedTicket?.specialGiftWeekEndKRW} million won</td> :
                                                    <td>주중 {selectedTicket && selectedTicket?.specialGiftWeekDay} 회    {selectedTicket && selectedTicket?.specialGiftWeekDayKRW} 만원  | 주말  {selectedTicket && selectedTicket?.specialGiftWeekEnd}  회 {selectedTicket && selectedTicket?.specialGiftWeekEndKRW} 만원</td>
                                                :
                                                <td>----------------</td>}
                                        </tr>
                                    </table>
                                </div>
                                <br />
                                <Form.Item
                                    name='category'
                                    initialValue={'both'}
                                >
                                    <Radio.Group
                                        className='ticketsellRadio'
                                        buttonStyle='solid' onChange={(e) => {
                                            if (e?.target?.value === 'both' || e?.target?.value === 'group') {
                                                setShow(true)
                                            } else {
                                                setShow(false)
                                            }
                                        }}>
                                        <Radio.Button value={'both'} style={{ marginRight: 10, borderRadius: 8 }}>{lanKorCondition ? 'Both' : '일반 판매와 공동구매로 판매'}</Radio.Button>
                                        <Radio.Button value={'normal'} style={{ marginRight: 10, borderRadius: 8 }}>{language?.NormalSell}</Radio.Button>
                                        <Radio.Button value={'group'} style={{ marginRight: 10, borderRadius: 8 }}>{lanKorCondition ? 'Group Buy Sell' : '공동구매 '}</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item
                                    name='sellingPrice'
                                    label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' />{lanKorCondition ? 'Wanted Selling Price for this ticket ' : '회원권 판매 희망 금액'}</h4>}
                                    rules={[
                                        {
                                            required: true,
                                            message: language?.PleaseInputSellingPrice,
                                        },
                                    ]}
                                >
                                    <Input
                                        type='number'
                                        suffix={language?.KRW}
                                        maxLength={10}
                                        style={{ width: '100%' }}

                                    // value={sellingPrice}
                                    // onChange={(e) => setSellingPrice(e.target.value?.replace(/[^0-9]/g, ''))}
                                    />
                                </Form.Item>
                                {show ?
                                    <>
                                        <Form.Item
                                            style={{ display: show ? '' : 'none' }}
                                            name='totalSeats'
                                            label={<h4 style={{ marginBottom: 0 }}><PlusCircleFilled className='plus_with_text' />{language?.TotalDesiredAccount}</h4>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: language?.PleaseinputSeats
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                type='number'
                                                min={1}
                                                max={100}
                                                maxLength={7}
                                                style={{ width: '100%' }}
                                                placeholder={language?.TotalDesiredAccount}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            style={{ display: show ? '' : 'none' }}
                                            name='totalSeatsPrice'
                                            label={<h4 style={{ marginBottom: 0 }}>
                                                <PlusCircleFilled className='plus_with_text' />{lanKorCondition ? 'Seat Price' : '구좌당 금액'}</h4>}
                                        >
                                            <div>
                                                <InputNumber
                                                    disabled
                                                    value={seatPrice || 0}
                                                    type='number'
                                                    style={{ width: '100%' }}
                                                    suffix={language?.KRW}
                                                    placeholder={language?.AmoutperAccount}
                                                    maxLength={7}
                                                />
                                            </div>
                                        </Form.Item>
                                    </>
                                    : ''}
                                <Form.Item
                                    name='TermAndConditions'
                                    label={language?.TermCondition}
                                    valuePropName='checked'
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject(new Error(language?.Shouldacceptagreement)),
                                        }
                                    ]}
                                >
                                    <Checkbox />
                                </Form.Item>

                                <div className='agreement scroll_class' style={{ marginBottom: 20 }}>
                                    <p>{language?.TAC1}</p>
                                    <p>{language?.TAC2}</p>
                                    <p>{language?.TAC3}</p>
                                    <p>{language?.TAC4}</p>
                                    <p>{language?.TAC5}</p>
                                    <p>{language?.TAC6}</p>
                                    <p>{language?.TAC7}</p>
                                </div>
                                <Button loading={loading} htmlType='submit' className='req_exc_btn' type="primary">{language?.RequestSell}</Button>
                            </Form>
                        </div>


                    </div>
                </div>
            </Modal>
            <Modal
                open={isModalOpen2}
                onCancel={handleCancel2}
                footer={[<Button className='req_exc_btn' type="primary" onClick={showModal3}>{language?.BuyTicket}</Button>]}
                width={800}
            >
                <h2 style={{ textAlign: 'center' }}>{language?.SeetradingInformation}</h2>
                <h3 style={{ textAlign: 'left' }}>
                    {language?.Info}
                </h3>
                <div className='main_modal_con' style={{ borderBottom: '1px solid black' }}>
                    <div className='ticket_sale_info'>
                        <div className="column-1 box">
                            <h4>{language?.GolfClubName}</h4>
                        </div>
                        <div className="column-2 box">
                            <p>East Valley CC</p>
                        </div>
                    </div>
                    <div className='ticket_sale_info'>
                        <div className="column-1 box">
                            <h4>{language?.Type}</h4>
                        </div>
                        <div className="column-2 box">
                            <p>Company</p>
                        </div>
                    </div>
                    <div className='ticket_sale_info'>
                        <div className="column-1 box">
                            <h4>{language?.JoiningFee}</h4>
                        </div>
                        <div className="column-2 box">
                            <p>10 billion won</p>
                        </div>
                    </div>
                    <div className='ticket_sale_info'>
                        <div className="column-1 box">
                            <h4>{language?.JoiningDate}</h4>
                        </div>
                        <div className="column-2 box">
                            <p>YY.MM.DD ~ YY.MM.DD</p>
                        </div>
                    </div>
                    <div className='ticket_sale_info'>
                        <div className="column-1 box">
                            <h4>{language?.SpecialGift}</h4>
                        </div>
                        <div className="column-2 box">
                            <p>4 Weekends | 10 million won</p>
                        </div>
                    </div>
                    <div className='ticket_sale_info'>
                        <div className="column-1 box">
                            {/* <h4>Expiration Date</h4> */}
                        </div>
                        <div className="column-2 box">
                            <p>8 times a week | 9 million won</p>
                        </div>
                    </div>
                    <div className='ticket_sale_info'>
                        <div className="column-1 box">
                            <h4>{language?.Fee}</h4>
                        </div>
                        <div className="column-2 box">
                            <p>Member | 9 million won</p>
                        </div>
                    </div>
                    <div className='ticket_sale_info'>
                        <div className="column-1 box">
                            {/* <h4>Expiration Date</h4> */}
                        </div>
                        <div className="column-2 box">
                            <p>Non-members | 10 million won</p>
                        </div>
                    </div>
                </div>
                <h3 style={{ textAlign: 'left', marginTop: '10px' }}>
                    {language?.GolfCourseInfo}
                </h3>
                <div className='main_modal_con' style={{ borderBottom: '1px solid black' }}>
                    <div className='ticket_sale_info'>
                        <div className="column-1 box">
                            <h4>{language?.Address}</h4>
                        </div>
                        <div className="column-2 box">
                            <p>Address</p>
                        </div>
                    </div>
                    <div className='ticket_sale_info'>
                        <div className="column-1 box">
                            <h4>{language?.OpeningDate}</h4>
                        </div>
                        <div className="column-2 box">
                            <p>Opening Date</p>
                        </div>
                    </div>
                    <div className='ticket_sale_info'>
                        <div className="column-1 box">
                            <h4>{language?.GreenFee}</h4>
                        </div>
                        <div className="column-2 box">
                            <p>Green Fee</p>
                        </div>
                    </div>
                    <div className='ticket_sale_info'>
                        <div className="column-1 box">
                            <h4>{language?.CaddyFee}</h4>
                        </div>
                        <div className="column-2 box">
                            <p>Caddy Fee</p>
                        </div>
                    </div>
                    <div className='ticket_sale_info'>
                        <div className="column-1 box">
                            <h4>{language?.CartFee}</h4>
                        </div>
                        <div className="column-2 box">
                            <p>Cart Fee</p>
                        </div>
                    </div>
                    <div className='ticket_sale_info'>
                        <div className="column-1 box">
                            <h4>{language?.PhoneNumber}</h4>
                        </div>
                        <div className="column-2 box">
                            <p>Phone Number</p>
                        </div>
                    </div>
                    <div className='ticket_sale_info'>
                        <div className="column-1 box">
                            <h4>{language?.Homepage}</h4>
                        </div>
                        <div className="column-2 box">
                            <p>Homepage</p>
                        </div>
                    </div>
                </div>
                <h3 style={{ textAlign: 'left', marginTop: '10px' }}>
                    {language?.AdditionalBenefits}
                </h3>
                <div className='main_modal_con' style={{ borderBottom: '0px' }}>
                    <div className='ticket_sale_info'>
                        <div className="column-1 box">
                            <h4>{language?.Benefits}</h4>
                        </div>
                        <div className="column-2 box">
                            {/* <p>Address</p> */}
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                open={isModalOpen3}
                onCancel={handleCancel3}
                footer={[<div className='message_modal'><Button className='message_modal_btn' type="primary"
                    onClick={() => {
                        handleOk3();
                        history.push({
                            pathname: allPaths?.MYPAGE,
                            search: `?membershipTransaction`
                        })
                    }}>{lanKorCondition ? 'Go To My Page' : language?.GoTicketSalesRight}</Button>
                    <Button className='message_modal_btn marginLeft' type="primary" onClick={handleOk3}>{lanKorCondition ? 'Chat Bot' : language?.TalkToUser}</Button></div>]}
            // width={800}
            >
                <h2 style={{ textAlign: 'center' }}>{language?.Completion}</h2>
                <div style={{ padding: '20px' }}>
                    <center><HiCheckCircle className='tick_icon' /></center>
                    <h4 style={{ textAlign: 'center' }}>
                        {language?.ExchangeRentRequest}
                    </h4>
                    <h4 style={{ textAlign: 'center' }}>
                        {language?.PleaseWaitFor}
                    </h4>
                </div>
            </Modal>
        </>
    )
}

export default MembershipTicketModal