const authApi = `/api/user`
const getApi = `/api/get`
const postApi = `/api/post`
const adminApi = `/api/admin`
const memberTransactionApi = `/api/member-transaction`


const AUTH = {
    LOGIN: `${authApi}/login`,
    INDVIDUAL_SIGNUP: `${authApi}/individual-signup`,
    CORPORATE_SIGNUP: `${authApi}/corporate-signup`,
    SOCIAL_LOGIN: `${authApi}/social-login`,
    SEND_EMAIL_VERIFICATION_CODE: `${authApi}/send-email-verification-code`,
    SEND_CORPORATE_VERIFICATION_CODE: `${authApi}/send-corporate-verification-code`,
    VERIFY_EMAIL: `${authApi}/verify-email`,
    CHANGE_PASSWORD: `${authApi}/change-password`,
    EDIT_PERSONAL_INFO: `${authApi}/edit-personal-info`,
    EDIT_ADDITIONAL_INFO: `${authApi}/edit-additional-info`,
    DELETE_ADDITIONAL_INFO: `${authApi}/delete-additional-info`,
    EDIT_ADDRESS: `${authApi}/edit-address`,
    DELETE_ADDRESS: `${authApi}/delete-address`,
    FIND_CORPORATE_MEMBER_ID: `${authApi}/find-corporate-member-id`,
    CORPORATE_REGISTRATION_NUMBER: `${authApi}/corporate-registration-no`,
    NEW_PASSWORD: `${authApi}/new-password`,
    EDIT_PHONE: `${authApi}/edit-phone`,
    INDIV_SEND_CODE: `${authApi}/individual-member-send-code`,
    VERIFY_INDIV_OTP: `${authApi}/verify-individual-member-otp`,
    INDIV_MEMBER_SEND_CODE: `${authApi}/personal-individual-member-send-code`,
    VERIFY_MEMBER_INDIV_OTP: `${authApi}/verify-member-individual-member-otp`,
    CORPORATE_MEMBER_SEND_CODE: `${authApi}/corporate-member-send-code`,
    VERIFY_CORPORATE_MEMBER_OTP: `${authApi}/verify-corporate-member-otp`,
    EDIT_PREFERRED_REGION: `${authApi}/edit-preferred-region`,
    EDIT_PREFERRED_GOLF_CLUB: `${authApi}/edit-preferred-region`,
    DELETE_ACCOUNT: `${authApi}/delete-account`,
    IS_USER_LOGIN: `${authApi}/isUser-login`,
    // manager
    VERIFY_MANAGER: `${authApi}/verify-manager`,
    VERIFY_MANAGER_CODE: `${authApi}/verify-managerCode`,
    GET_PASS_VERIFICATION_RES: `${authApi}/get-pass-verification-result`,
    GET_PASS_VERIFICATION_CORPORATE_RES: `${authApi}/get-pass-verification-corporate-result`,
    GET_PASS_VERIFICATION_EDIT_PERSONAL_INFO_RES: `${authApi}/get-pass-verification-edit-personal-info-result`,
    GET_PASS_VERIFICATION_FIND_YOUR_ID_RES: `${authApi}/get-pass-verification-find-your-ID-result`,
    GET_PASS_VERIFICATION_FIND_YOUR_PW_RES: `${authApi}/get-pass-verification-find-your-PW-result`,
    FAIL_URL: `${authApi}/fail-url`,//params user id
}

const GET = {
    GET_USER: `${getApi}/get-user`, // params user id
    GET_TICKTS: `${getApi}/get-tickets`, // params user id
    GET_USER_CHAT_HEAD: `${getApi}/get-user-all-chat-head-by-userId`, // params user id
    GET_AUCTION_FOR_ALL_USERS: `${getApi}/get-auction-for-all-users`, // params user id
    GET_USERS_PARTICIPATION_AUCTIONS: `${getApi}/get-user-particiption-auctions`, // params user id
    GET_AUCTION_BID_FOR_USERS: `${getApi}/get-auction-Bid-for-user`,//params user id
    GET_AUCTION_FOR_USER: `${getApi}/get-auction-for-user`,//params user id
    MY_AUCTION_BID: `${getApi}/my-auction-bid`,//params user id
    GET_ALL_RENTAL_AUCTION: `${getApi}/get-all-rental-auctions`,//params user id
    GET_ALL_SALE_AUCTION: `${getApi}/get-all-sale-auctions`,//params user id
    GET_ALL_GOLF_AUCTION: `${getApi}/get-golf-res-auction`,//params user id
    //AUCTION MANAGE
    GET_ALL_MANAGE_RENTAL_AUCTION: `${getApi}/get-my-all-rental-auctions`,//params user id
    GET_ALL_MANAGE_SALE_AUCTION: `${getApi}/get-my-all-sale-auctions`,//params user id
    GET_ALL_MANAGE_RESERVATION_AUCTION: `${getApi}/get-my-all-golf-res`,//params user id
    TICKET_SHARE_RECOMMENDATION: `${getApi}/ticket-share-recommendations`,//params user id
    GET_ALL_SHARE_TICKET: `${getApi}/get-all-share-tickets`,
    GET_REAL_TIME_MARKET: `${getApi}/get-real-time-market`,
    // get-today-hot
    GET_TODAY_HOT_TICKET_SHARE: `${getApi}/get-today-hot-ticket-share`,//params user id
    GET_TODAY_HOT_TICKET_SALE: `${getApi}/get-today-hot-ticket-sale`,//params user id
    GET_TODAY_HOT_TICKET_GROUP_BUY: `${getApi}/get-today-hot-ticket-group-buy`,//params user id
    // sale tickets
    GET_ALL_SALE_TICKET: `${getApi}/get-all-sale-tickets`,

    GET_EXCHANE_REQUEST: `${getApi}/get-exchange-requests`,//params user id
    GET_RENT_REQUEST: `${getApi}/get-rent-requests`,
    // group buy
    GET_TICKETS_FOR_GROUP_BUY: `${getApi}/get-tickets-for-group-buy`,//params user id
    GET_ALL_GROUP_BUYS: `${getApi}/get-all-group-buy-tickets`,//params user id

    GET_ALL_GROUP_BUYS_REQUESTS: `${getApi}/get-Group-buy-participate-request`,//params user id

    GET_ALL_SALES_REQUESTS: `${getApi}/get-requests-buy`,//params user id
    GET_NOTIFICATIONS: `${getApi}/get-notifications`,//params user id
    PAYMENT_STATUS: `${getApi}/get-payment-status`,//params user id
    GET_ALL_FAQS: `${getApi}/get-all-Faqs`,/* GET  */
    GET_SINGLE_CHAT_HEAD_DATA: `${getApi}/get-single-chat-head-data`,/* GET  */
    GET_CHAT_MENU_COUNT: `${getApi}/get-chat-menu-count`,/* GET  */
}

const POST = {
    GOLF_RESERVATION_REGISTRATION: `${postApi}/golf-reservation-registration`, // params user id
    GET_ALL_GOLF_RESERVATION: `${postApi}/golf-all-reservation-registration`, // params user id
    CREATE_RESERVATION_REQUEST: `${postApi}/create-reservation-request`,
    GET_GOLF_RESERVATION: `${postApi}/get-golf-reservation`,
    EDIT_GOLF_RESERVATION: `${postApi}/edit-golf-reservation`,

    DELETE_GOLF_RESERVATION: `${postApi}/delete-golf-reservation`,
    GET_ONE_TO_ONE_CHATS: `${postApi}/get-one-to-one-chats`,
    PIN_CHAT: `${postApi}/pin-chat`,
    CREATE_AUCTION: `${postApi}/create-auction`,
    AUCTION_BID: `${postApi}/auction-bid`,
    ACCEPT_AUCTION: `${postApi}/accept-auction`,
    CANCEL_AUCTION: `${postApi}/cancel-auction`,
    DELETE_AUCTION_BID: `${postApi}/delete-auction-bid`,
    // NEW API's
    ADD_TICKET: `${postApi}/add-ticket`,
    EDIT_TICKET: `${postApi}/edit-ticket`,

    DELETE_TICKET: `${postApi}/delete-ticket`,
    CREATE_AUCTION_RENTAL: `${postApi}/create-auction-rental`,
    CREATE_AUCTION_SALE: `${postApi}/create-auction-sale`,
    CREATE_AUCTION_GOLF_RESERVATION: `${postApi}/create-auc-golf-res`,
    CREATE_AUCTION_RENTAL_BID: `${postApi}/create-auction-rental-bid`,
    CREATE_AUCTION_SALE_BID: `${postApi}/create-auction-sale-bid`,
    CREATE_AUCTION_GOLF_BID: `${postApi}/create-auc-golf-res-bid`,
    //AUCTION MANAGE
    ACCEPT_MANAGE_AUCTION_SALE_GOLF_BID: `${postApi}/accept-auction-sale-bid`,
    ACCEPT_MANAGE_AUCTION_RENTAL_GOLF_BID: `${postApi}/accept-auction-rental-bid`,
    ACCEPT_MANAGE_AUCTION_RESERVATION_GOLF_BID: `${postApi}/accept-golf-res-auction-bid`,
    // 
    CANCEL_MANAGE_AUCTION_SALE_GOLF_BID: `${postApi}/cancel-auction-sale-bid`,
    CANCEL_MANAGE_AUCTION_RENTAL_GOLF_BID: `${postApi}/cancel-auction-rental-bid`,
    CANCEL_MANAGE_AUCTION_RESERVATION_GOLF_BID: `${postApi}/cancel-golf-res-auction-bid`,
    // 
    SINGLE_TICKET_INFO: `${postApi}/get-single-ticket-info`,
    SHARE_TICKET: `${postApi}/share-ticket`,
    SALE_TICKET: `${postApi}/sale-ticket`,
    EXCHANGE_REQUEST: `${postApi}/exchange-request`,
    RENT_REQUEST: `${postApi}/rent-request`,
    REQUEST_BUY: `${postApi}/request-buy`,
    DELETE_CHAT: `${postApi}/delete-chat`,
    CREATE_REPORT: `${postApi}/create-report`,

    //group-buy
    UPLOAD_GROUP_BUY: `${postApi}/group-buy-ticket`,
    PARTICIPATE_GROUP_BUY: `${postApi}/group-buy-participate`,

    // Request 
    CREATE_PAYMENT_REQUEST: `${postApi}/create-payment-requests`,
    CANCEL_PAYMENT_REQUEST: `${postApi}/cancel-payment-requests`,
    EXTEND_PAYMENT_REQUEST: `${postApi}/extend-payment-requests-expiry`,
    SET_PIN_GOLF_RES: `${postApi}/set-pin-golf-res`,
    UPDATE_SALE_AUCTION_BID_PRICE: `${postApi}/update-sale-auction-bid-price`,
    UPDATE_SHARE_AUCTION_BID_PRICE: `${postApi}/update-share-auction-bid-price`,
    UPDATE_GOLF_RES_AUCTION_BID_PRICE: `${postApi}/update-golf-res-auction-bid-price`,
    // 
    DELETE_AUCTION_SALE_BID: `${postApi}/delete-auction-sale-bid`,
    DELETE_AUCTION_RENTAL_BID: `${postApi}/delete-auction-rental-bid`,
    DELETE_AUCTION_RESERVATION_GOLF_BID: `${postApi}/delete-golf-res-auction-bid`,
    PASS_VERIFICATION: `${postApi}/pass-verification`,
    // 
    GET_CHAT_DATA_BY_TICKET_OR_GOLF_ID: `${postApi}/get-chat-data-by-ticket-or-golf-id`,

    UPDATE_NOTIFICATION_IS_READ: `${postApi}/update-notification-is-read`,
    GET_MY_GOLF_RESERVATION_REQUEST: `${postApi}/get-my-reservation-request`,
    DELETE_RESERVATION_REQUEST: `${postApi}/delete-reservation-req`,
}

const ADMIN = {
    GOLF_CLUB: `${adminApi}/get-all-golf-club`,
    GET_NOTICES_AND_EVENTS: `${adminApi}/get-notice-and-events`,
    GET_BANK_INFO: `${adminApi}/get-admin-bank-info`,
    GET_HOME_BANNERS: `${adminApi}/get-all-active-banner`,
    GET_COMPANY_INFO: `${adminApi}/get-company-intro`,

}

const MEMBER_TRANSACTION = {
    REQ_EXCHANGE_BUY: `${memberTransactionApi}/req-exchange-buy`,   /* POST REQ */
    REQ_EXCHANGE_SELL: `${memberTransactionApi}/req-exchange-sell`, /* POST REQ */
    REQ_RENT_BUY: `${memberTransactionApi}/rent-buy`, /* POST REQ */
    REQ_RENT_SELL: `${memberTransactionApi}/req-rent-sell`, /* POST REQ */
    // 
    REQ_TICKET_BUY: `${memberTransactionApi}/req-ticket-buy`, /* POST REQ */
    REQ_TICKET_SELL: `${memberTransactionApi}/req-ticket-sell`, /* POST REQ */
    REQ_GROUPBUY_BUY: `${memberTransactionApi}/req-groupbuy-buy`, /* POST REQ */
    REQ_GROUPBUY_SELL: `${memberTransactionApi}/req-groupbuy-sell`, /* POST REQ */
}

export {
    AUTH,
    GET,
    POST,
    ADMIN,
    MEMBER_TRANSACTION
}