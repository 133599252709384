const HOME = '/'
const LOGIN = '/login'
const FIND = '/find-username-password'
const FINDIID = '/find-individual-ID'
const FIND_ID_PASS_VERIFICATION = '/find-id'
const FIND_ID_GUIDE = '/find-id-guide'
const FIND_PW_GUIDE = '/find-pw-guide'
const FINDCID = '/find-coporate-ID'
const FINDIP = '/find-individual-password'
const FINDCP = '/find-corporate-password'
const INDIVIDUAL_SIGNUP = '/individual-signup'
const ADMIN_INDIVIDUAL_SIGNUP = '/admin/individual-signup'
const CORPORATE_SIGNUP = '/corporate-signup'
const EASY_LOGIN = '/easy-login'
const NOTIFICATION = '/notification'
const NOTICE = '/notice'
const EVENTS = '/events'
const SERVICE_CENTER = '/service-center'
const FAQ = '/FAQ'
const TICKETSHAREREC = '/ticket-share'
const MYPAGE = '/my-page'
const CHAT = '/chat'
const FINDPASSWORD = '/find-password'
const MOBILE_PASS_VERIFICATION = '/mobile-pass-verification'
const MOBILE_MAP = '/mobile-map'
const ADMIN_SIGNUP = '/admin/individual-signup'

export default {
    ADMIN_INDIVIDUAL_SIGNUP,
    HOME,
    LOGIN,
    FINDIID,
    NOTIFICATION,
    NOTICE,
    EVENTS,
    SERVICE_CENTER,
    FAQ,
    FINDCID,
    FINDCP,
    FINDIP,
    FIND,
    EASY_LOGIN,
    INDIVIDUAL_SIGNUP,
    CORPORATE_SIGNUP,
    TICKETSHAREREC,
    MYPAGE,
    CHAT,
    FINDPASSWORD,
    FIND_ID_PASS_VERIFICATION,
    FIND_ID_GUIDE,
    FIND_PW_GUIDE,
    MOBILE_PASS_VERIFICATION,
    MOBILE_MAP,
    ADMIN_SIGNUP
}