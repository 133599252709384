import { MinusCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Divider, Form, Image, Input, Modal, Select, Space, Upload } from 'antd';
import React, { useEffect, useState } from 'react';

import axios from 'axios';
import moment from 'moment';
import { ADMIN, POST } from '../../utils/apis';
import { regions, regionsKr, typesKr } from '../../utils/constants';
import { errorMessage, requiredMessage, successMessage } from '../../utils/helpers';
import CoustomDatePicker from '../DatePicker/DatePicker';
import CoustomRangePicker from '../RangePicker/RengePicker';


const { RangePicker } = DatePicker


const { Option } = Select


const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 24,
        },
        md: {
            span: 6,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 24,
        },
        md: {
            span: 18,
        },
    },
}

const AddHoldingMembership = (props) => {

    const { user, setShowHoldingModal, showHoldingModal, getTickets, language, lanKorCondition } = props
    const [form] = Form.useForm();

    const types = ['Individual member', 'Family member', 'Individual weekday', 'Family weekday', 'Company']
    const [am, setAm] = useState('am')
    const [hr, setHr] = useState('1')
    const [min, setMin] = useState('1')
    const [joiningFee, setjoiningFee] = useState(null)
    const [weekdayFee, setWeekdayFee] = useState(null)
    const [weekday, setWeekday] = useState(null)
    const [weekendFee, setWeekendFee] = useState(null)
    const [weekend, setWeekend] = useState(null)
    const [memberFee, setMemberFee] = useState(null)
    const [nonMemberFee, setNonMemberFee] = useState(null)
    const [caddyFee, setCaddyFee] = useState(null)
    const [cartFee, setcartFee] = useState(null)
    const [greenFeeWeekday, setGreenFeeWeekday] = useState(null)
    const [greenFeeWeekend, setGreenFeeWeekend] = useState(null)
    const [totalTime, setTotalTime] = useState(hr + ':' + min + ' ' + am)
    const [isModalOpen2, setIsModalOpen2] = useState(false)
    const [isModalOpen3, setIsModalOpen3] = useState(false)
    const [showGolfClub, setShowGolfClub] = useState(false)
    const [formContinue, setFormContinue] = useState(false)
    const [golfClubData, setGolfClubData] = useState(null)
    const [phoneValue, setPhoneValue] = useState('')
    const [address, setAddress] = useState(null)
    const [loading, setLoading] = useState(false)
    const [loadingReg, setLoadingReg] = useState(false)
    const [fileList, setFileList] = useState([])
    const [golfClub, setGolfClubName] = useState(null)

    const config = {
        rules: [
            {
                type: 'object',
                required: true,
                message: language?.Pleaseselectdate,
            },
        ],
    }
    const rangeConfig = {
        rules: [
            {
                type: 'array',
                required: true,
                message: language?.Pleaseselectdate
            },
        ],
    }
    const showModal = () => {
        setShowHoldingModal(true)
    }

    const handleCancel = () => {
        setShowHoldingModal(false)
    }
    const showModal2 = () => {
        setIsModalOpen2(true)
        setShowHoldingModal(false)

    }

    const allStatesNull = () => {
        setjoiningFee(null)
        setWeekday(null)
        setWeekdayFee(null)
        setWeekendFee(null)
        setWeekend(null)
        setMemberFee(null)
        setNonMemberFee(null)
        setCaddyFee(null)
        setcartFee(null)
        setGreenFeeWeekday(null)
        setGreenFeeWeekend(null)
        setPhoneValue('')
        setAddress(null)
        setFileList([])
        setGolfClubData(null)

    }

    const handleCancel2 = () => {
        setIsModalOpen2(false)
    }
    const showModal3 = () => {
        setIsModalOpen3(true)
        setIsModalOpen2(false)

    }

    const handleOk3 = () => {
        setIsModalOpen3(false)
    }

    const handleCancel3 = () => {
        setIsModalOpen3(false)
    }
    const onChange = (value) => {
        // console.log(`selected ${value}`)
    }

    const onSearch = (value) => {
        console.log('search:', value)
    }
    const onChangeDate = (date, dateString) => {
        // console.log(date, dateString)
    }

    const onFinish = (fieldsValue) => {
        const formData = new FormData()
        const values = { ...fieldsValue }
        // console.log('fieldsValue', fieldsValue)
        if (!fieldsValue?.Benefits || !fieldsValue?.Benefits?.length) {
            return errorMessage(language?.PleaseaddBenefits)
        }
        !formContinue ? setLoadingReg(true) : setLoading(true)

        const files = values?.file?.file
        // console.log('files', files)
        let joinDate = {
            date1: fieldsValue['joiningDate'][0].format('YYYY-MM-DD'),
            date2: fieldsValue['joiningDate'][1].format('YYYY-MM-DD')
        }
        formData.append('file', files)
        formData.append('userId', user?._id)
        formData.append('golfClub', golfClubData?._id)
        formData.append('membershipType', values?.membershipType)
        formData.append('joiningDate', JSON.stringify(joinDate))
        formData.append('joiningFee', values?.joiningFee)
        formData.append('specialGiftWeekDay', weekday)
        formData.append('specialGiftWeekDayKRW', weekdayFee)
        formData.append('specialGiftWeekEnd', weekend)
        formData.append('specialGiftWeekEndKRW', weekendFee)
        formData.append('memberFee', memberFee)
        formData.append('NONmemberFee', nonMemberFee)
        formData.append('address', address)
        formData.append('openingDate', values?.openingDate)
        formData.append('greenFeeWeekDay', greenFeeWeekday)
        formData.append('greenFeeWeekEnd', greenFeeWeekend)
        formData.append('caddyFee', values?.caddyFee)
        formData.append('cartFee', values?.cartFee)
        formData.append('phoneNumber', values?.phoneNumber)
        formData.append('homepage', values?.homepage)
        formData.append('additionalBenefits', JSON.stringify(values?.Benefits))
        formData.append('lang', lanKorCondition ? 'eng' : 'kr')

        axios.post(POST?.ADD_TICKET, formData)
            .then((res) => {
                const { data } = res
                // console.log('data', data)
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    getTickets()
                    // form.resetFields()
                    allStatesNull()
                    if (!formContinue) {
                        handleCancel()
                    }
                    setFormContinue(false)
                }
                else {
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                !formContinue ? setLoadingReg(false) : setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setFormContinue(false)
                setLoading(false)
                !formContinue ? setLoadingReg(false) : setLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageKr)
            })
    }

    const normFile = (e) => {
        setFileList([])

        if (e?.fileList?.length && e?.file?.type !== 'image/png' && e?.file?.type !== 'image/jpeg') {
            errorMessage('You can only upload Images!')
            return []
        }

        if (Array.isArray(e)) {
            return e
        }

        e?.fileList?.length ? setFileList([...[e?.file]]) : setFileList([])
        return e && [e.file]
    }

    const handlePhoneInput = (e) => {
        let value = e?.target?.value?.replace(/[^0-9-]/g, '')
        let event = e?.nativeEvent?.data
        if (event && ((value?.length === 3 || value?.length === 8) || (phoneValue?.length === 3 || phoneValue?.length === 8))) {
            if (phoneValue?.length === 3 || phoneValue?.length === 9) {
                value = `${phoneValue}-${event}`
            }
            else {
                value = `${value}-`
            }
        }
        setPhoneValue(value)
    }

    const onChanges = (value) => {

        let new_value = golfClub?.find((v) => v?._id === value)
        setGolfClubData(new_value)
        setAddress(new_value?.clubAddress)

    }


    let golfClubName = () => {
        axios.get(ADMIN.GOLF_CLUB)
            .then((res) => {

                const { data } = res
                // console.log('data====>',data)
                if (data.success) {
                    setGolfClubName(data?.data)
                }
                else {
                    console.log('runing')
                }
            })
            .catch((e) => {
                console.log('err', e)
            })
    }

    useEffect(() => {
        golfClubName()
    }, [])

    const setExpiryDate = (e) => {
        let date = moment(e?._d).add(-1, 'days').format('YYYY-MM-DD')
        form.setFieldValue('openingDate', moment(date, 'YYYY-MM-DD'))
    }
    const setDateRange = (e) => {
        let date1 = moment(e[0]?._d).add(-1, 'days').format('YYYY-MM-DD')
        let date2 = moment(e[1]?._d).add(-1, 'days').format('YYYY-MM-DD')
        form.setFieldValue('joiningDate', [moment(date1, 'YYYY-MM-DD'), moment(date2, 'YYYY-MM-DD')])
    }


    return (
        <>
            <Modal
                open={showHoldingModal}
                onCancel={handleCancel}
                className='addholdingModal'
                footer={null}
                width={'100%'}
                style={{ top: '10px', }}
            >
                <div
                    className='scroll_class'
                    style={{
                        height: 'auto',
                        width: '100%',
                        maxHeight: '85vh',
                        overflowY: 'scroll',
                        marginTop: '12px'
                    }}>
                    <div className='modal_main_div'>
                        <center><h2>{language?.RegisterYourMembership}</h2></center>

                        <h2>{language?.Info}</h2>
                        <Form name='time_related_controls' form={form} className='formClass' requiredMark={false}  {...formItemLayout} onFinish={onFinish}>

                            <Form.Item
                                name='golfClub'
                                label={language?.GolfCourseName}
                            >
                                <div className='row_fields' style={{ marginBottom: 10, alignItems: 'baseline' }} >
                                    <Select
                                        style={{ width: '70%' }}
                                        showSearch
                                        value={golfClubData?.id}
                                        placeholder={language?.SearchGolfCourseName}
                                        optionFilterProp='children'
                                        onChange={onChanges}
                                        onSearch={onSearch}
                                        filterOption={(input, option) => option.children.toLowerCase()?.includes(input?.toLowerCase())}
                                    >
                                        {golfClub?.map((v, i) => (<Option key={i} value={v?._id}>{v?.clubName}</Option>))}
                                    </Select>
                                    <Button
                                        style={{ marginLeft: 10 }}
                                        className='golfSearchButton'
                                        size='middle'
                                        onClick={() => setShowGolfClub(true)}
                                    >{language?.searchbygolf}</Button>
                                </div>
                            </Form.Item>

                            {golfClubData?._id ?
                                <div className='row_fields1' style={{ maxWidth: 300, marginBottom: 20 }}>
                                    <Image width={100} height={100} src={golfClubData?.imgUrl} />
                                    <div style={{ marginTop: -10 }}>
                                        <h2>{golfClubData?.clubName}</h2>
                                        <p>
                                            {lanKorCondition ? golfClubData?.region : regionsKr[regions?.indexOf(golfClubData?.region)]?.TitleKr}
                                        </p>
                                        {/* <p>{golfClubData?.region}</p> */}

                                        <Button onClick={() => { setGolfClubData(null); setAddress(null) }}>{language?.Delete}</Button>
                                    </div>
                                </div> : null}

                            <Form.Item
                                name='file'
                                label={language?.AttachmentMembershipCar}
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please input Membership Card!',
                            //         whitespace: false
                            //     },
                            // ]}
                            >
                                <Upload
                                    style={{ width: '100%' }}
                                    type='file'
                                    name='file'
                                    multiple={false}
                                    beforeUpload={() => false}
                                    accept='image/png, image/jpeg'
                                    onChange={normFile}
                                    fileList={fileList}
                                >
                                    <Button style={{ width: '100%' }} icon={<UploadOutlined />}></Button>
                                </Upload>

                            </Form.Item>

                            <Form.Item
                                name='membershipType'
                                label={language?.membershipType}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.PleaseSelectType,
                                    },
                                ]}
                            >
                                <Select placeholder={language?.membershipType}>
                                    {language?.login === "Login" ?
                                        types?.map((v, i) => (<Option key={i} value={v}>{v}</Option>)) :
                                        typesKr?.map((v, i) => (
                                            <Option key={i} value={v?.typesEn}>{v?.typesKr}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name='joiningFee'
                                label={language?.entrancefee}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.PleaseinputJoiningFee
                                    },
                                ]}
                            >
                                <div>
                                    <Input
                                        suffix={language?.KRW}
                                        placeholder={language?.entrancefee}
                                        maxLength={10}
                                        value={joiningFee}
                                        onChange={(e) => setjoiningFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item name='joiningDate' label={language?.Timeperiod} {...rangeConfig}>
                                {/* <RangePicker style={{ width: '100%' }} placeholder={[language?.Startdate, language?.Enddate]} /> */}
                                <CoustomRangePicker noDefault={true} format={'YYYY-MM-DD'} /* date={dateRange1} */ setDate={setDateRange} />

                            </Form.Item>

                            <Form.Item
                                name='specialGift'
                                label={language?.SpecialGift}
                                rules={[
                                    {
                                        required: true,
                                        validator: async (_, names) => {
                                            if (!weekday) {
                                                return Promise.reject(new Error(language?.PleaseEnterWeekday))
                                            }
                                            if (!weekdayFee) {
                                                return Promise.reject(new Error(language?.PleaseEnterWeekdayFee))
                                            }
                                            if (!weekend) {
                                                return Promise.reject(new Error(language?.PleaseEnterWeekend))
                                            }
                                            if (!weekendFee) {
                                                return Promise.reject(new Error(language?.PleaseEnterWeekendFee))
                                            }
                                        }
                                    }
                                ]}
                            >
                                <div className='holdingDiv' style={{ display: 'flex' }}>
                                    <div className='holding_member_fee'  >

                                        {/* <label style={{ marginRight: '4%' }} htmlFor=''>{language?.Weekday}</label> */}

                                        <div style={{ width: 'auto', display: 'flex' }}>
                                            <p className='p_weekday' >{language?.Weekday}</p>
                                            <Input
                                                placeholder={language?.episode}
                                                className='input_weekday'
                                                maxLength={3}
                                                value={weekday}
                                                onChange={(e) => setWeekday(e.target.value.replace(/[^0-9]/g, ''))}
                                            />
                                            <Input
                                                suffix={language?.KRW}
                                                className='input_weekend'
                                                value={weekdayFee}
                                                onChange={(e) => setWeekdayFee(e.target.value.replace(/[^0-9]/g, ''))}
                                                maxLength={10}

                                            />
                                        </div>
                                    </div>
                                    <div className='holding_member_fee'>
                                        {/* <label style={{ marginRight: '4%' }} htmlFor=''>{language?.Weekend}</label> */}
                                        <div style={{ width: 'auto', display: 'flex' }}>
                                            <p className='p_weekday'>{language?.Weekend}</p>

                                            <Input
                                                placeholder={language?.episode}
                                                className='input_weekday'
                                                maxLength={4}
                                                value={weekend}
                                                onChange={(e) => setWeekend(e.target.value.replace(/[^0-9]/g, ''))}
                                            />
                                            <Input
                                                suffix={language?.KRW}
                                                className='input_weekend'
                                                maxLength={9}
                                                value={weekendFee}
                                                onChange={(e) => setWeekendFee(e.target.value.replace(/[^0-9]/g, ''))}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item
                                name='fee'
                                label={language?.usageFee}
                                rules={[
                                    {
                                        required: true,
                                        validator: async (_, names) => {
                                            if (!memberFee) {
                                                return Promise.reject(new Error(language?.PleaseEnterMemberFee))
                                            }
                                            if (!nonMemberFee) {
                                                return Promise.reject(new Error(language?.PleaseEnterNon_MemberFee))
                                            }
                                        }
                                    }
                                ]}
                            >
                                <div className='usageFeeMemberNonmember' style={{ display: 'flex' }}>
                                    <div style={{ width: '50%', textAlign: 'center' }}>
                                        <label style={{ marginRight: '4%' }} htmlFor=''>{language?.Member}</label>
                                        <Input
                                            suffix={language?.KRW}
                                            style={{
                                                width: 'auto',
                                                // marginLeft: '4%',
                                            }}
                                            maxLength={10}
                                            value={memberFee}
                                            onChange={(e) => setMemberFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                        />
                                    </div>

                                    <div style={{ width: '50%', marginLeft: 'auto', textAlign: 'center' }}>
                                        <label htmlFor='' style={{ marginRight: '4%' }}>{language?.NonMember}</label>

                                        <Input
                                            suffix={language?.KRW}
                                            style={{
                                                width: 'auto',
                                                // marginLeft: '4%',

                                            }}
                                            maxLength={10}
                                            value={nonMemberFee}
                                            onChange={(e) => setNonMemberFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                        />
                                    </div>
                                </div>
                            </Form.Item>

                            <Divider style={{ backgroundColor: '#bbb', marginBottom: 20 }} />

                            <h2>{language?.GolfCourseInformation}</h2>

                            {/* <Form.Item
                            name='address'
                            label={language?.addressMyPage}
                        // rules={[
                        //     {
                        //         required: true,
                        //         validator: async (_, names) => {
                        //             if (!golfClubData?.address) {
                        //                 return Promise.reject(new Error('Please Enter address'))
                        //             }
                        //         }
                        //     }
                        // ]}
                        >
                            <div className='row_fields1'>

                                <Input
                                    type='text'
                                    disabled
                                    style={{ width: '100%' }}
                                    value={golfClubData?.clubAddress}
                                />

                            </div>
                        </Form.Item> */}



                            <Form.Item name='openingDate' label={language?.OpeningDate}{...config}>
                                {/* <DatePicker onChange={()=>console.log('the date',form.getFieldValue('openingDate'))} placeholder={language?.Selectdate} style={{ width: '100%' }} /> */}
                                <CoustomDatePicker placeholder={language?.Selectdate} setDate={setExpiryDate} />

                            </Form.Item>

                            <Form.Item
                                name='greenFee'
                                label={language?.GreenFee}
                                rules={[
                                    {
                                        required: true,
                                        validator: async (_, names) => {
                                            if (!greenFeeWeekday) {
                                                return Promise.reject(new Error(language?.Pleaseentergreenfeeweekday))
                                            }
                                            if (!greenFeeWeekend) {
                                                return Promise.reject(new Error(language?.Pleaseentergreenfeeweekend))
                                            }
                                        }
                                    }
                                ]}
                            >
                                <div style={{ display: 'flex' }}>
                                    <Input
                                        style={{ width: '49.5%', marginRight: '0.5%' }}
                                        suffix={language?.KRW}
                                        addonBefore={language?.Weekday}
                                        maxLength={7}
                                        value={greenFeeWeekday}
                                        onChange={(e) => setGreenFeeWeekday(e.target.value?.replace(/[^0-9]/g, ''))}
                                    />
                                    <Input
                                        style={{ width: '49.5%', marginLeft: '0.5%' }}
                                        suffix={language?.KRW}
                                        addonBefore={language?.Weekend}
                                        maxLength={7}
                                        value={greenFeeWeekend}
                                        onChange={(e) => setGreenFeeWeekend(e.target.value?.replace(/[^0-9]/g, ''))}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item
                                name='caddyFee'
                                label={language?.Caddy}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.PleaseinputCaddyFee,
                                    },
                                ]}
                            >
                                <Input
                                    suffix={language?.KRW}
                                    placeholder={language?.Caddy}
                                    maxLength={7}
                                    value={caddyFee}
                                    onChange={(e) => setCaddyFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                />
                            </Form.Item>

                            <Form.Item
                                name='cartFee'
                                label={language?.CartFee}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.PleaseinputCartFee,
                                    },
                                ]}
                            >
                                <Input
                                    suffix={language?.KRW}
                                    placeholder={language?.CartFee}
                                    maxLength={7}
                                    value={cartFee}
                                    onChange={(e) => setcartFee(e.target.value?.replace(/[^0-9]/g, ''))}
                                />
                            </Form.Item>

                            <Form.Item
                                name='phoneNumber'
                                label={language?.PhoneNumber}
                                rules={[
                                    {
                                        required: true,
                                        message: requiredMessage(language?.PhoneNumber)
                                    }
                                ]}
                            >
                                <div>
                                    <Input
                                        required
                                        maxLength={13}
                                        minLength={13}
                                        value={phoneValue}
                                        onChange={handlePhoneInput}
                                        placeholder={language?.phonePlaceholder}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item
                                name='homepage'
                                label={language?.HomePage}
                                rules={[
                                    {
                                        required: true,
                                        message: language?.Pleaseinputyourhomepage,
                                    }
                                ]}
                            >
                                <Input placeholder={language?.HomePage} />
                            </Form.Item>

                            <Divider style={{ backgroundColor: '#bbb', marginBottom: 20 }} />

                            <h2>{language?.Additionalbenefits}</h2>

                            <Form.List name='Benefits'  >
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            < Space
                                                key={key}
                                                style={{
                                                    display: 'flex',
                                                    marginBottom: 8,
                                                    width: '100%'
                                                }}
                                                align='baseline'
                                            >
                                                <Form.Item
                                                    wrapperCol={{ span: 24 }}
                                                    {...restField}
                                                    // label='Benefits'
                                                    name={[name, 'benefitName']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: language?.Missingfirstname,
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder={language?.BenefitName} />
                                                </Form.Item>
                                                <Form.Item
                                                    wrapperCol={{ span: 24 }}

                                                    {...restField}
                                                    name={[name, 'benefitContent']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: language?.Missinglastname,
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder={language?.BenefitContents} />
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                            </Space>
                                        ))}

                                        <Form.Item wrapperCol={{ span: 24 }}>
                                            <Button className='req_exc_btn1' onClick={() => add()} block>
                                                {language?.EnterAdditionalBenefits}
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>


                            {/* <Button
                                onClick={() => setFormContinue(true)}
                                // loading={loading}
                                className='req_exc_btn1'
                                htmlType='submit'
                                block
                                loading={loading}
                                disabled={loadingReg}
                            >
                                {language?.AddYourMembership}
                            </Button>
                            <br />
                            <br /> */}


                            <Button
                                loading={loadingReg}
                                disabled={loadingReg}
                                className='req_exc_btn'
                                type='primary'
                                htmlType='submit'>{language?.Register}</Button>
                        </Form>


                    </div>
                </div>
            </Modal>
        </>
    )
}

export default AddHoldingMembership