import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'
import { ADMIN } from '../../utils/apis'
import { Modal } from 'antd'

const CompanyIntro = (props) => {
    const { modalOpen, setModalOpen } = props
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const getData = () => {
        setLoading(true)
        axios.get(ADMIN?.GET_COMPANY_INFO)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setData(data?.data)
                    console.log(data?.data, "======+=+++++++++++++++++++++")
                    setLoading(false)
                }
                else {
                    console.log('runing')
                }
            })
            .catch((e) => {
                console.log('err', e)
            })
    }
    useEffect(() => {
        getData()
    }, [])

    const im = [{ _id: '64372579e4fcc10be4f1fc05', url: 'https://golf-ticket.s3.amazonaws.com/company-intro…39aec95260bee428fcd6b63c&X-Amz-SignedHeaders=host', name: 'golf club.jpg' }, { _id: '64372579e4fcc10be4f1fc05', url: 'https://golf-ticket.s3.amazonaws.com/company-intro…39aec95260bee428fcd6b63c&X-Amz-SignedHeaders=host', name: 'golf club.jpg' }]
    return (
        <>

            <>
                <Modal
                    open={modalOpen}
                    onCancel={() => setModalOpen(false)}
                    className='addholdingModal'
                    footer={null}
                    width={'100%'}
                    style={{ top: '10px' }}
                >
                    <div
                        className='scroll_class'
                        style={{
                            minHeight: window.innerHeight - 200,
                            maxHeight: 'auto',
                            width: '100%',
                            maxHeight: '85vh',
                            overflowY: 'scroll',
                            marginTop: '12px',
                        }}>
                        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                            <h1>회사소개</h1>
                            <h1>{data?.content}</h1>
                        </div>
                        {data?._id ?
                            <>
                                {data?.imageUrls?.map((v) => {
                                    return (
                                        <img style={{ width: 200, height: 200, marginRight: 20 }} src={v?.url} />
                                    )
                                })}
                            </>
                            :
                            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                                <h1>No Data Found...</h1>
                            </div>
                        }
                    </div>
                </Modal>
            </>



        </>
    )
}

export default CompanyIntro