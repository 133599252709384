import React, { useState, useEffect, useRef, } from 'react'
import { Tabs, Button, Input, Form, Select, Radio, Typography, Card, Tooltip, Badge, Popconfirm, Modal, Dropdown, Space, } from 'antd'
import Header from '../Home/Header'
import { CheckCircleOutlined, SearchOutlined, PushpinOutlined, FileImageOutlined, ArrowRightOutlined, ArrowLeftOutlined, MoreOutlined } from '@ant-design/icons'
import { RiSoundModuleFill, RiChat3Line } from 'react-icons/ri'
import { GrAttachment } from 'react-icons/gr'
import { AiOutlineSend } from 'react-icons/ai'
import { MdNotificationImportant, MdOutlineLogout, MdSpeakerNotesOff } from 'react-icons/md'
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css'
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator, MessageSeparator, Avatar } from '@chatscope/chat-ui-kit-react'
import { errorMessage, successMessage, stringLimiter, convertTitle } from '../../utils/helpers'
import TextArea from 'antd/lib/input/TextArea'
import axios from 'axios'
import _, { isNull } from 'lodash'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import PaymentRequestModal from '../../Components/TicketShareTabs/PaymentRequestModal'
import socket from '../../Config/socket'
import { setSelectedChat, setSelectedChatHead } from '../../Redux/actions/userActions'
import { GET, POST } from '../../utils/apis'
import { UseCountdown } from './CountDown'
import { loadTossPayments } from '@tosspayments/payment-sdk'
import { allPaths, regions, regionsKr, tossPayClientKey, tossPaySecretKey } from '../../utils/constants'

const { Title } = Typography

const { Option } = Select

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        // sm: {
        //     span: 8,
        // },
        // md: {
        //     span: 6,
        // },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        // sm: {
        //     span: 16,
        // },
        // md: {
        //     span: 18,
        // },
    },
}

const Chat = (props) => {
    const { user, history, userActions, lanKorCondition, language, location } = props
    const selectedChat = useSelector(state => state?.userReducer?.selectedChat)
    const selectedChatHead = useSelector(state => state?.userReducer?.selectedChatHead)
    const { fullName, email, _id } = user
    const [messageText, setMessageText] = useState('')
    const [userName, setUserName] = useState('')
    const [file, setFile] = useState(null)
    const receiverId = new URLSearchParams(location.search).get('userId')
    const chatHeadId = new URLSearchParams(location.search).get('chatHeadId')
    const [messageList, setMessageList] = useState([])
    const [loading, setLoading] = useState(false)
    const [reportLoading, setReportLoading] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [rightArrow, setrightArrow] = useState(true)
    const [funEffect, setFunEffect] = useState(true)
    const [showNewChat, setShowNewChat] = useState(false)
    const [chatHead, setChatHead] = useState([])
    const dispatch = useDispatch()
    const [selectedFile, setSelectedFile] = useState(null)
    const [localImage, setLocalImage] = useState(null)
    const [reportOpen, setReportOpen] = useState(false)
    const [openPaymentRequestModal, setOpenPaymentRequestModal] = useState(false)
    const [loadingExtend, setLoadingExtend] = useState(false)
    const [selectedHeadId, setSelectedHeadId] = useState(null)
    const [reportedUserId, setReportedUserId] = useState({})
    const [popupData, setPopupData] = useState({})
    const [form] = Form.useForm()
    const [searchValue, setSearchValue] = useState('')
    const [chatfilterValue, setChatFilterValue] = useState('A')

    let popupDetail = location?.state

    const reportType = [
        { eng: 'Advertisement/Obscence chat ', kr: ' 광고/음란성 채팅' },
        { eng: 'Profanity / Inappropriate language', kr: ' 욕설 / 부적절한 언어' },
        { eng: 'Trading fraud', kr: ' 거래사기' }
    ]

    const paymentKey = new URLSearchParams(location.search).get('paymentKey')
    const paymentKeyOrderId = new URLSearchParams(location.search).get('orderId')
    const paymentKeyAmount = new URLSearchParams(location.search).get('amount')
    // // CHANGES
    const golfReservationId = new URLSearchParams(location.search).get('golfReservationId')
    const ticketId = new URLSearchParams(location.search).get('ticketId')
    const chatType = new URLSearchParams(location.search).get('chatType')

    useEffect(() => {
        if (chatHeadId) {
            axios.get(`${GET?.GET_SINGLE_CHAT_HEAD_DATA}/${chatHeadId}`)
                .then((res) => {
                    const { data } = res
                    if (data.success) {
                        setPopupData(data?.data)
                    }
                    else {
                        console.log(data?.message)
                    }
                }).catch((err) => {
                    console.log('err', err)
                    errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
                })
        }
    }, [chatHeadId])
    useEffect(() => {
        if ((golfReservationId || ticketId) && chatType) {
            let obj = {
                golfReservationId: golfReservationId || undefined,
                ticketId: ticketId || undefined,
            }
            axios.post(POST?.GET_CHAT_DATA_BY_TICKET_OR_GOLF_ID, obj)
                .then((res) => {
                    const { data } = res
                    if (data.success) {
                        setPopupData(data?.data)
                    }
                    else {
                        console.log(data?.message)
                    }
                }).catch((err) => {
                    console.log('err', err)
                    errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
                })
        }
    }, [golfReservationId, ticketId, chatType])

    useEffect(() => {
        if (paymentKey && paymentKeyOrderId && paymentKeyAmount) {
            axios.get(`${GET?.PAYMENT_STATUS}/?paymentKey=${paymentKey}&orderId=${paymentKeyOrderId}&amount=${paymentKeyAmount}`)
                .then((res) => {
                    const { data } = res
                    if (data.success) {
                        successMessage(data.message)
                        getPaymentFuns()
                    }
                    else {
                        console.log(data?.message)
                    }
                }).catch((err) => {
                    console.log('err', err)
                    setReportLoading(false)
                    errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
                })
        }
    }, [paymentKey, paymentKeyOrderId, paymentKeyAmount])

    // UPDATE USER SOCKET ID
    socket.emit('updateUser', { userId: _id }, ({ error }) => {
        // console.log('User Added Successfully')
        console.log(error)
    })

    const findUser = (v) => {
        axios.get(`${GET?.GET_USER}/${v || receiverId}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setUserName(data?.user)
                }
                else {
                    setUserName('')
                }
            })
            .catch((err) => {
                console.log('err', err)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    const getChatHead = () => {
        axios.get(`${GET?.GET_USER_CHAT_HEAD}/${user._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setLoading(false)
                    setChatHead(data?.data)
                }
                else {
                    setLoading(false)
                    console.log(data?.message)
                }
            })
            .catch((err) => {
                console.log('err', err)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })

    }

    const socketFunc = () => {
        socket.on('message-receive', (d) => {
            if (!messageList?.includes(d)) {
                setMessageList((list) => [...list, d])
            }
        })
        setFunEffect('true')
    }
    // CHANGES
    const sendMessage = () => {
        setFunEffect('false')
        let obj;
        if (chatType && (golfReservationId || ticketId)) {
            obj = {
                senderId: _id,
                type: 'text',
                receiverId: receiverId,
                message: messageText,
                time:
                    new Date(Date.now()).getHours() +
                    ':' +
                    new Date(Date.now()).getMinutes(),
                chatType: chatType,
                golfReservationId: golfReservationId || undefined,
                ticketId: ticketId || undefined,
            }
        } else {
            obj = {
                senderId: _id,
                type: 'text',
                receiverId: receiverId,
                message: messageText,
                time:
                    new Date(Date.now()).getHours() +
                    ':' +
                    new Date(Date.now()).getMinutes(),
                chatHeadId: chatHeadId
            }
        }

        socket.emit('message-send', obj, (e, d) => {
            console.log('Message Sent Successfully')
        })
        setMessageList((list) => {
            return [...list, obj]
        })
        setMessageText('')
        getChatHead()
    }

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0])
        setLocalImage(URL?.createObjectURL(e.target.files[0]))
    }

    const sendImage = () => {
        setFunEffect('false')
        let obj = {
            senderId: _id,
            type: 'image',
            receiverId: receiverId,
            message: selectedFile,
            time:
                new Date(Date.now()).getHours() +
                ':' +
                new Date(Date.now()).getMinutes()
        }

        socket.emit('image-send', obj, (e, d) => {
            // console.log('Message Sent Successfully')
        })

        let obj1 = {
            senderId: _id,
            type: 'image',
            receiverId: receiverId,
            message: localImage,
            time:
                new Date(Date.now()).getHours() +
                ':' +
                new Date(Date.now()).getMinutes()
        }
        setMessageList((list) => {
            return [...list, obj1]
        })
        setMessageText('')
        getChatHead()
    }

    useEffect(() => {
        getChatHead()
        setLoading(true)
    }, [socket, buttonLoading, messageList])

    useEffect(() => {
        socketFunc()

    }, [socket])
    // CHANGES
    const selectChat = (firstUser, secondUser, val, chatHeadId, chatType, golfReservationId, ticketId) => {
        let matchCond = _id === firstUser ? secondUser : firstUser
        if (matchCond) {
            if (chatHeadId?.match(/^[0-9a-fA-F]{24}$/)) {
                history.push({
                    pathname: '/chat',
                    search: `?userId=${matchCond}&chatHeadId=${chatHeadId}`,
                    state: location?.state
                })
            } else {
                history.push({
                    pathname: '/chat',
                    search: `?userId=${matchCond}&chatType=${chatType}&golfReservationId=${golfReservationId}&ticketId=${ticketId}`,
                    state: location?.state
                })
            }
            findUser(matchCond)
        }
        let obj;
        if (chatHeadId) {
            obj = {
                currentUserId: firstUser,
                opponentId: secondUser,
                chatHeadId
            }
        } else {
            obj = {
                currentUserId: firstUser,
                opponentId: secondUser,
                ticketId,
                golfReservationId,
                chatType
            }
        }
        // console.log('obj', obj)
        let newVal = val ? val : selectedChat
        dispatch(setSelectedChatHead({ chatHeadId: chatHeadId }))
        dispatch(setSelectedChat(newVal))
        axios.post(`${POST?.GET_ONE_TO_ONE_CHATS}`, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setLoading(false)

                    setMessageList(data?.data || [])
                    // console.log('data', data)
                }
                else {
                    setMessageList([])
                    setLoading(false)
                    console.log(data?.message)
                    // errorMessage(data?.message)
                }
            }).catch((err) => {
                console.log('err', err)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })

    }
    // CHANGES
    useEffect(() => {
        findUser()
        if (chatType && (golfReservationId || ticketId)) {
            selectChat(user?._id, receiverId, null, null, chatType, golfReservationId, ticketId)
        } else {
            selectChat(user?._id, receiverId, null, chatHeadId)
        }
    }, [])

    const deleteChat = () => {
        let obj = {
            _id: selectedHeadId
        }
        setLoading(true)
        // console.log('obj', obj)
        axios.post(`${POST?.DELETE_CHAT}`, obj)
            .then((res) => {
                const { data } = res
                // console.log('data', data)
                if (data.success) {
                    setLoading(false)
                    successMessage('Successfully Deleted')
                    history.push('/chat')
                    // getChatHead()
                    window.location.reload()
                }
                else {
                    setLoading(false)
                    console.log(data?.message)
                }
            }).catch((err) => {
                console.log('err', err)
                setLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    const pinFunc = (isPin, chatHeadId) => {
        setButtonLoading(true)
        let obj = {
            userId: user?._id,
            isPin: isPin,
            chatHeadId: chatHeadId
        }
        axios.post(`${POST?.PIN_CHAT}`, obj)
            .then((res) => {
                const { data } = res
                // console.log('data', data)
                if (data.success) {
                    setButtonLoading(false)
                }
                else {
                    // setMessageList([])
                    setButtonLoading(false)
                    console.log(data?.message)
                    // errorMessage(data?.message)
                }
            }).catch((err) => {
                console.log('err', err)
                setButtonLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    const handleOk = () => {
        setReportOpen(false)
    }
    const handleCancel = () => {
        setReportOpen(false)
    }


    const onFinish = (values) => {
        let checkReportedUser = reportedUserId?.firstUser === user?._id ? reportedUserId?.secondUser : reportedUserId?.firstUser
        values.userId = user?._id
        values.reportedUserId = checkReportedUser
        // console.log(values)
        setReportLoading(true)
        axios.post(`${POST?.CREATE_REPORT}`, values)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setReportLoading(false)
                    successMessage('Report Submitted Succesfully')
                    setReportOpen(false)
                }
                else {
                    setReportOpen(false)
                    setReportLoading(false)
                    console.log(data?.message)
                }
            }).catch((err) => {
                console.log('err', err)
                setReportLoading(false)
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
            })
    }

    const getPaymentFuns = () => {
        findUser(receiverId)
        getChatHead()
        selectChat(user?._id, receiverId)
    }

    const getRegion = (e) => {
        if (lanKorCondition) {
            return e
        } else {
            return regionsKr[regions?.indexOf(e)]?.TitleKr
        }
    }
    
    return (
        <div className='home-main'>
            <Header {...props} />
            <div className='card-parent'>
                <div className='flex-2-box'>
                    {rightArrow ?
                        <Button style={{ height: 30, marginTop: 10 }} icon={<ArrowLeftOutlined />} className='arrowButton' onClick={() => setrightArrow(false)} type='text' size='large' />
                        :
                        <Button style={{ height: 30, marginTop: 10 }} icon={<ArrowRightOutlined />} className='arrowButton' onClick={() => setrightArrow(true)} type='text' size='large' />}
                    <div style={!rightArrow ? { display: 'none' } : null} className='chat_card scroll_class1 chat_main chat_card1 collapseClass'>
                        <div>
                            <Input
                                style={{ marginBottom: 5 }}
                                bordered={false}
                                placeholder={language?.Membername_content}
                                onChange={(e) => setSearchValue(e?.target?.value)}
                                suffix={<SearchOutlined />} />
                            <Select
                                style={{
                                    width: '100%',
                                    textAlign: 'center'
                                }}
                                defaultValue='All'
                                placeholder='Search to Select'
                                onChange={(e) => setChatFilterValue(e)}
                            >
                                <Option value='A'>{lanKorCondition ? 'All Message' : '전체메시지'}</Option>
                                <Option value='S'>{lanKorCondition ? 'Seller Message' : ' 구매 메시지 '}</Option>
                                <Option value='B'>{lanKorCondition ? 'Buyer Message' : '판매 메시지'}</Option>
                            </Select>
                        </div>
                        <div className='users_chat'>

                            {
                                chatHead?.length ?
                                    chatHead?.filter((val, i) => {
                                        if (val?.lastMessage?.toLowerCase()?.includes(searchValue?.toLowerCase()?.trim()) || val?.secondUser?.fullName?.toLowerCase()?.includes(searchValue?.toLowerCase()?.trim()) || val?.firstUser?.fullName?.toLowerCase()?.includes(searchValue?.toLowerCase()?.trim())) {
                                            return val
                                        } else if (chatfilterValue == 'S') {
                                            if (user?._id == val?.golfReservationId?.userId || user?._id == val?.ticketId?.userId) {
                                                return val
                                            } else {
                                                return []
                                            }
                                        } else if (chatfilterValue == 'B') {
                                            if (user?._id != val?.golfReservationId?.userId || user?._id != val?.ticketId?.userId) {
                                                return val
                                            } else {
                                                return []
                                            }
                                        }
                                    })?.map((val, i) => {
                                        let url = val?.lastMessage
                                        let now = moment(new Date()).utc() //todays date
                                        let firstUserLogin = moment(val?.firstUser?.lastLogin).utc() // another date
                                        let secondUserLogin = moment(val?.secondUser?.lastLogin).utc() // another date
                                        let durationFirstUser = moment.duration(now.diff(firstUserLogin)).asMinutes()
                                        let durationSecondUser = moment.duration(now.diff(secondUserLogin)).asMinutes()
                                        return (
                                            <div key={i} className='users_chats' style={messageList?.length && (chatHeadId === val?._id || (val?.golfReservationId?._id === golfReservationId && golfReservationId && val?.chatType === chatType) || (val?.ticketId?._id === ticketId && ticketId && val?.chatType === chatType)) ? { border: '3px solid #5ea29f' } : { border: '2px solid #bbb' }}
                                                onClick={() => {
                                                    setSelectedHeadId(val?._id),
                                                        setReportedUserId({ firstUser: val?.firstUser?._id, secondUser: val?.secondUser?._id, val }),
                                                        selectChat(val?.firstUser?._id, val?.secondUser?._id, val, val?._id)
                                                }}
                                            >
                                                <div className='userchat_first_div'>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <h5 className='chat_name'>{_id == val?.firstUser?._id ? val?.secondUser?.fullName : val?.firstUser?.fullName}

                                                        </h5>
                                                        <p className='lastMessage'>
                                                            {url?.includes('golf-ticket/Chat/') ?
                                                                // <FileImageOutlined />
                                                                <Button
                                                                    // disabled
                                                                    style={{ border: 'none', top: 10, right: 8, backgroundColor: 'transparent' }}
                                                                    icon={<FileImageOutlined />}
                                                                >Photo</Button>
                                                                : val?.lastMessage}
                                                        </p>
                                                    </div>
                                                    {_id === val?.firstUser?._id ?
                                                        (
                                                        /* val?.secondUser?.isLogin === true && */ (durationSecondUser <= 10) ?
                                                                <span className="logged-in">●</span>
                                                                : <span className="logged-out">●</span>
                                                        ) :
                                                        (
                                                        /* val?.firstUser?.isLogin === true && */ (durationFirstUser <= 10) ?
                                                                <span className="logged-in">●</span>
                                                                : <span className="logged-out">●</span>
                                                        )
                                                    }
                                                    {user?._id === val?.golfReservationId?.userId || user?._id == val?.ticketId?.userId ?
                                                        <span className='is-seller'>B</span>
                                                        : <span className='is-buyer'>S</span>
                                                    }
                                                    <p style={{ marginLeft: 'auto', width: 75 }}>{moment(val?.updated_at).utc().format('hh:mm')}
                                                        <Button
                                                            style={{ marginLeft: 8 }}
                                                            loading={buttonLoading}
                                                            type={val?.pin ? 'primary' : 'default'} shape='circle'
                                                            onClick={() => pinFunc(!val?.pin, val?._id)}
                                                            icon={<PushpinOutlined />}
                                                        />
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : null
                                // <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginTop: '40px' }}><MdSpeakerNotesOff size={80} color={'lightgrey'} /></div>
                            }

                            {receiverId && userName && (_.findIndex(chatHead, (v) => (v?.firstUser?._id === receiverId || v?.secondUser?._id === receiverId)) === -1) ?
                                <div className='users_chats' style={{ border: '3px solid #5ea29f' }}>
                                    <div className='userchat_first_div' style={{ justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <h5>{userName?.fullName}</h5>

                                        </div>
                                        <Button
                                            style={{ marginLeft: 8 }}
                                            // type={val?.pin ? 'primary' : 'default'} 
                                            shape='circle'
                                            icon={<PushpinOutlined />}
                                        />
                                    </div>
                                </div> : null
                            }
                        </div>
                    </div>

                    <div className='chat_card chat_main chat_card2'>
                        <Title className='chatHeading' level={4}>{lanKorCondition ? 'Chat' : '1:1 채팅'}</Title>

                        <div className='realChatContainer'>
                            {
                                (popupData?.chatType == 'inquiryReservation' || chatType == 'inquiryReservation')/*  && (popupData?.golfReservationId?.userId === receiverId || popupData?.userId === receiverId || popupData?.golfReservationId?.userId === user?._id || popupData?.userId === user?._id)  */ ?
                                    <>
                                        <h4 className='chat_popup'>{lanKorCondition ? 'Reservation Inquiry' : '골프예약 문의'}</h4>
                                        <div className='PaymentDiv'>
                                            <div className='PaymentDiv1'><div className='paymentTag'>{language?.GolfReservation} </div>
                                                <div style={{ marginLeft: 'auto' }}>
                                                    {selectedHeadId || receiverId ?
                                                        <>
                                                            <MdNotificationImportant onClick={() => setReportOpen(true)} className='payment_chat_icons' />
                                                            <Popconfirm placement='leftBottom' title={
                                                                <div style={{ maxWidth: 250 }}>{language?.DeleteChatRoomAlert}’{language?.ClickYesToDelete}</div>
                                                            } okText={language?.Yes} cancelText={language?.No} onConfirm={deleteChat}>
                                                                <MdOutlineLogout style={{ marginLeft: 5 }} className='payment_chat_icons' />
                                                            </Popconfirm>
                                                        </>
                                                        : null}
                                                </div>
                                            </div>
                                            <div className='PaymentDiv2'> {getRegion(popupData?.golfReservationId?.golfClub?.region || popupData?.golfClub?.region)} / {popupData?.golfReservationId?.golfClub?.clubName || popupData?.golfClub?.clubName} / {moment(popupData?.golfReservationId?.reservedDate || popupData?.reservedDate).format('YYYY-MM-DD')} {popupData?.golfReservationId?.reservedTime || popupData?.reservedTime} <br />
                                                {language?.GreenFee}: {popupData?.golfReservationId?.greenFee || popupData?.greenFee} {language?.Won} / {language?.TransferFee} : {popupData?.golfReservationId?.transferFee || popupData?.transferFee || 0} {language?.Won}
                                            </div>
                                        </div>
                                    </>
                                    :
                                    (popupData?.chatType == 'sellRequest' || chatType == 'sellRequest')/*  && (popupData?.ticketId?.userId === receiverId || popupData?.userId === receiverId)  */ ?
                                        <>
                                            <h4 className='chat_popup'>Request for Sell</h4>
                                            <div className='PaymentDiv'>
                                                <div className='PaymentDiv1'><div className='paymentTag'>
                                                    {language?.sell} </div>
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        {selectedHeadId || receiverId ?
                                                            <>
                                                                <MdNotificationImportant onClick={() => setReportOpen(true)} className='payment_chat_icons' />
                                                                <Popconfirm placement='leftBottom' title={
                                                                    <div style={{ maxWidth: 250 }}>{language?.DeleteChatRoomAlert}’{language?.ClickYesToDelete}</div>
                                                                } okText={language?.Yes} cancelText={language?.No} onConfirm={deleteChat}>
                                                                    <MdOutlineLogout style={{ marginLeft: 5 }} className='payment_chat_icons' />
                                                                </Popconfirm>
                                                            </>
                                                            : null}
                                                    </div>
                                                </div>
                                                <div className='PaymentDiv2'>{getRegion(popupData?.ticketId?.golfClub?.region || popupData?.golfClub?.region)} / {popupData?.ticketId?.golfClub?.clubName || popupData?.golfClub?.clubName} / {moment(popupData?.ticketId?.usableDate || popupData?.usableDate).format('YYYY-MM-DD HH:mm a')} <br />
                                                    {language?.MemberFee}: {popupData?.ticketId
                                                        ?.memberFee || popupData?.memberFee} {language?.KRW} / {language?.NonMemberFee} : {popupData?.ticketId
                                                            ?.NONmemberFee || popupData?.NONmemberFee} {language?.KRW} / {language?.RightPrice}: {popupData?.ticketId?.rightPrice || popupData?.rightPrice} {language?.KRW}
                                                </div>
                                            </div>
                                        </> :
                                        (popupData?.chatType == 'rentRequest' || chatType == 'rentRequest')/*  && (popupData?.ticketId?.userId === receiverId || popupData?.userId === receiverId) */ ?
                                            <>
                                                <h4 className='chat_popup'>Request for Rent</h4>
                                                <div className='PaymentDiv'>
                                                    <div className='PaymentDiv1'><div className='paymentTag'>
                                                        {language?.Rent} </div>
                                                        <div style={{ marginLeft: 'auto' }}>
                                                            {selectedHeadId || receiverId ?
                                                                <>
                                                                    <MdNotificationImportant onClick={() => setReportOpen(true)} className='payment_chat_icons' />
                                                                    <Popconfirm placement='leftBottom' title={
                                                                        <div style={{ maxWidth: 250 }}>{language?.DeleteChatRoomAlert}’{language?.ClickYesToDelete}</div>
                                                                    } okText={language?.Yes} cancelText={language?.No} onConfirm={deleteChat}>
                                                                        <MdOutlineLogout style={{ marginLeft: 5 }} className='payment_chat_icons' />
                                                                    </Popconfirm>
                                                                </>
                                                                : null}
                                                        </div>
                                                    </div>
                                                    <div className='PaymentDiv2'> {getRegion(popupData?.ticketId?.golfClub?.region || popupData?.golfClub?.region)} / {popupData?.ticketId?.golfClub?.clubName || popupData?.golfClub?.clubName} / {moment(popupData?.ticketId?.usableDate || popupData?.usableDate).format('YYYY-MM-DD HH:mm a')} <br />
                                                        {language?.MemberFee}: {popupData?.ticketId
                                                            ?.memberFee || popupData?.memberFee} {language?.KRW} / {language?.NonMemberFee} : {popupData?.ticketId
                                                                ?.NONmemberFee || popupData?.NONmemberFee} {language?.KRW} / {language?.RightPrice}: {popupData?.ticketId?.rightPrice || popupData?.rightPrice} {language?.KRW}
                                                    </div>
                                                </div>
                                            </> :
                                            (popupData?.chatType == 'participateRequest' || chatType == 'participateRequest') /* && (popupData?.ticketId?.userId === receiverId || popupData?.userId === receiverId) */ ?
                                                <>
                                                    <h4 className='chat_popup'>Request for Participation</h4>
                                                    <div className='PaymentDiv'>
                                                        <div className='PaymentDiv1'><div className='paymentTag'>
                                                            {language?.Participate} </div>
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                {selectedHeadId || receiverId ?
                                                                    <>
                                                                        <MdNotificationImportant onClick={() => setReportOpen(true)} className='payment_chat_icons' />
                                                                        <Popconfirm placement='leftBottom' title={
                                                                            <div style={{ maxWidth: 250 }}>{language?.DeleteChatRoomAlert}’{language?.ClickYesToDelete}</div>
                                                                        } okText={language?.Yes} cancelText={language?.No} onConfirm={deleteChat}>
                                                                            <MdOutlineLogout style={{ marginLeft: 5 }} className='payment_chat_icons' />
                                                                        </Popconfirm>
                                                                    </>
                                                                    : null}
                                                            </div>
                                                        </div>
                                                        <div className='PaymentDiv2'> {getRegion(popupData?.ticketId?.golfClub?.region || popupData?.golfClub?.region)} / {popupData?.ticketId?.golfClub?.clubName || popupData?.golfClub?.clubName} / {moment(popupData?.ticketId?.usableDate || popupData?.usableDate).format('YYYY-MM-DD HH:mm a')}<br />
                                                            {language?.MemberFee}: {popupData?.ticketId
                                                                ?.memberFee || popupData?.memberFee} {language?.KRW} / {language?.NonMemberFee} : {popupData?.ticketId?.NONmemberFee || popupData?.NONmemberFee} {language?.KRW} / {language?.RightPrice}: {popupData?.ticketId?.rightPrice || popupData?.rightPrice} {language?.KRW}
                                                        </div>
                                                    </div>
                                                </> :
                                                (popupData?.chatType === 'exchangeRequest' || chatType == 'exchangeRequest') /* && (popupData?.ticketId?.userId === receiverId || popupData?.userId === receiverId) */ ?
                                                    <>
                                                        <h4 className='chat_popup'>Request an Exchange</h4>
                                                        <div className='PaymentDiv'>
                                                            <div className='PaymentDiv1'><div className='paymentTag'>
                                                                {language?.Exchange} </div>
                                                                <div style={{ marginLeft: 'auto' }}>
                                                                    {selectedHeadId || receiverId ?
                                                                        <>
                                                                            <MdNotificationImportant onClick={() => setReportOpen(true)} className='payment_chat_icons' />
                                                                            <Popconfirm placement='leftBottom' title={
                                                                                <div style={{ maxWidth: 250 }}>{language?.DeleteChatRoomAlert}’{language?.ClickYesToDelete}</div>
                                                                            } okText={language?.Yes} cancelText={language?.No} onConfirm={deleteChat}>
                                                                                <MdOutlineLogout style={{ marginLeft: 5 }} className='payment_chat_icons' />
                                                                            </Popconfirm>
                                                                        </>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                            <div className='PaymentDiv2'> {getRegion(popupData?.ticketId?.golfClub?.region || popupData?.golfClub?.region)} / {popupData?.ticketId?.golfClub?.clubName || popupData?.golfClub?.clubName} / {moment(popupData?.ticketId?.usableDate || popupData?.usableDate).format('YYYY-MM-DD HH:mm a')} <br />
                                                                {language?.MemberFee}: {popupData?.ticketId?.memberFee || popupData?.memberFee} {language?.KRW} / {language?.NonMemberFee} : {popupData?.ticketId?.NONmemberFee || popupData?.NONmemberFee} {language?.KRW} / {language?.RightPrice}: {popupData?.ticketId?.rightPrice || popupData?.rightPrice} {language?.KRW}
                                                            </div>
                                                        </div>
                                                    </> :
                                                    receiverId ?
                                                        <>
                                                            <div className='PaymentDiv'>
                                                                <div className='PaymentDiv1'>
                                                                    <div className='paymentTag'>{userName?.fullName} </div>
                                                                    <div style={{ marginLeft: 'auto' }}>
                                                                        {selectedHeadId || receiverId ?
                                                                            <>
                                                                                <MdNotificationImportant onClick={() => setReportOpen(true)} className='payment_chat_icons' />
                                                                                <Popconfirm placement='leftBottom' title={
                                                                                    <div style={{ maxWidth: 250 }}>{language?.DeleteChatRoomAlert}’{language?.ClickYesToDelete}</div>
                                                                                } okText={language?.Yes} cancelText={language?.No} onConfirm={deleteChat}>
                                                                                    <MdOutlineLogout style={{ marginLeft: 5 }} className='payment_chat_icons' />
                                                                                </Popconfirm>
                                                                            </>
                                                                            : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : null
                            }
                            <Modal footer={null} title={null} open={reportOpen} onOk={handleOk} onCancel={handleCancel}>
                                <center>
                                    <h2>{language?.ReasonforReport}</h2>
                                </center>

                                <Form form={form} {...formItemLayout} requiredMark={false} name='control-hooks' onFinish={onFinish}>

                                    <Form.Item
                                        name='type'
                                        // label={language?.Type}
                                        style={{ width: '100%' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: language?.PleaseselectreportType
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder={language?.SelectaReportType}
                                            allowClear
                                        >
                                            {reportType?.map((v, i) => (<Option key={i} value={v?.eng}>{lanKorCondition ? v?.eng : v?.kr}</Option>))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name='message'
                                        label={language?.InputHere}
                                        rules={[
                                            {
                                                required: true,
                                                message: language?.PleaseInputDetails
                                            },
                                        ]}
                                    >
                                        <TextArea rows={4} />
                                    </Form.Item>

                                    <Button
                                        loading={reportLoading}
                                        className='req_exc_btn'
                                        type='primary'
                                        htmlType='submit'>{language?.Reportthismeassage}</Button>
                                </Form>
                            </Modal>

                            <div className={receiverId ? 'chatLib2' : 'chatLib'}>
                                {messageList?.length ?
                                    <ChatContainer>
                                        <MessageList>
                                            {messageList?.map((v, i) => {
                                                let createdDate
                                                let expiryDate
                                                let duration
                                                if (v?.type == 'CREATE PAYMENT REQUEST' && v?.paymentRequestId?.isCanceled == false) {
                                                    createdDate = moment(v?.paymentRequestId?.created).tz('Asia/Seoul') //todays date
                                                    expiryDate = moment(v?.paymentRequestId?.expiry).tz('Asia/Seoul') // another date
                                                    duration = createdDate.diff(expiryDate, 'hours')
                                                    // setInterval(function () {
                                                    //     duration = createdDate.diff(expiryDate, 'hours')
                                                    //     console.log('duration ***', duration)
                                                    // }, 10);
                                                }
                                                return (
                                                    <>
                                                        {v?.type == 'CREATE PAYMENT REQUEST' && v?.paymentRequestId?.isCanceled == false && v?.paymentRequestId?.isCompleted == false ?
                                                            <Message model={{
                                                                direction: _id === v?.senderId ? 'outgoing' : 'incoming',
                                                                payload:
                                                                    < Message.CustomContent
                                                                    >
                                                                        <div style={{ width: 220, fontSize: '0.8em' }}
                                                                            onClick={() => {
                                                                                v?.paymentRequestId?.userId == user?._id ? null :
                                                                                    null
                                                                            }
                                                                            }
                                                                        >
                                                                            <div className='row_content'>
                                                                                <h3>{language?.PaymentRequested} <span style={{ color: '#0090ff' }} id='countdown'>{duration}:00h</span>
                                                                                </h3>
                                                                                {
                                                                                    v?.paymentRequestId?.userId == user?._id ?
                                                                                        < div class='dropdown'>
                                                                                            <MoreOutlined className='dropbtn' style={{ fontSize: '1.8em' }} />

                                                                                            <div class='dropdown-content'>
                                                                                                <Button loading={loading} >{language?.CancelRequest}</Button>
                                                                                                {
                                                                                                    v?.paymentRequestId?.expiryExtendLimit == 1 ? null :
                                                                                                        <Button loading={loadingExtend} >{language?.ExtendRequest}</Button>
                                                                                                }
                                                                                            </div>
                                                                                        </div> : null
                                                                                }
                                                                            </div>
                                                                            <a href={v?.paymentRequestId?.payementUrlWeb}>
                                                                                <div style={{ backgroundColor: '#EAEAEA', borderRadius: 5, width: '100%', padding: 10, height: 40 }} className='row_content'>
                                                                                    <h3>{lanKorCondition ? 'Requested Amount' : "결제요청 금액"}</h3> <h3>{v?.paymentRequestId?.amount} {language?.KRW}</h3>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </Message.CustomContent>
                                                            }} >
                                                                <Message.Footer sentTime={moment(v?.created).utc().format('YYYY-MM-DD hh:mm')}></Message.Footer>
                                                            </Message>
                                                            : v?.type == 'CANCEL PAYMENT REQUEST' && v?.paymentRequestId?.isCanceled == true ?
                                                                <Message model={{
                                                                    direction: _id === v?.senderId ? 'outgoing' : 'incoming',
                                                                    payload:
                                                                        < Message.CustomContent >
                                                                            <div style={{ width: 220, fontSize: '0.8em' }} >
                                                                                <div className='row_content'>
                                                                                    <h3>Cancelled Payment requested <span style={{ color: '#0090ff' }} id='countdown'></span>
                                                                                    </h3>
                                                                                </div>
                                                                                <div style={{ backgroundColor: '#EAEAEA', borderRadius: 5, width: '100%', padding: 10, height: 40 }} className='row_content'>
                                                                                    <h3>Payment Request</h3>  <h3> <del>{v?.paymentRequestId?.amount} KRW</del></h3>
                                                                                </div>
                                                                            </div>
                                                                        </Message.CustomContent>

                                                                }} >
                                                                    <Message.Footer sentTime={moment(v?.created).utc().format('YYYY-MM-DD hh:mm')}></Message.Footer>
                                                                </Message>
                                                                : v?.type == 'PAYMENT COMPLETED' && v?.paymentRequestId?.isCanceled == false && v?.paymentRequestId?.isCompleted == true && v?.paymentRequestId?.isPending == false ?
                                                                    <Message model={{
                                                                        direction: _id === v?.senderId ? 'outgoing' : 'incoming',
                                                                        payload:
                                                                            < Message.CustomContent >
                                                                                <div style={{ width: 220, fontSize: '0.8em' }} >
                                                                                    <div className=''>
                                                                                        <h3>Payment Completed <span style={{ color: '' }} id='countdown'>{`(${moment(v?.created).utc().format('YYYY-MM-DD hh:mm')})`}</span>
                                                                                        </h3>
                                                                                        <br />

                                                                                        <h3>Payment number : <span style={{ color: '' }} id='countdown'>{(v?.paymentRequestId?.paymentKey)}</span>
                                                                                        </h3>
                                                                                        <br />
                                                                                    </div>
                                                                                    <div style={{ backgroundColor: '#EAEAEA', borderRadius: 5, width: '100%', padding: 10, height: 40 }} className='row_content'>
                                                                                        <h3>Payment Request</h3>  <h3> <del>{v?.paymentRequestId?.amount} KRW</del></h3>
                                                                                    </div>
                                                                                </div>
                                                                            </Message.CustomContent>

                                                                    }} >
                                                                        <Message.Footer sentTime={moment(v?.created).utc().format('YYYY-MM-DD hh:mm')}></Message.Footer>
                                                                    </Message>
                                                                    : v?.type == 'image' ?
                                                                        <Message type='image' model={{
                                                                            message: v?.message,
                                                                            sentTime: v?.time,
                                                                            sener: 'localSender',
                                                                            direction: _id === v?.senderId ? 'outgoing' : 'incoming',
                                                                            position: 'last',
                                                                            payload: {
                                                                                src: v?.message,
                                                                                alt: 'Chat',
                                                                                width: '220px',
                                                                                height: '220px',
                                                                            }
                                                                        }} >
                                                                            <Message.Footer sentTime={moment(v?.created).utc().format('YYYY-MM-DD hh:mm')}></Message.Footer>
                                                                        </Message>
                                                                        : v?.type == 'text' ?
                                                                            <Message model={{
                                                                                message: v?.message,
                                                                                sentTime: v?.time,
                                                                                sener: 'localSender',
                                                                                position: 'last',
                                                                                direction: _id === v?.senderId ? 'outgoing' : 'incoming'
                                                                            }} >
                                                                                <Message.Footer sentTime={moment(v?.created).utc().format('YYYY-MM-DD hh:mm')}></Message.Footer>
                                                                            </Message>
                                                                            :
                                                                            null

                                                        }
                                                    </>
                                                )
                                            })}

                                        </MessageList>
                                    </ChatContainer>
                                    : <div style={{ position: 'relative', top: '50%', left: '50%' }}>
                                        <MdSpeakerNotesOff size={80} color={'lightgrey'} />
                                        <br />
                                        {/* {lanKorCondition ? 'No chat selected' : '선택한 채팅 없음'} */}
                                    </div>}
                            </div>
                            {
                                receiverId ?
                                    <div className='chatInput'>
                                        <div style={{ display: 'flex' }}>
                                            <Input onPressEnter={sendMessage} disabled={!receiverId} /* disabled={receiverId == 'undefined' || receiverId == 'null' || null ? true : false} */ value={messageText} onChange={(e) => setMessageText(e?.target?.value)} suffix={
                                                <Button disabled={messageText?.trim()?.length ? false : true} onClick={sendMessage} size='large' style={{ backgroundColor: 'lightgrey' }}>{language?.Send}</Button>
                                            } placeholder={language?.Pleasesendamessage} size='large' />

                                        </div>
                                        {!receiverId ?
                                            null
                                            :
                                            <label for='resume' style={{ display: !selectedFile ? 'inline' : 'none' }}>
                                                <GrAttachment /> {language?.FileAttachment}
                                            </label>
                                        }

                                        <input
                                            placeholder=''
                                            type='file'
                                            accept='image/*'
                                            id='resume'
                                            style={{ display: 'none' }}
                                            // value={selectedCv}
                                            onChange={handleFileInput}
                                        />
                                        <Button disabled={selectedFile ? false : true} style={{ backgroundColor: '#5EA29F', display: selectedFile ? 'inline' : 'none', border: 'none', fontWeight: 600, color: 'white', alignItems: 'center' }} onClick={() => { setSelectedFile(null), sendImage(selectedFile) }} icon={<AiOutlineSend color='white' style={{ marginLeft: '5px', marginTop: '5px' }} />}>Add Attachement </Button>

                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Chat