import React, { useState, useEffect, useRef } from 'react'
import { Tabs, Button, Table, Card } from 'antd'
import { GET, POST } from '../../utils/apis'
import { HiPlusCircle } from "react-icons/hi"
import AVATAR from '../../assets/Avatar.png'
import moment from 'moment'
import EditPersonalInfo from './EditPersonalInfo'
import axios from 'axios'
import { errorMessage, facebookLogin, googleLogin, requiredMessage, successNotification, kakaoLogin, successMessage, isUserLoginApi, MyInfoAlert, formatTelNo } from '../../utils/helpers'
import { setTicket } from '../../Redux/actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import AddHoldingMembership from './AddHoldingMembership'
import HoldingMembershipModal from './HoldingMembershipModal'
import ParticipateModal from '../TicketShareTabs/ParticipateModal'
import RevisionHoldingModal from './RevisionHoldingModal'
import noData from '../../assets/noData.gif'
import loadingImg from '../../assets/loading.gif'
import { loginUser, removeUser } from '../../Redux/actions/authActions'
import { setRouteBreadCrumbs, clearRouteBreadCrumbs } from '../../Redux/actions/routeActions'
import EditCorporateMemberInfo from './EditCorporateMemberInfo'
import { regions, regionsKr, types, typesKr } from '../../utils/constants'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const { TabPane } = Tabs
const children = []

for (let i = 10; i < 36; i++) {
    children.push(<Option key={i?.toString(36) + i}>{i?.toString(36) + i}</Option>)
}

const data = []

const MyPageTab = (props) => {
    const { user, language, lanKorCondition } = props
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get('edit');

    const dispatch = useDispatch()
    const [showEditModal, setShowEditModal] = useState(false)
    const [showHoldingModal, setShowHoldingModal] = useState(false)
    const [showEditHolding, setshowEditHolding] = useState(false)
    const [loading, setLoading] = useState(false)
    const [ticketData, setTicketData] = useState([])
    const [editData, setEditData] = useState(null)
    const [editModal, setEditModal] = useState(false)
    const [allData, setAllData] = useState({})
    console.log('status', status)


    useEffect(() => {

        if (status) {
            setShowEditModal(true)
        }

        getTickets()
        getUser()
    }, [])


    const getTickets = () => {
        setLoading(true)
        axios.get(`${GET?.GET_TICKTS}/${user?._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    dispatch(setTicket(data?.data))
                    setLoading(false)
                    setTicketData(data?.data || [])
                }
                else {
                    setLoading(false)
                    console.log(lanKorCondition ? data?.message : data?.messageKr)
                    // errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
            }).catch((err) => {
                console.log('err', err)
                setLoading(false)
                // errorMessage( lanKorCondition ? 'Oops Something Went Wrong!':'  문제가 발생했습니다!')
            })
        // console.log('user._id', user._id)
    }

    const getUser = () => {
        axios.get(`${GET.GET_USER}/${user?._id}`)
            .then(async (res) => {
                const { data } = res
                if (data.success) {
                    // dispatch(loginUser(data.user))
                }
                else {
                    await isUserLoginApi(user?._id, false)
                    dispatch(removeUser())
                    dispatch(clearRouteBreadCrumbs())
                    history?.replace(allPaths?.LOGIN)
                }
            })
            .catch(async (e) => {
                await isUserLoginApi(user?._id, false)
                dispatch(removeUser())
                dispatch(clearRouteBreadCrumbs())
            })
    }

    const deleteTicket = (id) => {

        setLoading(true)
        let obj = {
            userId: user?._id,
            _id: id
        }
        axios.post(POST.DELETE_TICKET, obj)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                    getTickets()
                    setLoading(false)

                }
                else {
                    setLoading(false)
                    console.log(lanKorCondition ? data?.message : data?.messageKr)
                }
            }).catch((err) => {
                console.log('err', err)
                setLoading(false)
            })

    }

    console.log('user', user)
    return (
        <div className='my_page_cont'>
            <div className='top_card'>
                <div className="av_wrapper">
                    <section className="av_columns">
                        <div className="av_column">
                            <center> <img className='av_img' src={AVATAR} /></center>
                        </div>
                        <div className="av_column_mid">
                            <h2 className='userInfo_heading'>{user?.fullName && user?.fullName} {language?.UserInfo}</h2>
                            {/* 기본정보 */}
                            <div className="av_container">
                                <div className="av_layout">
                                    <h4 className="av_col av_col_main userInfo_text">
                                        {language?.PhoneNumber}
                                    </h4>
                                    <p className="av_col av_col_complementary" role="complementary">
                                        {user && user?.telNo ? formatTelNo(user?.telNo) : '---------'}
                                    </p>
                                </div>
                            </div>
                            <div className="av_container">
                                <div className="av_layout">
                                    <h4 className="av_col av_col_main userInfo_text">
                                        {language?.BirthDate} (YYYY-MM-DD)
                                    </h4>
                                    <p className="av_col av_col_complementary" role="complementary">
                                        {user && user?.DOB && moment(user?.DOB).format('YYYY-MM-DD') || '---------'}
                                    </p>
                                </div>
                            </div>
                            <div className="av_container">
                                <div className="av_layout">
                                    <h4 className="av_col av_col_main userInfo_text">
                                        {language?.addressExDetail}
                                    </h4>
                                    <p className="av_col av_col_complementary" role="complementary">
                                        {user && user?.address ? user?.address : '---------'}
                                    </p>
                                </div>
                            </div>
                            <div className="av_container">
                                <div className="av_layout">
                                    <h4 className="av_col av_col_main userInfo_text">
                                        {language?.NumberofTicketHand}
                                    </h4>
                                    <p className="av_col av_col_complementary" role="complementary" style={{ color: 'blue' }}>
                                        {ticketData && ticketData?.length}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="av_column">
                            <div className='btn_per_cont'>
                                <div>
                                    {
                                        /* !user?.userType === 'individual' */true ?
                                            <Button className='btn_per_info' onClick={() => setShowEditModal(true)}>
                                                {language?.EditPersonalInformation}
                                            </Button> :
                                            <Button className='btn_per_info_cop' onClick={() => setShowEditModal(true)}>
                                                {language?.EditPersonalInformation + 'Coprate'}
                                            </Button>
                                    }

                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </div>

            <AddHoldingMembership language={language} allData={allData} getTickets={getTickets} showHoldingModal={showHoldingModal} setShowHoldingModal={setShowHoldingModal} {...props} />
            {
                user?.userType === 'individual' ?
                    <EditPersonalInfo language={language} allData={allData} showEditModal={showEditModal} setShowEditModal={setShowEditModal} {...props} />
                    :
                    <EditCorporateMemberInfo language={language} allData={allData} showEditModal={showEditModal} setShowEditModal={setShowEditModal} {...props} />
            }

            <HoldingMembershipModal language={language} allData={allData} editData={editData} editModal={editModal} showEditHolding={showEditHolding} setshowEditHolding={setshowEditHolding} {...props} />

            <RevisionHoldingModal language={language} allData={allData} setEditModal={setEditModal} editData={editData} editModal={editModal} {...props} />


            <div className='top_card sec_panel_cont'>
                <div className='mypageBtnDiv' style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h2 >
                        {language?.MyMembership}
                    </h2>
                    <div style={{ textAlign: 'end' }} >
                        {user?._id && user?.telNo && user?.bank && user?.bankAccountNumber ?
                            <Button style={{ bottom: '0px' }} className='btn_per_info' onClick={() => setShowHoldingModal(true)}>
                                {language?.AddHoldingMembership}
                            </Button> :
                            <Button style={{ bottom: '0px' }} className='btn_per_info' onClick={() => { MyInfoAlert(language) }}>
                                {language?.AddHoldingMembership}
                            </Button>}
                    </div>
                </div>

                <div className="row">
                    {!loading ? ticketData?.length ? ticketData?.map((v, i) => {
                        return (
                            <div className="column_new_cont" >
                                <span onClick={() => {
                                    setshowEditHolding(true)
                                    setEditData(v?._id)
                                    setAllData(v)
                                }}>
                                    <div className='card_panel'>
                                        <h5>{language?.GolfClubName}</h5>
                                        <h5 className='not_bold'>{v && v?.golfClub?.clubName}</h5>
                                    </div>
                                    <div className='card_panel'>
                                        <h5>{language?.Type + ' '}</h5>
                                        <h5 className='not_bold'>{v?.membershipType ? lanKorCondition ? ' ' + v?.membershipType : typesKr[types?.indexOf(v?.membershipType)]?.typesKr : '----'}</h5>
                                    </div>
                                    <div className='card_panel'>
                                        <h5>{language?.JoiningFee}</h5>
                                        <h5 className='not_bold'>{v && v?.joiningFee}</h5>
                                    </div>
                                    <div className='card_panel'>
                                        <h5>{language?.Timeperiod}</h5>
                                        <h5 className='not_bold'>{moment(v?.joiningDate?.date1).format('YYYY-MM-DD')} / {moment(v?.joiningDate?.date2).format('YYYY-MM-DD')}</h5>
                                    </div>
                                    {/* <div className='card_panel last_des'>
                                        <h5><HiPlusCircle className='plus_icon' />{language?.TotalSeatsAvail}</h5>
                                        <h5 className='not_bold'>{v?.totalSeats - v?.usedSeats?.length}  {language?.Account}</h5>
                                    </div>
                                    <div className='card_panel '>
                                        <h5><HiPlusCircle className='plus_icon' />{language?.SeatpriceGroup}</h5>
                                        <h5 className='not_bold'>{v?.totalSeatsPrice && v?.totalSeats ? v?.totalSeatsPrice / v?.totalSeats : 0} {language?.MillonWon}</h5>
                                    </div> */}
                                    <div className='card_panel last_des'>
                                        <h5><HiPlusCircle className='plus_icon' />{language?.SpecialGiftTIKSALE}</h5>
                                        {/* <h5 className='not_bold'></h5> */}
                                    </div>
                                    <div className='card_panel last_des'>
                                        {lanKorCondition ?
                                            <h5 className='not_bold'>8 times a week ({v && v?.specialGiftWeekDay} times)/  {v && v?.specialGiftWeekDayKRW} won</h5>
                                            : <h5 className='not_bold'>주중 8 회 ({v && v?.specialGiftWeekDay} 회)/  {v && v?.specialGiftWeekDayKRW}만원</h5>
                                        }

                                    </div>
                                    <div className='card_panel last_des' style={{ textAlign: 'left' }}>
                                        {lanKorCondition ?
                                            <h5 className='not_bold'>Weekend 4 times ({v && v?.specialGiftWeekEnd} times)/ {v && v?.specialGiftWeekEndKRW} won</h5>
                                            :
                                            <h5 className='not_bold'> 주말 4 회 ({v && v?.specialGiftWeekEnd} 회)/ {v && v?.specialGiftWeekEndKRW} 만원</h5>
                                        }
                                    </div>
                                </span>
                                <div style={{ justifyContent: 'space-between', display: 'flex', paddingTop: '10px' }} className='btn_card'>
                                    <Button style={{ width: '49%' }} className='btn_exchange' onClick={() => {
                                        setAllData(v)
                                        setEditData(v && v?._id)
                                        setEditModal(true)
                                    }}>{language?.Edit}</Button>
                                    <Button style={{ width: '49%' }} loading={loading} className='btn_exchange1'
                                        onClick={() => deleteTicket(v && v?._id)}>{language?.Delete}</Button>
                                </div>
                            </div>
                        )
                    }) :
                        <div className='NodataDiv'>
                            <img src={noData} className='NodataImg' />
                            <h3>{language?.MembershipNotFound}</h3>
                        </div> :
                        <div className='cards-lodaing-div' >
                            <Card className='loading-card' loading={true} />
                            <Card className='loading-card' loading={true} />
                            <Card className='loading-card' loading={true} />
                            <Card className='loading-card' loading={true} />
                        </div>}
                </div>
            </div>
        </div >
    )
}


export default MyPageTab