import { Card } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { HiPlusCircle } from "react-icons/hi";
import noData from '../../assets/noData.gif';
import { GET } from '../../utils/apis';
import { types, typesKr, typesOnlyKr } from '../../utils/constants';
import { MyInfoAlert } from '../../utils/helpers';
import LoginAlert from '../LoginAlert/LoginAlert';
import TicketSalesInfo from '../MyPageTabs/TicketSalesInfo';
import ExchangeModal from './ExchangeModal';
import RentModal from './RentModal';
import TicketShareInfoModal from './TicketShareInfo';
import UploadTicketShareModal from './UploadTicketShareModal';

const TicketSharing = (props) => {
    const { user, sellType, language, lanKorCondition, ticketnameFilter, selectedTags, selectedClubs, selectedMemberships, ticketPrice, setclickedViewMore } = props
    const [shareTicket, setShareTicket] = useState([])
    const [loading, setLoading] = useState(false)
    const [showEditHolding, setshowEditHolding] = useState(false)
    const [editData, setEditData] = useState(null)
    const [allData, setAllData] = useState({})
    const [filteredData, setfilteredData] = useState([])
    const [text, setText] = useState(language?.VeiwMore)
    const seeLess = async () => {
        setText(language?.VeiwMore)
        getAllTicketSharing()
    }
    const seeAll = async () => {
        setclickedViewMore(true)
        setLoading(true)
        axios.get(`${GET.GET_ALL_SHARE_TICKET}/?id=${user?._id}&limit=${0}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setShareTicket(data?.data)
                }
                else {
                }
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                console.log('err', e)
            })
    }

    const getAllTicketSharing = () => {
        setLoading(true)
        axios.get(`${GET.GET_ALL_SHARE_TICKET}/?id=${user?._id}&limit=${6}`)
            .then((res) => {

                const { data } = res
                if (data.success) {
                    setShareTicket(data?.data)
                }
                else {
                }
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                console.log('err', e)
            })
    }


    useEffect(() => {
        getAllTicketSharing()
    }, [])

    const checkFilterData = (v) => {
        if (ticketnameFilter && selectedTags?.length) {
            return (v?.ticketId?.membershipCardName?.toLowerCase()?.includes(ticketnameFilter.trim().toLowerCase())) && (_.difference(selectedTags, v?.ticketId?.locationPreference).length !== selectedTags?.length)
        }
        else if (ticketnameFilter && !selectedTags?.length) {
            return v?.ticketId?.membershipCardName?.toLowerCase()?.includes(ticketnameFilter.trim().toLowerCase())
        }
        else if (!ticketnameFilter && selectedTags?.length) {
            return _.difference(selectedTags, v?.ticketId?.locationPreference).length !== selectedTags?.length
        }
        else {
            return v
        }
    }
    const checkClubsData = (v) => {
        if (selectedClubs?.length) {
            return selectedClubs?.includes(v?.ticketId?.golfClub?.clubName)
        }
        else {
            return v
        }
    }
    const checkMembershipType = (v) => {
        if (selectedMemberships?.length) {
            return selectedMemberships?.includes(v?.ticketId?.membershipType)
        }
        else {
            return v
        }
    }
    const checkTicketPrice = (v) => {
        if (ticketPrice?.start || ticketPrice?.end) {
            return (ticketPrice?.start < v?.ticketId?.rightPrice && v?.ticketId?.rightPrice < ticketPrice?.end)
        }
        else {
            return v
        }
    }

    useEffect(() => {
        setfilteredData(shareTicket.filter(checkFilterData).filter(checkClubsData).filter(checkMembershipType).filter(checkTicketPrice))
        // filterClub()
    }, [shareTicket, ticketnameFilter, selectedTags, selectedClubs, selectedMemberships])

    return (
        <div className='ticket_share_panels'>
            <div className="container_tick_share">
                <div className="layout_tick_share">
                    <div className="col_tick_share col_main_tick_share">
                        <div className='left_panel'>
                            <h2 className='col_primary'>{language?.TicketSharing}</h2>
                            <p onClick={seeAll} style={{ cursor: 'pointer', color: '#1890ff', margin: '7px 0px 0px 9px', textDecoration: 'underLine' }}>{text}</p>
                        </div>
                    </div>
                    <div className="col_tick_share col_complementary_tick_share" >
                        {/* <TicketShareInfoModal setAllData={setAllData} All_Data={allData} showTicketShare={showEditHolding} setshowTicketShare={setshowEditHolding} {...props} /> */}

                        <UploadTicketShareModal language={language} {...props} fun={getAllTicketSharing} />
                    </div>
                </div>
            </div>
            {showEditHolding ?
                <TicketSalesInfo allData={allData} editData={editData} showEditHolding={showEditHolding} setshowEditHolding={setshowEditHolding} {...props} />
                : null}

            <div className="row_new_cont">
                {!loading ?
                    filteredData?.length ?
                        filteredData && filteredData?.map((v, i) => {
                            return (
                                <div key={i} className="column_new_cont">
                                    <span onClick={() => {
                                        setAllData(v)
                                        setEditData(v?._id)
                                        setshowEditHolding(true)
                                    }}>
                                        <div className='card_panel'>
                                            <h5>{language?.GolfClubName}</h5>
                                            <h5 className='not_bold'>{v && v?.ticketId?.golfClub?.clubName}</h5>
                                        </div>
                                        <div className='card_panel'>
                                            <h5>{language?.Type}</h5>
                                            <h5 className='not_bold'>{v?.ticketId?.membershipType ? lanKorCondition ? v?.ticketId?.membershipType :
                                                typesKr[typesOnlyKr?.indexOf(v?.ticketId?.membershipType)]?.typesKr ?
                                                    typesKr[typesOnlyKr?.indexOf(v?.ticketId?.membershipType)]?.typesKr :
                                                    typesKr[types?.indexOf(v?.ticketId?.membershipType)]?.typesKr
                                                : '----'}</h5>
                                        </div>
                                        <div className='card_panel'>
                                            <h5>{language?.MemberFee}</h5>
                                            <h5 className='not_bold'>{v && v?.ticketId?.memberFee}{language?.Won}</h5>
                                        </div>
                                        <div className='card_panel'>
                                            <h5>{language?.NonMemberFee}</h5>
                                            <h5 className='not_bold'>{v && v?.ticketId?.NONmemberFee}{' '}{language?.Won}</h5>
                                        </div>
                                        <div className='card_panel'>
                                            <h5><HiPlusCircle className='plus_icon' />{language?.RightPrice}</h5>
                                            <h5 className='not_bold'>{v && v?.rightPrice}{' '} {language?.Won}</h5>
                                        </div>
                                        <div className='card_panel '>
                                            <h5><HiPlusCircle className='plus_icon' />{language?.ReservationDate}</h5>
                                            <h5 className='not_bold'>{moment(v && v?.usableDate).format('YYYY-MM-DD')}</h5>

                                        </div>
                                        <div className='card_panel last_des'>
                                            <h5><HiPlusCircle className='plus_icon' />{language?.ExpirationDate}</h5>
                                            <h5 className='not_bold'>{moment(v && v?.expirationDate).format('YYYY-MM-DD')}</h5>
                                        </div>
                                    </span>
                                    <div className='btn_card'>
                                        {user?._id ?
                                            <ExchangeModal language={language} {...props} shareTicket={v} ticketId={v?.ticketId?._id} />
                                            :
                                            <LoginAlert clas={'btn_exchange'} buttonName={language?.Exchange} {...props} />}
                                        {user?._id ? <RentModal language={language} {...props} purchaseData={v} /> :
                                            <LoginAlert clas={'btn_exchange1'} buttonName={language?.Rent} {...props} />}
                                    </div>
                                </div>
                            )
                        }) :

                        <div className='NodataDiv'>
                            <img src={noData} className='NodataImg' />
                            <h3>{language?.ThereNoTicket}</h3>
                        </div> :
                    <div className='cards-lodaing-div'>
                        <Card className='column_new_cont' loading={true} />
                        <Card className='column_new_cont' loading={true} />
                        <Card className='column_new_cont' loading={true} />
                        <Card className='column_new_cont' loading={true} />
                    </div>}

            </div>
        </div>
    )
}

export default TicketSharing