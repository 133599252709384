import React, { useEffect, useRef, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Descriptions, Input, Space, Tabs, Tag, Tooltip, Table, Typography, Popover, Badge, Select } from 'antd'
import axios from 'axios'
import { GET, POST } from '../../utils/apis';
import { RiSoundModuleFill, RiChat3Line } from 'react-icons/ri'
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io"
import { useDispatch } from 'react-redux'
import Header from '../Home/Header'
import moment from 'moment';
import { errorMessage } from '../../utils/helpers';
import { allPaths } from '../../utils/constants';

const { Search } = Input
const { TabPane } = Tabs
const { Title } = Typography

const Notification = (props) => {
    const { language, user, lanKorCondition,history } = props
    const [tags, setTags] = useState(['Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag',])
    const [inputVisible, setInputVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [searchValue, setSearchValue] = useState('')
    const [notifications, setNotifications] = useState([])

    useEffect(() => {
        if (user?._id) {
            axios.post(POST?.UPDATE_NOTIFICATION_IS_READ, { reciverId: user?._id })
                .then((res) => {
                    const { data } = res
                    if (data.success) {
                        console.log('data', data?.messsage)
                    }
                })
        }
    }, [])
    const inputRef = useRef(null)
    const editInputRef = useRef(null)
    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus()
        }
    }, [inputVisible])
    useEffect(() => {
        editInputRef.current?.focus()
    }, [inputValue])
    useEffect(() => {
        getNotifications()
    }, [])

    const getNotifications = () => {
        setLoading(true)
        axios.get(`${GET?.GET_NOTIFICATIONS}/${user?._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    // console.log('data', data)
                    setNotifications(data?.data)
                }
                else {
                    console.log('')
                    console.log('runing', data)
                }
                setLoading(false)
            })
            .catch((e) => {
                errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
                setLoading(false)
                console.log('err', e)
            })
    }
    const columns = [
        {
            Title: language?.Title,
            dataIndex: 'title',
            width: 400,
            render: (e) => <p onClick={()=>history?.push(`${allPaths?.MYPAGE}?membershipTransaction=true`)}>{e}</p>
        },
        {
            Title: language?.Date,
            dataIndex: 'created',
            width: 100,
            render: (e) => e
        }
    ]

    let filterNotifications = notifications?.length && notifications?.filter((val, i) => {
        if (searchValue.trim() === '') {
            return val
        } else if (val?.titleKr.trim()?.toLowerCase()?.includes(searchValue?.toLowerCase()?.trim())) {
            return val
        }
        else if (val?.title.trim()?.toLowerCase()?.includes(searchValue?.toLowerCase()?.trim())) {
            return val
        }
    }).map((v) => {
        return {
            title: lanKorCondition ? v?.title : v?.titleKr,
            created: moment(v?.created).format('YYYY.MM.DD'),
            description: v?.description
        }
    })

    return (
        <div className='home-main'>
            <Header {...props} />

            <center>
                <div className='div_text'>
                    <Title level={4}>{language?.Notification}</Title>
                </div>

            </center>
            <div className='card-parent2'>
                <div className='card3' style={{ marginTop: 0 }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <Input
                            placeholder={language?.ENterSearchTerm}
                            className='noti_search'
                            onChange={(e) => setSearchValue(e?.target?.value)}
                        />
                    </div>
                    <br />
                    <br />
                    <Table
                        dataSource={filterNotifications}
                        loading={loading}
                        expandable={{
                            expandedRowRender: record => <p style={{ margin: 0 }}>{record?.description}</p>,
                            rowExpandable: record => record.description,
                            expandIcon: ({ expanded, onExpand, record }) =>
                                expanded ? (
                                    <a style={{ color: 'black' }} onClick={(e) => onExpand(record, e)}>{record?.description ? <IoIosArrowUp /> : null}</a>
                                ) : (
                                    <a style={{ color: 'black' }} onClick={(e) => onExpand(record, e)}>{record?.description ? <IoIosArrowDown /> : null}</a>
                                ),
                        }}
                        showHeader={false}
                        //  pagination={false}
                        rowClassName={"tableTextColor"}
                        columns={columns}
                        scroll={{ x: 800 }}
                        pagination={true}
                        className='table-striped-rows table_home scroll_class'
                    />
                </div>
            </div >
        </div >
    )
}

export default Notification