import React, { useState, useEffect, useRef } from 'react'
import Header from '../Home/Header'
import { Input, Space, Tag, Tooltip, Tabs } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import { RiSoundModuleFill } from 'react-icons/ri';
import TicketShareTab from '../../Components/TicketShareTabs/TicketShareRec';
import TodayHotTab from '../../Components/TicketShareTabs/TodayHot';
import TicketSharing from '../../Components/TicketShareTabs/TicketSharing';
import GroupBuy from '../../Components/TicketShareTabs/GroupBuy';
import TicketSale from '../../Components/TicketShareTabs/TicketSale';
import GolfReservation from '../../Components/TicketShareTabs/GolfReservation';
import Auction from '../../Components/TicketShareTabs/Auction';
import RealTimeMarket from '../../Components/HomeTabs/RealTimeMarket';
const { Search } = Input;
const { TabPane } = Tabs;

const TicketShareRec = (props) => {
    const [tags, setTags] = useState(['Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag', 'Tag',]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');

    const inputRef = useRef(null);
    const editInputRef = useRef(null);
    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);
    useEffect(() => {
        editInputRef.current?.focus();
    }, [inputValue]);

    const handleClose = (removedTag) => {
        const newTags = tags.filter((tag) => tag !== removedTag);
        console.log(newTags);
        setTags(newTags);
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        if (inputValue && tags?.indexOf(inputValue) === -1) {
            setTags([...tags, inputValue]);
        }

        setInputVisible(false);
        setInputValue('');
    };

    const handleEditInputChange = (e) => {
        setEditInputValue(e.target.value);
    };

    const handleEditInputConfirm = () => {
        const newTags = [...tags];
        newTags[editInputIndex] = editInputValue;
        setTags(newTags);
        setEditInputIndex(-1);
        setInputValue('');
    };

    const onChange = (value) => {
        console.log(value);
    };

    const onSearch = (value) => console.log(value);
    const onChangeTab = (key) => {
        console.log(key);
    };
    return (
        <>
            <div className='home-main'>
                <Header {...props} />
                <div className='ticket_share_cont'>
                    <div className='search_filter_width'>
                        <div className='search_div'>
                            {/* <Space direction='vertical' className='space'> */}
                            <Search
                                bordered={false}
                                placeholder='Please enter a search term.'
                                onSearch={onSearch}
                                className='search_width'
                                style={{
                                    backgroundColor: 'white',
                                    borderRadius: 2
                                }}
                            />
                            {/* </Space> */}
                        </div>
                        <div className='tags_filter1'>
                            <span style={{ fontWeight: 'bold' }}>
                                <RiSoundModuleFill className='filter_icon' />
                                Filter
                            </span>
                            <span className='tags_width scroll_class'>
                                {tags?.map((tag, index) => {
                                    if (editInputIndex === index) {
                                        return (
                                            <Input
                                                ref={editInputRef}
                                                key={tag}
                                                size='small'
                                                className='tag-input'
                                                value={editInputValue}
                                                onChange={handleEditInputChange}
                                                onBlur={handleEditInputConfirm}
                                                onPressEnter={handleEditInputConfirm}
                                            />
                                        );
                                    }
                                    const isLongTag = tag?.length > 20;
                                    const tagElem = (
                                        <Tag
                                            className='edit-tag'
                                            key={tag}
                                            closable={index !== 0}
                                            onClose={() => handleClose(tag)}
                                        >
                                            <span
                                                onDoubleClick={(e) => {
                                                    if (index !== 0) {
                                                        setEditInputIndex(index);
                                                        setEditInputValue(tag);
                                                        e.preventDefault();
                                                    }
                                                }}
                                            >
                                                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                                            </span>
                                        </Tag>
                                    );
                                    return isLongTag ? (
                                        <Tooltip title={tag} key={tag}>
                                            {tagElem}
                                        </Tooltip>
                                    ) : (
                                        tagElem
                                    );
                                })}
                                {inputVisible && (
                                    <Input
                                        ref={inputRef}
                                        type='text'
                                        size='small'
                                        className='tag-input'
                                        value={inputValue}
                                        onChange={handleInputChange}
                                        onBlur={handleInputConfirm}
                                        onPressEnter={handleInputConfirm}
                                    />
                                )}
                                {!inputVisible && (
                                    <Tag className='site-tag-plus' onClick={showInput}>
                                        <PlusOutlined /> New Tag
                                    </Tag>
                                )}
                            </span>
                        </div>
                    </div>
                </div>
                <div className='tab_con'>
                    <Tabs className='tab_menu ' defaultActiveKey='1' onChange={onChangeTab} centered={true}>
                        <TabPane className='pri_color' tab='Real-time market' key='1'>
                            <RealTimeMarket {...props}/>
                        </TabPane>
                        <TabPane tab='Ticket Share Recommendations' key='2'>
                            <TicketShareTab {...props}/>
                        </TabPane>
                        <TabPane tab='TODAY HOT' key='3'>
                            <TodayHotTab {...props}/>
                        </TabPane>
                        <TabPane tab='Ticket Sharing' key='4'>
                            <TicketSharing {...props} />
                        </TabPane>
                        <TabPane tab='Group Buy' key='5'>
                            <GroupBuy {...props}/>
                        </TabPane>
                        <TabPane tab='Ticket Sale' key='6'>
                            <TicketSale {...props}/>
                        </TabPane>
                        <TabPane tab='Golf Reservation' key='7'>
                            <GolfReservation {...props} />
                        </TabPane>
                        <TabPane tab='Auction' key='8'>
                            <Auction {...props}/>
                        </TabPane>
                    </Tabs>
                </div>
            </div >
        </>

    )
}

export default TicketShareRec