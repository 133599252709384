import { Card } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { HiPlusCircle } from 'react-icons/hi'
import { LoginAlert } from '..'
import noData from '../../assets/noData.gif'
import { GET } from '../../utils/apis'
import { types, typesKr, typesOnlyKr } from '../../utils/constants'
import GroupBuyInfo from './GroupBuyInfo'
import GroupBuyParticipate from './GroupBuyParticipate'
import UploadGroupBuyModal from './UploadGroupBuyModal'
const { Meta } = Card;

const GroupBuy = (props) => {
    const { ticketPrice, user, language, ticketnameFilter, lanKorCondition, selectedTags, selectedClubs, selectedMemberships, setclickedViewMore } = props
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [groupBuyInfoData, setGroupBuyInfoData] = useState({})
    const [groupBuyData, setGroupBuyData] = useState([])
    const [filteredData, setfilteredData] = useState([])
    const [text, setText] = useState(language?.VeiwMore)
    const seeAll = async () => {
        setclickedViewMore(true)
        setLoading(true)
        axios.get(`${GET.GET_ALL_GROUP_BUYS}/?id=${user?._id}&limit=${0}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setGroupBuyData(data?.data || [])
                }
                else {
                    console.log(data?.message)
                }
                setLoading(false)
            }).catch((err) => {
                console.log(err, 'err')
                setLoading(false)
            })
    }

    useEffect(() => {
        getAllGroupBuys()
    }, [])

    const showGroupInfo = (v) => {
        setGroupBuyInfoData(v)
        setOpen(true)
    }

    const CancelGroupInfo = () => {
        setOpen(false)
    }
    const getAllGroupBuys = () => {
        setLoading(true)
        axios.get(`${GET.GET_ALL_GROUP_BUYS}/?id=${user?._id}&limit=${6}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setGroupBuyData(data?.data || [])
                }
                else {
                    console.log(data?.message)
                }
                setLoading(false)
            }).catch((err) => {
                console.log(err, 'err')
                setLoading(false)
            })
    }

    const checkFilterData = (v) => {
        if (ticketnameFilter && selectedTags?.length) {
            return (v?.membershipCardName?.toLowerCase()?.includes(ticketnameFilter.trim().toLowerCase())) && (_.difference(selectedTags, v?.locationPreference)?.length !== selectedTags?.length)
        }
        else if (ticketnameFilter && !selectedTags?.length) {
            return v?.membershipCardName?.toLowerCase()?.includes(ticketnameFilter.trim().toLowerCase())
        }
        else if (!ticketnameFilter && selectedTags?.length) {
            return _.difference(selectedTags, v?.locationPreference).length !== selectedTags?.length
        }
        else {
            return v
        }
    }

    const checkClubsData = (v) => {
        if (selectedClubs?.length) {
            return selectedClubs.includes(v?.golfClub?.clubName)
        }
        else {
            return v
        }
    }
    const checkMembershipType = (v) => {
        if (selectedMemberships?.length) {
            return selectedMemberships.includes(v?.membershipType)
        }
        else {
            return v
        }
    }

    const checkTicketPrice = (v) => {
        if (ticketPrice?.start && ticketPrice?.end) {
            return (ticketPrice?.start < v?.rightPrice && v?.rightPrice < ticketPrice?.end)
        }
        else {
            return v
        }
    }

    useEffect(() => {
        setfilteredData(groupBuyData?.filter(checkFilterData)?.filter(checkClubsData)?.filter(checkMembershipType)?.filter(checkTicketPrice))
    }, [groupBuyData, ticketnameFilter, selectedTags, selectedClubs, selectedMemberships])

    return (
        <div className='ticket_share_panels'>
            <div className='container_tick_share'>
                <div className='layout_tick_share'>
                    <div className='col_tick_share col_main_tick_share'>
                        <div className='left_panel'>
                            <h2 className='col_primary'>{language?.GroupBuy}</h2>
                            <p onClick={seeAll} style={{ cursor: 'pointer', color: '#1890ff', margin: '7px 0px 0px 9px', textDecoration: 'underLine' }}>{text}</p>
                        </div>
                    </div>
                    {
                        user?._id ?
                            <div className='col_tick_share col_complementary_tick_share' >
                                <UploadGroupBuyModal language={language} {...props} />
                            </div> :
                            <div className='col_tick_share col_complementary_tick_share' >
                                <LoginAlert clas={'upload_sharing_btn'} buttonName={language?.UploadGroupBuy} {...props} />

                            </div>
                    }
                </div>
            </div>
            <GroupBuyInfo language={language} CancelGroupInfo={CancelGroupInfo} GroupBuyInfoData={groupBuyInfoData} open={open} {...props} />

            <div className='row_new_cont'>
                {!loading ? filteredData?.length ? filteredData?.map((v, i) => (
                    <div className='column_new_cont'>
                        <span onClick={() => showGroupInfo(v)} >
                            <div className='card_panel'>
                                <h5>{language?.GolfClub}</h5>
                                <h5 className='not_bold'>{v?.golfClub?.clubName}</h5>
                            </div>
                            <div className='card_panel'>
                                <h5>{language?.Type}</h5>
                                <h5 className='not_bold'>{v?.membershipType ? lanKorCondition ? v?.membershipType :
                                    typesKr[typesOnlyKr?.indexOf(v?.membershipType)]?.typesKr ?
                                        typesKr[typesOnlyKr?.indexOf(v?.membershipType)]?.typesKr :
                                        typesKr[types?.indexOf(v?.membershipType)]?.typesKr
                                    : '----'}</h5>
                            </div>
                            <div className='card_panel'>
                                <h5>{language?.JoiningFee}</h5>
                                <h5 className='not_bold'>{v?.joiningFee}{language?.Won}</h5>
                            </div>
                            <div className='card_panel'>
                                <h5>{language?.Timeperiod}</h5>
                                <h5 className='not_bold'>{`${moment(v && v?.joiningDate?.date1).format('YYYY-MM-DD')} ~ ${moment(v && v?.joiningDate?.date2).format('YYYY-MM-DD')}`}</h5>
                            </div>
                            <div className='card_panel last_des'>
                                <h5><HiPlusCircle className='plus_icon' />{language?.TotalSeatsAvail}</h5>
                                <h5 className='not_bold'>{v?.totalSeats - v?.usedSeats?.length} {language?.account}</h5>
                            </div>
                            <div className='card_panel '>
                                <h5 style={{ width: '85px' }}><HiPlusCircle className='plus_icon' />{language?.SeatpriceGroup}</h5>
                                <h5 className='not_bold'>{v?.totalSeatsPrice / v?.totalSeats} {language?.won}</h5>
                            </div>
                            <div className='card_panel last_des'>
                                <h5 ><HiPlusCircle className='plus_icon' />{language?.SpecialGiftTIKSALE}</h5>
                            </div>
                            <div className='card_panel last_des'>
                                {lanKorCondition ?
                                    <h5 className='not_bold'> Weekday {v && v?.specialGiftWeekDay}  회/  {v && v?.specialGiftWeekDayKRW} KRW</h5>
                                    :
                                    <h5 className='not_bold'>주중{v && v?.specialGiftWeekDay}회/ {v && v?.specialGiftWeekDayKRW}만원</h5>
                                }
                            </div>
                            <div className='card_panel last_des' style={{ textAlign: 'left' }}>
                                {lanKorCondition ?
                                    <h5 className='not_bold'> Weekend {v && v?.specialGiftWeekEnd}  회/{v && v?.specialGiftWeekEndKRW}KRW</h5>
                                    :
                                    <h5 className='not_bold'> 주말  {v && v?.specialGiftWeekEnd}회/{v && v?.specialGiftWeekEndKRW}만원</h5>
                                }
                            </div>
                        </span>
                        <div className='btn_card'>
                            {user?._id ?
                                <GroupBuyParticipate language={language} participateData={v} {...props} /> :
                                <LoginAlert clas={'btn_participate'} buttonName={language?.Participate} {...props} />

                            }
                        </div>
                    </div>
                )) :
                    <div className='NodataDiv'>
                        <img src={noData} className='NodataImg' />
                        <h3>{language?.ThereNoTicket}</h3>
                    </div>
                    : <div className='cards-lodaing-div'>
                        <Card className='column_new_cont' loading={true} />
                        <Card className='column_new_cont' loading={true} />
                        <Card className='column_new_cont' loading={true} />
                        <Card className='column_new_cont' loading={true} />
                    </div>
                }
            </div >
        </div >
    )
}

export default GroupBuy