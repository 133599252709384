import React, { useState, useEffect, useRef } from 'react'
import { Cascader, Input, Space, Tag, Tooltip, Popover, Badge, Tabs } from 'antd'
import { BorderBottomOutlined, PlusOutlined } from '@ant-design/icons'
import { RiSoundModuleFill, RiChat3Line } from 'react-icons/ri'
import SharedRentalAuction from './SharedRentalAuction'
import GolfReservationAuction from './GolfReservationAuction'
import axios from 'axios'
import { GET } from '../../utils/apis'
import { errorMessage, successMessage } from '../../utils/helpers'
import SaleAuction from './SaleAuction'

const { TabPane } = Tabs

const Auction = (props) => {
    let { user, language, lanKorCondition, adminBankDetail, setclickedViewMore, selectedTags, ticketnameFilter, selectedClubs } = props
    const [auction, setAuction] = useState([])
    const [auctionSale, setAuctionSale] = useState([])
    const [auctionGolf, setAuctionGolf] = useState([])
    const [filteredAuction, setFilteredAuction] = useState([])
    const [filteredAuctionSale, setFilteredAuctionSale] = useState([])
    const [filteredAuctionGolf, setFilteredAuctionGolf] = useState([])
    const [auctionRentalLoading, setAuctionRentalLoading] = useState(false)
    const [auctionSaleLoading, setAuctionSaleLoading] = useState(false)
    const [auctionGolfLoading, setAuctionGolfLoading] = useState(false)
    const [ticket, setTicket] = useState([])
    let userId = user?._id

    const onChangeTab = (key) => {
    }

    let getAuction = () => {
        setAuctionRentalLoading(true)
        setAuctionSaleLoading(true)
        setAuctionGolfLoading(true)
        axios.get(`${GET.GET_ALL_RENTAL_AUCTION}/${userId}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setAuction(data?.data)
                }
                else {
                    console.log('runing')
                }
                setAuctionRentalLoading(false)
            })
            .catch((e) => {
                console.log('err', e)
                setAuctionRentalLoading(false)

            })

        axios.get(`${GET.GET_ALL_SALE_AUCTION}/${userId}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setAuctionSale(data?.data)
                }
                else {
                    console.log('runing')
                }

                setAuctionSaleLoading(false)
            })
            .catch((e) => {
                console.log('err', e)

                setAuctionSaleLoading(false)
            })

        axios.get(`${GET.GET_ALL_GOLF_AUCTION}/${userId}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    setAuctionGolf(data?.data)
                }
                else {
                    console.log('runing')
                }

                setAuctionGolfLoading(false)
            })
            .catch((e) => {
                console.log('err', e)
                setAuctionGolfLoading(false)

            })

        if (user?._id) {
            axios.get(`${GET.GET_TICKTS}/${user?._id}`)
                .then((res) => {
                    const { data } = res
                    if (data.success) {
                        setTicket(data?.data)
                    }
                    else {
                        errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                    }
                })
                .catch((e) => {
                    errorMessage(lanKorCondition ? 'Oops Something Went Wrong!' : '  문제가 발생했습니다!')
                })
        }

    }

    const checkFilterData = (v) => {
        if (ticketnameFilter && selectedTags?.length) {
            return (v?.ticketId?.membershipCardName?.toLowerCase()?.includes(ticketnameFilter.trim().toLowerCase())) && (_.difference(selectedTags, v?.ticketId?.locationPreference).length !== selectedTags?.length)
        }
        else if (ticketnameFilter && !selectedTags?.length) {
            return v?.ticketId?.membershipCardName?.toLowerCase()?.includes(ticketnameFilter.trim().toLowerCase())
        }
        else if (!ticketnameFilter && selectedTags?.length) {
            return _.difference(selectedTags, v?.ticketId?.locationPreference).length !== selectedTags?.length
        }
        else {
            return v
        }
    }
    const checkClubsData = (v) => {
        if (selectedClubs?.length) {
            return selectedClubs.includes(v?.ticketId?.golfClub?.clubName)
        }
        else {
            return v
        }
    }

    console.log('auction**', auctionSale)
    useEffect(() => {
        getAuction()
    }, [])

    useEffect(() => {
        setFilteredAuction(auction?.filter(checkFilterData).filter(checkClubsData))
    }, [auction,ticketnameFilter, selectedTags, selectedClubs])

    useEffect(() => {
        setFilteredAuctionSale(auctionSale?.filter(checkFilterData).filter(checkClubsData))
    }, [auctionSale,ticketnameFilter, selectedTags, selectedClubs])

    // useEffect(() => {
    //     setFilteredAuctionGolf(auctionGolf.filter(checkFilterData).filter(checkClubsData).filter(checkMembershipType).filter(checkTicketPrice).filter(checkSellType))

    // }, [auctionGolf])

    return (
        <div className='tab_con_auction'>
            <Tabs tabBarGutter={20} className='tab_menu ' defaultActiveKey='1' onChange={onChangeTab} centered={true}>
                <TabPane className='pri_color' tab={language?.SharedRentalAuction} key='1'>
                    <SharedRentalAuction adminBankDetail={adminBankDetail} language={language} loader={auctionRentalLoading} fun={getAuction} {...props} auction={filteredAuction} ticket={ticket} />
                </TabPane>
                <TabPane tab={language?.SaleAuction} key='2'>
                    <SaleAuction adminBankDetail={adminBankDetail} language={language} loader={auctionSaleLoading} fun={getAuction} {...props} auction={filteredAuctionSale} ticket={ticket} />
                </TabPane>
                <TabPane tab={language?.GolfReservationAuction} key='3'>
                    <GolfReservationAuction adminBankDetail={adminBankDetail} language={language} loader={auctionGolfLoading} fun={getAuction} {...props} auction={auctionGolf} ticket={ticket} />
                </TabPane>
            </Tabs>
        </div >
    )
}

export default Auction