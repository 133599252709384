import { BREAD_CRUMBS,CLEAR_BREAD_CRUMBS } from '../types'

const setRouteBreadCrumbs = (breadCrumbs) => {
    return {
        type: BREAD_CRUMBS,
        breadCrumbs
    }
}

const clearRouteBreadCrumbs = () => {
    return {
        type: CLEAR_BREAD_CRUMBS
    }
}

export {
    setRouteBreadCrumbs,
    clearRouteBreadCrumbs
}