import { Button, Input, Modal, Radio, Select, Tag } from 'antd'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { loginUser } from '../../Redux/actions/authActions';
import { setPriceFilter } from '../../Redux/actions/ticketPriceActions';
import { setSellType } from '../../Redux/actions/sellTypeAction'

import { ADMIN, AUTH, GET } from '../../utils/apis';
import { regions, regionsKr } from '../../utils/constants';
import { errorMessage, successMessage } from '../../utils/helpers';
import englishLanguage from '../../utils/englishLanguage.json'
const { CheckableTag } = Tag;

const Filter = (props) => {
    const { ticketPrice, transactionType, sellType, setTransactionType, endPrice, setEndPrice, startPrice, setStartPrice, language, user, showFillOut, golfClub, setShowFillOut, selectedTags, setSelectedTags, selectedClubs, setSelectedClubs, lanKorCondition } = props
    const [loading, setLoading] = useState(false)
    const [selectedClubsId, setSelectedClubsId] = useState(user?._id ? user?.preferredGolfCourse : [])
    const dispatch = useDispatch()
    const handleChange = (tag, checked) => {
        const nextSelectedTags = checked
            ? [...selectedTags, tag]
            : selectedTags.filter((t) => t !== tag);
        setSelectedTags(nextSelectedTags);
    };

    const editPreferredRegion = () => {
        setLoading(true)
        let obj = {
            _id: user?._id,
            preferredRegion: selectedTags,
        }

        if ((Number(endPrice) || Number(startPrice)) && Number(startPrice) >= Number(endPrice)) {
            setLoading(false)

            return errorMessage('Start price must be greater than End price')
        }

        axios.post(AUTH?.EDIT_PREFERRED_REGION, obj)
            .then((res) => {
                const { data } = res

                if (data.success) {
                    getUser()
                    dispatch(setPriceFilter({
                        start: Number(startPrice),
                        end: Number(endPrice)
                    }))
                    dispatch(setSellType({
                        transactionType: transactionType
                    }))
                    successMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                else {
                    // setLoading(false)
                    setSelectedTags(user?.preferredRegion)
                    errorMessage(lanKorCondition ? data?.message : data?.messageKr)
                }
                setLoading(false)
            })
            .catch((e) => {
                console.log('e ', e)
                setSelectedTags(user?.preferredRegion)
                setLoading(false)
                errorMessage(lanKorCondition ? e?.message : e?.messageKr)

            })
    }

    const getUser = () => {
        axios.get(`${GET.GET_USER}/${user?._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    updateUser(data?.user)
                    setShowFillOut(false)
                }
            })
            .catch((e) => {
            })
    }

    const updateUser = (data) => {
        dispatch(loginUser(data))
    }

    const handleCancel = () => {
        setSelectedTags(user?.preferredRegion)
        setEndPrice(ticketPrice?.end)
        setStartPrice(ticketPrice?.start)
        setTransactionType(sellType?.transactionType)
        setShowFillOut(false)
    }

    const onSave = () => {
        editPreferredRegion()
    }

    const onChangeTransaction = (e) => {
        setTransactionType(e.target.value);
    }

    const handleChange1 = (tag, checked) => {
        const nextSelectedTags = checked
            ? [...transactionType, tag]
            : transactionType.filter((t) => t !== tag);
        setTransactionType(nextSelectedTags)
    }

    useEffect(() => {
        setSelectedClubs(_.map(golfClub?.filter((v, i) => selectedClubsId.includes(v?._id)), 'clubName'))
    }, [golfClub])

    return (
        <Modal
            open={showFillOut}
            onCancel={handleCancel}
            footer={null}
            // className='fillout'
            className='responsiveModal fillout'
        >
            <h3 style={{ textAlign: 'center' }}>{lanKorCondition ? 'Filter' : '필터'} </h3>
            <br />
            <div className='column_div' style={{ display: 'flex' }}>
                <h5 style={{ marginTop: 5 }} className='filter_labels Text_filter_labels '>{lanKorCondition ? 'All Tickets' : '거래 전체'}</h5>

                <div style={{ marginTop: 5 }} >
                    <CheckableTag
                        className='tags_class'
                        key={englishLanguage[0]?.NormalSell}
                        checked={transactionType.indexOf(englishLanguage[0]?.NormalSell) > -1}
                        onChange={(checked) => handleChange1(englishLanguage[0]?.NormalSell, checked)}
                    >
                        {language?.NormalSell}
                    </CheckableTag>
                    <CheckableTag
                        className='tags_class'
                        key={englishLanguage[0]?.GroupBuySell}
                        checked={transactionType.indexOf(englishLanguage[0]?.GroupBuySell) > -1}
                        onChange={(checked) => handleChange1(englishLanguage[0]?.GroupBuySell, checked)}
                    >
                        {language?.GroupBuySell}
                    </CheckableTag>
                </div>
            </div>
            <br />
            <br />
            <div className='column_div' style={{ display: 'flex' }}>

                <h5 style={{ marginTop: 5 }} className='filter_labels  Text_filter_labels '>{lanKorCondition ? 'All Price' : '금액 전체'}</h5>
                <div style={{ marginTop: 5 }}>
                    <Input
                        suffix={language?.KRW}
                        style={{ width: '45%', marginRight: '2%' }}
                        maxLength={9}
                        value={startPrice}
                        onChange={(e) => setStartPrice(e.target.value?.replace(/[^0-9]/g, ''))}
                    />
                    <Input
                        suffix={language?.KRW}
                        style={{ width: '45%' }}
                        maxLength={9}
                        value={endPrice}
                        onChange={(e) => setEndPrice(e.target.value?.replace(/[^0-9]/g, ''))}
                    />
                </div>
            </div>

            <br />
            <br />
            <div className='column_div' style={{ display: 'flex' }}>

                <h5 style={{ marginTop: 5 }} className='filter_labels  Text_filter_labels '>{lanKorCondition ? 'All Region' : '지역 전체'}</h5>
                <div style={{ marginTop: 5 }}>
                    {regions?.slice(1)?.map((tag, i) => {
                        return (
                            <CheckableTag
                                style={{ marginTop: '10px' }}
                                key={tag}
                                checked={selectedTags?.indexOf(tag) > -1}
                                onChange={(checked) => handleChange(tag, checked)}
                            >
                                {lanKorCondition ? tag : regionsKr[regions?.indexOf(tag)]?.TitleKr}
                            </CheckableTag>
                        )
                    })}
                </div>
            </div>


            <br />
            <br />

            <Button loading={loading} className='req_exc_btn' onClick={onSave} style={{ color: 'white' }}>{lanKorCondition ? 'OK ' : '확인'}</Button>



        </Modal >
    )
}

export default Filter