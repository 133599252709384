const LOGIN_USER = 'LOGIN_USER'
const REMOVE_USER = 'REMOVE_USER'
const ALL_USERS = 'ALL_USERS'
const PIN = 'PIN'
const SELECTED_CHAT = 'SELECTED_CHAT'
const CHAT_HEADER = 'CHAT_HEADER'
const TICKET = 'TICKET'
const LANGUAGE='LANGUAGE'
const BREAD_CRUMBS='BREADCRUMBS'
const PRICE_FILTER='PRICE_FILTER'
const SELL_TYPE='SELL_TYPE'
const CLEAR_BREAD_CRUMBS ='CLEAR_BREAD_CRUMBS'
const TEMP_USER = 'TEMP_USER'
const SELECTED_CHAT_HEAD = 'SELECTED_CHAT_HEAD'
export {
    LOGIN_USER,
    REMOVE_USER,
    ALL_USERS,
    SELECTED_CHAT,
    PIN,
    TICKET,
    LANGUAGE,
    CHAT_HEADER,
    BREAD_CRUMBS,
    PRICE_FILTER,
    SELL_TYPE,
    CLEAR_BREAD_CRUMBS,
    TEMP_USER,
    SELECTED_CHAT_HEAD
}
