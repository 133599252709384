import { Divider, Modal, Typography } from 'antd';
import React from 'react';
const { Text } = Typography;




const SharedRentalAuctionModal = (props) => {

    const { user, setshowEditHolding, tittle,showEditHolding } = props
    const handleCancel = () => {
        setshowEditHolding(false)
    }

    return (
        <>
            <Modal
                open={showEditHolding}
                // open={true}
                onCancel={handleCancel}
                className='paymentModal'
                footer={null}
            >

                <div className='modal_main_div'>
                    <center><h2>{tittle}</h2></center>
                    <h2>Info</h2>

                    <div className='static_text'>
                        <table>
                            <tr>
                                <td style={{ minWidth: 100, maxWidth: 'auto' }}><Text>Golf club name</Text></td>
                                <td className='values_style'><Text>East Valley CC</Text></td>
                            </tr>
                            <tr>
                                <td style={{ minWidth: 100, maxWidth: 'auto' }}><Text>Type</Text></td>
                                <td className='values_style'><Text>Company</Text></td>
                            </tr>
                            <tr>
                                <td style={{ minWidth: 100, maxWidth: 'auto' }}><Text>Joining Fee</Text></td>
                                <td className='values_style'><Text>1 billion won</Text></td>
                            </tr>
                            <tr>
                                <td style={{ minWidth: 100, maxWidth: 'auto' }}><Text>Joining date</Text></td>
                                <td className='values_style'><Text>YY.MM.DD ~ YY.MM.DD</Text></td>
                            </tr>
                            <tr>
                                <td style={{ minWidth: 100, maxWidth: 'auto' }}><Text>Special gift</Text></td>
                                <td className='values_style'><Text>8 times a week | 9 milion won 4 Weekends | 10 million won</Text></td>
                            </tr>
                            <tr>
                                <td style={{ minWidth: 100, maxWidth: 'auto' }}><Text>Fee</Text></td>
                                <td className='values_style'><Text>Member | 9 million won Non-members | 10 million won</Text></td>
                            </tr>
                        </table>
                    </div>

                    <Divider style={{ backgroundColor: '#bbb', marginBottom: 20 }} />

                    <h2>Golf Course Info</h2>

                    <div className='static_text'>
                        <table>
                            <tr>
                                <td style={{}}><Text>Address</Text></td>
                                <td className='values_style'><Text>Address</Text></td>
                            </tr>
                            <tr>
                                <td style={{}}><Text>Opening date</Text></td>
                                <td className='values_style'><Text>Opening date</Text></td>
                            </tr>
                            <tr>
                                <td style={{}}><Text>Green Fee</Text></td>
                                <td className='values_style'><Text>Green Fee</Text></td>
                            </tr>
                            <tr>
                                <td style={{}}><Text>Caddy Fee</Text></td>
                                <td className='values_style'><Text>Caddy Fee</Text></td>
                            </tr>
                            <tr>
                                <td style={{}}><Text>Phone number</Text></td>
                                <td className='values_style'><Text>Phone number</Text></td>
                            </tr>
                            <tr>
                                <td style={{}}><Text>Homepage</Text></td>
                                <td className='values_style'><Text>Homepage</Text></td>
                            </tr>
                        </table>
                    </div>

                    <Divider style={{ backgroundColor: '#bbb', marginBottom: 20 }} />
                    <h2>Additional benefits</h2>

                    <div className='static_text'>

                        <table>
                            <tr>
                                <td style={{ width: 'auto' }}><Text>Benefits</Text></td>
                                <td style={{ width: 'auto' }}><Text></Text></td>
                            </tr>
                        </table>
                    </div>
                </div>

            </Modal>

        </>
    )
}

export default SharedRentalAuctionModal